import React from "react";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  MuiThemeProvider,
  Tab,
  AppBar,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import OpportunitySearch from "./OpportunitySearch.jsx";
import OpportunitiesName from "./OpportunitiesName.jsx";
import { localTheme, useStyles } from "./Opportunity";

export default function Opportunity() {
  const classes = useStyles();
  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MuiThemeProvider theme={localTheme}>
      <div className={classes.root}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="body1" className={classes.headTypo}>
              Search Opportunities
            </Typography>
            <TabContext value={value}>
              <AppBar
                position="static"
                elevation={0}
                className={classes.appbar}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  className={classes.tabList}
                >
                  <Tab label="Opportunity Search" value="0" />
                  <Tab label="Request by Name/Spaciality" value="1" />
                </TabList>
              </AppBar>
              <TabPanel value="0">
                <OpportunitySearch />
              </TabPanel>
              <TabPanel value="1">
                <OpportunitiesName />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </div>
    </MuiThemeProvider>
  );
}
