export const Permissions = (theme) => ({
    productHead: {
        backgroundColor: '#e1e1e1',
        textAlign: 'center',
        position: "sticky",
        left: 0,
        zIndex: 10,
        minWidth: 113,
    },
    taskHead: {
        backgroundColor: '#e1e1e1',
        textAlign: 'center',
        position: "sticky",
        left: 113,
        zIndex: 20,
        minWidth: 299,
    },
    rolesHead: {
        minWidth: 269,
        textAlign: 'center',
        borderLeft: '1px solid #f3f2f2',
        borderRight: '1px solid #f3f2f2',
        backgroundColor: '#d1d1d1',
        padding: 8
    },
    productSubHead: {
        top: '39px',
        backgroundColor: '#d1d1d1',
        padding: 20,
    },
    container: {
        textAlign: 'center',
        fontSize: 12,
    },
    round: {
        padding: '0px 5px',
        marginLeft: 5,
        color: '#ffffff',
        background: '#4165a6',
        borderRadius: '50%',
        width: 100,
        height: 100,
        cursor: 'pointer',
    },
    wanish: {
        display: 'none',
    },
    edit: {
        color: '#882e47',
        fontSize: 10,
        fontWeight: 600,
        background: 'none!important',
        textDecoration: 'underline !important',
        cursor: 'pointer',
    },
    cancelButton: {
        color: '#313387',
    },
    checkBox: {
        '& .MuiSvgIcon-root': {
            height: 22,
            color: '#4165a6',
        },
    },
    unCheck: {
        '& .MuiSvgIcon-root': {
            height: 22,
            color: '#cccaca',
        },
    },
    productTitle: {
        fontWeight: 600,
    },
    icons: {
        color: '#959595',
    },
    next_left: {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        height: 52,
    },
    even_left: {
        border: '1px solid #ccc',
        backgroundColor: '#f5f5f5',
        height: 52,
    },
    td: {
        borderTop: '1px solid #ccc',
        padding: 8,
        width: 100,
        borderBottom: '1px solid #ccc',
        borderRight: '1px solid #ccc',
    },
    eventd: {
        borderTop: '1px solid #ccc',
        padding: 8,
        width: 100,
        borderBottom: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        backgroundColor: '#f5f5f5',
    },
    thead: {
        backgroundColor: '#e1e1e1',
        width: 334,
        padding: 8,
        textAlign: 'center',
        borderRight: '1px solid #f3f2f2',
        borderLeft: '1px solid #f3f2f2',
    },
    subhead: {
        backgroundColor: '#d1d1d1',
        width: 267,
        padding: 8,
        textAlign: 'center',
        borderRight: '1px solid #e9e9e9',
    },
    loading: {
        marginLeft: '45%',
    },
    paper: {
        height: '100vh',
        position: 'relative',
    },
    mainTitle: {
        padding: '5px 0 8px 28px',
        color: '#000',
        fontSize: 20,
        fontWeight: '700',
    },
    formControl: {
        '& .MuiInputBase-input': {
            backgroundColor: '#fff',
            fontSize: 13,
        },
        '& .MuiTypography-body1': {
            fontSize: 13,
            fontWeight: '500',
        },
        '& .MuiSvgIcon-fontSizeSmall': {
            fontSize: '1.1rem',
        },
        width: '78%',
        padding: '5px 0px 8px 25px',
        fontSize: 13,
        fontWeight: '500',
    },
    initialLabel: {
        padding: '16px 16px 8px 0px',
        marginTop: 0,
        color: '#000',
        fontSize: 'small',
        fontWeight: '500',
    },
    label: {
        padding: '0px 16px 8px 0px',
        marginTop: 20,
        color: '#000',
        fontSize: 'small',
        fontWeight: '500',
    },
    saveBtn: {
        float: 'right',
        width: '15%',
        position: 'absolute',
        bottom: 0,
        marginTop: 15,
    },
    saveDisableBtn: {
        float: 'right',
        width: '15%',
        position: 'absolute',
        bottom: 0,
        marginTop: 15,
        opacity: '0.5',
    },
    secondTable: {
        borderCollapse: 'seperate',
        borderSpacing: 0,
        width: '100%',
        border: '1px solid #ddd',
        // overflow: 'auto'
    },
    mainDiv: {
        [theme.breakpoints.between(1280, 1500)]: {
            width: 1203,
        },
        [theme.breakpoints.down('md')]: {
            width: 1200,
        },
        [theme.breakpoints.down('sm')]: {
            width: 1270,
        },
        [theme.breakpoints.between(1500, 1607.95)]: {
            width: 1513,
        },
        [theme.breakpoints.between(1600, 1708)]: {
            width: 1520,
        },
    },
    leftDiv: {
        width: '35%',
        float: 'left',
    },
    leftTable: {
        // borderLeft: '1px solid #a09d9d',
        borderCollapse: 'seperate',
        color: '#555',
        borderSpacing: 0,
        // tableLayout: 'fixed',
    },
    tableHead: {
        zIndex: 1,
        // position: 'sticky',
    },
    products: {
        border: '1px solid #e9e9e9',
        textAlign: 'center',
        padding: 2,
        zIndex: 1,
        left: 0,
        position: 'sticky',
        backgroundColor: 'white'
    },
    rightTable: {
        width: '65%',
        float: 'right',
        overflow: 'auto',
    },
});

export default Permissions;