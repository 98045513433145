import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
  Button,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  fetchDispositionKeywords,
  addDispositions,
} from "../../../redux/sims/actions";
import { BootstrapInput, useStyles } from "./Despositions";

const Despositions = ({
  newSim,
  dispositions,
  fetchDispositionKeywords,
  addDispositions,
  takeSim,
}) => {
  useEffect(() => {
    fetchDispositionKeywords();
  }, [fetchDispositionKeywords]);

  const [data, setData] = useState(newSim);
  const [state, setState] = useState({
    callType: newSim.dispositions.callType,
    primaryDisposition: newSim.dispositions.primaryDisposition,
    secondaryDisposition: newSim.dispositions.secondaryDisposition,
    callStatus: newSim.dispositions.callStatus,
    agentActionTakenSummary: newSim.dispositions.agentActionTakenSummary,
    warmTransferredTo: newSim.dispositions.warmTransferredTo,
    languageLineUsed: newSim.dispositions.languageLineUsed,
    consumerReasonForCallingSummary:
      newSim.dispositions.consumerReasonForCallingSummary,
    miscNotes: newSim.dispositions.miscNotes,
  });

  const setResult = (characterData) => {
    setData((prevData) => ({ ...prevData, ...characterData }));
  };

  const updateData = () => {
    const res = { ...newSim };
    res.dispositions.callType = state.callType;
    res.dispositions.primaryDisposition = state.primaryDisposition;
    res.dispositions.secondaryDisposition = state.secondaryDisposition;
    res.dispositions.callStatus = state.callStatus;
    res.dispositions.agentActionTakenSummary = state.agentActionTakenSummary;
    res.dispositions.warmTransferredTo = state.warmTransferredTo;
    res.dispositions.languageLineUsed = state.languageLineUsed;
    res.dispositions.consumerReasonForCallingSummary =
      state.consumerReasonForCallingSummary;
    res.dispositions.miscNotes = state.miscNotes;
    setResult(res);
  };

  const handleChange = (event) => {
    const word =
      event.target.name.charAt(0).toLowerCase() +
      event.target.name.substring(1);
    const name = word.replace(/[^a-zA-Z]/g, "");
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const save = async () => {
    updateData();
    await addDispositions(data);
  };

  const getValue = (field) => {
    let value = "";
    switch (field) {
      case "Call Type":
        value = state.callType;
        break;
      case "Primary Disposition":
        value = state.primaryDisposition;
        break;
      case "Secondary Disposition":
        value = state.secondaryDisposition;
        break;
      case "Call Status":
        value = state.callStatus;
        break;
      case "Agent Action Taken Summary":
        value = state.agentActionTakenSummary;
        break;
      case "Warm Transferred To":
        value = state.warmTransferredTo;
        break;
      case "Language Line Used":
        value = state.languageLineUsed;
        break;
      case "Consumer Reason For Calling - Summary":
        value = state.consumerReasonForCallingSummary;
        break;
      case "Misc. Notes":
        value = state.miscNotes;
        break;
      default:
        value = state.callType;
    }
    return value;
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            {Object.entries(dispositions).map(([key, data]) => (
              <Grid item xs={12} className={classes.grid} key={data.id}>
                <Grid item xs={5} className={classes.labelGrid}>
                  <InputLabel className={classes.fieldLable}>
                    {data.fieldTitle}
                  </InputLabel>
                </Grid>
                {data.fieldType === "selection" ? (
                  <Grid item xs={7}>
                    <NativeSelect
                      className={classes.margin}
                      name={data.fieldTitle}
                      value={getValue(data.fieldTitle)}
                      onChange={handleChange}
                      variant="outlined"
                      required
                      input={<BootstrapInput />}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {Object.entries(data.fieldOption).map(([key, value]) => (
                        <option name={data.fieldTitle} value={value} key={key}>
                          {value}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                ) : (
                  <div>
                    <TextField
                      className={
                        takeSim ? classes.takeSimTextArea : classes.textArea
                      }
                      key={key}
                      required
                      variant="outlined"
                      id={data.id}
                      rows={data.fieldTitle === "Warm Transferred To" ? 1 : 4}
                      name={data.fieldTitle}
                      value={getValue(data.fieldTitle)}
                      onChange={handleChange}
                      multiline
                    />
                  </div>
                )}
              </Grid>
            ))}
            <Button variant="contained" color="primary" onClick={save}>
              Save
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
Despositions.propTypes = {
  newSim: PropTypes.object,
  dispositions: PropTypes.array,
  fetchDispositionKeywords: PropTypes.func,
  addDispositions: PropTypes.func,
  takeSim: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  dispositions: state.sim.dispositions,
  newSim: state.sim.newSim,
});

const mapDispatchToProps = { fetchDispositionKeywords, addDispositions };

export default connect(mapStateToProps, mapDispatchToProps)(Despositions);
