import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Divider, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import Avatars from "./Avatars.jsx";
// import redux action for sim step
import {
  increaseStep,
  decreaseStep,
  addAvatarDetails,
} from "../../../redux/sims/actions";
import { saveAvatarDetails } from "../../../services/result.service";
import { useStyles } from "./SelectAvatars";

const getBackground = (charachter) => {
  let background = "";
  switch (charachter) {
    case "coach":
      background = "#b7d6f6";
      break;
    case "agent":
      background = "#f7d8c4";
      break;
    case "customer":
      background = "#f7d8e1";
      break;
    case "other":
      background = "#abe0c7";
      break;
    default:
      background = "#882647";
  }
  return background;
};

const SelectAvatars = ({
  newSim,
  increaseStep,
  decreaseStep,
  addAvatarDetails,
}) => {
  const classes = useStyles();
  const [data, setData] = useState(newSim);
  const [allPresent, setAllPresent] = useState(false);
  const updateData = (characterData) => {
    const sim = { ...data };
    for (const singleChar of sim.sim_characters) {
      if (singleChar.type === characterData.type) {
        singleChar.name = characterData.name;
        singleChar.voice = characterData.voice;
        singleChar.avatar = characterData.avatar;
        singleChar.masked_type = characterData.masked_type;
      }
    }
    setData(sim);
  };

  const invalid = () => {
    setAllPresent(false);
  };

  useEffect(() => {
    for (const [key, value] of Object.entries(data.sim_characters)) {
      if (value.name === "" || value.voice === "" || !key) {
        setAllPresent(false);
        return;
      }
    }
    setAllPresent(true);
  }, [data]);
  const handleNext = async () => {
    for (const singleCharacter of data.sim_characters) {
      singleCharacter.sim_id = data.id;
    }
    const simCharacters = data.sim_characters;
    const requestData = { sim_characters: simCharacters };
    const response = await saveAvatarDetails(requestData);
    await addAvatarDetails(response);
    increaseStep();
  };
  return (
    <Paper elevation={0}>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant="h6" component="div">
          Select Avatars
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          className={classes.backBtn}
          onClick={() => decreaseStep()}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.nextBtn}
          onClick={handleNext}
          disabled={!allPresent}
        >
          NEXT
        </Button>
      </Toolbar>
      <Divider />
      <Grid
        container
        spacing={0}
        justify="space-evenly"
        className={classes.gridContainer}
      >
        {data.sim_characters.map((character) => (
          <Grid item key={character.type}>
            <Avatars
              bgColor={getBackground(character.type)}
              data={character}
              updateData={updateData}
              invalid={invalid}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

SelectAvatars.propTypes = {
  newSim: PropTypes.object,
  increaseStep: PropTypes.func,
  decreaseStep: PropTypes.func,
  addAvatarDetails: PropTypes.func,
};
const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
});

const mapDispatchToProps = { increaseStep, decreaseStep, addAvatarDetails };

export default connect(mapStateToProps, mapDispatchToProps)(SelectAvatars);
