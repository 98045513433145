import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Grid,
  Paper,
  Typography,
  InputLabel,
  TextField,
  TextareaAutosize,
  NativeSelect,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  addGroup,
  fetchEmployees,
} from '../../redux/admin/action';
import { deleteGroup, fetchUserByGroup } from '../../services/result.service';
import TagInput from '../settings/GroupTagInput';
import { permissionCheck } from '../../utils/permissionCheck';
import AdminsPermissions from '../../permissions/admin';
import CreateGroupStyle from './CreateGroup';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 13,
    fontWeight: '500',
    padding: 10,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
const CreateGroup = ({
  group, addGroup, fetchEmployees, employees, createGroup, prop,
}) => {
  const useStyles = makeStyles((theme) => CreateGroupStyle(theme));
  const classes = useStyles();
  const [deleteOpenDialog, setDeleteOpenDialog] = useState(false);
  const [groupUsers, setGroupUsers] = useState('');
  const [state, setState] = useState({
    name: prop ? prop.name : group.name,
    owner_id: prop ? prop.owner_id : '',
    description: prop ? prop.description : group.description,
  });
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMesage, setAlertMessage] = React.useState('');
  const [disableSave, setDisableSave] = React.useState(false);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  useEffect(() => {
    if (prop) {
      fetchUserByGroup(prop._id).then((res) => {
        setGroupUsers(res.data);
      });
    }
  }, [prop]);
  const handleChange = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  const saveGroup = async () => {
    setDisableSave(true);
    if (prop) {
      state._id = prop._id;
      await addGroup(state);
    } else {
      await addGroup(state);
    }
    createGroup(false);
  };
  // To set the initial owner_id
  if (employees.length > 0 && state.owner_id === '') {
    setState({
      ...state,
      owner_id: employees[0]._id,
    });
  }
  const handleDelete = () => {
    setDeleteOpenDialog(true);
  };
  // Method to close delete dialog bix
  const handleDeleteClose = () => {
    setDeleteOpenDialog(false);
  };

  // Method to delete group
  const deleteConfirm = async () => {
    setDeleteOpenDialog(false);
    const deleteGroupstatus = await deleteGroup({ id: prop._id });
    if (!deleteGroupstatus.data.success) {
      setAlertMessage(deleteGroupstatus.data.message);
      setOpenAlert(true);
    } else {
      createGroup(false);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setAlertMessage('');
    createGroup(false);
  };
  return (
    <Paper elevation={2} className={classes.paper}>
      {openAlert ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={handleAlertClose}
          className={classes.errorAlert}
        >
          {alertMesage}
        </Alert>
      ) : null}
      <Typography className={classes.mainTitle}>
        {prop ? 'Update Group' : ' Create New Group' }
        {prop
          ? (
            <Button
              variant="contained"
              size="small"
              disableElevation
              className={classes.deleteBtn}
              onClick={() => handleDelete()}
            >
              <DeleteIcon
                fontSize="small"
                className={classes.deleteIcon}
              />
              {' '}
              DELETE
            </Button>
          ) : ''}

      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <form className={classes.formControl}>
            <InputLabel className={classes.initialLabel}>Group Name</InputLabel>
            <TextField
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: 'string',
              }}
              variant="outlined"
              size="small"
              fullWidth
              name="name"
              value={state.name}
              error={!state.name}
              onChange={handleChange}
            />
            <InputLabel className={classes.label}>Group Owner</InputLabel>
            <NativeSelect
              className={classes.select}
              name="owner_id"
              fullWidth
              value={state.owner_id}
              onChange={handleChange}
              variant="outlined"
              required
              input={<BootstrapInput />}
              IconComponent={KeyboardArrowDownIcon}
            >
              {employees && employees.map((emp) => (
                <option value={emp._id} key={emp._id}>
                  {`${emp.first_name} ${emp.last_name}`}
                </option>
              ))}
            </NativeSelect>
            <InputLabel className={classes.label}>Description</InputLabel>
            <TextareaAutosize
              aria-label="other reason"
              rowsMin={12}
              name="description"
              value={state.description}
              className={classes.description}
              onChange={handleChange}
            />
            <Button
              className={classes.saveBtn}
              variant="contained"
              color="primary"
              onClick={saveGroup}
              disabled={!(state.name && state.owner_id) || disableSave}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className={classes.saveBtn}
              disableElevation
              color="primary"
              onClick={() => createGroup(false)}
            >
              CANCEL
            </Button>
          </form>
        </Grid>
        {prop
          ? (
            <Grid item xs={4}>
              <Typography className={classes.memberTitle}>
                Members
              </Typography>
              {(employees.length && groupUsers)
                ? (
                  <TagInput
                    groupUsers={groupUsers && groupUsers.length ? groupUsers[0] : {}}
                    employees={employees}
                    prop={prop}
                  />
                ) : null}
            </Grid>
          ) : ''}

      </Grid>
      <Dialog
        open={deleteOpenDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Group</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this group
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Tooltip
            title={
            !permissionCheck(
              AdminsPermissions.groups.delete.task,
              AdminsPermissions.groups.delete.type,
            )
              ? "You don't have permission to delete groups"
              : ''
            }
            placement="top"
          >
            <Button
              onClick={() => deleteConfirm()}
              autoFocus
              className={permissionCheck(
                AdminsPermissions.groups.delete.task,
                AdminsPermissions.groups.delete.type,
              ) ? classes.delete : classes.deleteDisable}
            >
              Delete
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>

    </Paper>
  );
};
CreateGroup.propTypes = {
  employees: PropTypes.array,
  group: PropTypes.object,
  addGroup: PropTypes.func,
  fetchEmployees: PropTypes.func,
  createGroup: PropTypes.func,
  prop: PropTypes.object,
};

const mapStateToProps = (state) => ({
  group: state.admin.group,
  employees: state.admin.employees ? state.admin.employees : [],
});
const mapDispatchToProps = { addGroup, fetchEmployees, fetchUserByGroup };

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
