export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const FETCH_STATS = 'FETCH_STATS';
export const FETCH_CURRENT = 'FETCH_CURRENT';
export const FETCH_KEYWORD = 'FETCH_KEYWORD';
export const FETCH_LEVEL_CLIPS = 'FETCH_LEVEL_CLIPS';
export const FETCH_KBCA = 'FETCH_KBCA';
export const ADD_BASIC_INFORMATION = 'ADD_BASIC_INFORMATION';
export const INCREASE_NEWSIM_STEP = 'INCREASE_NEWSIM_STEP';
export const DECREASE_NEWSIM_STEP = 'DECREASE_NEWSIM_STEP';
export const ADD_AVATAR_DETAILS = 'ADD_AVATAR_DETAILS';
export const INITIALIZE_NEWSIM = 'INITIALIZE_NEWSIM';
export const ADD_KEYWORD = 'ADD_KEYWORD';
export const FETCH_CHARACTERS = 'FETCH_CHARACTERS';
export const ADD_LEVELS = 'ADD_LEVELS';
export const FETCH_TAKESIM = 'FETCH_TAKESIM';
export const FETCH_SCREENING_KEYWORD = 'FETCH_SCREENING_KEYWORD';
export const FETCH_INSURANCES = 'FETCH_INSURANCES';
export const FETCH_PHYSICIAN_MAP_FORM = 'FETCH_PHYSICIAN_MAP_FORM';
export const SELECTED_KBCA = 'SELECTED_KBCA';
export const FETCH_DISPOSITIONS = 'FETCH_DISPOSITIONS';
export const ADD_DISPOSITIONS = 'ADD_DISPOSITIONS';
export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const SELECTED_ARTICLE = 'SELECTED_ARTICLE';
export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const FETCH_OPPORTUNITY = 'FETCH_OPPORTUNITY';
export const SAVE_OPPORTUNITY = 'SAVE_OPPORTUNITY';
export const FETCH_OPPBYNAME = 'FETCH_OPPBYNAME';
export const FETCH_USERDATA = 'FETCH_USERDATA';
export const FETCH_TOTAL_ASSIGNMENTS = 'FETCH_TOTAL_ASSIGNMENTS';
export const FETCH_EMPLOYEE_LIST = 'FETCH_EMPLOYEE_LIST';
export const FETCH_MEMBERS_LIST = 'FETCH_MEMBERS_LIST';
export const FETCH_ASSIGNMENTS = 'FETCH_ASSIGNMENTS';
export const FETCH_PERMISSIONS_LIST = 'FETCH_PERMISSIONS_LIST';
export const FETCH_GROUPS_LIST = 'FETCH_GROUPS_LIST';
export const FETCH_SELECTION_DATA = 'FETCH_SELECTION_DATA';
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT';
export const CHANGE_PRODUCT_ACCESS = 'CHANGE_PRODUCT_ACCESS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_SIM_DETAILS = 'FETCH_SIM_DETAILS';
export const SET_EDIT_SIM_STEP = 'SET_EDIT_SIM_STEP';
export const UPLOAD_LEVELS = 'UPLOAD_LEVELS';
export const SAVE_ELAPSED_TIME = 'SAVE_ELAPSED_TIME';
export const GENERATE_SIM = 'GENERATE_SIM';
export const FETCH_USERS = 'FETCH_USERS';
export const ADD_NEW_LEVELS = 'ADD_NEW_LEVELS';
export const ADD_CLIP_AUDIO = 'ADD_CLIP_AUDIO';
export const FETCH_SIM_LEVEL_USER_RESPONSE = 'FETCH_SIM_LEVEL_USER_RESPONSE';
export const FETCH_USER_FINAL_SCORE_RESPONSE = 'FETCH_USER_FINAL_SCORE_RESPONSE';
export const FETCH_SAVED_KEYWORDS = 'FETCH_SAVED_KEYWORDS';
export const RESET = 'RESET';
export const SAVE_CASE_VALUES = 'SAVE_CASE_VALUES';
export const SAVE_USER = 'SAVE_USER';
export const SET_PASS_FAIL_CRITERIEA = 'SET_PASS_FAIL_CRITERIEA';
export const FETCH_AUTO_POPULATE = 'FETCH_AUTOPOPULATE';
export const FETCH_GROUP_LIST = 'FETCH_GROUP_LIST';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const NOTES_SAVE = 'NOTES_SAVE';
export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_CURRENT_STATUS = 'FETCH_CURRENT_STATUS';
export const DELETE_PREVIOUS_ASSIGNMENT_DETAILS = 'DELETE_PREVIOUS_ASSIGNMENT_DETAILS';
export const TOGGLE_RESULT_BUTTONS = 'TOGGLE_RESULT_BUTTONS';
export const SAVE_SIM_TITLE = 'SAVE_SIM_TITLE';
export const SELECTED_SIM = 'SELECTED_SIM';
export const CURRENT_AUDIO_PLAYING = 'CURRENT_AUDIO_PLAYING';
export const AUDIO_PLAY = 'AUDIO_PLAY';
export const ADD_ASSESSOR_NOTES = 'ADD_ASSESSOR_NOTES';
export const SAVE_ASSESSOR_NOTES = 'SAVE_ASSESSOR_NOTES';
export const SHOW_REVIEWER_BUTTON = 'SHOW_REVIEWER_BUTTON';
export const FETCH_SCORES = 'FETCH_SCORES';
export const ALL_LEVEL_AGENT_AUDIO_PROCESS = 'ALL_LEVEL_AGENT_AUDIO_PROCESS';
export const ADD_SCREEN_RECORDER_BLOB = 'ADD_SCREEN_RECORDER_BLOB';
export const SET_EDIT_SIM_DETAILS_STEP = 'SET_EDIT_SIM_DETAILS_STEP';
export const RESET_STATS = 'RESET_STATS';
export const SAVE_ASSIGNMENT_NOTES = 'SAVE_ASSIGNMENT_NOTES';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const UPDATE_CURRENT_CLIP = 'UPDATE_CURRENT_CLIP';
export const RESTART_LEVEL_IN_TAKE_SIM = 'RESTART_LEVEL_IN_TAKE_SIM';
export const ENABLE_VIDEO_RECORDING = 'ENABLE_VIDEO_RECORDING';
export const RESET_VIDEO_STATS = 'RESET_VIDEO_STATS';
export const VIDEO_UPLOAD_STATUS = 'VIDEO_UPLOAD_STATUS';
