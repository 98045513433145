/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent,
  DialogContentText, Divider, DialogActions,
  Button, TextField,
} from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import {
  fetchAllCaseId,
  fetchCaseDetails,
  sfReset,
  setDataLoaded,
} from '../../../redux/sf/actions';

const LoadCase = ({
  showModal,
  setShowModal,
  caseIds,
  fetchAllCaseId,
  fetchCaseDetails,
  caseDetails,
  status,
  sfReset,
  setDataLoaded,
}) => {
  const [open, setOpen] = useState(showModal);
  const [caseId, setCaseId] = useState(null);
  const [loadSuccess, setLoadSuccess] = useState(null);

  useEffect(() => {
    fetchAllCaseId();
  }, [fetchAllCaseId]);

  useEffect(() => {
    setLoadSuccess(null);
  }, [caseIds]);

  const handleClose = () => {
    setOpen(false);
    setShowModal(false);
  };
  const handleChange = (event) => {
    setCaseId(event.target.value);
  };
  const handleLoad = () => {
    if (caseId) {
      sfReset();
      fetchCaseDetails(parseInt(caseId));
    }
  };
  useEffect(() => {
    if (status === 200 && Object.keys(caseDetails).length > 3) {
      setLoadSuccess(true);
      setDataLoaded(true);
    } else if (status === 500) {
      setLoadSuccess(false);
      setDataLoaded(false);
    }
  }, [status, caseDetails, setDataLoaded]);
  if (loadSuccess) {
    return (
      <Alert
        onClose={() => {
          setShowModal(false);
          sfReset();
        }}
        variant="filled"
        severity="success"
      >
        Case Details loaded successfully — check it out!
      </Alert>
    );
  }
  if (loadSuccess === false) {
    return (
      <Alert onClose={() => setShowModal(false)} variant="filled" severity="error" style={{ backgroundColor: '#600727' }}>
        Case Details not loaded — Please try another case_number!
      </Alert>
    );
  }
  return (
    <div>
      <Dialog open={open} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Load Case Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select case number to load its full details
          </DialogContentText>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={caseIds.map((option) => option.caseNumber.toString())}
            value={caseId}
            onChange={(event, newValue) => {
              setCaseId(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} type="number" label="case_number" margin="normal" variant="outlined" value={caseId} onChange={handleChange} />
            )}
          />
          { }
          <Divider variant="fullWidth" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#313387' }}>
            Cancel
          </Button>
          <Button onClick={() => handleLoad()} color="primary">
            Load
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

LoadCase.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  caseIds: PropTypes.array,
  fetchAllCaseId: PropTypes.func,
  caseDetails: PropTypes.object,
  status: PropTypes.number,
  fetchCaseDetails: PropTypes.func,
  sfReset: PropTypes.func,
  setDataLoaded: PropTypes.func,
};
const mapStateToProps = (state) => ({
  caseIds: state.sf.caseIds,
  caseDetails: state.sf.caseDetails,
  status: state.sf.status,
});

const mapDispatchToProps = {
  fetchAllCaseId, fetchCaseDetails, sfReset, setDataLoaded,
};

// connect salesforce redux state

export default connect(mapStateToProps, mapDispatchToProps)(LoadCase);
