import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {
  Grid,
  NativeSelect,
  FormControl,
  Divider,
  Radio,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import StyledLink from "../common/StyledLink";
import {
  addBasicInfo,
  increaseStep,
  fetchProductList,
  fetchSimDetails,
  reset,
} from "../../redux/sims/actions";
import TagsInput from 'react-tagsinput-special';
import { sfReset } from "../../redux/sf/actions";
import { useBasicInfoStyles, BootstrapInput } from "./BasicInfo";
import { removePdf } from "../../services/result.service";

const useQuery = () => new URLSearchParams(useLocation().search);

const BasicInfo = ({
  newSim,
  addBasicInfo,
  increaseStep,
  fetchProductList,
  products,
  fetchSimDetails,
  reset,
  sfReset,
}) => {
  const query = useQuery();
  const classes = useBasicInfoStyles();
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [links, setLinks] = React.useState([]);
  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);
  const [data, setData] = useState({
    ...newSim,
    createdOn: moment().format("MMM DD YYYY"),
  });
  const [state, setState] = useState({
    title: newSim.title,
    description: newSim.description,
    brief_description: newSim.brief_description,
    product_id: newSim.product_id,
    sim_type: newSim.sim_type,
    auto_populate: newSim.auto_populate,
    real_time_feedback: newSim.real_time_feedback,
    admin_note: newSim.admin_note,
    draft: newSim.draft,
  });
  useEffect(() => {
    if (query.get("simId")) {
      fetchSimDetails(query.get("simId"));
    } else if (newSim.id) {
      fetchSimDetails(newSim.id);
    } else if (!data.id) {
      reset();
      sfReset();
    }
  }, [fetchSimDetails, reset]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      title: newSim.title,
      description: newSim.description,
      brief_description: newSim.brief_description,
      product_id: newSim.product_id,
      sim_type: newSim.sim_type,
      auto_populate: newSim.auto_populate,
      real_time_feedback: newSim.real_time_feedback,
      admin_note: newSim.admin_note,
      draft: newSim.draft,
    }));
    if (newSim?.documents?.length) {
      setSelectedFile(newSim.documents);
    }
    if (newSim?.hot_links?.length) {
      setLinks(newSim.hot_links);
    }
    setData(newSim);
    if(newSim.id && !localStorage.getItem("sims_id")) {
      localStorage.setItem('sims_id', newSim.id);
    }
  }, [newSim, products]);
  const updateData = (characterData) => {
    setData((prevData) => ({ ...prevData, ...characterData }));
  };
  
  const handleChange = async (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const handleProductChange = async (evt) => {
    const { value } = evt.target;
    const requestData = { ...state };
    requestData.product_id = value;
    for (const product of products) {
      if (product._id === value) {
        requestData.sim_type = product.name === "Training SIM" ? "training" : "hiring";
      }
    }
    setState(requestData);
    if (data.id) {
      const formData = new FormData();
      requestData._id = data.id;
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
    updateData(requestData);
  }

  const validate = async (evt) => {
    if (state.title) {
      const res = { selectSimType: {} };
      res.title = state.title;
      res.description = state.description;
      res.brief_description = state.brief_description;
      res.product_id = state.product_id;
      res.sim_type = state.sim_type;
      res.auto_populate = state.auto_populate;
      res.real_time_feedback = state.real_time_feedback;
      res.draft = state.draft;
      res.admin_note = state.admin_note;
      updateData(res);
      if (evt.target.name === 'title' || evt.target.name === 'description' || evt.target.name === 'brief_description' ||
        evt.target.name === 'admin_note') {
        const { value } = evt.target;
        const requestData = {};
        const formData = new FormData();
        if (data.id) {
          requestData._id = data.id;
        }
        const currentState = { ...state, [evt.target.name]: value };
        requestData.title = currentState.title;
        requestData.description = currentState.description;
        requestData.brief_description = currentState.brief_description;
        requestData.admin_note = currentState.admin_note;
        const details = JSON.stringify(requestData);
        formData.append("data", details);
        await addBasicInfo(formData, requestData);
      }
    }
  };

  const handleCheckboxChange = async (evt) => {
    setState({ ...state, [evt.target.name]: evt.target.checked });
    if (data.id) {
      const requestData = {};
      const formData = new FormData();
      requestData._id = data.id;
      const currentState = { ...state, [evt.target.name]: evt.target.checked };
      requestData.auto_populate = currentState.auto_populate;
      requestData.real_time_feedback = currentState.real_time_feedback;
      requestData.draft = currentState.draft;
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
  };

  const handleNext = async () => {
    setUploadInProgress(true);
    const requestData = {};
    const formData = new FormData();
    if (data.id) {
      requestData._id = data.id;
    }
    requestData.title = data.title;
    requestData.description = data.description;
    requestData.brief_description = data.brief_description;
    requestData.sim_type = data.sim_type;
    requestData.auto_populate = data.auto_populate;
    requestData.real_time_feedback = data.real_time_feedback;
    requestData.draft = data.draft;
    requestData.product_id =
      state.product_id === "" ? products[0]._id : state.product_id;
    requestData.admin_note = state.admin_note;
   // requestData.hot_links =  links;
    const details = JSON.stringify(requestData);
    formData.append("data", details);
    await addBasicInfo(formData, requestData);
    setUploadInProgress(false);
    increaseStep();
  };
  const fileSelected = async (evt) => {
    const docs = selectedFile.concat(evt.target.files[0]);
    setSelectedFile(docs);
    if (data.id) {
      if (newSim?.documents?.length) {
        newSim.documents = docs;
      }
      const requestData = {};
      requestData._id = data.id;
      const formData = new FormData();
      formData.append('file', evt.target.files[0]);
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
  };
  const removeDocument = async (file) => {
    const array = [...selectedFile]; // make a separate copy of the array
    const index = file._id
      ? selectedFile.findIndex((sf) => sf._id === file._id)
      : selectedFile.findIndex((sf) => sf.name === file.name);

    if (index !== -1) {
      array.splice(index, 1);
      setSelectedFile(array);
      if(data.id) {
        newSim.documents = array
      }
      if (file._id) {
        await removePdf(data.id, file._id);
      }
    }
  };
 
  const linkChange = async (e) => {
    setLinks(e);
    if (data.id) {
      const requestData = {};
      requestData._id = data.id;
      requestData.hot_links = e;
      const formData = new FormData();
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
  };
  return (
    <Paper elevation={0}>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Basic Information
        </Typography>

        <StyledLink to="/sims">
          <Button
            variant="contained"
            className={classes.cancel}
            disableElevation
          >
            CANCEL
          </Button>
        </StyledLink>
        <Button
          variant="contained"
          color="primary"
          disabled={!(state.title && state.description)}
          className={classes.nextButton}
          disableElevation
          onClick={handleNext}
        >
          {uploadInProgress && selectedFile.length ? (
            <CircularProgress color="#FFFFFF" size={25} />
          ) : (
            "NEXT"
          )}
        </Button>
      </Toolbar>
      <Divider />

      <form className={classes.root}>
        <div>
          <Grid className={classes.formHeight} container>
            <Grid className={classes.colBorderRight} item xs={5}>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Title
                </InputLabel>
                <TextField
                  required
                  variant="outlined"
                  name="title"
                  size="small"
                  error={!state.title}
                  fullWidth
                  onBlur={validate}
                  value={state.title}
                  className={classes.margin}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Description
                </InputLabel>
                <TextField
                  className={classes.margin}
                  fullWidth
                  name="description"
                  required
                  multiline
                  error={!state.description}
                  rows={6}
                  variant="outlined"
                  onBlur={validate}
                  value={state.description}
                  onChange={handleChange}
                />
              </div>

              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Brief Description
                </InputLabel>
                <TextField
                  name="brief_description"
                  onBlur={validate}
                  onChange={handleChange}
                  className={classes.margin}
                  fullWidth
                  multiline
                  rows={12}
                  variant="outlined"
                  value={state.brief_description}
                />
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Admin Notes
                </InputLabel>
                <TextField
                  className={classes.margin}
                  fullWidth
                  name="admin_note"
                  multiline
                  rows={6}
                  variant="outlined"
                  onBlur={validate}
                  value={state.admin_note}
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid className={classes.colBorderLeft} item xs={4}>
              <div className={classes.paper}>
                <InputLabel htmlFor="product" className={classes.inputLable}>
                  Product
                </InputLabel>
                <NativeSelect
                  className={classes.select}
                  name="product_id"
                  value={state.product_id}
                  onChange={(evt) => handleProductChange(evt)}
                  variant="outlined"
                  required
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {Object.entries(products).map(([key, product]) => (
                    <option name={product.name} value={product._id} key={key}>
                      {product.name}
                    </option>
                  ))}
                </NativeSelect>
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="sim-type" className={classes.inputLable}>
                  Select Sim Type
                </InputLabel>
                <Radio
                  className={classes.radio}
                  checked={state.sim_type === "hiring"}
                  name="sim_type"
                  value="hiring"
                  onBlur={validate}
                  color="primary"
                  inputProps={{ "aria-label": "Hiring" }}
                />
                Hiring (One Level sim)
                <Radio
                  checked={state.sim_type !== "hiring"}
                  value="training"
                  color="primary"
                  onBlur={validate}
                  name="sim_type"
                  inputProps={{ "aria-label": "Training" }}
                />
                Training (Multi Level sim)
              </div>
              <div className={classes.paper}>
                <div className={classes.border}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={state.auto_populate}
                            checked={state.auto_populate}
                            onBlur={validate}
                            name="auto_populate"
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Enable auto-populate"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="real-time-feedback"
                            name="real_time_feedback"
                            checked={state.real_time_feedback}
                            onBlur={validate}
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Enable Real-time feedback"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className={classes.paper}>
                <div className={classes.border}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="draft"
                            name="draft"
                            checked={state.draft}
                            onBlur={validate}
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Draft"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className={classes.paper}>
                <div className={classes.border}>
                  <InputLabel>Add attachment</InputLabel>
                  <Grid container>
                    <Grid item xs={3}>
                      <Tooltip
                        placement="top"
                        title={
                          selectedFile.length === 5
                            ? "Only 5 documents can be attached at a time."
                            : ""
                        }
                      >
                        <span>
                          <Button
                            color="primary"
                            className={
                              selectedFile.length === 5
                                ? classes.browseDisableBtn
                                : classes.browseBtn
                            }
                            component="label"
                            variant="outlined"
                          >
                            Browse{" "}
                            <input
                              hidden
                              type="file"
                              accept=".pdf"
                              onChange={fileSelected}
                            />
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={9} className={classes.fileName}>
                      {selectedFile &&
                        selectedFile.map((file, index) => (
                          <div key={index}>
                            <span>
                              {file.name}
                              <CloseIcon
                                onClick={() => removeDocument(file)}
                                className={classes.closeIcon}
                                fontSize="small"
                              />
                            </span>
                            <br />
                          </div>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                Add URL link(s)
                </InputLabel>
                <TagsInput
                    onlyUnique
                    value={links}
                    className={classes.tagInput}
                    name="link"
                  //  onFocusOut={(e) => hotLinkSave(e)}
                    onChange={linkChange}
                    inputProps={{
                      style: { width: '400px' },
                    }}
                  />
                  <em className={classes.toolBarValue}>
                    Click on &apos;Enter&apos; button after adding each link.
                  </em>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </Paper>
  );
};

BasicInfo.propTypes = {
  newSim: PropTypes.object,
  addBasicInfo: PropTypes.func,
  increaseStep: PropTypes.func,
  fetchProductList: PropTypes.func,
  products: PropTypes.array,
  fetchSimDetails: PropTypes.func,
  reset: PropTypes.func,
  sfReset: PropTypes.func,
};
const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
  products: state.sim.products,
});

const mapDispatchToProps = {
  addBasicInfo,
  increaseStep,
  fetchProductList,
  fetchSimDetails,
  reset,
  sfReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
