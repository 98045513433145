import React, { useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { currentStatus, audioPlay } from '../../../redux/sims/actions';
import { useStyles } from './OtherScript';

const OtherScript = ({
  singleScript,
  index,
  currentStatus,
  currentAudioPlaying,
  audioPlaying,
  totalLength,
  audioPlay,
  callTranscript,
  setCallTranscript,
  setTimePlayfun,
  timePlay,
  currentAudio,
  pauseFunction,
}) => {
  const classes = useStyles();
  let audioPlayer = useRef(null);
  useEffect(() => {
    if (
      singleScript
      && singleScript.audio_url === ''
      && currentAudioPlaying === index
    ) {
      if (totalLength === currentAudioPlaying && callTranscript <= 0) {
        currentStatus(0);
        audioPlay('');
        setCallTranscript(callTranscript + 1);
      } else {
        currentStatus(index + 1);
      }
    }
  }, [singleScript, currentAudioPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

  const pauseAudioUrl = (data, direct) => {
    if (direct) {
      const type = 'other';
      pauseFunction(data, type);
    }
    currentAudio.current.pause();
  };

  const playAudioUrl = (data) => {
    const type = 'other';
    setTimePlayfun(audioPlayer, data, type);
    const direct = false;
    if (timePlay > 0) {
      pauseAudioUrl(data, direct);
    }
    audioPlayer.current.play();
    audioPlayer.current.onended = () => {
      if (audioPlaying === 'call') {
        currentStatus(index + 1);
      }
      audioPlayer = null;
      pauseFunction(data, type);
      if (totalLength === currentAudioPlaying) {
        currentStatus(0);
        audioPlay('');
      }
    };
  };
  const audioChange = () => {
    if (currentAudioPlaying === index && audioPlaying === 'call') {
      playAudioUrl(singleScript);
    }
    if (audioPlaying === 'agent') {
      currentStatus(index + 1);
      if (totalLength === currentAudioPlaying) {
        audioPlayer = null;
        currentStatus(0);
        audioPlay('');
      }
    }
  };
  const showPlayButton = (audio, data) => {
    if (audio && data && data.play) {
      return (
        <PlayArrowIcon
          className={classes.priorityIcon}
          onClick={() => playAudioUrl(data)}
        />
      );
    }
    if (audio && data && !data.play) {
      const direct = true;
      return (
        <PauseIcon
          className={classes.priorityIcon}
          onClick={() => pauseAudioUrl(data, direct)}
        />
      );
    }
    if (!audio) {
      return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
    }
    if (audio) {
      return (
        <PlayArrowIcon
          className={classes.priorityIcon}
          onClick={() => playAudioUrl(data)}
        />
      );
    }
    return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
  };
  return (
    <div className={classes.customer}>
      {currentAudioPlaying === index
        && (audioPlaying === 'call' || audioPlaying === 'agent') && (
          <audio onLoadedData={audioChange} ref={audioPlayer}>
            <source src={singleScript.audio_url} type="audio/mp3" />
          </audio>
      )}
      {showPlayButton(singleScript.audio_url, singleScript)}
      <Typography className={`${classes.scriptTypo} ${classes.scriptStyle}`}>
        <span className={`${classes.bold}`}>
          {singleScript.sim_character_id.name}
          ,
        </span>
        {' '}
        The
        {' '}
        {singleScript.sim_character_id.masked_type
          ? singleScript.sim_character_id.masked_type : singleScript.sim_character_id.type}
        <br />
        <span className={classes.script}>{singleScript.script}</span>
      </Typography>
      {!audioPlaying && !currentAudioPlaying && (
        <audio key={singleScript.audio_url} ref={audioPlayer}>
          <source src={singleScript.audio_url} type="audio/mp3" />
        </audio>
      )}
      <div style={{ clear: 'both' }} />
    </div>
  );
};

OtherScript.propTypes = {
  singleScript: PropTypes.object,
  index: PropTypes.number,
  currentStatus: PropTypes.func,
  currentAudioPlaying: PropTypes.any,
  audioPlaying: PropTypes.string,
  totalLength: PropTypes.number,
  callTranscript: PropTypes.number,
  audioPlay: PropTypes.func,
  setCallTranscript: PropTypes.func,
  currentAudio: PropTypes.object,
  setTimePlayfun: PropTypes.func,
  timePlay: PropTypes.number,
  pauseFunction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // audioPlaying: state.sim.audioPlaying,
});

const mapDispatchToProps = {
  currentStatus,
  audioPlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherScript);
