import { makeStyles } from "@material-ui/core/styles";

export const useCharacterStyle = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  characterMainGrid: {
    margin: 23,
    background: "#F5F5F5",
    minWidth: "18vw",
    borderRadius: "1%",
    marginTop: 20,
    padding: theme.spacing(1.5),
  },
  characterLabel: {
    fontSize: 17,
    fontWeight: 700,
    marginTop: 12,
    marginLeft: 15,
  },
  characterGrid: {
    margin: "auto",
    width: "5vw",
    fontWeight: 800,
    borderBottomRightRadius: "1%",
    borderBottomLeftRadius: "1%",
  },
  characterName: {
    fontSize: 13,
    fontWeight: 600,
    color: "#ffff",
  },
  characterRole: {
    fontSize: 11,
    fontFamily: "cursive",
    color: "#ffff",
    marginTop: 5,
  },
  characterDiv: {
    paddingLeft: theme.spacing(1),
  },
  avatar: {
    width: 50,
    height: 50,
  },
  cardContent: {
    height: 80,
  },
  details: {
    display: "flex",
  },
  card: {
    margin: 7,
    cursor: "pointer",
    boxShadow: "none",
  },
}));
