import React, { useRef, useEffect } from "react";
import { Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import loadingIcon from "../../../assets/images/icons/loading-1.gif";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { currentStatus, audioPlay } from "../../../redux/sims/actions";
import { useStyles } from "./AgentScript";

const AgentScript = ({
  singleScript,
  currentAgentScript,
  latestAgentScript,
  index,
  currentStatus,
  audioPlaying,
  currentAudioPlaying,
  totalLength,
  audioPlay,
  simCharacter,
  setLastFive,
  value,
  callTranscript,
  setCallTranscript,
  setTimePlayfun,
  timePlay,
  currentAudio,
  pauseFunction,

}) => {
  const classes = useStyles();
  let audioPlayer = useRef(null);
  useEffect(() => {
    if (
      latestAgentScript &&
      latestAgentScript.audio_url === "" &&
      currentAudioPlaying === index
    ) {
      const val = currentAgentScript.findIndex(
        (agentId) => agentId.id === latestAgentScript.id
      );
      setLastFive(currentAgentScript[val + 1]);
    } else if (currentAudioPlaying === index && !latestAgentScript) {
      if (totalLength === currentAudioPlaying && callTranscript <= 0) {
        if (singleScript.audio_url) return;
        currentStatus(0);
        audioPlay("");
        setCallTranscript(callTranscript + 1);
      } else {
        if (singleScript.audio_url) return;
        currentStatus(index + 1);
      }
    }
  }, [latestAgentScript, setLastFive, currentAudioPlaying]); // eslint-disable-line react-hooks/exhaustive-deps
 
  const pauseAudioUrl = (data, direct) => {
    if (direct) {
      const type = 'agent';
      pauseFunction(data, type);
    }
    currentAudio.current.pause();
  };

  const playAudioUrl = (data) => {
    let type = 'agent';
    setTimePlayfun(audioPlayer, data, type);
    const direct = false;
    if (timePlay > 0) {
      pauseAudioUrl(data, direct);
    }
    audioPlayer.current.play();
    //  when audio playback ends, set isPlaying to false
    audioPlayer.current.onended = () => {
      const indexValue = currentAgentScript.findIndex(
        (agentAudio) => agentAudio.audio_url === latestAgentScript.audio_url
      );
      if (audioPlaying === "call" || audioPlaying === "agent") {
        if (indexValue + 1 === currentAgentScript.length) {
          setLastFive("");
          currentStatus(index + 1);
        } else {
          setLastFive(currentAgentScript[indexValue + 1]);
        }
      }
      pauseFunction(data, type);
      if (
        totalLength === currentAudioPlaying &&
        indexValue + 1 === currentAgentScript.length
      ) {
        audioPlayer = null;
        currentStatus(0);
        audioPlay("");
      }
    };
  };
 
  const audioChange = () => {
    if (currentAudioPlaying === index && simCharacter === "agent") {
      playAudioUrl(latestAgentScript ? latestAgentScript : singleScript);
    }
    // if (audioPlaying === 'call') {
    //   currentStatus(index + 1);
    //   if (totalLength === currentAudioPlaying) {
    //     audioPlayer = null;
    //     currentStatus(0);
    //     audioPlay('');
    //   }
    // }
  };
  const highLightKeywords = (str, arr = []) => {
    var keywordArray = [];
    arr.map((ele) => {
      const substring = new RegExp(ele, "gi");
      var res = str.match(substring);
      if (res && res.length) {
        keywordArray.push(res[0]);
      }
      return keywordArray;
    });
    keywordArray.map((ele) => {
      str = str.replaceAll(
        ele,
        `<span title='Keyword' className="keywordsStyle" style='background: #ADBBD4;display: inline-block;padding: 0rem .3rem;border-radius: 3px;'>${ele}</span>`
      );
      return str;
    });
    return str;
  };
  
  const highLightMatchKeywords = (str, match = []) => {
    var keywordArray = [];
    match.map((ele) => {
      const substring = new RegExp(ele, "gi");
      var res = str.match(substring);
      if (res && res.length) {
        keywordArray.push(res[0]);
      }
      return keywordArray;
    });
    keywordArray.map((ele) => {
      str = str.replaceAll(
        ele,
        `<span title='Matched keyword' style='background: #c4e89b;display: inline-block;padding: 0rem .3rem;border-radius: 3px;'>${ele.toLowerCase()}</span>`
      );
      return str;
    });
    return str;
  };
  const showPlayButton = (audio, isProcessed, data) => {
    if (audio && data && data.play) {
      return (
        <PlayArrowIcon
          className={classes.priorityIcon}
          onClick={() => playAudioUrl(data)}
        />
      );
    }
    if (audio && data && !data.play) {
      const direct = true;
      return (
        <PauseIcon
          className={classes.priorityIcon}
          onClick={() => pauseAudioUrl(data, direct)}
        />
      );
    }
    if (isProcessed === "Processing" && !audio) {
      return (
        <img
          className={classes.priorityIcon}
          src={loadingIcon}
          alt="loading..."
        />
      );
    }
    if (!audio) {
      return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
    }
    if (audio) {
      return (
        <PlayArrowIcon
          className={classes.priorityIcon}
          onClick={() => playAudioUrl()}
        />
      );
    }
    return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
  };
  return (
    <div key={singleScript._id} className={classes.agent}>
      <div className={classes.checkCircle}>
        <CheckCircleIcon className={classes.checkCircleIcon} />
        {latestAgentScript && latestAgentScript.myCount
          ? latestAgentScript.myCount
          : 0}{" "}
        take
      </div>
      {currentAudioPlaying === index &&
        !value &&
        (audioPlaying === "call" || audioPlaying === "agent") && (
          <audio onLoadedData={audioChange} id="1" ref={audioPlayer}>
            <source
              src={
                singleScript?.audio_url
                  ? singleScript?.audio_url
                  : latestAgentScript?.audio_url
              }
              type="audio/mp3"
            />
          </audio>
        )}
      {currentAudioPlaying === index && value && audioPlaying && (
        <audio onLoadedData={audioChange} id="2" ref={audioPlayer}>
          <source
            src={
              singleScript?.audio_url
                ? singleScript?.audio_url
                : latestAgentScript?.audio_url
            }
            type="audio/mp3"
          />
        </audio>
      )}
      {latestAgentScript || singleScript?.audio_url
        ? showPlayButton(
          latestAgentScript?.audio_url || singleScript?.audio_url,
          latestAgentScript?.status || "Completed", latestAgentScript ? latestAgentScript : singleScript
        )
        : showPlayButton(null, null, latestAgentScript ? latestAgentScript : singleScript)}
      <Typography className={`${classes.scriptTypo} ${classes.scriptStyle}`}>
        <span className={`${classes.bold}`}>
          {singleScript.sim_character_id.name},
        </span>
        {' '}
        The
        {' '}
        {singleScript.sim_character_id.masked_type
          ? singleScript.sim_character_id.masked_type : singleScript.sim_character_id.type}
        &nbsp;
        <span className={classes.script}>
          {latestAgentScript ? (
            latestAgentScript.status === "Completed" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: highLightMatchKeywords(
                    latestAgentScript.transcribed_text,
                    latestAgentScript.keywords_matched
                  ),
                }}
              ></span>
            ) : (
              <img
                height="20px"
                className={classes.loadingIcon}
                src={loadingIcon}
                alt="loading..."
              />
            )
          ) : singleScript?.audio_url ? (
            ""
          ) : (
            "( Not Generated! )"
          )}
        </span>
        <br />
        <span className={classes.script}>
          <span className={classes.bold}>SCRIPT</span>:{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: highLightKeywords(
                singleScript.script,
                singleScript.keywords
              ),
            }}
          ></span>
        </span>
        {latestAgentScript?.missed_keywords ? (
          <p className={classes.script} style={{ textAlign: "right" }}>
            {latestAgentScript.keywords_matched.length ? (
              <span className={classes.bold}>Correct Keywords : </span>
            ) : null}
            {latestAgentScript?.keywords_matched?.length
              ? latestAgentScript?.keywords_matched?.map((ele, i) => (
                <span key={i}>
                  {i !== 0 ? (
                    <span style={{ color: "green" }}>, {ele}</span>
                  ) : (
                    <span style={{ color: "green" }}>{ele}</span>
                  )}
                </span>
              ))
              : null}
          </p>
        ) : null}
        {latestAgentScript?.missed_keywords ? (
          <p className={classes.script} style={{ textAlign: "right" }}>
            {latestAgentScript.missed_keywords.length ? (
              <span className={classes.bold}>Missed Keywords : </span>
            ) : null}
            {latestAgentScript.missed_keywords.length
              ? latestAgentScript.missed_keywords.map((ele, i) => (
                <span key={i}>
                  {i !== 0 ? (
                    <span style={{ color: "red" }}>, {ele}</span>
                  ) : (
                    <span style={{ color: "red" }}>{ele}</span>
                  )}
                </span>
              ))
              : null}
          </p>
        ) : null}
      </Typography>
      {!audioPlaying && !currentAudioPlaying && (
        <audio
          key={
            latestAgentScript
              ? latestAgentScript?.audio_url
              : singleScript?.audio_url
          }
          id="3"
          ref={audioPlayer}
        >
          <source
            src={
              singleScript?.audio_url
                ? singleScript?.audio_url
                : latestAgentScript?.audio_url
            }
            type="audio/mp3"
          />
        </audio>
      )}
      <div style={{ clear: "both" }}></div>
    </div>
  );
};

AgentScript.propTypes = {
  singleScript: PropTypes.object,
  currentAgentScript: PropTypes.array,
  latestAgentScript: PropTypes.object,
  index: PropTypes.number,
  currentStatus: PropTypes.func,
  currentAudioPlaying: PropTypes.any,
  audioPlaying: PropTypes.string,
  totalLength: PropTypes.number,
  audioPlay: PropTypes.func,
  setLastFive: PropTypes.func,
  setTimePlayfun: PropTypes.func,
  pauseFunction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // return {
  //   audioPlaying: state.sim.audioPlaying,
  // }
});

const mapDispatchToProps = {
  currentStatus,
  audioPlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentScript);
