import React, { useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { fetchScreening } from "../../../redux/sims/actions";
import { useTabContentStyles } from "./Screening";

const Screening = ({ screening, fetchScreening }) => {
  useEffect(() => {
    fetchScreening();
  }, [fetchScreening]);
  const classes = useTabContentStyles();

  return (
    <div className={classes.root}>
      <Card variant="outlined" className={classes.mainCard}>
        <CardContent>
          <Grid container spacing={2}>
            {screening.map((screeningData) => (
              <Grid item xs={12} key={screeningData.id}>
                <Typography className={classes.title}>
                  <ArrowDropDownIcon className={classes.arrowDropDown} />
                  {screeningData.name}
                </Typography>
                <Grid container>
                  <Grid item className={classes.link}>
                    <a href={screeningData.link} className={classes.linkText}>
                      {screeningData.text}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
Screening.propTypes = {
  screening: PropTypes.array,
  fetchScreening: PropTypes.func,
};
const mapStateToProps = (state) => ({
  screening: state.sim.screening,
});

const mapDispatchToProps = { fetchScreening };

export default connect(mapStateToProps, mapDispatchToProps)(Screening);
