import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import {
  Grid,
  Paper,
  Typography,
  Card,
  Avatar,
  InputLabel,
  TextField,
  Button,
  NativeSelect,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  LinearProgress,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { fetchSelectGroups, updatePassword } from '../redux/settings/actions';
import { fetchRoleData } from '../redux/admin/action';
import { saveUserSettings, fetchCurrentUserData } from '../redux/sims/actions';
import { updateRole, fetchPermissionDetails } from '../redux/authentication/action';
import TagInput from '../components/settings/TagInput';
import { BootstrapInput, useToolbarStyles } from './Settings';
import config from '../config';
import ProfilePic from '../assets/images/profile_pic.png';

const Settings = ({
  userData,
  fetchCurrentUserData,
  selectRoles,
  selectGroups,
  fetchRoleData,
  fetchSelectGroups,
  updatePassword,
  updatePwd,
  status,
  saveUserSettings,
  updateRole,
  fetchPermissionDetails,
}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const classes = useToolbarStyles();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [subStatus, setSubStatus] = useState([
    'Candidate',
    'Offered',
    'Refused',
    'No Offer',
    'Hired',
  ]);
  localStorage.setItem('sims_id', '');
  useEffect(() => {
    fetchCurrentUserData();
  }, [fetchCurrentUserData]);
  useEffect(() => {
    if (Object.keys(selectRoles).length === 0) {
      fetchRoleData();
    }
  }, [fetchRoleData, selectRoles]);
  useEffect(() => {
    if (Object.keys(selectGroups).length === 0) {
      fetchSelectGroups();
    }
  }, [fetchSelectGroups, selectGroups]);
  const [state, setState] = useState({
    _id: userData._id,
    first_name: userData.first_name,
    last_name: userData.last_name,
    nick_name: userData.nick_name,
    title: userData.title,
    email_id: userData.email_id,
    phone_number: userData.phone_number,
    profile_pic_url: userData.profile_pic_url,
    status: userData.status,
    sub_status: userData.sub_status,
    role: userData.role,
    schedule: userData.schedule,
    duration: userData.duration,
    employment: userData.employment,
    workplace: userData.workplace,
    joined_company: userData.joined_company,
    probation: userData.probation,
    promoted: userData.promoted,
    groups: userData.groups,
  });
  const [currentPwdFlag, setCurrentPwdFlag] = useState(false);
  const [matchFlag, setMatchFlag] = useState(false);
  const [progress, setProgress] = useState('');
  const [password, setPassword] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });
  useEffect(() => {
    if (updatePwd.success) {
      setPassword({
        current_password: '',
        new_password: '',
        confirm_password: '',
      });
      setCurrentPwdFlag(false);
    }
    if (!status) {
      setCurrentPwdFlag(true);
    }
  }, [updatePwd, status]);
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      _id: userData._id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      nick_name: userData.nick_name,
      title: userData.title,
      email_id: userData.email_id,
      phone_number: userData.phone_number,
      profile_pic_url: userData.profile_pic_url,
      status: userData.status,
      sub_status: userData.sub_status,
      role: userData.role,
      schedule: userData.schedule,
      duration: userData.duration,
      employment: userData.employment,
      workplace: userData.workplace,
      joined_company: userData.joined_company,
      probation: userData.probation,
      promoted: userData.promoted,
      groups: userData.groups,
    }));
    if (userData.status === 'active') {
      setSubStatus(['Training', 'Nesting', 'Operations']);
    } else if (userData.status === 'inActive') {
      setSubStatus(['Suspended', 'Leave of Absence']);
    } else if (userData.status === 'postEmployment') {
      setSubStatus(['Promotion Non-CC', 'Voluntary Separation', 'Termination']);
    } else {
      setSubStatus(['Candidate', 'Offered', 'Refused', 'No Offer', 'Hired']);
    }
  }, [userData]);
  // method to handle user info changes
  const handleChange = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  // method to handle password section changes
  const passwordChange = (event) => {
    const { value } = event.target;
    setPassword({
      ...password,
      [event.target.name]: value,
    });
    if (event.target.name === 'confirm_password') {
      setMatchFlag(true);
      if (password.new_password === value) {
        setMatchFlag(false);
      }
    }
  };
  // method to change status and substatus according to the status chooses
  const handleStatusChange = (evt) => {
    const { value } = evt.target;
    if (value === 'active') {
      setSubStatus(['Training', 'Nesting', 'Operations']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Training',
      });
    } else if (value === 'inActive') {
      setSubStatus(['Suspended', 'Leave of Absence']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Suspended',
      });
    } else if (value === 'postEmployment') {
      setSubStatus(['Promotion Non-CC', 'Voluntary Separation', 'Termination']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Promotion Non-CC',
      });
    } else {
      setSubStatus(['Candidate', 'Offered', 'Refused', 'No Offer', 'Hired']);
      setState({
        ...state,
        [evt.target.name]: value,
        sub_status: 'Candidate',
      });
    }
  };
  // method to call backend API for saving updated password
  const changePassword = async () => {
    const data = {
      current_password: password.current_password,
      new_password: password.new_password,
    };
    await updatePassword(data);
  };
  // method to handle group changes
  const handleGroupChange = (value) => {
    setState({
      ...state,
      groups: value,
    });
  };

  // method to update changes in the user informations
  const updateUserInfo = async () => {
    const roleName = selectRoles.find(
      (item) => item._id === state.role,
    );
    updateRole(roleName.name);
    const user = JSON.parse(localStorage.getItem('user'));
    user.role = roleName.name;
    localStorage.setItem('user', JSON.stringify(user));
    await saveUserSettings(state);
    await fetchPermissionDetails();
  };

  // update profile photo
  const updatePhoto = async (event) => {
    const formData = new FormData();
    formData.append('photo', event.target.files[0]);
    if (event.target.files[0].size / 1000000 > 2) {
      setOpenAlert(true);
    } else {
      const options = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          task: null,
          type: null,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      };
      axios.post(`${config.API_BASE_URL}/api/v1/users/update-profile-photo`, formData, options)
        .then((res) => {
          setProgress('');
          setState({
            ...state,
            profile_pic_url: res.data.url,
          });
          const user = JSON.parse(localStorage.getItem('user'));
          user.profile_pic_url = res.data.url;
          localStorage.setItem('user', JSON.stringify(user));
        })
        .catch(() => {
          setProgress('');
        });
    }
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  const LinearProgressWithLabel = (props) => (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(
            props.value,
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
  return (
    <div>
      {progress ? (
        <LinearProgressWithLabel value={progress} />
      ) : null}
      {openAlert ? (
        <Alert
          variant="filled"
          onClose={handleAlertClose}
          severity="error"
          className={classes.errorAlert}
        >
          Image size exceeds more than 2mb
        </Alert>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.paper}>
            <Card className={classes.profileCard}>
              <Avatar
                alt="profile"
                className={classes.avatar}
                src={state.profile_pic_url ? state.profile_pic_url : ProfilePic}
              />
              <div className={classes.profileBtnDiv}>
                <Button variant="outlined" component="label" className={classes.updateProfileBtn}>
                  UPDATE PROFILE PHOTO
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={updatePhoto}
                  />
                </Button>
              </div>
            </Card>
            <Grid item xs={12} md={12}>
              <form className={classes.formControl}>
                <InputLabel className={classes.label}>First Name</InputLabel>
                <TextField
                  id="firstName"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'string',
                    readOnly: (user.role).toLowerCase() === 'agent',
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="first_name"
                  value={state.first_name}
                  onChange={handleChange}
                />
                <InputLabel className={classes.label}>Last Name</InputLabel>
                <TextField
                  id="lastName"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'string',
                    readOnly: (user.role).toLowerCase() === 'agent',
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="last_name"
                  value={state.last_name}
                  onChange={handleChange}
                />
                <InputLabel className={classes.label}>Nickname</InputLabel>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="nick_name"
                  value={state.nick_name}
                  onChange={handleChange}
                />
                <InputLabel className={classes.label}>Title</InputLabel>
                <TextField
                  id="title"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'string',
                    readOnly: (user.role).toLowerCase() === 'agent',
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="title"
                  value={state.title}
                  onChange={handleChange}
                />
                <InputLabel className={classes.label}>Email Address</InputLabel>
                <TextField
                  id="email"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'string',
                    readOnly: (user.role).toLowerCase() === 'agent',
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="email_id"
                  value={state.email_id}
                  onChange={handleChange}
                />
                <InputLabel className={classes.label}>Phone number</InputLabel>
                <NumberFormat
                  variant="outlined"
                  onChange={handleChange}
                  customInput={TextField}
                  name="phone_number"
                  InputProps={{
                    readOnly: (user.role).toLowerCase() === 'agent',
                  }}
                  value={state.phone_number}
                  error={!state.phone_number}
                  required
                  className={classes.numFormat}
                  format="+1 (###) ###-####"
                  mask="_"
                />
              </form>
              <div className={classes.btnDiv}>
                <Button
                  variant="outlined"
                  className={classes.updateBtn}
                  onClick={updateUserInfo}
                >
                  UPDATE CHANGES
                </Button>
              </div>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3} className={(user.role).toLowerCase() === 'agent' ? classes.agentDisplay : ''}>
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Status details</Typography>
            <form className={classes.formControl}>
              <InputLabel id="selectLable" className={classes.statusLabel}>
                Status
              </InputLabel>
              <NativeSelect
                lableid="selectLable"
                id="selectStatus"
                name="status"
                input={<BootstrapInput />}
                value={state.status}
                onChange={handleStatusChange}
                variant="outlined"
                required
                IconComponent={KeyboardArrowDownIcon}
              >
                <option value="applicant">Applicant</option>
                <option value="active">Active</option>
                <option value="inActive">Inactive</option>
                <option value="postEmployment">Post-employment</option>
              </NativeSelect>
              <InputLabel id="selectLable" className={classes.label}>
                Sub-status
              </InputLabel>
              <NativeSelect
                lableid="selectLable"
                id="selectSubStatus"
                name="sub_status"
                input={<BootstrapInput />}
                value={state.sub_status}
                error={!state.sub_status}
                onChange={handleChange}
                variant="outlined"
                required
                IconComponent={KeyboardArrowDownIcon}
              >
                {subStatus.map((sub) => (
                  <option key={sub}>{sub}</option>
                ))}
              </NativeSelect>
              <InputLabel id="selectLable" className={classes.label}>
                Role
              </InputLabel>
              <NativeSelect
                lableid="selectLable"
                id="selectRole"
                name="role"
                input={<BootstrapInput />}
                value={state.role}
                onChange={handleChange}
                variant="outlined"
                required
                IconComponent={KeyboardArrowDownIcon}
              >
                {selectRoles.map((role) => (
                  <option key={role._id} value={role._id}>
                    {role.name}
                  </option>
                ))}
              </NativeSelect>
            </form>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Schedule</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="schedule"
                name="schedule"
                value={state.schedule}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Full time"
                  control={<Radio color="primary" size="small" />}
                  label="Full Time"
                />
                <FormControlLabel
                  value="Part time"
                  control={<Radio color="primary" size="small" />}
                  label="Part time"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Duration</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="duration"
                name="duration"
                value={state.duration}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Permanent"
                  control={<Radio color="primary" size="small" />}
                  label="Permanent"
                />
                <FormControlLabel
                  value="Temporary"
                  control={<Radio color="primary" size="small" />}
                  label="Temporary"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Employment</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="employment"
                name="employment"
                value={state.employment}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Employee"
                  control={<Radio color="primary" size="small" />}
                  label="Employee"
                />
                <FormControlLabel
                  value="Contract"
                  control={<Radio color="primary" size="small" />}
                  label="Contract"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={3} className={(user.role).toLowerCase() === 'agent' ? classes.agentDisplay : ''}>
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Workplace</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="workplace"
                name="workplace"
                value={state.workplace}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Premise"
                  control={<Radio color="primary" size="small" />}
                  label="Premise"
                />
                <FormControlLabel
                  value="WAH"
                  control={<Radio color="primary" size="small" />}
                  label="WAH"
                />
                <FormControlLabel
                  value="Off site"
                  control={<Radio color="primary" size="small" />}
                  label="Off site"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Joined company</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="joined_company"
                name="joined_company"
                value={state.joined_company}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="New hire"
                  control={<Radio color="primary" size="small" />}
                  label="New hire"
                />
                <FormControlLabel
                  value="Transfer"
                  control={<Radio color="primary" size="small" />}
                  label="Transfer"
                />
                <FormControlLabel
                  value="Rehire"
                  control={<Radio color="primary" size="small" />}
                  label="Rehire"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Probation</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="probation"
                name="probation"
                value={state.probation}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="New hire"
                  control={<Radio color="primary" size="small" />}
                  label="New hire"
                />
                <FormControlLabel
                  value="Performance"
                  control={<Radio color="primary" size="small" />}
                  label="Performance"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" size="small" />}
                  label="NA"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <br />
          <Paper elevation={2} className={classes.height}>
            <Typography className={classes.title}>Promoted in CC</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="promoted"
                name="promoted"
                value={state.promoted}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Leadership"
                  control={<Radio color="primary" size="small" />}
                  label="Leadership"
                />
                <FormControlLabel
                  value="Support"
                  control={<Radio color="primary" size="small" />}
                  label="Support"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={(user.role).toLowerCase() === 'agent' ? classes.agentDisplay : classes.padding}>
            <Typography className={classes.title}>Groups</Typography>
            {userData.groups.length && selectGroups.length ? (
              <TagInput
                selectGroups={selectGroups}
                userData={userData}
                handleGroupChange={handleGroupChange}
              />
            ) : null}
          </Paper>
          { (user.role).toLowerCase() === 'agent' ? ''
            : <br />}
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.title}>Update Password</Typography>
            <form className={classes.formControl}>
              <InputLabel className={classes.currentPwdLabel}>
                Current password
              </InputLabel>
              <TextField
                id="currentPassword"
                required
                placeholder="Enter current password"
                name="current_password"
                error={!state.current_password}
                onChange={passwordChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: 'string',
                }}
                variant="outlined"
                size="small"
                fullWidth
                value={password.current_password}
                helperText={
                  currentPwdFlag ? (
                    <span className={classes.pwdError}>
                      {' '}
                      Current password is not the right one !
                    </span>
                  ) : (
                    ''
                  )
                }
              />
              <InputLabel className={classes.label}>New password</InputLabel>
              <TextField
                id="newPassword"
                required
                error={!state.new_password}
                placeholder="Enter new password"
                onChange={passwordChange}
                name="new_password"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: 'string',
                }}
                variant="outlined"
                size="small"
                fullWidth
                type="password"
                value={password.new_password}
              />
              <InputLabel className={classes.label}>
                Re-enter new password
              </InputLabel>
              <TextField
                id="reNewPassword"
                required
                error={!state.confirm_password}
                placeholder="Re-enter new password"
                InputLabelProps={{
                  shrink: true,
                }}
                name="confirm_password"
                helperText={matchFlag ? 'Password mismatch !' : ''}
                onChange={passwordChange}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: 'string',
                }}
                variant="outlined"
                size="small"
                fullWidth
                type="password"
                value={password.confirm_password}
              />
            </form>
            <div className={classes.passwordBtnDiv}>
              <Button
                variant="outlined"
                className={classes.updateBtn}
                disabled={
                  !(
                    password.current_password
                    && password.new_password
                    && password.confirm_password
                  ) || matchFlag
                }
                onClick={changePassword}
              >
                UPDATE PASSWORD
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
Settings.propTypes = {
  userData: PropTypes.object,
  fetchCurrentUserData: PropTypes.func,
  selectRoles: PropTypes.array,
  selectGroups: PropTypes.array,
  fetchRoleData: PropTypes.func,
  fetchSelectGroups: PropTypes.func,
  saveUserSettings: PropTypes.func,
  updatePwd: PropTypes.object,
  updatePassword: PropTypes.func,
  status: PropTypes.bool,
  updateRole: PropTypes.func,
  fetchPermissionDetails: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  userData: state.sim.currentUserData,
  selectStatus: state.settings.selectStatus,
  selectSubStatus: state.settings.selectSubStatus,
  selectRoles: state.admin.selectRoles,
  selectGroups: state.settings.selectGroups,
  updatePwd: state.settings.result ? state.settings.result : {},
  status:
    state.settings.status && state.settings.status.response
      ? state.settings.status.response.data.success
      : true,
});

const mapDispatchToProps = {
  fetchCurrentUserData,
  fetchRoleData,
  fetchSelectGroups,
  updatePassword,
  saveUserSettings,
  updateRole,
  fetchPermissionDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
