import React, { useEffect, useState } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  Card,
  CardContent,
  Avatar,
  Tab,
  Box,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Input,
  Toolbar,
  Paper,
  Icon,
  Badge,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EditIcon from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Clone from "../../../assets/images/icons/Clone.png";
import Preview from "../../../assets/images/icons/Preview.png";
import Launch from "../../../assets/images/icons/Launch.png";
import Note from "../../../assets/images/icons/Note.png";
import Distance from "../../../assets/images/icons/Distance.png";
import Suitcase from "../../../assets/images/icons/Suitcase_redbg.png";
import Screening from "../keywords/Screening.jsx";
import Opportunity from "../keywords/Opportunity.jsx";
import KnowledgeArticle from "../keywords/KnowledgeArticle.jsx";
import Dispositions from "../keywords/Despositions.jsx";
import KBCA from "../keywords/KBCA.jsx";
import PhysicianMap from "../keywords/PhysicianMap.jsx";
import { fetchDemographics, saveCaseVaules } from "../../../redux/sims/actions";
import { fetchCaseCaller } from "../../../redux/sf/actions";
import moment from "moment";
// import { before } from "lodash";
import KeywordCheckBox from "./KeywordCheckBox";
import KeywordInput from "./KeywordInput";
import {
  SmallAvatar,
  useTabContentStyles,
  useNavbarStyles,
  useBasicInfoStyles,
  AntTabs,
} from "./TakeSimDetails";
import GetAppIcon from "@material-ui/icons/GetApp";
import LinkIcon from '@material-ui/icons/Link';
import { useLocation } from "react-router-dom";
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    minHeight: "2px",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    "&$selected": {
      color: "rgb(38,65,114)",
      fontWeight: 600,
    },
    "&:focus": {
      color: "rgb(38,65,114)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const Demographics = ({
  keywords,
  fetchCaseCaller,
  fetchDemographics,
  saveCaseVaules,
  currentClip,
  levelClips,
  isRestartLevel,
}) => {
  const classes = useTabContentStyles();
  const [callerName, setCallerName] = useState("");
  const [textCount, setTextCount] = useState(0);
  const [otherCount, setOtherCount] = useState(0);
  // const [caseDetails, setCaseDetails] = useState({});
  const [caseArray, setCaseArray] = useState([]);
  const [autoPopulateDataArray, setAutoPopulateDataArray] = useState([]);

  useEffect(() => {
    const simId = localStorage.getItem("sim_id");
    fetchDemographics(simId);
  }, [fetchDemographics]);
  useEffect(() => {
    const checkScorableField = (singleClip) => {
      let textCnt = 0;
      let otherCnt = 0;
      let arr = [];
      const { autopopulate_data } = singleClip;
      keywords.map((keyword, ind) =>
        keyword.fields.map((singleField, i) => {
          if (
            autopopulate_data &&
            autopopulate_data[ind] &&
            autopopulate_data[ind].fields &&
            autopopulate_data[ind].fields[i].is_enabled
          )
          singleField.is_scorable = true;
          if (singleField.is_scorable && singleField.type === "text") {
            textCnt += 1;
            const alreadyInCaseArray = caseArray.filter(
              (ele) => ele.id.toString() === singleField._id.toString()
            );
            const obj = {
              id: singleField._id,
              input:
                alreadyInCaseArray.length && alreadyInCaseArray[0].input !== ""
                  ? alreadyInCaseArray[0].input
                  : "",
              target: singleField.value,
              type: singleField.type,
            };
            arr.push(obj);
          } else if (singleField.is_scorable && singleField.type !== "text") {
            const alreadyInCaseArray = caseArray.filter(
              (ele) => ele.id.toString() === singleField._id.toString()
            );
            const obj = {
              id: singleField._id,
              input:
                alreadyInCaseArray.length &&
                  alreadyInCaseArray[0].input !== "false"
                  ? alreadyInCaseArray[0].input
                  : "false",
              target: singleField.value,
              type: singleField.type,
            };
            arr.push(obj);
            otherCnt += 1;
          }
          return singleField;
        })
      );
      setTextCount(textCnt);
      setOtherCount(otherCnt);
      setCaseArray(arr);
    };
    levelClips.map((singleClip) => {
      checkScorableField(singleClip);
      return singleClip;
    });
  }, [setOtherCount, setTextCount, keywords, levelClips]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAutopopulate = (keyword, currentClip) => {
    const autoPopulateArray = autoPopulateDataArray.length
      ? [...autoPopulateDataArray]
      : [...keyword];
    const { autopopulate_data } = currentClip;
    if (autopopulate_data && autoPopulateArray.length) {
      autopopulate_data.map((value, i) => {
        value.fields.map((val, j) => {
          if (val.is_enabled) {
            autoPopulateArray[i].fields[j].current_value =
              autoPopulateArray[i].fields[j].value;
          }
          return val;
        });
        return value;
      });
      setAutoPopulateDataArray(autoPopulateArray);
    }
  };
  useEffect(() => {
    setTimeout(
      () => setAutopopulate(keywords, currentClip),
      currentClip.autopopulate_timer * 1000
    );
    setAutoPopulateDataArray(keywords);
  }, [currentClip, keywords]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    saveCaseVaules(caseArray, textCount, otherCount);
  }, [saveCaseVaules, caseArray, textCount, otherCount]);

  useEffect(() => {
    if (isRestartLevel) {
      const autoPopulateArray = [...autoPopulateDataArray];
      autoPopulateArray.map((keyword) => {
        keyword.fields.map((field) => {
          delete field.current_value;
          return field;
        });
        return keyword;
      });
      setAutoPopulateDataArray(autoPopulateArray);
    }
  }, [isRestartLevel]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const fetchCaseCallerDetails = (e) => {
    if (e.key === "Enter") {
      fetchCaseCaller(callerName);
    } else {
      setCallerName(e.target.value);
    }
  };
  const handleBlur = (e) => {
    if (
      e.target.dataset.isscorable === "true" &&
      e.target.dataset.currentvalue
    ) {
      const objInput = {
        input: e.target.value,
        target: e.target.dataset.currentvalue,
        type: e.target.dataset.inputtype,
        id: e.target.dataset.caseid,
      };
      const array = caseArray.filter(
        (ele) => ele.id?.toString() !== objInput.id?.toString()
      );
      // push only caseDetails is non-empty object
      if (e.target.type === "text") {
        setCaseArray([...array, objInput]);
      } else {
        let obj = {
          input: e.target.checked ? "true" : "false",
          target: e.target.dataset.currentvalue,
          type: e.target.dataset.inputtype,
          id: e.target.dataset.caseid,
        };
        const array = caseArray.filter(
          (ele) => ele.id?.toString() !== obj.id?.toString()
        );
        setCaseArray([...array, obj]);
      }
    }
  };
  const handleChange = (e, parentIndex, fieldIndex) => {
    const autopopulateArray = [...autoPopulateDataArray];
    autopopulateArray[parentIndex].fields[fieldIndex].current_value =
      e.target.value;
    setAutoPopulateDataArray(autopopulateArray);
  };
  const handleCheckBoxChange = (e, parentIndex, fieldIndex) => {
    const autopopulateArray = [...autoPopulateDataArray];
    autopopulateArray[parentIndex].fields[fieldIndex].current_value = e.target
      .checked
      ? "true"
      : "false";
    setAutoPopulateDataArray(autopopulateArray);
  };
  return (
    <Grid container spacing={3}>
      {autoPopulateDataArray.map((keyword, index) => {
        return (
          <Grid item xs={12} key={keyword._id}>
            <Typography className={classes.title}>
              <ArrowDropDownIcon className={classes.arrowDropDown} />
              <span className={classes.span}>{keyword.name}</span>
            </Typography>
            <Grid container spacing={3} className={classes.grid}>
              {keyword.fields.map((fieldName, i) => {
                return (
                  <Grid
                    item
                    xs={6}
                    key={fieldName._id}
                    className={classes.gridItem}
                  >
                    <FormHelperText>{fieldName.fieldTitle}</FormHelperText>
                    {fieldName.type === "text" ? (
                      <KeywordInput
                        handleChange={(e) => handleChange(e, index, i)}
                        classes={classes}
                        handleBlur={handleBlur}
                        fieldName={fieldName}
                        fetchCaseCallerDetails={fetchCaseCallerDetails}
                      />
                    ) : (
                      <Input
                        fullWidth
                        disabled={true}
                        onKeyUp={
                          fieldName.fieldTitle === "Caller Name"
                            ? fetchCaseCallerDetails
                            : null
                        }
                        onBlur={handleBlur}
                        inputProps={{
                          "data-currentvalue": fieldName.value,
                          "data-caseid": fieldName._id,
                        }}
                        name={fieldName.fieldTitle}
                        className={fieldName.value ? classes.anim : null}
                        endAdornment={
                          <InputAdornment position="end">
                            <EditIcon className={classes.editIcon} />
                          </InputAdornment>
                        }
                        startAdornment={
                          fieldName.is_scorable === false ||
                            fieldName.is_scorable === true ? (
                            <InputAdornment position="start">
                              <KeywordCheckBox
                                handleCheckBoxChange={(e) =>
                                  handleCheckBoxChange(e, index, i)
                                }
                                classes={classes}
                                handleBlur={handleBlur}
                                fieldName={fieldName}
                              />
                            </InputAdornment>
                          ) : (
                            ""
                          )
                        }
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
const History = ({ history }) => {
  const classes = useBasicInfoStyles();
  return (
    <div>
      {history.map((caseHistory, index) => (
        <Grid
          container
          key={caseHistory.Id}
          className={index === 3 ? "" : classes.border}
        >
          <Grid item xs={6} className={classes.history}>
            <span> Date:</span>
          </Grid>
          <Grid item xs={6} className={classes.txtAlign}>
            <span>{moment(caseHistory.CreatedDate).format("MM-DD-YYYY")}</span>
          </Grid>
          <Grid item xs={6} className={classes.history}>
            <span>Field:</span>
          </Grid>
          <Grid item xs={6} className={classes.txtAlign}>
            <span>{caseHistory.Field}</span>
          </Grid>
          <Grid item xs={6} className={classes.history}>
            <span>User:</span>
          </Grid>
          <Grid item xs={6} className={classes.txtAlign}>
            <span>{caseHistory.CreatedById}</span>
          </Grid>
          <Grid item xs={6} className={classes.history}>
            <span>Orginal Value:</span>
          </Grid>
          <Grid item xs={6} className={classes.txtAlign}>
            <span>{caseHistory.OldValue}</span>
          </Grid>
          <Grid item xs={6} className={classes.history}>
            <span>New Value:</span>
          </Grid>
          <Grid item xs={6} className={classes.txtAlign}>
            <span>{caseHistory.NewValue}</span>
          </Grid>
        </Grid>
      ))}
      <div className={classes.center}>
        <Button variant="contained" className={classes.button} disableElevation>
          VIEW ALL
        </Button>
      </div>
    </div>
  );
};
const Navbar = ({
  keywords,
  fetchCaseCaller,
  fetchDemographics,
  saveCaseVaules,
  levelClips,
  currentClip,
  isRestartLevel,
}) => {
  const classes = useNavbarStyles();
  const [value, setValue] = React.useState(0);
  const handleTabs = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.navbar}>
      <AntTabs value={value} onChange={handleTabs}>
        <AntTab label="Demographics" />
        <AntTab label="Screening" />
        <AntTab label="KB CA" />
        <AntTab label="KB Other" />
        <AntTab label="Opportunities" />
        <AntTab label="Physician Map" />
        <AntTab label="Dispositions" />
        <AntTab label="Related" />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <Demographics
          keywords={keywords}
          fetchCaseCaller={fetchCaseCaller}
          fetchDemographics={fetchDemographics}
          saveCaseVaules={saveCaseVaules}
          levelClips={levelClips}
          currentClip={currentClip}
          isRestartLevel={isRestartLevel}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Screening />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <KBCA />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <KnowledgeArticle takeSim />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Opportunity />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PhysicianMap />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Dispositions takeSim />
      </TabPanel>
    </div>
  );
};
const TakeSimDetails = ({
  takeSim,
  keywords,
  history,
  fetchDemographics,
  saveCaseVaules,
  fetchCaseCaller,
  levelClips,
  currentClip,
  isRestartLevel,
}) => {
  const classes = useBasicInfoStyles();
  const patient = true;
  const [value, setValue] = React.useState(0);
  const [openDocuments,setOpenDocuments] = React.useState(false);
  const [openLinks, setOpenLinks] = React.useState(false);
  localStorage.setItem('sims_id', localStorage.getItem("sim_id"))
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Paper elevation={0}>
      <Card>
        <CardContent className={classes.background}>
          <Toolbar className={classes.background}>
            <Grid className={classes.title} container wrap="nowrap" spacing={2}>
              <Grid item>
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <SmallAvatar variant="rounded" src={Suitcase} />
                  }
                >
                  <Avatar
                    variant="rounded"
                    alt="Travis Howard"
                    src={takeSim.profile_pic}
                  />
                </Badge>
              </Grid>
              <Grid item xs={9} zeroMinWidth>
                <Typography className={classes.title} noWrap>
                  <span className={classes.case}>CASE</span>
                  <br />
                  {takeSim.name}
                </Typography>
              </Grid>
            </Grid>
            {location.state.documents && location.state.documents.length ?
             <Tooltip
             title="Download documents"
             placement="top">
              <IconButton className={classes.icon} onClick={() =>setOpenDocuments(true)}>
                <Icon>
                  <GetAppIcon className={classes.download} />
                </Icon>
              </IconButton>
            </Tooltip> : ''
            }
            {location.state.hotLinks && location.state.hotLinks.length ?
             <Tooltip
             title="Hot Link URL(s)"
             placement="top">
              <IconButton className={classes.icon} onClick={() =>setOpenLinks(true)}>
                <Icon>
                  <LinkIcon className={classes.download} />
                </Icon>
              </IconButton>
            </Tooltip> : ''
            }
            
            <IconButton className={classes.icon}>
              <Icon>
                <img
                  className={classes.img}
                  src={Launch}
                  height={20}
                  width={20}
                  alt="print"
                />
              </Icon>
            </IconButton>
            <IconButton className={classes.icon}>
              <Icon>
                <img
                  className={classes.img}
                  src={Clone}
                  height={20}
                  width={20}
                  alt="print"
                />
              </Icon>
            </IconButton>
            <IconButton className={classes.icon}>
              <Icon>
                <img
                  className={classes.img}
                  src={Distance}
                  height={20}
                  width={20}
                  alt="print"
                />
              </Icon>
            </IconButton>
            <IconButton className={classes.icon}>
              <Icon>
                <img
                  className={classes.img}
                  src={Note}
                  height={20}
                  width={20}
                  alt="print"
                />
              </Icon>
            </IconButton>
            <IconButton className={classes.icon}>
              <Icon>
                <img
                  className={classes.img}
                  src={Preview}
                  height={20}
                  width={20}
                  alt="print"
                />
              </Icon>
            </IconButton>
          </Toolbar>
        <Dialog
          open={openDocuments}
          onClose={() => {setOpenDocuments(false)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Download Documents</DialogTitle>
        <DialogContent>
          <List>
              {location.state.documents && location.state.documents.map((doc,index) => (
                <ListItem key={doc._id}>
                  <ListItemText>{index+1}. {doc.name}</ListItemText>
                  <ListItemSecondaryAction className={classes.listAction}>
                    <IconButton href={doc.document_url} target="blank">
                     {' '} <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDocuments(false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    
      <Dialog
        open={openLinks}
        onClose={() => {setOpenLinks(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Hot Link URL(s)</DialogTitle>
        <DialogContent>
          <List>
            {location.state.hotLinks && location.state.hotLinks.map((link,index) => (
              <ListItem key={index} component="a" href={link} target="blank">
                <ListItemText title={link.length > 55 ? link : null}>
                  {index + 1}. {link.length > 55
                    ? `${link.slice(0, 55)}  ...`
                    : link}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenLinks(false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    
      </CardContent>

      <CardContent className={classes.bgContent}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <span className={classes.topHeaderFont}>Case Number</span>
              <br />
              <span className={classes.bottomHeaderFont}>
                {takeSim.caseNumber}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className={classes.topHeaderFont}>Case Record Type</span>
              <br />
              <span className={classes.bottomHeaderFont}>
                {takeSim.caseRecordType}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className={classes.topHeaderFont}>Date/Time opened</span>
              <br />
              <span className={classes.bottomHeaderFont}>
                {takeSim.openedDate}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className={classes.topHeaderFont}>
                Hours/Days on the case
              </span>
              <br />
              <span className={classes.bottomHeaderFont}>
                {takeSim.passedTime}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className={classes.topHeaderFont}>Recent Case</span>
              <br />
              <span className={classes.bottomHeaderFont}>
                {takeSim.recentCase}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className={classes.topHeaderFont}>Recent Case Date</span>
              <br />
              <span className={classes.bottomHeaderFont}>
                {takeSim.recentCaseDate}
              </span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.keywordSelectionGrid}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Navbar
              keywords={keywords}
              fetchCaseCaller={fetchCaseCaller}
              fetchDemographics={fetchDemographics}
              saveCaseVaules={saveCaseVaules}
              levelClips={levelClips}
              currentClip={currentClip}
              isRestartLevel={isRestartLevel}
            />
          </Grid>
          <Grid item xs={4} className={classes.tabPadding}>
            <div className={classes.root}>
              <div className={classes.demo1}>
                <AntTabs value={value} onChange={handleChange}>
                  <AntTab
                    style={{ marginRight: 30 }}
                    label="Case History (3+)"
                  />{" "}
                  {/* need to change */}
                  <AntTab label="More Information" />
                </AntTabs>
              </div>
            </div>
            <TabPanel value={value} index={0} className={classes.tabBody}>
              <History history={history} />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabBody}>
              <Grid container spacing={5}>
                <Grid item xs={1} className={classes.moreButton}>
                  Post
                </Grid>
                <Grid item xs={3} className={classes.moreButton}>
                  Note Check
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={
                    patient ? classes.selectedButton : classes.moreButton
                  }
                >
                  New Patient
                </Grid>
                <Grid item xs={3} className={classes.moreButton}>
                  New Request
                </Grid>
                <Grid item xs={2} className={classes.moreButton}>
                  Email
                </Grid>
                <Grid item xs={3} className={classes.moreButton}>
                  Call Back
                </Grid>
                <Grid item xs={12} className={classes.moreTitle}>
                  New Patient
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <InputLabel
                      htmlFor="accountNumber"
                      className={classes.formFont}
                    >
                      Account Number
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.margin}
                    />
                    <InputLabel
                      htmlFor="salutation"
                      className={classes.formFont}
                    >
                      Salutation
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="select-filter-type"
                      id="simple-select-filter"
                      size="small"
                      className={classes.select}
                    >
                      <MenuItem selected>None</MenuItem>
                    </Select>
                    <InputLabel
                      htmlFor="firstName"
                      className={classes.formFont}
                    >
                      First Name
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.margin}
                    />
                    <InputLabel
                      htmlFor="MiddleName"
                      className={classes.formFont}
                    >
                      Middle Name
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.margin}
                    />
                    <InputLabel htmlFor="suffix" className={classes.formFont}>
                      Suffix
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.margin}
                    />
                    <InputLabel
                      htmlFor="preferredNumber"
                      className={classes.formFont}
                    >
                      Preferred Number
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="select-filter-type"
                      id="simple-select-filter"
                      size="small"
                      className={classes.select}
                    >
                      <MenuItem selected>Cell</MenuItem>
                    </Select>
                    <InputLabel htmlFor="gender" className={classes.formFont}>
                      Gender
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="select-filter-type"
                      id="simple-select-filter"
                      size="small"
                      className={classes.select}
                    >
                      <MenuItem selected>None</MenuItem>
                    </Select>
                    <InputLabel htmlFor="call" className={classes.formFont}>
                      Call
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.margin}
                    />
                    <InputLabel htmlFor="home" className={classes.formFont}>
                      Home
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.margin}
                    />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Card>
     
    </Paper>
  );
};
TakeSimDetails.propTypes = {
  takeSim: PropTypes.object,
  fetchTakeSim: PropTypes.func,
  fetchDemographics: PropTypes.func,
  saveCaseVaules: PropTypes.func,
  fetchCaseCaller: PropTypes.func,
  keywords: PropTypes.array,
  history: PropTypes.array,
  fetchHistory: PropTypes.func,
  levelClips: PropTypes.array,
  currentClip: PropTypes.object,
  isRestartLevel: PropTypes.bool,
};
Navbar.propTypes = {
  keywords: PropTypes.array,
  fetchCaseCaller: PropTypes.func,
  fetchDemographics: PropTypes.func,
  saveCaseVaules: PropTypes.func,
  levelClips: PropTypes.array,
  currentClip: PropTypes.object,
  isRestartLevel: PropTypes.bool,
};
History.propTypes = {
  history: PropTypes.array,
};
Demographics.propTypes = {
  keywords: PropTypes.array,
  levelClips: PropTypes.array,
  fetchCaseCaller: PropTypes.func,
  fetchDemographics: PropTypes.func,
  saveCaseVaules: PropTypes.func,
  currentClip: PropTypes.object,
  isRestartLevel: PropTypes.bool,
};
TabPanel.propTypes = {
  children: PropTypes.object,
  index: PropTypes.number,
  value: PropTypes.number,
};
const mapStateToProps = (state) => ({
  keywords: state.sim.createKeywords,
  takeSim: state.sf.callerCaseDetails,
  history: state.sf.caseHistory,
  levelClips: state.sim.levelClips.sim_scripts || [],
  currentClip: state.sim.currentClip,
  isRestartLevel: state.sim.isRestartLevel,
});

const mapDispatchToProps = {
  fetchDemographics,
  fetchCaseCaller,
  saveCaseVaules,
};

export default connect(mapStateToProps, mapDispatchToProps)(TakeSimDetails);
