export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SAVE_PERMISSIONS = 'SAVE_PERMISSIONS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
