export const CreateUser = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(100),
      height: theme.spacing(100),
    },
  },
  formControl: {
    '& .MuiInputBase-input': {
      backgroundColor: '#fff',
      fontSize: 13,
    },
    '& .MuiTypography-body1': {
      fontSize: 13,
      fontWeight: '500',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.1rem',
    },
    width: '78%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
    fontSize: 13,
    fontWeight: '500',
    borderColor: '#e64c4cde',

  },
  paper: {
    boxShadow: 'none !important',
  },
  label: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: theme.spacing(4),
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  initialLabel: {
    padding: '16px 16px 8px 0px',
    marginTop: 0,
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  statusLabel: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: 9,
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  cssOutlinedInput: {},
  cssFocused: {},
  notchedOutline: {},
  updateBtn: {
    color: 'rgb(136,38,71)',
    border: '2px solid rgb(136,38,71)',
    fontSize: 12,
    fontWeight: '600',
    background: 'none !important',
    width: '78%',
  },
  title: {
    padding: '20px 0 8px 28px',
    color: '#000',
    fontSize: 16,
    fontWeight: '700',
  },
  groupTitle: {
    padding: '20px 0 8px 0',
    color: '#000',
    fontSize: 16,
    fontWeight: '700',
  },
  mainTitle: {
    padding: '5px 0 8px 28px',
    color: '#000',
    fontSize: 20,
    fontWeight: '700',
  },
  radioGroup: {
    marginLeft: 28,
    paddingBottom: theme.spacing(2),
  },
  height: {
    height: 208,
    boxShadow: 'none !important',
  },
  padding: {
    padding: '0 20px 20px 20px',
    boxShadow: 'none !important',
  },
  buttonDiv: {
    width: '100%',
    padding: 10,
  },
  saveBtn: {
    float: 'right',
    width: '15%',
  },
  asterisk: {
    color: '#f25a4a',
    fontSize: 15,
  },
  error: {
    fontSize: 10,
    fontWeight: 400,
    color: '#f25a4a',
  },
  helperText: {
    '& .MuiFormHelperText-contained': {
      color: 'red',
    },
    '& .MuiInputBase-input': {
      '-webkit-text-security': 'disc',
    },
  },
  breadcrumbs: {
    textDecoration: 'underline !important',
    color: '#3a383896',
    fontSize: 14,
  },
  bcBlock: {
    marginTop: -15,
    marginBottom: 5,
  },
  cancelBtn: {
    marginRight: 10,
    border: '2px solid',
    float: 'right',
    width: '15%',
    '&:hover': {
      border: '2px solid',
    },
  },
  email: {
    '& .MuiFormHelperText-contained': {
      color: 'red',
    },
  },
  numFormat: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#fff',
      fontSize: 13,
      height: '2px !important',
    },
  },
  pwd: {
    '& .MuiInputBase-input': {
      '-webkit-text-security': 'disc',
    },
    '& .MuiFormHelperText-contained': {
      color: 'red',
    },
  },
});

export default CreateUser;
