import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .Mui-selected": {
      fontWeight: "bold",
    },
  },
  tabList: {
    "& button": {
      textTransform: "none",
    },
  },
  headTypo: {
    fontSize: "1.2vw",
    fontWeight: 700,
  },
  appbar: {
    borderBottom: "1px solid lightgrey",
  },
}));

export const localTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#black",
    },
    secondary: {
      main: "rgb(70,117,211)",
      contrastText: "#black",
    },
  },
});
