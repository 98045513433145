import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SelectAvatars from "../../components/sim/selectAvatars/SelectAvatars.jsx";
import BasicInfo from "../../components/sim/BasicInfo.jsx";
import Keywords from "../../components/sim/keywords/Keywords.jsx";
import CreateLevels from "../../components/sim/createLevels/CreateLevels.jsx";

const CreateSims = ({ newSim }) => {
  // const useQuery = () => new URLSearchParams(useLocation().search);
  // const query = useQuery();
  if (newSim.activeStep === 0) {
    return (
      <div>
        <BasicInfo />
      </div>
    );
  }
  if (newSim.activeStep === 1) {
    return (
      <div>
        <SelectAvatars />
      </div>
    );
  }
  if (newSim.activeStep === 2) {
    return (
      <div>
        <CreateLevels />
      </div>
    );
  }
  if (newSim.activeStep === 3) {
    return (
      <div>
        <Keywords />
      </div>
    );
  }
  // if (query.get('simId')) {
  //   return (
  //     <div>
  //       <CreateLevels />
  //     </div>
  //   );
  // }
  return (
    <div>
      {/* redirect to sims page */}
      <Redirect to="/sims" />
    </div>
  );
};

CreateSims.propTypes = {
  newSim: PropTypes.shape({
    activeStep: PropTypes.number,
  }),
};

const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
});

export default connect(mapStateToProps)(CreateSims);
