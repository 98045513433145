/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import { Tooltip, Icon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import KeywordsCheckbox from './KeywordsCheckbox.jsx';
import Data from '../../../assets/images/icons/Data.png';
import Print from '../../../assets/images/icons/Print.png';
import Screening from './Screening.jsx';
import PhysicianMap from './PhysicianMap.jsx';
import KBCA from './KBCA.jsx';
import Despositions from './Despositions.jsx';
import KnowledgeArticle from './KnowledgeArticle.jsx';
import Opportunity from './Opportunity.jsx';
import Details from '../Details.jsx';
import {
  fetchCreateKeywords,
  fetchCurrentSim,
  decreaseStep,
  addKeywords,
  reset,
} from '../../../redux/sims/actions';
import { saveKeywords } from '../../../services/result.service';
import LoadCase from './LoadCase';
import { sfReset, setDataLoaded } from '../../../redux/sf/actions';
import {
  useNavbarStyles,
  useTabContentStyles,
  useToolbarStyles,
} from './Keywords';

const EnhancedTableToolbar = (props) => {
  const [showModal, setShowModal] = useState(false);
  // const useQuery = () => new URLSearchParams(useLocation().search);
  // const query = useQuery();
  // const history = useHistory();

  const handleClick = () => {
    props.decreaseStep();
    props.sfReset();
  };
  const handleNext = async () => {
    // if (query.get('simId')) {
    props.keywords.forEach((field) => {
      delete field._id;
      field.fields.forEach((element) => {
        delete element._id;
      });
    });
    // }

    const requestData = {};
    requestData.sim_id = props.newSim.id;
    requestData.tabs = [
      {
        name: 'demographics',
        sections: props.keywords,
      },
    ];
    await saveKeywords(requestData);
    // props.reset();
    // props.sfReset();
    localStorage.setItem('sim_id', requestData.sim_id);
    const data = JSON.stringify({ sim_id: requestData.sim_id });
    localStorage.setItem('state', data);
  };

  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Data Input
      </Typography>
      {/* modal to load case from salesforce proxy */}
      {showModal && (
        <LoadCase showModal={showModal} setShowModal={setShowModal} />
      )}
      <IconButton className={classes.icon} onClick={() => setShowModal(true)}>
        <Icon>
          <img src={Data} height={20} width={20} alt="data" />
        </Icon>
      </IconButton>
      <IconButton className={classes.icon} style={{ marginRight: '1.5rem' }}>
        <Icon>
          <img src={Print} height={20} width={20} alt="print" />
        </Icon>
      </IconButton>
      <Divider orientation="vertical" flexItem style={{ marginTop: '5px' }} />
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '0rem 1rem', width: '43px', minWidth: '37px' }}
        disableElevation
        onClick={handleClick}
      >
        <KeyboardBackspaceOutlinedIcon />
      </Button>
      <Link
        to={{
          pathname: '/sims',
        }}
        target="_blank"
      >
        <Tooltip title="Save & Preview">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            className={classes.saveBtn}
            onClick={handleNext}
          >
            PREVIEW
          </Button>
        </Tooltip>
      </Link>
      <Divider />
    </Toolbar >
  );
};

EnhancedTableToolbar.propTypes = {
  decreaseStep: PropTypes.func,
  reset: PropTypes.func,
  keywords: PropTypes.array,
  newSim: PropTypes.object,
  sfReset: PropTypes.func,
};

const Navbar = ({ keywords, addKeywords }) => {
  const activeTabColorChange = {
    backgroundColor: 'white',
    color: 'rgb(69, 66, 158)',
    borderRadius: '3px',
  };

  const [value, setValue] = React.useState('0');
  const handleTabs = (event, value) => {
    setValue(value);
  };
  const classes = useNavbarStyles();

  return (
    <TabContext value={value}>
      <AppBar position="static" elevation={0} className={classes.navbar}>
        <TabList
          onChange={handleTabs}
          TabIndicatorProps={{ style: { height: '0px' } }}
        >
          <Tab
            label="Demographics"
            value="0"
            style={value === '0' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="Screening"
            value="1"
            style={value === '1' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="KB CA"
            value="2"
            style={value === '2' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="KB Other"
            value="3"
            style={value === '3' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="Opportunities"
            value="4"
            style={value === '4' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="Physician Map"
            value="5"
            style={
              (value === '5' ? activeTabColorChange : null,
                { margin: '0px 10px' })
            }
            className={classes.tab}
          />
          <Tab
            label="Dispositions"
            value="6"
            style={value === '6' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="Related"
            value="7"
            style={value === '7' ? activeTabColorChange : null}
            className={classes.tab}
          />
          <Tab
            label="More Information"
            value="8"
            style={value === '8' ? activeTabColorChange : null}
            className={classes.tab}
          />
        </TabList>
      </AppBar>
      <TabPanel value="0" className={classes.tabPanel}>
        <Demographics keywords={keywords} addKeywords={addKeywords} />
      </TabPanel>
      <TabPanel value="1" className={classes.tabPanel}>
        <Screening />
      </TabPanel>
      <TabPanel value="2" className={classes.tabPanel}>
        <KBCA />
      </TabPanel>
      <TabPanel value="3" className={classes.tabPanel}>
        <KnowledgeArticle />
      </TabPanel>
      <TabPanel value="4" className={classes.tabPanel}>
        <Opportunity />
      </TabPanel>
      <TabPanel value="5" className={classes.tabPanel}>
        <PhysicianMap />
      </TabPanel>
      <TabPanel value="6" className={classes.tabPanel}>
        <Despositions />
      </TabPanel>
    </TabContext>
  );
};

Navbar.propTypes = {
  keywords: PropTypes.array,
  addKeywords: PropTypes.func,
};

const Demographics = ({ keywords, addKeywords }) => {
  const classes = useTabContentStyles();
  const [data, setData] = React.useState(keywords);

  const addDataToRedux = () => {
    addKeywords(data);
  };

  const handleCheckboxChange = async (event, fieldTitleIndex, fieldIndex) => {
    const array = [...data];
    array[fieldIndex].fields[fieldTitleIndex].is_scorable = event.target.checked;
    setData(array);
    addDataToRedux();
  };
  const handleInputChange = async (event, fieldTitleIndex, fieldIndex) => {
    const array = [...data];
    const { value } = event.target;
    array[fieldIndex].fields[fieldTitleIndex].value = value;
    setData(array);
  };

  return (
    <Grid container spacing={3}>
      {data.map((keyword, fieldIndex) => (
        <Grid item xs={6} key={keyword.name}>
          <Typography className={classes.title}>
            <ArrowDropDownIcon className={classes.arrowDropDown} />
            {keyword.name}
          </Typography>
          <Grid container spacing={2}>
            {keyword.fields.map((fieldName, fieldTitleIndex) => (
              <Grid item xs={6} key={fieldName.fieldTitle}>
                <KeywordsCheckbox
                  name={fieldName.fieldTitle}
                  value={fieldName.value ? fieldName.value : ''}
                  handleCheckboxChange={handleCheckboxChange}
                  handleInputChange={handleInputChange}
                  fieldTitleIndex={fieldTitleIndex}
                  fieldIndex={fieldIndex}
                  scorable={fieldName.is_scorable}
                  save={addDataToRedux}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

Demographics.propTypes = {
  keywords: PropTypes.array,
  addKeywords: PropTypes.func,
};

const Keywords = ({
  fetchCurrentSim,
  fetchCreateKeywords,
  decreaseStep,
  reset,
  currentSim,
  keywords,
  sims,
  caseDetails,
  addKeywords,
  sfReset,
  setDataLoaded,
  dataLoaded,
}) => {
  useEffect(() => {
    if (sims.id && currentSim && Object.keys(currentSim).length === 0) {
      fetchCurrentSim(sims.id);
    }
  }, [fetchCurrentSim, sims.id, currentSim]);

  useEffect(() => {
    if (!keywords.length) {
      fetchCreateKeywords();
    }
  }, [keywords, fetchCreateKeywords]);

  const [keywordArray, setKeywordArray] = React.useState(keywords);
  useEffect(() => {
    setKeywordArray(keywords);
  }, [keywordArray, keywords]);

  useEffect(() => {
    if (Object.keys(caseDetails).length !== 0 && dataLoaded) {
      const data = [...keywordArray];
      for (const keyword of data) {
        for (const field of keyword.fields) {
          if (field.attribute_name in caseDetails) {
            if (caseDetails[field.attribute_name] !== null) {
              field.is_scorable = !caseDetails[field.attribute_name]
                ? caseDetails[field.attribute_name]
                : true;
            }
            field.value = caseDetails[field.attribute_name];
          }
        }
      }
      setKeywordArray(data);
      addKeywords(data);
      setDataLoaded(false);
    }
  }, [caseDetails, dataLoaded, addKeywords, keywordArray, setDataLoaded]);
  return (
    <Paper>
      <EnhancedTableToolbar
        decreaseStep={decreaseStep}
        reset={reset}
        keywords={keywordArray}
        newSim={sims}
        sfReset={sfReset}
      />
      <Details currentSim={currentSim} title={sims.title} />
      {keywordArray.length > 0 && (
        <Navbar keywords={keywordArray} addKeywords={addKeywords} />
      )}
    </Paper>
  );
};

Keywords.propTypes = {
  fetchCurrentSim: PropTypes.func,
  fetchCreateKeywords: PropTypes.func,
  decreaseStep: PropTypes.func,
  reset: PropTypes.func,
  currentSim: PropTypes.object,
  keywords: PropTypes.array,
  sims: PropTypes.object,
  caseDetails: PropTypes.object,
  addKeywords: PropTypes.func,
  sfReset: PropTypes.func,
  setDataLoaded: PropTypes.func,
  dataLoaded: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  status: state.sim.status,
  sims: state.sim.newSim,
  keywords: state.sim.createKeywords,
  currentSim: state.sim.currentSim,
  caseDetails: state.sf.caseDetails,
  dataLoaded: state.sf.dataLoaded,
});

const mapDispatchToProps = {
  fetchCreateKeywords,
  fetchCurrentSim,
  decreaseStep,
  addKeywords,
  reset,
  sfReset,
  setDataLoaded,
};

// connect Sims component to redux store

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
