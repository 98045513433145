export const Roles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '1.4rem',
    boxShadow: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
    fontWeight: [600],
    fontSize: '0.7em',
    background: '#778AAC',
    color: '#fff !important',
    padding: '.3rem .8rem',
  },
  label: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: theme.spacing(2),
    color: '#000',
    fontSize: 'small',
  },
  roleGrid: {
    background: '#EDF1F9',
    padding: '.8rem .9rem',
    borderRadius: '6px',
    marginTop: '2rem',
  },
  addRoleGrid: {
    background: '#FBEFF3',
    padding: '.8rem .9rem',
    borderRadius: '6px',
    marginTop: '2rem',
  },
  boldFont: {
    fontWeight: '700',
  },
  select: {
    padding: theme.spacing(0, 1, 0, 1),
    marginRight: 10,
    background: '#fff',
    borderRadius: 5,
  },
  input: {
    background: '#fff',
  },
  search: {
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: '#FFF',
    width: '100%',
    border: '2px solid #e1e1e1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  inputRoot: {
    color: 'inherit',
    flexGrow: 1,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  whiteColor: {
    color: '#fff !important',
    cursor: 'alias',
  },
  transprentBackground: {
    background: 'transparent !important',
  },
  bodyCell: {
    background: '#fff',
    padding: '11px 8px',
    margin: '.6rem 0',
    border: 'none',
  },
  leftTdRadius: {
    borderRadius: '4px 0 0 4px',
  },
  rightTdRadius: {
    borderRadius: '0 4px 4px 0',
  },
  headingBorder: {
    borderBottomLeftRadius: 5,
  },
  textRight: {
    textAlign: 'right',
    paddingRight: '.8rem !important',
    borderBottomRightRadius: 5,
  },
  pySmall: {
    padding: '.4rem 0',
    border: 'none',
  },
  addRoleButton: {
    background: '#882847',
    color: '#FFF',
    width: 150,
    height: 35,
    float: 'right',
    '&:hover': {
      backgroundColor: '#7c1335',
    },
  },
  disableAddBtn: {
    background: '#882847',
    color: '#FFF',
    width: 150,
    height: 35,
    float: 'right',
    pointerEvents: 'none',
    opacity: '0.5',
  },
  cancelButton: {
    color: '#313387',
  },
  cancel: {
    color: 'rgb(136,38,71)',
    border: '2px solid rgb(136,38,71)',
    padding: '5px 30px 5px 30px',
    width: '45%',
    float: 'right',
    background: 'none !important',
    marginRight: 10,
  },
  duplicate: {
    color: '#882847',
  },
  disableDuplicate: {
    color: '#882847',
    pointerEvents: 'none',
    opacity: '0.5',
  },
  disableSwitch: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
});

export default Roles;
