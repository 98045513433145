import { Checkbox } from "@material-ui/core";
import React from "react";

const KeywordCheckBox = ({
  classes,
  handleBlur,
  fieldName,
  handleCheckBoxChange,
}) => {
  return (
    <Checkbox
      className={classes.selectedCheckbox}
      onBlur={handleBlur}
      onChange={handleCheckBoxChange}
      checked={
        fieldName.current_value && fieldName.current_value === "true"
          ? true
          : false
      }
      inputProps={{
        "data-currentvalue": fieldName.value,
        "data-caseid": fieldName._id,
        "data-inputtype": fieldName.type,
        "data-isscorable": fieldName.is_scorable,
        "data-fieldtitle": fieldName.fieldTitle,
      }}
    />
  );
};
export default KeywordCheckBox;
