export const AddAssignment = (theme) => ({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'normal',
      justifyContent: 'flex-end',
    },
    '& .MuiDialog-paper': {
      margin: 0,
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: 'max-content',
    },
    '& .MuiDialog-paperFullWidth': {
      padding: '0px 28px',
      width: 'calc(32% - 64px)',
    },
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '18px',
      fontWeight: '700',
      [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
      },
    },
    padding: 0,
  },
  modalBody: {
    margin: theme.spacing(1, 7, 7, 7),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1, 3, 3, 3),
    },
  },
  label: {
    color: '#232222fa',
    fontWeight: 500,
    padding: theme.spacing(4, 0, 0, 0),
    fontSize: '15px',
  },
  select: {
    width: '100%',
    padding: theme.spacing(0, 1, 0, 1),
    marginTop: 10,
    height: 34,
    '& .MuiSelect-select': {
      background: '#ffffff',
      fontSize: '15px'
    },
  },
  labelFont: {
    fontSize: 15,
  },
  link: {
    color: '#5a5959',
    cursor: 'pointer',
    borderBottom: '1px solid #949090',
    fontSize: '15px',
  },
  text: {
    width: 68,
    marginTop: 10,
  },
  notes: {
    marginTop: 10,
    '& .MuiTextField-root': {
      fontSize: '15px',
    },
    '& .MuiFilledInput-multiline': {
      fontSize: '15px',
      padding: '7px 7px 10px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '15px',
    }

  },
  assignButton: {
    borderWidth: '2px',
    marginTop: 25,
    width: '97%',
    float: 'right',
    padding: '5px 23px 5px 23px',
    fontSize: '13px',
    height: '34px',
  },
  cancel: {
    color: 'rgb(136,38,71)',
    padding: '5px 23px 5px 23px',
    background: 'none !important',
    marginRight: 10,
    marginTop: 25,
    width: '97%',
    fontSize: '13px',
  },
});

export default AddAssignment;
