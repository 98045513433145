// Function to check whether the user is permitted to view/create/update/delete a particular task.
import store from '../redux/store';

export const permissionCheck = (task, type) => {
  // fetch current state
  const state = store.getState();
  const { permissions } = state.login;
  let permited;
  let accessPermission = 0;
  if (permissions && permissions.length) {
    // eslint-disable-next-line array-callback-return
    permissions.map((permission) => {
      if (type === 'view') {
        const sameTaskArray = permission.permission_details.filter(
          (ele) => ele.task_name === task,
        );
        const firstType = sameTaskArray[0] ? sameTaskArray[0].permission_type : null;
        const secondType = sameTaskArray[1] ? sameTaskArray[1].permission_type : null;
        const thirdType = sameTaskArray[2] ? sameTaskArray[2].permission_type : null;
        const fourthType = sameTaskArray[3] ? sameTaskArray[3].permission_type : null;
        permited = permission.permission_details.some(
          (ele) => ele.permission_type === firstType
                || ele.permission_type === secondType
                || ele.permission_type === thirdType
                || ele.permission_type === fourthType,
        );
        if (permited) {
          accessPermission += 1;
        }
      } else {
        permited = permission.permission_details.some((ele) => (ele.task_name === task)
        && (ele.permission_type === type));
        if (permited) {
          accessPermission += 1;
        }
      }
    });
  }
  if (accessPermission > 0) {
    return true;
  }
  return false;
};
export default permissionCheck;
