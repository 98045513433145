import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AdminIcon from '../../assets/images/icons/Admin.png';
// import DescriptionIcon from "@material-ui/icons/Description";
// import BuildIcon from "@material-ui/icons/Build";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles, useTheme, createMuiTheme } from "@material-ui/core/styles";
import Draggable from "react-draggable";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, ThemeProvider } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// import moment from "moment";
import { connect } from "react-redux";
import StyledLink from "./StyledLink";
import NewSimMenu from "./NewSimMenu.jsx";
import { userLogout } from "../../redux/authentication/action";
import { notesSave, fetchNotes, deleteNotes, reset } from "../../redux/sims/actions";
import ResultScreen from "./ResultScreen.jsx";
import Dragicon from "../../assets/images/Dragicon.png";
import Notes from '../../assets/images/icons/Notes.png';
import Logout from '../../assets/images/icons/logout.png';
import Logo from '../../assets/images/icons/iLearnLogo.png';
import SimsPermissions from '../../permissions/sims';
import DashboardPermissions from '../../permissions/dashboard';
import AdminPermissions from '../../permissions/admin';
import { permissionCheck } from '../../utils/permissionCheck';
import ResponsiveDrawerStyle from './ResponsiveDrawer';

const menuTheme = createMuiTheme({
  palette: {
    divider: 'rgb(51, 64, 88)',
    action: {
      selected: "#3061b9",
    },
  },
});

const ResponsiveDrawer = (props) => {
  const {
    allNotes,
    window,
    title,
    subtitle,
    children,
    userLogout,
    notesSave,
    fetchNotes,
    deleteNotes,
    showResultButtons,
    reset,
    permissions,
    userDetails,
  } = props;
  const useStyles = makeStyles((theme) => ResponsiveDrawerStyle(theme));
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const [openAllNotes, setAllNotes] = React.useState(false);
  const [viewAll, setViewAll] = React.useState(false);
  const [controlledPosition, setControlledPosition] = React.useState({
    x: 0,
    y: 0,
  });
  const [editNote, setEditNote] = React.useState("");
  const [showSims, setShowSims] = React.useState(false);
  const [showAdmin, setShowAdmin] = React.useState(false);
  const [showNotes, setShowNotes] = React.useState(false);
  const [createNotes, setCreateNotes] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNotes = () => {
    setOpenNotes(true);
  };

  const handleAllNotes = () => {
    setAllNotes(true);
  };

  const handleClose = () => {
    setOpenNotes(false);
    setNotes("");
    setControlledPosition({ x: 0, y: 0 });
  };

  const handleNotesClose = () => {
    setAllNotes(false);
    setViewAll(false);
    setControlledPosition({ x: 0, y: 0 });
    setEditNote("");
  };

  const saveNotes = () => {
    const data = localStorage.getItem("sims_id") ? {
      notes,
      sim_id: localStorage.getItem("sims_id")
    } : {notes};
    setOpenNotes(false);
    setNotes("");
    notesSave(data);
  };

  const deleteNote = (id) => {
    deleteNotes(id);
  };

  const handleDrag = (e, position) => {
    const { x, y } = position;
    setControlledPosition({ x, y });
  };

  const handleChangeNotes = (e) => {
    const id = e.target.name;
    setEditNote({
      id: id,
      note: e.target.value,
    });
  };

  const handleEditNote = () => {
    if (!editNote.id) {
      return
    }
    const data = {
      id: editNote.id,
      notes: editNote.note,
    };
    notesSave(data);
    setEditNote('');
  };

  useEffect(() => {
    if (permissionCheck(
      DashboardPermissions.my_notepad.view.task,
      DashboardPermissions.my_notepad.view.type,
    )) {
      fetchNotes();
    }
  }, [fetchNotes, permissions]);
  useEffect(() => {
    reset();
  }, [reset]);

  let data = [];
  if (!viewAll) {
    data = allNotes?.slice(0, 4);
  } else {
    data = [...allNotes];
  }

  useEffect(() => {
    if (permissionCheck(
      SimsPermissions.sim_list.create.task,
      SimsPermissions.sim_list.create.type,
    ) || permissionCheck(
      SimsPermissions.sim_list.update.task,
      SimsPermissions.sim_list.update.type,
    ) || permissionCheck(
      SimsPermissions.sim_list.delete.task,
      SimsPermissions.sim_list.delete.type,
    ) || permissionCheck(
      SimsPermissions.sim_list.view.task,
      SimsPermissions.sim_list.view.type,
    )) {
      userDetails.role.toLowerCase() !== 'agent' ? setShowSims(true) : setShowSims(false);
    } else {
      setShowSims(false);
    }
  }, [setShowSims, permissions, userDetails]);
  useEffect(() => {
    if (permissionCheck(
      AdminPermissions.employee_list.create.task,
      AdminPermissions.employee_list.create.type,
    ) || permissionCheck(
      AdminPermissions.employee_list.update.task,
      AdminPermissions.employee_list.update.type,
    ) || permissionCheck(
      AdminPermissions.employee_list.delete.task,
      AdminPermissions.employee_list.delete.type,
    ) || permissionCheck(
      AdminPermissions.employee_list.view.task,
      AdminPermissions.employee_list.view.type,
    ) || permissionCheck(
      AdminPermissions.groups.create.task,
      AdminPermissions.groups.create.type,
    ) || permissionCheck(
      AdminPermissions.groups.update.task,
      AdminPermissions.groups.update.type,
    ) || permissionCheck(
      AdminPermissions.groups.delete.task,
      AdminPermissions.groups.delete.type,
    ) || permissionCheck(
      AdminPermissions.groups.view.task,
      AdminPermissions.groups.view.type,
    ) || permissionCheck(
      AdminPermissions.permissions.create.task,
      AdminPermissions.permissions.create.type,
    ) || permissionCheck(
      AdminPermissions.permissions.update.task,
      AdminPermissions.permissions.update.type,
    ) || permissionCheck(
      AdminPermissions.permissions.view.task,
      AdminPermissions.permissions.view.type,
    ) || permissionCheck(
      AdminPermissions.roles.create.task,
      AdminPermissions.roles.create.type,
    ) || permissionCheck(
      AdminPermissions.roles.update.task,
      AdminPermissions.roles.update.type,
    ) || permissionCheck(
      AdminPermissions.roles.delete.task,
      AdminPermissions.roles.delete.type,
    ) || permissionCheck(
      AdminPermissions.roles.view.task,
      AdminPermissions.roles.view.type,
    )) {
      userDetails.role.toLowerCase() !== 'agent' ? setShowAdmin(true) : setShowAdmin(false);
    } else {
      setShowAdmin(false);
    }
  }, [setShowAdmin, permissions, userDetails]);
  useEffect(() => {
    if (permissionCheck(
      DashboardPermissions.my_notepad.view.task,
      DashboardPermissions.my_notepad.view.type,
    )) {
      setShowNotes(true);
    } else {
      setShowNotes(false);
    }
  }, [setShowNotes, permissions]);

  useEffect(() => {
    if (permissionCheck(
      DashboardPermissions.my_notepad.create.task,
      DashboardPermissions.my_notepad.create.type,
    )) {
      setCreateNotes(true);
    } else {
      setCreateNotes(false);
    }
  }, [setCreateNotes, permissions]);

  const drawer = (
    <ThemeProvider theme={menuTheme}>
      <div className={classes.toolbar} />
      <List>
        <StyledLink to="/dashboard">
          <ListItem
            variant="contained"
            button
            key="dashboard"
            className={classes.menuContainer}
            selected={title === "DASHBOARD"}
          >
            <ListItemIcon>
              <DashboardIcon className={classes.menuIcon} />
            </ListItemIcon>
            <div className={classes.menuText}>DASHBOARD</div>
          </ListItem>
        </StyledLink>
        {title !== "DASHBOARD" && title !== "SIMS" && (
          <Divider variant="middle" />
        )}
        {showSims ?
          <StyledLink to="/sims">
            <ListItem
              button
              key="sims"
              className={classes.menuContainer}
              selected={title === "SIMS"}
            >
              <ListItemIcon>
                <PlayIcon className={classes.menuIcon} />
              </ListItemIcon>
              <div className={classes.menuText}>SIMS</div>
            </ListItem>
          </StyledLink> : null}
        {title !== "SIMS" && title !== "ADMIN" && <Divider variant="middle" />}
        {showAdmin ?
          <StyledLink to="/admin">
            <ListItem
              button
              key="admin"
              className={classes.menuContainer}
              selected={title === "ADMIN"}
            >
              <ListItemIcon>
                <img
                  src={AdminIcon}
                  height={23}
                  width={23}
                  alt="Admin"
                  className={classes.adminIcon}
                />
              </ListItemIcon>
              <div className={classes.menuText}>ADMIN</div>
            </ListItem>
          </StyledLink> : null}
        {title !== "ADMIN" && title !== "ACCOUNT" && (
          <Divider variant="middle" />
        )}
        {/* <StyledLink to="/account">
          <ListItem
            button
            key="account"
            className={classes.menuContainer}
            selected={title === "ACCOUNT"}
          >
            <ListItemIcon>
              <DescriptionIcon className={classes.menuIcon} />
            </ListItemIcon>
            <div className={classes.menuText}>ACCOUNT</div>
          </ListItem>
        </StyledLink> */}
        {title !== "ACCOUNT" && title !== "TOOLS" && (
          <Divider variant="middle" />
        )}
        {/* <StyledLink to="/tools">
          <ListItem
            button
            key="tools"
            className={classes.menuContainer}
            selected={title === "TOOLS"}
          >
            <ListItemIcon>
              <BuildIcon className={classes.menuIcon} />
            </ListItemIcon>
            <div className={classes.menuText}>TOOLS</div>
          </ListItem>
        </StyledLink> */}
        {title !== "TOOLS" && title !== "SETTINGS" && (
          <Divider variant="middle" />
        )}
        <StyledLink to="/settings">
          <ListItem
            button
            key="settings"
            className={classes.menuContainer}
            selected={title === "SETTINGS"}
          >
            <ListItemIcon>
              <SettingsIcon className={classes.menuIcon} />
            </ListItemIcon>
            <div className={classes.menuText}>SETTINGS</div>
          </ListItem>
        </StyledLink>
      </List>
    </ThemeProvider>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const containerRef = React.useRef();
  // changes page title
  useEffect(() => {
    document.title = `iLearnCC | ${title}` || "";
  }, [title]);
  useEffect(() => {
    setOpenNotes(false);
    setNotes("");
    setControlledPosition({ x: 0, y: 0 });
    setAllNotes(false);
    setViewAll(false);
    setControlledPosition({ x: 0, y: 0 });
    setEditNote("");
  }, [title, subtitle])
  return (
    <div ref={containerRef}
      className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar
          disableGutters
          className={classes.customToolbar}
          variant="dense"
        >
          <div className={classes.logo}>
            <img
              src={Logo}
              height={25}
              width={50}
              alt='logo'
            >
            </img>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="small"
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{ width: "20%" }}
            display="flex"
            ml={3}
            alignItems="center"
          >
            <Typography
              variant="h6"
              color={subtitle ? "primary" : "textPrimary"}
              className={classes.title}
            >
              {title}
            </Typography>
            {subtitle && (
              <>
                <Box display="flex" alignItems="center">
                  <KeyboardArrowRightIcon fontSize="small" />
                </Box>
                <Typography vairant="h6" className={classes.title}>
                  {subtitle} {showResultButtons ? " > RESULTS " : ""}
                </Typography>
              </>
            )}
          </Box>
          {subtitle === "CREATE SIM" && <NewSimMenu />}
          {subtitle === "TAKE SIM" && showResultButtons && <ResultScreen />}
          <List className={classes.actionContainer}>
            {createNotes ?
              <ListItem button key="add note" disableGutters>
                <img
                  src={Notes}
                  height={30}
                  width={30}
                  alt="notes"
                  onClick={handleNotes}
                />
                <Draggable
                  handle="#drag"
                  position={controlledPosition}
                  onDrag={handleDrag}
                >
                  <Popover
                  container={containerRef.current}
                    style={{ width: '25%', zIndex: 0 }}
                    open={openNotes}
                    // onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 47, left: 1103 }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div style={{ padding: "0.5rem", width: "293px" }}>
                      <div
                        style={{
                          backgroundColor: "#882647",
                          borderRadius: "3px",
                        }}
                      >
                        <Tooltip title="Close">
                          <IconButton
                            style={{ padding: "1px" }}
                            onClick={handleClose}
                          >
                            <CloseIcon
                              style={{
                                backgroundColor: "#741C39",
                                float: "left",
                                color: "white",
                                padding: 5,
                                borderRadius: "1px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          style={{
                            display: "inline-block",
                            padding: "4px 12px",
                            color: "white",
                            fontSize: "0.8rem",
                          }}
                        >
                          New Class Note
                        </Typography>
                        <IconButton
                          id="drag"
                          style={{ float: "right", padding: 5 }}
                        >
                          <img
                            src={Dragicon}
                            width={17}
                            height={17}
                            alt="drag icon"
                          />
                        </IconButton>
                      </div>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                          style: { fontSize: 12, paddingLeft: 8 },
                        }}
                        fullWidth
                        multiline
                        rows={5}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        style={{
                          backgroundColor: "#F1F1F1",
                          marginTop: "11px",
                          borderRadius: "4px",
                        }}
                        placeholder="Type your note here"
                      />
                      <Button
                        size="small"
                        style={{
                          fontWeight: 600,
                          padding: "3px 11px",
                          backgroundColor: "#F8DEE7",
                          float: "right",
                          color: "#A55B73",
                          margin: "8px 0px",
                        }}
                        onClick={saveNotes}
                      >
                        <CheckIcon
                          style={{ width: "17px", marginRight: "5px" }}
                        />
                        Save
                      </Button>
                    </div>
                  </Popover>
                </Draggable>
              </ListItem>
              : ''
            }

            <ListItem button key="see notes">
              <Draggable
                handle="#drag"
                position={controlledPosition}
                onDrag={handleDrag}
              >
                <Popover
                  open={openAllNotes}
                  style={{ zIndex: 0, marginTop: 25, width: '25%' }}
                  // onClose={handleNotesClose}
                  container={containerRef.current}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 47, left: 1103 }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "0.5rem", width: "293px" }}>
                    <div
                      style={{
                        backgroundColor: "#882647",
                        borderRadius: "3px",
                      }}
                    >
                      <Tooltip title="Close">
                        <IconButton
                          style={{ padding: "1px" }}
                          onClick={handleNotesClose}
                        >
                          <CloseIcon
                            style={{
                              backgroundColor: "#741C39",
                              float: "left",
                              color: "white",
                              padding: 5,
                              borderRadius: "1px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        style={{
                          display: "inline-block",
                          padding: "4px 12px",
                          color: "white",
                          fontSize: "0.8rem",
                        }}
                      >
                        Class Notes ({allNotes?.length})
                      </Typography>
                      <IconButton
                        id="drag"
                        style={{ float: "right", padding: 5 }}
                      >
                        <img
                          src={Dragicon}
                          width={17}
                          height={17}
                          alt="drag icon"
                        />
                      </IconButton>
                    </div>
                    {data?.map((note) => {
                      return (
                        <div
                          key={note._id}
                          style={{
                            backgroundColor: "#F1F1F1",
                            marginTop: "11px",
                            borderRadius: "4px",
                            padding: "5px",
                          }}
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              style={{ padding: "0px", float: "right" }}
                              onClick={() => deleteNote(note._id)}
                            >
                              <CloseIcon
                                style={{
                                  borderRadius: "3px",
                                  width: "15px",
                                  height: "15px",
                                  padding: "2px",
                                  backgroundColor: "white",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Save">
                            <IconButton
                              style={{
                                padding: "0px",
                                float: "right",
                                marginRight: 8,
                              }}
                              onClick={handleEditNote}
                            >
                              <CheckIcon
                                style={{
                                  borderRadius: "3px",
                                  width: "15px",
                                  height: "15px",
                                  padding: "2px",
                                  backgroundColor: "white",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <div style={{ margin: "5px 0px 5px 8px" }}>
                            {/* <Typography variant="subtitle1" style={{ fontSize: 12 }}>
                                  {note.notes}
                                </Typography> */}
                            <TextField
                              InputProps={{
                                disableUnderline: true,
                                style: { fontSize: 12 },
                              }}
                              fullWidth
                              multiline
                              // rows={5}
                              name={note._id}
                              value={
                                editNote?.id === note._id
                                  ? editNote.note
                                  : note.notes
                              }
                              onChange={(e) => handleChangeNotes(e)}
                              style={{
                                backgroundColor: "#F1F1F1",
                                borderRadius: "4px",
                              }}
                            // placeholder="Type your note here"
                            />
                             <Tooltip title={ note.sim_name === 0 ? 'None' : note.sim_name}>
                                <Typography
                                  variant="caption"
                                  style={{ fontSize: 12, color: "#AEADAE" }}
                                >
                                  {note?.sim_name? note?.sim_name?.length > 22
                                    ? `Sim Name - ${note.sim_name.slice(0, 22)}  ...`
                                    :`Sim Name - ${note.sim_name}` : ''}
                                </Typography>
                            </Tooltip>
                           
                            <br />
                            <Typography
                              variant="caption"
                              style={{ fontSize: 12, color: "#AEADAE" }}
                            >
                              Added On - {(new Date(note.createdAt)).toDateString()}
                            </Typography>
                            <br />
                            <Typography
                              variant="caption"
                              style={{ fontSize: 12, color: "#AEADAE" }}
                            >
                              Last Updated - {(new Date(note.updatedAt)).toDateString()}
                            </Typography>
                            <br />
                            <Typography
                              variant="caption"
                              style={{ fontSize: 12, color: "#AEADAE" }}
                            >
                              Created By - {note.user}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                    {allNotes.length > 4 && (
                      <Button
                        variant="text"
                        size="small"
                        style={{
                          fontWeight: 600,
                          padding: "3px 11px",
                          color: "#A55B73",
                          margin: "5px 99px",
                        }}
                        onClick={() => setViewAll(true)}
                      >
                        View All
                      </Button>
                    )}
                  </div>
                </Popover>
              </Draggable>
              {showNotes ?
                <ButtonGroup
                  variant="contained"
                  aria-label="split button"
                  size="small"
                  disableElevation
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    NOTES({allNotes?.length})
                  </Button>
                  <Button
                    variant="contained"
                    aria-label="NOTES(6)"
                    className={classes.expand}
                    onClick={handleAllNotes}
                  >
                    {openAllNotes ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </ButtonGroup>
                : ''
              }
            </ListItem>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Tooltip title="Logout" placement="top">
              <ListItem button onClick={() => {
                userLogout(); reset();
              }}
                key="logout" size="small">
                <img
                  src={Logout}
                  height={20}
                  width={18}
                  alt="notes"
                />
              </ListItem>
            </Tooltip>
          </List>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="menu nav">
        {/* The implementation can be swapped with js to avoid SEO duplication of StyledLinks. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div
          className={classes.customToolbar}
          style={{ marginBottom: "20px" }}
        />
        {children}
      </main>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
  notesSave: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  userLogout: PropTypes.func,
  fetchNotes: PropTypes.func,
  deleteNotes: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  allNotes: PropTypes.array,
  showResultButtons: PropTypes.bool,
  permissions: PropTypes.array,
  userDetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  showResultButtons: state.sim.showResultButtons,
  allNotes: state.sim.notes,
  permissions: state.login.permissions,
  userDetails: state.login.user,
});

const mapDispatchToProps = {
  userLogout,
  notesSave,
  fetchNotes,
  deleteNotes,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
