import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    width: "94%",
    height: "1.5rem",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "0.9em",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
  dropDown: {
    width: "65%",
    margin: theme.spacing(2, 0, 2, 0),
  },
  textField: {
    width: "70%",
    margin: theme.spacing(2, 0, 2, 0),
  },
  headTypo: {
    fontSize: "1.2vw",
    fontWeight: 700,
    color: "#000000",
  },
  mainDiv: {
    // width: '100%',
    display: "flex",
    justifyContent: "left",
  },
  mainCard: {
    padding: "0.5vw",
    margin: "1rem",
    width: "100%",
    borderColor: "#EBEAEA",
    border: "1px solid",
  },
  typo: {
    fontSize: "0.9vw",
  },
  searchCard: {
    width: "100%",
    display: "block",
    borderColor: "#EBEAEA",
    border: "1px solid",
    margin: "2rem 0rem",
  },
  searchButton: {
    color: "#4190DC",
    fontSize: "0.9vw",
    marginRight: "0.5vw",
    "& .Mui-focused fieldset": {
      borderColor: "#4190DC !important",
      backgroundColor: "#F4F6F9",
    },
  },
  searchText: {
    marginBottom: "1em",
    minWidth: "29vw",
    height: "4vw",
  },
  tableCard: {
    margin: "2rem 0rem",
    width: "100%",
    borderColor: "#EBEAEA",
    border: "1px solid",
  },
  tableHead: {
    backgroundColor: "#FAFAF9",
  },
  tableHeadCells: {
    padding: "0rem 0.5rem",
    fontSize: "0.9vw",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  tableBodyCells: {
    fontSize: "0.9vw",
    color: "#4190DC",
  },
  bodyProtocolCell: {
    fontSize: "0.9vw",
    color: "#16325C",
  },
  checkboxStyle: {
    padding: "0px",
  },
}));
