import { InputBase, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: "normal",
    paddingLeft: ".8rem",
  },
  iconStyle: {
    color: "#fff",
    background: "rgb(62, 214, 151)",
    borderRadius: "5px",
    padding: "4px",
  },
  gridStyle: {
    margin: "1.7rem 0",
    paddingLeft: "1.8rem",
  },
  lableStyle: {
    marginBottom: "5px",
    color: "#333",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
    marginTop: "0px !important",
  },
  gridButtonStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  buttonStyle: {
    background: " rgb(53, 103, 168)",
    color: "#fff",
    "&:hover": {
      background: " rgb(53, 103, 168)",
      color: "#fff",
    },
  },
}));

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
