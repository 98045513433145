import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchKbca, saveSelectedKbca } from "../../../redux/sims/actions";
import { useStyles } from "./KBCA";

const KBCA = ({ fetchKbca, kbca, saveSelectedKbca }) => {
  const [searchWord, setSearchWord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const handleSearch = (event) => {
    const word = event.target.value;
    setSearchWord(word.toLowerCase());
  };

  const searchButton = () => {
    setCurrentPage(1);
    fetchKbca(searchWord);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const onCheck = async (data) => {
    await saveSelectedKbca(data.id);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = kbca.slice(indexOfFirstRow, indexOfLastRow);

  const classes = useStyles();
  return (
    <div className={classes.mainDiv}>
      <Card className={classes.mainCard}>
        <Typography variant="body1" className={classes.headTypo}>
          Select Clinical Area
        </Typography>
        <Card className={classes.searchCard}>
          <CardContent>
            <Typography variant="body1" className={classes.typo}>
              Enter A Clinical Area
            </Typography>
            <form className={classes.mainDiv} noValidate autoComplete="off">
              <TextField
                className={classes.textField}
                variant="outlined"
                size="small"
                onChange={handleSearch}
              />
            </form>
            <Button
              variant="outlined"
              className={classes.searchButton}
              onClick={searchButton}
            >
              Search For Clinical Area
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.tableCard}>
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      padding="none"
                      className={classes.tableHeadCells}
                      align="left"
                    >
                      SELECT
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.tableHeadCells}
                      align="left"
                    >
                      CLINICAL AREA
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.tableHeadCells}
                      align="left"
                    >
                      PROTOCOL
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.tableHeadCells}
                      align="left"
                    >
                      SYNONYMS
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.tableHeadCells}
                      align="left"
                    >
                      APPOINTMENT
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.tableHeadReferCells}
                      align="left"
                    >
                      CENTER REF
                      <span className={classes.space}>PHY REF</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {currentRows.length > 0 &&
                  currentRows.map((data, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableBody key={data.id}>
                        <TableRow role="checkbox" tabIndex={-1} key={data.id}>
                          <TableCell padding="none" align="left">
                            <Checkbox
                              color="primary"
                              size="small"
                              className={classes.checkboxStyle}
                              onClick={() => onCheck(data)}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            padding="none"
                            className={classes.tableBodyCells}
                            align="left"
                          >
                            {data.clinical_area}
                          </TableCell>
                          <TableCell
                            padding="none"
                            className={classes.bodyProtocolCell}
                            align="left"
                          >
                            {data.protocol ? data.protocol : "None"}
                          </TableCell>
                          <TableCell
                            padding="none"
                            className={classes.bodyProtocolCell}
                            align="left"
                          >
                            {data.synonyms.length > 0
                              ? data.synonyms.map((synonym) => (
                                  <li
                                    key={synonym}
                                    className={classes.synonymCell}
                                  >
                                    {synonym}
                                  </li>
                                ))
                              : "null"}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="left"
                            className={classes.bodyProtocolCell}
                          >
                            {data.appointment}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            <RadioGroup
                              className={classes.radioGroup}
                              checked={data.centerRef ? "centerRef" : "phyRef"}
                              name="refer"
                            >
                              <Radio
                                size="small"
                                className={classes.radioButtonLeft}
                                value="centerRef"
                                name="refer"
                              />
                              <Radio
                                size="small"
                                className={classes.radioButtonRight}
                                value="phyRef"
                                name="refer"
                              />
                            </RadioGroup>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
              </Table>
              <Button
                variant="outlined"
                className={classes.searchButton}
                onClick={handlePrev}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                className={classes.searchButton}
                onClick={handleNext}
              >
                Next
              </Button>
            </TableContainer>
          </CardContent>
        </Card>
      </Card>
    </div>
  );
};

KBCA.propTypes = {
  fetchKbca: PropTypes.func,
  kbca: PropTypes.array,
  saveSelectedKbca: PropTypes.func,
};

const mapStateToProps = (state) => ({
  kbca: state.sim.kbca,
});

const mapDispatchToProps = {
  fetchKbca,
  saveSelectedKbca,
};

export default connect(mapStateToProps, mapDispatchToProps)(KBCA);
