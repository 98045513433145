import { makeStyles } from "@material-ui/core/styles";

export const useTabContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  accordionDetails: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  arrowDropDown: {
    backgroundColor: "#D7D7D7",
    margin: "3px 9px -5px 5px",
    borderRadius: "3px",
  },
  cardWidth: {
    minWidth: "61%",
  },
  cardMargin: {
    marginBottom: 15,
  },
  margin: {
    marginTop: 10,
  },
  button: {
    marginTop: 10,
    border: "1px solid #d2cfcff5",
    textTransform: "unset",
    background: "#ffffff",
    color: "#267adc",
    "&:hover": {
      backgroundColor: "#e5e9f3",
    },
  },
  articleName: {
    color: "#267adc",
    fontWeight: 500,
  },
  attachButton: {
    background: "#1566e0",
    color: "#ffffff",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "#0e52b9",
    },
  },
  title: {
    color: "#143365",
    fontWeight: 700,
    fontSize: 16,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& .MuiTablePagination-caption": {
      display: "none",
    },
    "& .MuiTableCell-head": {
      background: "#fafaf9",
      padding: "10px 20px 10px 12px",
    },
  },
  tableFooter: {
    float: "left",
    "& .MuiToolbar-gutters": {
      paddingLeft: 0,
    },
  },
  count: {
    marginTop: 20,
    color: "#143365",
  },
}));

export const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
  button: {
    marginLeft: theme.spacing(1.5),
    backgroundColor: "#ffffff !important",
    border: "1px solid #e0e0e0",
    textTransform: "none",
  },
}));
