import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { SaveAssessorNotes } from '../../services/result.service';
import DashboardPermissions from '../../permissions/dashboard';
import { permissionCheck } from '../../utils/permissionCheck';
import noAssignments from '../../assets/images/noAssignments.png';
import ScoreTableStyle from './scoreTable';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ScoreTable = ({
  scoreList,
  totalScore,
  handleNext,
  handleScore,
  assignment_loading,
  sortBy,
  sort,
  sorting,
}) => {
  const useStyles = makeStyles((theme) => ScoreTableStyle(theme));
  const classes = useStyles();
  const [assessor, setAssessor] = useState(false);
  const [responseId, setResponseId] = useState('');
  const [notes, setNotes] = React.useState('');
  const [noteStatus, setNoteStatus] = React.useState('view');
  const handleChange = () => {
    handleNext();
  };
  const openDialog = (row) => {
    setResponseId(row.response_id);
    setNotes(row.assessor_notes);
    setAssessor(true);
    if (
      !permissionCheck(
        DashboardPermissions.assessor_notes.update.task,
        DashboardPermissions.assessor_notes.update.type,
      )
      && !permissionCheck(
        DashboardPermissions.assessor_notes.create.task,
        DashboardPermissions.assessor_notes.create.type,
      )
      && permissionCheck(
        DashboardPermissions.assessor_notes.view.task,
        DashboardPermissions.assessor_notes.view.type,
      )
    ) {
      setNoteStatus('view');
    } else if (
      permissionCheck(
        DashboardPermissions.assessor_notes.update.task,
        DashboardPermissions.assessor_notes.update.type,
      )
      || permissionCheck(
        DashboardPermissions.assessor_notes.create.task,
        DashboardPermissions.assessor_notes.create.type,
      )
    ) {
      setNoteStatus(row.assessor_notes ? 'edit' : 'add');
    }
  };
  const handleClose = () => {
    setAssessor(false);
    setResponseId('');
  };
  const handleAdd = async () => {
    await SaveAssessorNotes(responseId, { assessor_notes: notes });
    setAssessor(false);
    handleScore();
  };
  const handleEdit = async () => {
    await SaveAssessorNotes(responseId, { assessor_notes: notes }, true);
    setAssessor(false);
    handleScore();
  };
  return (
    <>
      {(assignment_loading && scoreList.length === 0) ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <TableContainer className={classes.tableContainer}>
          <InfiniteScroll
            dataLength={scoreList.length}
            next={handleChange}
            hasMore={scoreList.length < totalScore}
            loader={<p className={classes.tableContainer}>Loading...</p>}
            height={740}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse
          >
            <Table aria-label="assignment table" className={classes.tableContainer}>
              <TableHead className={classes.thead}>
                <TableRow>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'sim' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'sim' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('sim')}
                    >
                      SIM NAME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'level' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'level' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('level')}
                    >
                      LEVEL
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'member' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'member' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('member')}
                    >
                      MEMBER
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'keywordAccuracy' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'keywordAccuracy' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('keywordAccuracy')}
                    >
                      KEYWORD ACCURACY
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.heading} sortDirection={sortBy === 'correct_selections' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'correct_selections' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('correct_selections')}
                    >
                      DATA SELECTION
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'correct_entries' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'correct_entries' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('correct_entries')}
                    >
                      TYPING ACCURACY
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'overall_percentage' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'overall_precentage' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('overall_percentage')}
                    >
                      OVERALL DATA ACCURACY
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'total_time' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'total_time' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('total_time')}
                    >
                      TOTAL TIME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'date' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'date' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('date')}
                    >
                      DATE
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'review_status' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'review_status' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('review_status')}
                    >
                      REVIEW STATUS
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'reviewed_on' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'reviewed_on' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('reviewed_on')}
                    >
                      REVIEWED ON
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.heading} sortDirection={sortBy === 'reviewed_by' ? sort : false}>
                    <TableSortLabel
                      direction={sortBy === 'reviewed_by' ? sort : 'asc'}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting('reviewed_by')}
                    >
                      REVIEWER NAME
                    </TableSortLabel>
                  </TableCell>
                  {permissionCheck(
                    DashboardPermissions.assessor_notes.view.task,
                    DashboardPermissions.assessor_notes.view.type,
                  ) ? (
                    <TableCell className={classes.heading} sortDirection={sortBy === 'assessor_notes' ? sort : false}>
                      <TableSortLabel
                        direction={sortBy === 'assessor_notes' ? sort : 'asc'}

                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sorting('assessor_notes')}
                      >
                        ASSESSOR NOTES
                      </TableSortLabel>
                    </TableCell>
                    ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {scoreList.map((row, index) => (
                  <StyledTableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <TableCell>{row.sim}</TableCell>
                    <TableCell>{row.level}</TableCell>
                    <TableCell>{row.member}</TableCell>
                    <TableCell className={classes.txtAlign}>
                      {row.keywordAccuracy === 'N/A'
                        ? 'N/A' : `${row.keywordAccuracy}%`}
                    </TableCell>
                    <TableCell className={classes.txtAlign}>
                      {row.correct_selections === 'N/A' ? 'N/A'
                        : `${row.correct_selections} of ${row.total_selections}`}
                    </TableCell>
                    <TableCell className={classes.txtAlign}>
                      {row.correct_entries === 'N/A' ? 'N/A'
                        : `${row.correct_entries} of ${row.total_entries}`}
                    </TableCell>
                    <TableCell>{row.overall_percentage === 'N/A' ? 'N/A' : row.overall_percentage}</TableCell>
                    <TableCell>{row.total_time}</TableCell>
                    <TableCell className={classes.date}>
                      {moment(row.date).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell className={classes.txtAlign}>
                      {row.status === 'PASS' || row.status === 'NO PASS'
                        ? row.review_status
                          ? 'PASS'
                          : 'FAIL'
                        : '------'}
                    </TableCell>
                    <TableCell>
                      {row.status === 'PASS' || row.status === 'NO PASS'
                        ? moment(row.reviewed_on).format('MM-DD-YYYY')
                        : '------'}
                    </TableCell>
                    <TableCell>
                      {row.status === 'PASS' || row.status === 'NO PASS'
                        ? row.reviewed_by
                        : '------'}
                    </TableCell>
                    {!permissionCheck(
                      DashboardPermissions.assessor_notes.update.task,
                      DashboardPermissions.assessor_notes.update.type,
                    )
                      && !permissionCheck(
                        DashboardPermissions.assessor_notes.create.task,
                        DashboardPermissions.assessor_notes.create.type,
                      )
                      && permissionCheck(
                        DashboardPermissions.assessor_notes.view.task,
                        DashboardPermissions.assessor_notes.view.type,
                      ) ? (
                        <TableCell>
                          <div
                            onClickCapture={() => openDialog(row)}
                            className={classes.assessor}
                            style={{
                              ...(row.assessor_notes
                                ? {
                                  borderRadius: '5%',
                                  background: 'inherit',
                                  color: '#636AD1',
                                }
                                : {}),
                            }}
                          >
                            {row.assessor_notes ? 'view' : null}
                          </div>
                        </TableCell>
                      ) : permissionCheck(
                        DashboardPermissions.assessor_notes.update.task,
                        DashboardPermissions.assessor_notes.update.type,
                      )
                      || permissionCheck(
                        DashboardPermissions.assessor_notes.create.task,
                        DashboardPermissions.assessor_notes.create.type,
                      ) ? (
                        <TableCell>
                          <div
                            onClickCapture={() => openDialog(row)}
                            className={classes.assessor}
                            style={{
                              ...(row.assessor_notes
                                ? {
                                  borderRadius: '5%',
                                  background: 'inherit',
                                  color: '#636AD1',
                                }
                                : {}),
                            }}
                          >
                            {row.assessor_notes ? 'view' : '+'}
                          </div>
                        </TableCell>
                        ) : null}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}
      <Dialog
        onClose={handleClose}
        open={assessor}
        classes={{ paper: classes.dialog }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Assessor Notes</DialogTitle>
        <DialogContent>
          <div style={{ padding: '0.5rem', width: '27vw', paddingLeft: 2 }}>
            <TextField
              InputProps={{
                disableUnderline: true,
                style: { fontSize: 14, paddingLeft: 8 },
              }}
              fullWidth
              multiline
              rows={5}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={classes.textArea}
              placeholder="Type your note here"
              disabled={
                !permissionCheck(
                  DashboardPermissions.assessor_notes.update.task,
                  DashboardPermissions.assessor_notes.update.type,
                )
                && !permissionCheck(
                  DashboardPermissions.assessor_notes.create.task,
                  DashboardPermissions.assessor_notes.create.type,
                )
                && permissionCheck(
                  DashboardPermissions.assessor_notes.view.task,
                  DashboardPermissions.assessor_notes.view.type,
                )
              }
            />
            <Button
              size="small"
              style={{
                fontWeight: 600,
                padding: '3px 11px',
                backgroundColor: '#F8DEE7',
                float: 'right',
                color: '#A55B73',
                margin: '8px 0px',
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            {noteStatus === 'edit' ? (
              <Tooltip
                title={
                  !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type,
                  )
                    ? "You don't have permission to update"
                    : ''
                }
                placement="top"
              >
                <span className={classes.editSpan}>
                  <Button
                    size="small"
                    className={classes.button}
                    disabled={
                      !permissionCheck(
                        DashboardPermissions.assessor_notes.update.task,
                        DashboardPermissions.assessor_notes.update.type,
                      )
                    }
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                </span>
              </Tooltip>
            ) : noteStatus === 'add' ? (
              <Tooltip
                title={
                  !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type,
                  )
                    ? "You don't have permission to create"
                    : ''
                }
                placement="top"
              >
                <span className={classes.editSpan}>
                  <Button
                    size="small"
                    className={classes.button}
                    disabled={
                      !permissionCheck(
                        DashboardPermissions.assessor_notes.create.task,
                        DashboardPermissions.assessor_notes.create.type,
                      )
                    }
                    onClick={handleAdd}
                  >
                    Add
                  </Button>
                </span>
              </Tooltip>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
      {!assignment_loading && scoreList.length === 0 ? (
        <div className={classes.noData}>
          <img src={noAssignments} height={55} width={54} alt="noAssignments" />
          <h3 className={classes.noAssign}>There are no Assignments.</h3>
        </div>
      ) : null}
    </>
  );
};
ScoreTable.propTypes = {
  scoreList: PropTypes.array,
  totalScore: PropTypes.number,
  handleNext: PropTypes.func,
  handleScore: PropTypes.func,
  assignment_loading: PropTypes.bool,
  sorting: PropTypes.func,
};

export default ScoreTable;
