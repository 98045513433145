import { makeStyles } from "@material-ui/core/styles";

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
  },
  icon: {
    padding: "6px",
    backgroundColor: "#F5E2E9",
    borderRadius: "11%",
    margin: theme.spacing(0, 1),
  },
  saveBtn: {
    padding: "8px 40px 8px 40px",
  },
}));


export const useNavbarStyles = makeStyles((theme) => ({
  navbar: {
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: "#3E5785",
    width: "99%",
    height: "46px",
    margin: theme.spacing(0, 1),
    borderRadius: "5px",
  },
  tab: {
    padding: theme.spacing(0),
    minWidth: "125px",
    width: "120px",
    fontSize: "11px",
    minHeight: "30px",
    height: "30px",
    textTransform: "none",
  },
  tabPanel: {
    padding: "14px",
    minHeight: "26rem",
  },
}));

export const useTabContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  accordionDetails: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  arrowDropDown: {
    backgroundColor: "#D7D7D7",
    margin: "3px 9px -5px 5px",
    borderRadius: "3px",
  },
  title: {
    backgroundColor: "#EFEFEF",
    padding: "6px 6px",
    borderRadius: "3px",
  },
}));