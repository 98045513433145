import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  TextField,
  Button,
  Box,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchArticles,
  saveSelectedArticle,
} from "../../../redux/sims/actions";
import { useTabContentStyles, useStyles1 } from "./KnowledgeArticle";

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const { count, page, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className={classes.button}
      >
        Previous
      </Button>
      <Button
        variant="contained"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / 5) - 1}
        aria-label="next page"
        className={classes.button}
      >
        Next
      </Button>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "select",
    numeric: false,
    disablePadding: false,
    label: "SELECT",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "ARTICLE NAME",
  },
];
const KnowledgeArticle = ({
  fetchArticles,
  articles,
  saveSelectedArticle,
  takeSim,
}) => {
  const classes = useTabContentStyles();
  const [selected, setSelected] = React.useState([]);
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 5;
  const records =
    articles.length - page * rowsPerPage > 5
      ? 5
      : articles.length - page * rowsPerPage;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleSearch = async () => {
    if (searchData) {
      fetchArticles(searchData);
    }
  };
  const onCheck = async (article) => {
    await saveSelectedArticle(article.id);
  };
  return (
    <div className={takeSim ? classes.margin : classes.root}>
      <Card variant="outlined" className={classes.cardWidth}>
        <CardContent>
          <Card variant="outlined" className={classes.cardMargin}>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <InputLabel htmlFor="kb-search">Search KB</InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.margin}
                    onChange={(e) => setSearchData(e.target.value)}
                    value={searchData}
                    name="searchData"
                  />
                  <br />
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={handleSearch}
                  >
                    Hit It
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" className={classes.cardMargin}>
            <CardContent>
              <Box display="flex" variant="outlined" justifyContent="flex-end">
                <Button variant="contained" className={classes.attachButton}>
                  Attach Knowledge Article
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card variant="outlined">
            <CardContent>
              <span className={classes.title}>Knowledge Article</span>
              <div className={classes.margin}>
                {articles.length > 0 ? (
                  <InputLabel
                    disabled={false}
                    className={classes.count}
                    htmlFor="title"
                  >
                    Showing {records} of {articles.length}
                  </InputLabel>
                ) : (
                  ""
                )}

                <Paper className={classes.paper}>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <TableHead>
                        <TableRow>
                          {headCells.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {articles
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((article, index) => {
                            const isItemSelected = isSelected(article.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, article.id)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={article.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    onClick={() => onCheck(article)}
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.articleName}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {article.articleName}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className={classes.tableFooter}
                    component="div"
                    count={articles.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </Paper>
              </div>
            </CardContent>
          </Card>
          <Box marginTop={2}>
            <Button variant="contained" className={classes.attachButton}>
              Attach Knowledge Article
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
KnowledgeArticle.propTypes = {
  fetchArticles: PropTypes.func,
  articles: PropTypes.array,
  saveSelectedArticle: PropTypes.func,
  takeSim: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  articles: state.sim.articles,
});

const mapDispatchToProps = { fetchArticles, saveSelectedArticle };

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeArticle);
