/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import lodash from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Toolbar,
  Box,
  Typography,
  TextField,
  Radio,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import TagsInput from 'react-tagsinput-special';
import 'react-tagsinput-special/react-tagsinput.css';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import Wave from 'wave-visualizer';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LinearProgress from '@material-ui/core/LinearProgress';
import PauseIcon from '@material-ui/icons/Pause';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GetAvatar from '../GetAvatars';
import {
  generateAudio,
  uploadRecordedAudio,
} from '../../../services/result.service';
import { useCharacterDataStyle } from './CharacterData';

const CharacterData = ({
  index,
  data,
  characterData,
  handleChange,
  handleAudioChange,
  handleKeywordChange,
  handleCheckboxChange,
  handleAudioGenerate,
  handleRadioChange,
  handleDeleteScript,
  handleAgentRecordedAudio,
  handleAutopopulateChange,
  handleResetAutopopulate,
  updateData,
  borderColor,
  sim,
  tabs,
  setTimePlayfun,
  timePlay,
  currentAudio,
  pauseFunction,
  levelOrder,
  newSim,
  currentVoice,
}) => {
  const selectedCharacter = characterData.find(
    (item) => item._id === data.sim_character_id,
  );
  const classes = useCharacterDataStyle();
  const [addTextArea, setTextArea] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openKeyword, setOpenKeyword] = React.useState(false);
  const [hintText, setHintText] = React.useState(data.hint_text);
  const [keywords, setKeywords] = React.useState(data.keywords);
  const [script, setScript] = React.useState(data.script);
  const [switchGenerate, setSwitchGenerate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectValue, setSelectValue] = useState('None');
  const [timer, setTimer] = useState(data.autopopulate_timer);
  const [autopopulateData, setAutopopulateData] = React.useState(
    data.autopopulate_data,
  );
  const [voice, setVoice] = useState(currentVoice[0]);
  const audioPlayer = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openScriptDialogBox, setOpenScriptDialogBox] = useState(false);
  const [
    openExistingScriptDialogBox,
    setOpenExistingScriptDialogBox,
  ] = useState(false);
  const [wave] = useState(new Wave());
  const rec = useRef(null);
  const stream = useRef(null);
  let blob;
  let blobs;
  let voiceStream;
  const [caseInformationArray, setCaseInformationArray] = useState([]);
  const [patientInformationArray, setPatientInformationArray] = useState([]);
  const [acmeInformationArray, setAcmeInformationArray] = useState([]);
  const [loremIpsumInformationArray, setLoremIpsumInformationArray] = useState(
    [],
  );
  const [genAudio, setGenAudio] = useState(false);
  const [bgColor, setbgColor] = useState(true);
  const node = useRef();

  // To hide the voice dropdown if clicked outside it's div/section.
  const handleClick = (e) => {
    if (node && node.current && node.current.contains(e.target)) {
      setGenAudio(true);
      // inside click
      return;
    }
    // outside click
    setGenAudio(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const getAutopopulateKeywords = () => {
    const array1 = [];
    const array2 = [];
    const array3 = [];
    const array4 = [];
    if (data.autopopulate_data && data.autopopulate_data.length > 0) {
      for (const keyword of data.autopopulate_data) {
        for (const field of keyword.fields) {
          if (keyword.name === 'Case Information') {
            if (field.is_enabled) {
              array1.push(field.fieldTitle);
            }
          } else if (keyword.name === 'Patient Information') {
            if (field.is_enabled) {
              array2.push(field.fieldTitle);
            }
          } else if (keyword.name === 'Acme Information') {
            if (field.is_enabled) {
              array3.push(field.fieldTitle);
            }
          } else if (keyword.name === 'Lorem Ipsum Information') {
            if (field.is_enabled) {
              array4.push(field.fieldTitle);
            }
          }
        }
      }
    }
    setCaseInformationArray([...array1]);
    setPatientInformationArray([...array2]);
    setAcmeInformationArray([...array3]);
    setLoremIpsumInformationArray([...array4]);
  };
  useEffect(getAutopopulateKeywords, [data.autopopulate_data, data._id]);
  useEffect(() => {
    if (data.newScript) {
      setTimeout(() => {
        setbgColor(false);
      }, 3000);
    }
  }, [data]);
  const showTextArea = (flag) => {
    setTextArea(flag);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleKeywordOpen = () => {
    setKeywords(data.keywords);
    setOpenKeyword(true);
  };

  const handleKeywordCancel = () => {
    setKeywords(data.keywords);
    setOpenKeyword(false);
  };
  const handleTextChange = (index, e) => {
    if (e.target.name === 'hint_text') {
      setHintText(e.target.value);
    }
    if (e.target.name === 'script') {
      setScript(e.target.value);
    }
  };
  const keywordsChange = (e) => {
    setKeywords(e);
  };
  const handleKeywordSave = async () => {
    handleKeywordChange(index, keywords);
    setOpenKeyword(false);
    await updateData(index, data);
  };
  const hintTextSave = async () => {
    handleChange(hintText, index, 'hint_text');
    setOpen(false);
    await updateData(index, data);
  };
  const saveScript = async () => {
    handleChange(script, index, 'script');
    if (data.audio_url) {
      setOpenExistingScriptDialogBox(true);
    } else {
      setOpenScriptDialogBox(true);
    }
    await updateData(index, data);
  };
  const cancelScript = () => {
    setScript(data.script);
    showTextArea(false);
  };
  const hintTextCancel = () => {
    setHintText(data.hint_text);
    setOpen(false);
  };
  const save = () => {
    setOpenKeyword(false);
    updateData(index, data);
  };
  const handleKeywordClose = () => {
    setOpenKeyword(false);
    save();
  };

  const showPlayAudio = async () => {
    setIsLoading(true);
    const req = {};
    if (genAudio) {
      req.voice = voice;
    }
    req.sim_script_id = data._id;
    let response = {};
    if (data.agent_recorded_audio || selectedCharacter.type !== 'agent' || levelOrder === 0 || levelOrder === 1) {
      response = await generateAudio(req);
    }
    if (selectedCharacter.type === 'agent' && (levelOrder === 0 || levelOrder === 1)) {
      await handleAudioGenerate(
        index,
        response.audio_url,
        response.expiry_date,
        response.audio_key,
        true,
        voice,
      );
    } else {
      await handleAudioGenerate(
        index,
        response.audio_url,
        response.expiry_date,
        response.audio_key,
        data.agent_recorded_audio,
        voice,
      );
    }
    setGenAudio(false);
    await updateData(index, data);
    setVoice(voice);
    setIsLoading(false);
    setPlayAudio(true);
  };
  const generate = () => {
    setVoice(currentVoice[0]);
    setGenAudio(true);
  };
  const pauseAudioUrl = (data, direct) => {
    if (direct) {
      pauseFunction(data);
    }
    currentAudio.current.pause();
  };

  const playAudioUrl = (data) => {
    setTimePlayfun(audioPlayer, data);
    const direct = false;
    if (timePlay > 0) {
      pauseAudioUrl(data, direct);
    }
    audioPlayer.current.play();
    //  when audio playback ends, set isPlaying to false
    audioPlayer.current.onended = () => {
      pauseFunction(data);
    };
  };

  const openAutopopulateDialog = () => {
    if (data.autopopulate_data.length) {
      setAutopopulateData(data.autopopulate_data);
    } else {
      setAutopopulateData(tabs);
    }
    setTimer(data.autopopulate_timer);
    setOpenDialog(true);
  };
  const closeDialog = async () => {
    setSelectValue('None');
    setTimer(data.autopopulate_timer);
    const dataArray = [...data.autopopulate_data];
    setAutopopulateData(dataArray);
    setOpenDialog(false);
  };
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };
  const autopopulateChange = async (event, tabIndex, fieldIndex) => {
    const newArray = lodash(autopopulateData);
    newArray[tabIndex].fields[fieldIndex].is_enabled = event.target.checked;
    setAutopopulateData(newArray);
  };
  const setAutopopulate = async () => {
    await handleAutopopulateChange(index, autopopulateData, timer);
    await updateData(index, data);
    setOpenDialog(false);
    setSelectValue('None');
  };
  const resetAutopopulate = async () => {
    await handleResetAutopopulate(index);
    await updateData(index, data);
    const dataArray = [...data.autopopulate_data];
    setAutopopulateData(dataArray);
    setTimer(0);
  };
  const showPlayButton = (audioPlay, audio, data) => {
    if (!audio) {
      return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
    }
    if (audio && data.play) {
      return (
        <PlayArrowIcon
          className={classes.priorityIcon}
          onClick={() => playAudioUrl(data)}
        />
      );
    }
    if (audio && !data.play) {
      const direct = true;
      return (
        <PauseIcon
          className={classes.priorityIcon}
          onClick={() => pauseAudioUrl(data, direct)}
        />
      );
    }
    return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
  };
  const switchGenerateAudio = (record) => {
    handleAudioChange(index);
    updateData(index, data);
    setSwitchGenerate(!switchGenerate);
  };
  const setAgentRecordedAudio = (record) => {
    data.agent_recorded_audio = record;
    setSwitchGenerate(!switchGenerate);
    handleAgentRecordedAudio(index);
    updateData(index, data);
  };
  const handleVoiceChange = (event) => {
    setVoice(event.target.value);
  };
  const showGenerateAudio = (switchAudio) => {
    if (
      selectedCharacter.type === 'agent'
      && data.audio_url
      && !data.agent_recorded_audio
    ) {
      return (
        <div className={classes.generateDiv}>
          <Button className={classes.switchBtn} onClick={() => setAgentRecordedAudio(false)}>
            <Typography className={classes.promptRecord}>
              Prompt to record:&nbsp;
            </Typography>
            SWITCH
          </Button>
        </div>
      );
    }
    if (selectedCharacter.type === 'agent' && data.agent_recorded_audio) {
      return (
        <div className={classes.generateDiv}>
          <Button
            className={classes.recordSwitchBtn}
            onClick={() => switchGenerateAudio(true)}
          >
            <Typography className={classes.recordedAudio}>
              Recorded Audio:
            </Typography>
            <span className={classes.switch}>SWITCH</span>
          </Button>
          <Divider />
          {
          !genAudio ? (
            <Button className={classes.generateAudioBtn} onClick={generate}>
              GENERATE AUDIO
            </Button>
          )
            : (
              <div className={classes.selectDiv} ref={node}>
                <Select
                  variant="outlined"
                  name="voice"
                  size="small"
                  className={classes.select}
                  value={voice}
                  onChange={handleVoiceChange}
                  native
                >
                  {Object.entries(newSim.selectVoices).map(([key, value]) => (
                    <option value={key} key={key} className={classes.descriptionLabel}>
                      {value}
                    </option>
                  ))}
                </Select>
                <CheckBoxIcon className={classes.chckboxIcn} onClick={showPlayAudio} />
              </div>
            )
        }
          <audio key={data.audio_url} ref={audioPlayer}>
            <source src={data.audio_url} type="audio/mp3" />
          </audio>
        </div>
      );
    }
    if (selectedCharacter.type === 'agent' && switchAudio) {
      return (
        <div className={classes.generateDiv}>
          <Button className={classes.switchBtn} onClick={() => setAgentRecordedAudio(false)}>
            <Typography className={classes.promptRecord}>
              Prompt to record:&nbsp;
            </Typography>
            SWITCH
          </Button>
        </div>
      );
    }
    if (selectedCharacter.type === 'agent' && !switchAudio) {
      return (
        <div className={classes.generateDiv}>
          <Button
            className={classes.recordSwitchBtn}
            onClick={() => switchGenerateAudio(true)}
          >
            <Typography className={classes.recordedAudio}>
              Recorded Audio:
            </Typography>
            <span className={classes.switch}>SWITCH</span>
          </Button>
          <Divider />
          <Button className={classes.generateAudio} onClick={showPlayAudio}>
            GENERATE AUDIO
          </Button>
          <audio key={data.audio_url} ref={audioPlayer}>
            <source src={data.audio_url} type="audio/mp3" />
          </audio>
        </div>
      );
    }
    if (!selectedCharacter.type === 'agent' && !switchAudio) {
      return (
        <div className={classes.generateDiv}>
          <Button className={classes.generateAudioBtn} onClick={showPlayAudio}>
            GENERATE AUDIO
          </Button>
          <audio key={data.audio_url} ref={audioPlayer}>
            <source src={data.audio_url} type="audio/mp3" />
          </audio>
        </div>
      );
    }
    return (
      <div className={classes.generateDiv}>
        {
          !genAudio ? (
            <Button className={classes.generateAudioBtn} onClick={generate}>
              GENERATE AUDIO
            </Button>
          )
            : (
              <div className={classes.selectDiv} ref={node}>
                <Select
                  variant="outlined"
                  name="voice"
                  size="small"
                  className={classes.select}
                  value={voice}
                  onChange={handleVoiceChange}
                  native
                >
                  {Object.entries(newSim.selectVoices).map(([key, value]) => (
                    <option value={key} key={key} className={classes.descriptionLabel}>
                      {value}
                    </option>
                  ))}
                </Select>
                <CheckBoxIcon className={classes.chckboxIcn} onClick={showPlayAudio} />
              </div>
            )
        }
        <audio key={data.audio_url} ref={audioPlayer}>
          <source src={data.audio_url} type="audio/mp3" />
        </audio>
      </div>
    );
  };
  const handleRecordStart = async () => {
    setOpenDialogBox(false);
    setIsRecording(true);
    voiceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    stream.current = new MediaStream(voiceStream);
    blobs = [];

    wave.fromStream(stream.current, 'output', {
      colors: ['red', 'white', 'blue'],
    });

    rec.current = new MediaRecorder(stream.current, {
      mimeType: 'audio/webm',
    });
    rec.current.ondataavailable = (e) => blobs.push(e.data);
    rec.current.onstop = async () => {
      setIsLoading(true);
      blob = new Blob(blobs, { type: 'audio/mp3' });
      const formData = new FormData();
      formData.append('script_id', data._id);
      formData.append('audio_blob', blob);
      const result = await uploadRecordedAudio(formData);
      if (selectedCharacter.type === 'agent') {
        await handleAudioGenerate(index,
          result.data.url,
          result.data.expiry_date,
          result.data.audio_key, true);
      } else {
        await handleAudioGenerate(index,
          result.data.url,
          result.data.expiry_date,
          result.data.audio_key,
          false);
      }
      await updateData(index, data);
      setIsLoading(false);
    };
    rec.current.start();
  };
  const handleRecordStop = () => {
    rec.current.stop();
    stream.current = null;
    setIsRecording(false);
  };
  const showDialogBox = () => {
    if (data.audio_url) {
      setOpenDialogBox(true);
    } else {
      handleRecordStart();
    }
  };
  const showMicrophone = (recording) => {
    if (recording) {
      return (
        <MicOffIcon className={classes.micOffIcon} onClick={handleRecordStop} />
      );
    }
    return <MicIcon className={classes.micIcon} onClick={showDialogBox} />;
  };

  const highLightKeywords = (str, arr = []) => {
    const keywordArray = [];
    arr.map((ele) => {
      const substring = new RegExp(ele, 'gi');
      const res = str.match(substring);
      if (res && res.length) {
        keywordArray.push(res[0]);
      }
      return keywordArray;
    });
    keywordArray.map((ele) => {
      str = str.replaceAll(
        ele,
        `<span title='Keyword' style='background: #ADBBD4;padding: 4px;border-radius: 3px;'>${ele}</span>`,
      );
      return str;
    });
    return str;
  };
  const getBorder = (charachter) => {
    let background = '';
    switch (charachter) {
      case 'coach':
        background = '#882647';
        break;
      case 'agent':
        background = '#EB6D20';
        break;
      case 'customer':
        background = '#2694D3';
        break;
      case 'other':
        background = '#2CB174';
        break;
      default:
        background = '#882647';
    }
    return background;
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.gridStyle}
        style={{
          borderLeft: `3px solid ${getBorder(selectedCharacter.type)}`,
          background: data.newScript && bgColor ? '#b5e8d175' : '#ffff',
        }}
        container
        key
      >
        <Grid item className={classes.index}>
          <Box>{index + 1}</Box>
        </Grid>
        <Grid item className={classes.scriptGrid}>
          <Box className={classes.topBox}>
            {selectedCharacter.type === 'agent'
            && !switchGenerate
            && !data.agent_recorded_audio
              ? null
              : showPlayButton(playAudio, data.audio_url, data)}

            {selectedCharacter.type === 'agent'
            && !switchGenerate
            && !data.agent_recorded_audio
              ? null
              : showMicrophone(isRecording)}
            <Dialog
              open={openDialogBox}
              onClose={() => setOpenDialogBox(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Record Audio</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  There is already a clip here. Are you sure you want to record
                  over it?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialogBox(false)} className={classes.cancelButton}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleRecordStart()}
                  className={classes.dialogDeleteBtn}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Divider orientation="vertical" flexItem />
            <GetAvatar
              avatar={selectedCharacter.avatar}
              borderColor={borderColor}
              classes={classes.avatarImg}
              alt={selectedCharacter.type}
            />
            <Typography component="div" className={classes.nameLabel}>
              {selectedCharacter.name}
              ,&nbsp;
              <em className={classes.roleLabel}>
                The&nbsp;
                {selectedCharacter.masked_type
                  ? selectedCharacter.masked_type : selectedCharacter.type}
              </em>
              <br />
              {addTextArea ? (
                <>
                  <TextField
                    required
                    variant="outlined"
                    name="script"
                    fullWidth
                    multiline
                    rows={4}
                    value={script}
                    className={classes.margin}
                    onChange={(e) => handleTextChange(index, e)}
                    InputProps={{
                      endAdornment: (
                        <div className={classes.textFielDiv}>
                          <Button
                            name="script"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.saveBtn}
                            onClick={() => saveScript()}
                          >
                            Save
                          </Button>
                          <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.cancelBtn}
                            onClick={() => cancelScript()}
                          >
                            Cancel
                          </Button>
                        </div>
                      ),
                    }}
                  />
                  <Dialog
                    open={openScriptDialogBox}
                    onClose={() => setOpenScriptDialogBox(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you want to auto generate audio?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setOpenScriptDialogBox(false);
                          showTextArea(false);
                        }}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          showPlayAudio();
                          setOpenScriptDialogBox(false);
                          showTextArea(false);
                        }}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openExistingScriptDialogBox}
                    onClose={() => setOpenExistingScriptDialogBox(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        There is already a clip here. Are you sure you want
                        {' '}
                        <br />
                        {' '}
                        to record over it?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setOpenExistingScriptDialogBox(false);
                          showTextArea(false);
                        }}
                        color="primary"
                      >
                        No
                      </Button>
                      <Button
                        onClick={() => {
                          showPlayAudio();
                          setOpenExistingScriptDialogBox(false);
                          showTextArea(false);
                        }}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : (
                <Typography className={classes.descriptionLabel}>
                  {data.script === '' ? (
                    'Empty'
                  ) : (
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highLightKeywords(data.script, data.keywords),
                        }}
                      />
                    </span>
                  )}
                  ..
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => showTextArea(true)}
                  />
                </Typography>
              )}
            </Typography>
            <Tooltip title="Delete Script">
              <DeleteOutlineIcon
                className={classes.deleteIcon}
                onClick={() => handleDeleteScript(index, data)}
              />
            </Tooltip>
          </Box>
          <br />
          <Box className={classes.bottomBox}>
            {isLoading ? <LinearProgress color="secondary" /> : null}
            <Divider />
            <Toolbar className={classes.toolBar}>
              <Tooltip title={keywords.length === 0 ? 'None' : keywords.slice(0, 20).join(' , ')} arrow>
                <Typography
                  className={classes.toolBarLabel}
                  onClick={handleKeywordOpen}
                >
                  Keywords :
                  {data.keywords.length ? (
                    <em className={classes.toolBarValue}>
                      {data.keywords.length <= 1
                        ? data.keywords.join(',')
                        : `${data.keywords.slice(0, 1).join(',')} ...`}
                    </em>
                  ) : (
                    <em className={classes.toolBarValue}>None</em>
                  )}
                </Typography>
              </Tooltip>
              <Dialog
                open={openKeyword}
                onClose={handleKeywordClose}
                aria-labelledby="keywords"
              >
                <DialogTitle id="keywords">Add Keyword</DialogTitle>
                <DialogContent>
                  <TagsInput
                    onlyUnique
                    value={keywords}
                    className={classes.tagInput}
                    name="keywords"
                    onChange={(e) => keywordsChange(e)}
                    inputProps={{
                      placeholder: 'Add Keyword',
                      style: { width: '100px' },
                    }}
                  />
                  <em className={classes.toolBarValue}>
                    Click on &apos;Enter&apos; button after adding each keyword
                    and then save.
                  </em>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleKeywordCancel} className={classes.cancelButton}>
                    Cancel
                  </Button>
                  <Button onClick={handleKeywordSave} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              <Divider
                className={classes.verticalLine}
                orientation="vertical"
                flexItem
              />
              <Tooltip title={hintText.length === 0 ? 'None' : hintText} arrow>
                <Typography
                  className={classes.toolBarLabel}
                  onClick={handleClickOpen}
                >
                  Hint Text:
                  {data.hint_text.length ? (
                    <em className={classes.toolBarValue}>
                      {data.hint_text.length > 10
                        ? `${data.hint_text.slice(0, 2)} ...`
                        : data.hint_text}
                    </em>
                  ) : (
                    <em className={classes.toolBarValue}>None</em>
                  )}
                </Typography>
              </Tooltip>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="hint_text"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="hint_text">Add Hint Text</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    id="hint_texts"
                    margin="dense"
                    name="hint_text"
                    value={hintText}
                    multiline
                    rows={3}
                    onChange={(e) => handleTextChange(index, e)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={hintTextCancel} className={classes.cancelButton}>
                    Cancel
                  </Button>
                  <Button onClick={hintTextSave} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              <Divider
                className={classes.verticalLine}
                orientation="vertical"
                flexItem
              />
              <FormControl component="fieldset">
                <FormGroup
                  className={classes.formGroup}
                  aria-label="position"
                  row
                >
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value="hide_text"
                        name="hide_text"
                        checked={!!data.hide_text}
                        onBlur={save}
                        // size="small"
                        color="primary"
                        onChange={(e) => handleCheckboxChange(index, e)}
                        className={classes.formControlLabel}
                      />
                    )}
                    label={(
                      <Typography
                        className={classes.toolBarLabel}
                      >
                        Hide Text
                      </Typography>
                    )}
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
              {/* <Divider
                className={classes.verticalLine}
                orientation="vertical"
                flexItem
              /> */}
              {selectedCharacter.type === 'agent' && sim.real_time_feedback ? (
                <div className={classes.radioBtnDiv}>
                  {/* <Divider
                      className={classes.verticalLine}
                      orientation="vertical"
                      flexItem
                    /> */}
                  <Toolbar>
                    <FormControl
                      className={classes.radioBtnDiv}
                      component="fieldset"
                    >
                      <FormLabel
                        className={classes.radioLabels}
                        component="legend"
                      >
                        <Typography className={classes.toolBarLabel}>
                          Real-time feedback
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        defaultValue="yes"
                        aria-label="feedback"
                        className={classes.radioBtnDiv}
                      >
                        <FormControlLabel
                          className={classes.radioBtn}
                          value="yes"
                          control={(
                            <Radio
                              checked={data.real_time_feedback}
                              value="yes"
                              onBlur={save}
                              onChange={(e) => handleRadioChange(index, e)}
                              className={classes.radio}
                            />
                        )}
                          label={<span className={classes.labelFont}>Yes</span>}
                        />
                        <FormControlLabel
                          className={classes.radioBtn}
                          value="no"
                          control={(
                            <Radio
                              checked={!data.real_time_feedback}
                              value="no"
                              onBlur={save}
                              onChange={(e) => handleRadioChange(index, e)}
                              className={classes.radio}
                              // size="small"
                            />
                        )}
                          label={<span className={classes.labelFont}>No</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Toolbar>
                </div>
              ) : null}
              <Typography component="span" style={{ marginLeft: 'auto' }}>
                { showGenerateAudio(!switchGenerate)}
              </Typography>
            </Toolbar>
          </Box>
          {sim.auto_populate ? (
            <Box className={classes.bottomBox}>
              <Divider />
              <Toolbar className={classes.toolBar}>
                <div style={{ display: 'contents' }}>
                  <Button onClick={openAutopopulateDialog}>
                    <Typography className={classes.autopopulateTypo}>
                      Autopopulate:
                    </Typography>
                  </Button>
                  <Box>
                    {caseInformationArray.length === 0
                    && patientInformationArray.length === 0
                    && acmeInformationArray.length === 0
                    && loremIpsumInformationArray.length === 0 ? (
                      <em className={classes.toolBarValue}>(None)</em>
                      ) : null}
                    {caseInformationArray.length !== 0 ? (
                      <Typography className={classes.toolBarLabel}>
                        Case Information:&nbsp;
                        <em className={classes.toolBarValue}>
                          {caseInformationArray.slice(0, 3).join(', ')}
                          &nbsp;
                          {caseInformationArray.length > 3 ? '...' : null}
                        </em>
                      </Typography>
                    ) : null}
                    {patientInformationArray.length !== 0 ? (
                      <Typography className={classes.toolBarLabel}>
                        Patient Information:&nbsp;
                        <em className={classes.toolBarValue}>
                          {patientInformationArray.slice(0, 3).join(', ')}
                          &nbsp;
                          {patientInformationArray.length > 3 ? '...' : null}
                        </em>
                      </Typography>
                    ) : null}
                    {acmeInformationArray.length !== 0 ? (
                      <Typography className={classes.toolBarLabel}>
                        Acme Information:&nbsp;
                        <em className={classes.toolBarValue}>
                          {acmeInformationArray.slice(0, 3).join(', ')}
                          &nbsp;
                          {acmeInformationArray.length > 3 ? '...' : null}
                        </em>
                      </Typography>
                    ) : null}
                    {loremIpsumInformationArray.length !== 0 ? (
                      <Typography className={classes.toolBarLabel}>
                        Lorem Ipsum Information:&nbsp;
                        <em className={classes.toolBarValue}>
                          {loremIpsumInformationArray.slice(0, 3).join(', ')}
                          &nbsp;
                          {loremIpsumInformationArray.length > 3 ? '...' : null}
                        </em>
                      </Typography>
                    ) : null}
                  </Box>
                </div>
                <Dialog
                  open={openDialog}
                  onClose={closeDialog}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>
                    Select Targets to fill in this level
                  </DialogTitle>
                  <DialogContent style={{ textAlign: 'center' }}>
                    <div style={{ float: 'right' }}>
                      Start after
                      {' '}
                      <TextField
                        InputProps={{ className: classes.secondsTextField }}
                        size="small"
                        variant="outlined"
                        type="number"
                        value={timer}
                        onChange={(e) => setTimer(e.target.value)}
                      />
                      {' '}
                      Seconds
                    </div>
                    <Typography style={{ textAlign: 'left', float: 'left' }}>
                      <strong>Select Tab</strong>
                    </Typography>
                    <br />
                    <br />
                    <FormControl>
                      <Select
                        value={selectValue}
                        inputProps={{ className: classes.dropdown }}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="None">None</MenuItem>
                        {autopopulateData.map((tab, index) => (
                          <MenuItem key={index} value={tab.name}>
                            {tab.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    {autopopulateData.map((tabs, tabIndex) => (tabs.name === selectValue
                      ? tabs.fields.map((field, fieldIndex) => (
                        <div
                          key={fieldIndex}
                          style={{ textAlign: 'left', marginLeft: '14px' }}
                        >
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    name={autopopulateData.fieldTitle}
                                    value={autopopulateData.fieldTitle}
                                    color="primary"
                                    className={classes.formControlLabel}
                                    checked={field.is_enabled}
                                    onChange={(e) => autopopulateChange(
                                      e,
                                      tabIndex,
                                      fieldIndex,
                                    )}
                                  />
                                    )}
                                label={(
                                  <Typography
                                    className={classes.toolBarLabel}
                                  >
                                    {field.fieldTitle}
                                  </Typography>
                                    )}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                          <br />
                        </div>
                      ))
                      : null))}
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Button variant="contained" onClick={closeDialog}>
                      Close
                    </Button>
                    <Button variant="contained" onClick={resetAutopopulate}>
                      Reset
                    </Button>
                    <Button variant="contained" onClick={setAutopopulate}>
                      Set auto-populate
                    </Button>
                  </DialogActions>
                </Dialog>
              </Toolbar>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

CharacterData.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  characterData: PropTypes.array,
  handleChange: PropTypes.func,
  handleAudioChange: PropTypes.func,
  handleKeywordChange: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  handleAudioGenerate: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDeleteScript: PropTypes.func,
  handleAgentRecordedAudio: PropTypes.func,
  handleAutopopulateChange: PropTypes.func,
  handleResetAutopopulate: PropTypes.func,
  updateData: PropTypes.func,
  borderColor: PropTypes.string,
  sim: PropTypes.object,
  tabs: PropTypes.array,
  currentAudio: PropTypes.object,
  setTimePlayfun: PropTypes.func,
  timePlay: PropTypes.number,
  pauseFunction: PropTypes.func,
  levelOrder: PropTypes.number,
  newSim: PropTypes.object,
  currentVoice: PropTypes.any,
};
export default CharacterData;
