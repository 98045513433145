import { combineReducers } from 'redux';
import simReducer from './sims/reducer';
import loginReducer from './authentication/reducer';
import adminReducer from './admin/reducer';
import settingsReducer from './settings/reducer';
import sfReducer from './sf/reducer';

export default combineReducers({
  sim: simReducer,
  login: loginReducer,
  admin: adminReducer,
  settings: settingsReducer,
  sf: sfReducer,
});
