const admin = {
  employee_list: {
    view: {
      task: 'Employee List',
      type: 'view',
    },
    create: {
      task: 'Employee List',
      type: 'create',
    },
    update: {
      task: 'Employee List',
      type: 'update',
    },
    delete: {
      task: 'Employee List',
      type: 'delete',
    },
  },
  groups: {
    view: {
      task: 'Groups',
      type: 'view',
    },
    create: {
      task: 'Groups',
      type: 'create',
    },
    update: {
      task: 'Groups',
      type: 'update',
    },
    delete: {
      task: 'Groups',
      type: 'delete',
    },
  },
  permissions: {
    view: {
      task: 'Permissions',
      type: 'view',
    },
    create: {
      task: 'Permissions',
      type: 'create',
    },
    update: {
      task: 'Permissions',
      type: 'update',
    },
  },
  roles: {
    view: {
      task: 'Roles',
      type: 'view',
    },
    create: {
      task: 'Roles',
      type: 'create',
    },
    update: {
      task: 'Roles',
      type: 'update',
    },
    delete: {
      task: 'Roles',
      type: 'delete',
    },
  },
};
export default admin;
