import axios from 'axios';
import config from '../../config';
import {
  FETCH_COMPANY_FAIL,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_ROLES_FAIL,
  FETCH_COMPANY_ROLES_REQUEST,
  FETCH_COMPANY_ROLES_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_STATUS,
  FETCH_SUB_STATUS,
  FETCH_ROLES,
  FETCH_GROUPS,
  SAVE_USER,
  SAVE_GROUP,
  FETCH_EMPLOYEES,
  ADD_ROLES,
  EDIT_GROUP_IN_USER,
  RESET,
  SAVE_TOOLTIP,
} from './adminTypes';
import admin from '../../permissions/admin';

export const fetchCompanyRequest = (payload) => ({
  type: FETCH_COMPANY_REQUEST,
  payload,
});

export const fetchCompanySuccess = (payload) => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
});

export const fetchCompanyFail = (payload) => ({
  type: FETCH_COMPANY_FAIL,
  payload,
});

// Fetch all companies
export const fetchCompany = () => (dispatch) => {
  dispatch(fetchCompanyRequest);
  axios({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v1/company/list`,
    headers: {
      Authorization: config.getToken(),
      task: null,
      type: null,
    },
  })
    .then((res) => dispatch(fetchCompanySuccess(res.data)))
    .catch((err) => dispatch(fetchCompanyFail(err)));
};

export const fetchCompanyRolesRequest = (payload) => ({
  type: FETCH_COMPANY_ROLES_REQUEST,
  payload,
});

export const fetchCompanyRolesSuccess = (payload) => ({
  type: FETCH_COMPANY_ROLES_SUCCESS,
  payload,
});

export const fetchCompanyRolesFail = (payload) => ({
  type: FETCH_COMPANY_ROLES_FAIL,
  payload,
});

// <-----------------------------Fetch roles of single company---------------------------------->

export const fetchCompanyRoles = (id) => (dispatch) => {
  dispatch(fetchCompanyRolesRequest);
  axios({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v1/company/role-list?company_id=${id}`,
    headers: {
      Authorization: config.getToken(),
      task: admin.roles.view.task,
      type: admin.roles.view.type,
    },
  })
    .then((res) => dispatch(fetchCompanyRolesSuccess(res.data)))
    .catch((err) => dispatch(fetchCompanyRolesFail(err)));
};
export const fetchRequest = () => ({
  type: FETCH_REQUEST,
});
export const fetchFailure = (data) => ({
  type: FETCH_FAILURE,
  payload: data,
});
export const fetchStatus = (data) => ({
  type: FETCH_STATUS,
  payload: data,
});
export const fetchSubStatus = (data) => ({
  type: FETCH_SUB_STATUS,
  payload: data,
});
export const fetchRoles = (data) => ({
  type: FETCH_ROLES,
  payload: data,
});
export const fetchGroups = (data) => ({
  type: FETCH_GROUPS,
  payload: data,
});
export const fetchEmploy = (data) => ({
  type: FETCH_EMPLOYEES,
  payload: data,
});

export const fetchSelectStatus = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.MOCK_URL}/selectStatus`)
    .then((res) => dispatch(fetchStatus(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchSelectSubStatus = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.MOCK_URL}/selectSubStatus`)
    .then((res) => dispatch(fetchSubStatus(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchSelectRoles = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.API_BASE_URL}/api/v1/company/role-list`,
    {
      headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
      },
    })
    .then((res) => dispatch(fetchRoles(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchRoleData = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.API_BASE_URL}/api/v1/company/role-list`,
    {
      headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
      },
    })
    .then((res) => dispatch(fetchRoles(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchSelectGroups = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.API_BASE_URL}/api/v1/sims/group/listing`,
    {
      headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
      },
    })
    .then((res) => dispatch(fetchGroups(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const addUserData = (data, res) => ({
  type: SAVE_USER,
  result: res.data,
});
export const addGroupData = (data, res) => ({
  type: SAVE_GROUP,
  result: res.data,
});
export const addRole = (data, res) => ({
  type: ADD_ROLES,
  result: res.data.id,
  payload: data,
});
// Save role
export const saveRole = (data) => async (dispatch) => {
  dispatch(fetchRequest);
  const task = data._id ? admin.roles.update.task : admin.roles.create.task;
  const type = data._id ? admin.roles.update.type : admin.roles.create.type;
  await axios.post(`${config.API_BASE_URL}/api/v1/roles`, data, {
    headers: {
      Authorization: config.getToken(),
      task,
      type,
    },
  })
    .then((res) => dispatch(addRole(data, res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const addGroup = (data) => async (dispatch) => {
  dispatch(fetchRequest);
  await axios.post(`${config.API_BASE_URL}/api/v1/groups/save-edit`, data, {
    headers: {
      Authorization: config.getToken(),
      task: admin.groups.create.task,
      type: admin.groups.create.type,
    },
  })
    .then((res) => dispatch(addGroupData(data, res)))
    .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchEmployees = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.API_BASE_URL}/api/v1/employee/minimal-data`,
    {
      headers: {
        Authorization: config.getToken(),
        task: admin.employee_list.view.task,
        type: admin.employee_list.view.type,
      },
    })
    .then((res) => dispatch(fetchEmploy(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const editGroupInUser = (data, res) => ({
  type: EDIT_GROUP_IN_USER,
  result: res.data,
});
export const addGroupToUser = (data) => async (dispatch) => {
  dispatch(fetchRequest);
  await axios.post(`${config.API_BASE_URL}/api/v1/users/edit-group`, data, {
    headers: {
      Authorization: config.getToken(),
      task: admin.groups.update.task,
      type: admin.groups.update.type,
    },
  })
    .then((res) => dispatch(editGroupInUser(data, res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const reset = () => ({
  type: RESET,
});
export const updateTooltip = (res) =>({
  type: SAVE_TOOLTIP,
  result: res.data,
});

export const savePermissionTooltip = (data, id) => async (dispatch) => {
  dispatch(fetchRequest);
  await axios.post(`${config.API_BASE_URL}/api/v1/permissions/save-permission-details?id=${id}`, data, {
    headers: {
      Authorization: config.getToken(),
      task: admin.permissions.update.task,
      type: admin.permissions.update.type,
    },
  })
    .then((res) => dispatch(updateTooltip(data, res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
