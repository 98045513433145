import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "contents",
    justifyContent: "center",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
  },
  labelGrid: {
    textAlign: "end",
  },
  fieldLable: {
    fontWeight: "600",
    color: "black",
    marginTop: theme.spacing(0.5),
  },
  margin: {
    marginLeft: theme.spacing(2),
  },
  textArea: {
    marginLeft: theme.spacing(2),
    width: "27vw",
  },
  takeSimTextArea: {
    marginLeft: theme.spacing(2),
    width: "21vw",
  },
}));
