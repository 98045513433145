import axios from 'axios';
import {
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_GROUPS,
  SAVE_SETTINGS,
  UPDATE_PASSWORD,
} from './settingsType';
import config from '../../config';

export const fetchRequest = () => ({
  type: FETCH_REQUEST,
});
export const fetchFailure = (data) => ({
  type: FETCH_FAILURE,
  payload: data,
});

export const fetchGroups = (data) => ({
  type: FETCH_GROUPS,
  payload: data,
});

export const fetchSelectGroups = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(`${config.API_BASE_URL}/api/v1/sims/group/listing`,
    {
      headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
      },
    })
    .then((res) => dispatch(fetchGroups(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
export const saveSettings = (data) => ({
  type: SAVE_SETTINGS,
  payload: data,
});
export const changePassword = (data, res) => ({
  type: UPDATE_PASSWORD,
  payload: res,
});
export const updatePassword = (data) => async (dispatch) => {
  dispatch(fetchRequest);
  await axios.post(`${config.API_BASE_URL}/api/v1/users/update-password`, data, {
    headers: {
      Authorization: config.getToken(),
      task: null,
      type: null,
    },
  })
    .then((res) => dispatch(changePassword(data, res)))
    .catch((error) => dispatch(fetchFailure(error)));
};
