export const EmployeeList = (theme) => ({
  root: {
    width: '100%',
  },
  thead: {
    backgroundColor: '#e1e1e1',
    '& .MuiTableCell-head': {
      paddingTop: 9,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
  },
  tbody: {
    '& .MuiTableCell-body': {
      paddingLeft: theme.spacing(2),
    },
  },
  cancelButton: {
    color: '#313387',
  },
  heading: {
    fontWeight: [600],
    fontSize: '0.9em',
    width: 250,
  },
  permissions: {
    fontWeight: [600],
    fontSize: '0.9em',
    width: 110,
  },
  role: {
    fontWeight: [600],
    fontSize: '0.9em',
    width: 200,
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  built: {
    fontSize: '0.9em',
    display: 'flex',
    justifyContent: 'left',
  },
  permissionsTableCell: {
    whiteSpace: 'nowrap',
    color: '#b2335d',
    textDecoration: 'underline',
    textAlign: 'center',
    fontWeight: [600],
    fontSize: '0.9em',
  },
  firstNameCell: {
    paddingTop: theme.spacing(1),
  },
  lastNameCell: {
    fontSize: '0.9em',
  },
  emailIdCell: {
    fontSize: '0.9em',
    height: 76,
  },
  roleCell: {
    fontSize: '0.9em',
  },
  groupCell: {
    textDecoration: 'underline',
    fontSize: '0.9em',
  },
  statusCell: {
    fontSize: '0.9em',
  },
  addIcon: {
    marginLeft: '0em',
    minWidth: '0vw',
    backgroundColor: '#882647',
    color: '#fff',
    width: '1.3vw',
    height: '1.1vw',
    borderRadius: 2,
  },
  titleCell: {
    fontSize: '0.9em',
  },
  phoneNumberCell: {
    fontSize: '0.9em',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  buttonSqr: {
    padding: '5px',
    marginRight: '5px',
    minHeight: 0,
    minWidth: 0,
  },
  buttonEditSqr: {
    padding: 5,
    marginRight: 3,
    minHeight: 0,
    minWidth: 0,
  },
  btnDisableSqr: {
    padding: '5px',
    marginRight: '5px',
    minHeight: 0,
    minWidth: 0,
    pointerEvents: 'none',
    opacity: '0.5',
  },
  btnEditDisableSqr: {
    padding: 5,
    marginRight: 3,
    minHeight: 0,
    minWidth: 0,
    pointerEvents: 'none',
    opacity: '0.5',
  },
  tableRow: {
    cursor: 'pointer',
  },
  table: {
    tableLayout: 'fixed',
    width: ' 100%',
  },
  hard_left: {
    position: 'absolute',
    left: 90,
    width: 156,
    paddingTop: 20,
    border: 'none',
  },
  hard_left_action_tbody: {
    position: 'absolute',
    left: 0,
    width: 90,
    paddingTop: 24,
    border: 'none',
  },
  even_left_action: {
    position: 'absolute',
    left: 0,
    width: 90,
    paddingTop: 20,
    height: 77,
    backgroundColor: '#f5f5f5',
  },
  even_left: {
    position: 'absolute',
    left: 90,
    width: 156,
    paddingTop: 20,
    height: 77,
    backgroundColor: '#f5f5f5',
  },
  next_left: {
    position: 'absolute',
    '*position': 'relative',
    left: 246,
    width: 114,
    paddingTop: 28,
    fontSize: 12,
    border: 'none',
  },
  even_next_left: {
    position: 'absolute',
    fontSize: 12,
    left: 246,
    width: 114,
    paddingTop: 28,
    height: 77,
    backgroundColor: '#f5f5f5',
  },
  hard_left_head: {
    position: 'absolute',
    ' *position': 'relative', /* ie7 */
    left: 92,
    width: 140,
    background: '#e1e1e1',
    fontSize: 12,
    fontWeight: 'bold',
  },
  hard_left_action: {
    position: 'absolute',
    ' *position': 'relative', /* ie7 */
    left: 0,
    background: '#e1e1e1',
    fontSize: 12,
    fontWeight: 'bold',
  },
  next_left_head: {
    position: 'absolute',
    '*position': 'relative',
    left: 232,
    width: 130,
    background: '#e1e1e1',
    fontSize: 12,
    fontWeight: 'bold',
  },
  outer: { position: 'relative' },
  inner: {
    overflowX: 'scroll',
    overflowY: 'visible',
    marginLeft: 360,
    [theme.breakpoints.between(1280, 1500)]: {
      width: 845,
    },
    [theme.breakpoints.down('md')]: {
      width: 1200,
    },
    [theme.breakpoints.down('sm')]: {
      width: 1270,
    },
    [theme.breakpoints.between(1500, 1607.95)]: {
      width: 995,
    },
    [theme.breakpoints.between(1600, 1708)]: {
      width: 1180,
    },
  },
  noWrap: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  actions: {
    width: 100,
    fontWeight: [600],
    fontSize: '0.9em',
  },
  noData: {
    position: 'absolute',
    top: 100,
    width: '100%',
    textAlign: 'center',
  },
  noAssign: {
    marginBottom: 0,
  },
  noAssignPara: {
    fontSize: 12,
  },
  addButton: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    height: 34,
  },
  plus: {
    fontSize: 16,
    fontWeight: 600,
    paddingRight: 5,
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '31%',
    },
  },
  Deletedialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '20%',
    },
  },
  assignRole: {
    color: '#882647',
    cursor: 'pointer',
  },
  deleteEmployee: {
    color: 'red',
    cursor: 'pointer',
  },
  centerLabel: {
    marginTop: 8,
  },
  tempRole: {
    fontWeight: [600],
    fontSize: '0.9em',
    width: 150,
    textAlign: 'center',
  },
  tempRoleSpan: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  arrowIcon: {
    '& .MuiNativeSelect-iconOutlined': {
      right: '14px !important',
    },
  },
  delete: {
    color: '#888484',
    cursor: 'pointer',
  },
  text: {
    color: '#000000b5',
  },
  errorAlert: {
    backgroundColor: '#600727',
    marginBottom: 10,
  },
});

export default EmployeeList;
