import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import iLearnLogo from '../assets/images/icons/iLearnLogo.png';

const handleBack = () => {
  window.location.href = '/dashboard';
  localStorage.setItem('backToDashboard', true);
};

const PermissionDenied = () => (
  <div>
    <IconButton onClick={handleBack}>
      <img alt="logo" src={iLearnLogo} width="85vw" />
    </IconButton>
    <div style={{ textAlign: 'center', margin: '12rem 0rem' }}>
      <h1>
        You don't seem to have the required permissions to access this page.
        <br />
        {' '}
        Please contact your system administrator.
      </h1>
      <Button color="primary" variant="contained" onClick={handleBack}>Back to Dashboard</Button>
    </div>
  </div>

);

export default PermissionDenied;
