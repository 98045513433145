import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  TableContainer,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControl,
  Select,
  Box,
  TextareaAutosize,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import StyledLink from "../../common/StyledLink";
import { fetchOpportunity, saveOpportunity } from "../../../redux/sims/actions";
import { useStyles } from "./OpportunitySearch";

const OpportunitySearch = ({
  loadedOpportunities,
  fetchOpportunity,
  saveOpportunity,
}) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const [opportunity, setOpportunity] = useState({
    centers: [],
    callType: [],
    insurance: [],
    passportREf: "",
    memberID: "",
    filterSpeciality: [],
    filterSubSpeciality: [],
  });
  const [chosenOpportunity, setChosenOpportunity] = useState({
    centers: [],
    callType: "-NONE-",
    insurance: null,
    filterSpeciality: null,
    filterSubSpeciality: null,
    passportRef: "",
    memberID: "",
    groupNumber: "",
    locationOffered: "Yes",
    highestOpportunityOffered: "Patient didn't qualify for higher opportunity",
  });
  // load dummy data
  useEffect(() => {
    fetchOpportunity();
  }, [fetchOpportunity]);

  // extract data
  useEffect(() => {
    if (loadedOpportunities && loadedOpportunities.tabContent) {
      setOpportunity(loadedOpportunities.tabContent[0]);
      setChosenOpportunity((prev) => ({
        ...prev,
        passportRef: loadedOpportunities.tabContent[0].passportRef,
        memberID: loadedOpportunities.tabContent[0].memberID,
        groupNumber: loadedOpportunities.tabContent[0].groupNumber,
      }));
    }
  }, [loadedOpportunities]);

  // filter data based on search text
  useEffect(() => {
    if (loadedOpportunities && loadedOpportunities.tabContent) {
      setOpportunity((prevState) => ({
        ...prevState,
        centers: loadedOpportunities.tabContent[0].centers.filter((center) =>
          center.name.includes(searchText)
        ),
      }));
    }
  }, [searchText, loadedOpportunities]);

  const isSelected = (id) => chosenOpportunity.centers.indexOf(id) !== -1;
  const handleCheckbox = (e, id) => {
    if (e.target.checked) {
      setChosenOpportunity((prevState) => ({
        ...prevState,
        centers: [...prevState.centers, id],
      }));
    } else {
      setChosenOpportunity((prevState) => ({
        ...prevState,
        centers: prevState.centers.filter((centerID) => centerID !== id),
      }));
    }
  };
  const handleSelectAll = (e) => {
    const selectAll = e.target.checked;
    if (selectAll) {
      const newSelectedItems = opportunity.centers.map((center) => center.id);
      setChosenOpportunity((prevState) => ({
        ...prevState,
        centers: newSelectedItems,
      }));
    } else {
      setChosenOpportunity((prevState) => ({
        ...prevState,
        centers: [],
      }));
    }
  };
  const handleCallType = (e) => {
    e.persist();
    setChosenOpportunity((prevState) => ({
      ...prevState,
      callType: e.target.value,
    }));
  };
  const handleInsurance = (e) => {
    e.persist();

    setChosenOpportunity((prevState) => ({
      ...prevState,
      insurance: e.target.value,
    }));
  };
  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    setChosenOpportunity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSave = () => {
    saveOpportunity(chosenOpportunity);
  };
  return (
    <div className={classes.root}>
      <TextField
        className={classes.card}
        value={searchText}
        fullWidth
        mb={2}
        variant="outlined"
        color="secondary"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Card variant="outlined" elevation={0}>
        <CardContent>
          <Typography variant="subtitle2" className={classes.headTypo}>
            Centers
          </Typography>
          {/* table card */}
          <Card variant="outlined" elevation={0} className={classes.card}>
            <CardContent>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                disableElevation
              >
                Sort by Distance
              </Button>
              <TableContainer>
                <Table aria-label="centers table" size="small">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell key="checkbox">
                        <Checkbox
                          name="center_selectAll"
                          id="selectAll"
                          onChange={handleSelectAll}
                          indeterminate={
                            chosenOpportunity.centers.length > 0 &&
                            chosenOpportunity.centers.length <
                              opportunity.centers.length
                          }
                        />
                      </TableCell>
                      <TableCell key="center_name">NAME</TableCell>
                      <TableCell key="center_links">CENTER LINKS</TableCell>
                      <TableCell key="center_directions">DIRECTIONS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {opportunity.centers &&
                      opportunity.centers.map((row) => {
                        const isItemCheck = isSelected(row.id);
                        return (
                          <TableRow
                            key={row.id}
                            tabIndex={-1}
                            className={classes.row}
                          >
                            <TableCell key={`${row.id}_checkbox`}>
                              <Checkbox
                                name="center_chk"
                                id={row.id.toString()}
                                checked={isItemCheck}
                                onClick={(e) => handleCheckbox(e, row.id)}
                              />
                            </TableCell>
                            <TableCell key={`${row.id}_name`}>
                              <StyledLink to="/">{row.name}</StyledLink>
                            </TableCell>
                            <TableCell key={`${row.id}_link`}>
                              {row.links &&
                                row.links.map((link) => (
                                  <div key={`${row.id}_${link}`}>
                                    <StyledLink to="/">{link}</StyledLink>
                                  </div>
                                ))}
                            </TableCell>
                            <TableCell key={`${row.id}_direction`}>
                              {row.directions &&
                                row.directions.map((ele) => (
                                  <div key={`${row.id}_${ele}`}>
                                    <StyledLink to="/">{ele}</StyledLink>
                                  </div>
                                ))}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          {/* instructions */}
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6">
                Offer location, if more than one location is qualified.
              </Typography>
              <br />
              <Typography variant="h6">
                Cummunicate opportunity. Return to the patient account and
                complete full demos.
              </Typography>
              <Typography variant="h6">
                Return here to answer location and highest opportunity questions
                below.
              </Typography>
            </CardContent>
          </Card>
          {/* call type */}
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.heading}>
                CALL TYPE
              </Typography>
              <FormControl>
                <Typography variant="body1">call type</Typography>
                <Select native onChange={handleCallType} variant="outlined">
                  <option key="none" value="NONE">
                    -NONE-
                  </option>
                  {opportunity.callType &&
                    opportunity.callType.map((ele) => (
                      <option key={ele} value={ele}>
                        {ele}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleSave}
              >
                save
              </Button>
            </CardContent>
          </Card>
          {/* insurance */}
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.heading}>
                CLINICAL AREA
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" className={classes.subHeading}>
                  Clinical Area{" "}
                </Typography>
                <InfoIcon fontSize="small" />
              </Box>
              <Typography variant="body1" className={classes.title}>
                (CA) Annual Physical Exams
              </Typography>
              <Typography variant="h6" className={classes.heading}>
                INSURANCE
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" className={classes.subHeading}>
                  Primary Insurance (DT){" "}
                </Typography>
                <InfoIcon fontSize="small" />
              </Box>
              <Typography variant="body1" className={classes.title}>
                ANTHEM BLUE CROSS (STANDARD NETWORK) - HMO
              </Typography>
              <Typography variant="body1" className={classes.mentions}>
                Use this if there is no physician match to check if insurance is
                the limitation (enter Self-Pay/Cash) or if consumer wants to use
                different insurance than the one they gave you in the payment
                screening
              </Typography>
              <Box my={4}>
                <FormControl>
                  <Typography
                    variant="subtitle1"
                    className={classes.subHeading}
                  >
                    Select Insurance Other than Primary
                  </Typography>

                  <Select native onChange={handleInsurance} variant="outlined">
                    <option aria-label="None" value="NONE">
                      -NONE-
                    </option>
                    {opportunity.insurance &&
                      opportunity.insurance.map((ele) => (
                        <option key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box my={4}>
                <Typography variant="subtitle1" className={classes.subHeading}>
                  Passport Reference Number
                </Typography>
                <TextField
                  variant="outlined"
                  name="passportRef"
                  value={chosenOpportunity.passportRef}
                  onChange={handleChange}
                />
              </Box>
              <Box my={4}>
                <Typography variant="subtitle1" className={classes.subHeading}>
                  Member ID
                </Typography>
                <TextField
                  variant="outlined"
                  name="memberID"
                  value={chosenOpportunity.memberID}
                  onChange={handleChange}
                />
              </Box>
              <Box my={4}>
                <Typography variant="subtitle1" className={classes.subHeading}>
                  Group Number
                </Typography>
                <TextField
                  variant="outlined"
                  name="groupNumber"
                  value={chosenOpportunity.groupNumber}
                  onChange={handleChange}
                />
              </Box>
              <Box my={4}>
                <Typography variant="h6" className={classes.heading}>
                  Filter Docs by Speciality And Sub-Speciality
                </Typography>
                <Box my={2}>
                  <FormControl>
                    <Typography
                      variant="subtitle1"
                      className={classes.subHeading}
                    >
                      Select Speciality
                    </Typography>

                    <Select
                      native
                      name="filterSpeciality"
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <option aria-label="None" value="NONE">
                        -NONE-
                      </option>
                      {opportunity.filterSpeciality &&
                        opportunity.filterSpeciality.map((ele) => (
                          <option key={ele} value={ele}>
                            {ele}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box my={2}>
                  <FormControl>
                    <Typography
                      variant="subtitle1"
                      className={classes.subHeading}
                    >
                      Select Sub-Speciality
                    </Typography>

                    <Select
                      native
                      name="filterSubSpeciality"
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <option aria-label="None" value="NONE">
                        -NONE-
                      </option>
                      {opportunity.filterSubSpeciality &&
                        opportunity.filterSubSpeciality.map((ele) => (
                          <option key={ele} value={ele}>
                            {ele}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </CardContent>
          </Card>
          {/* blank space card */}
          <Card variant="outlined" className={classes.blankcard} />
          {/* location */}
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.purpleTitle}>
                Did you offer location?
              </Typography>
              <Box my={2}>
                <FormControl>
                  <Typography
                    variant="subtitle1"
                    className={classes.subHeading}
                  >
                    Select An option
                  </Typography>

                  <Select
                    native
                    name="locationOffered"
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Card>
          {/* blank space card */}
          <Card variant="outlined" className={classes.blankcard} />
          {/* offered hisghest opportunity */}
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.purpleTitle}>
                Did you offer highest opportunity?
              </Typography>
              <Box my={2}>
                <FormControl>
                  <Typography
                    variant="subtitle1"
                    className={classes.subHeading}
                  >
                    Offered Higher Opportunity
                  </Typography>

                  <Select
                    native
                    name="locationOffered"
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Select>
                </FormControl>
              </Box>
              <Box my={2}>
                <FormControl>
                  <Typography
                    variant="subtitle1"
                    className={classes.subHeading}
                  >
                    select Reason if Highest Opportunity Not Offered
                  </Typography>

                  <Select
                    native
                    name="highestOpportunityOffered"
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <option value="Patient didn't qualify for higher opportunity">
                      Patient didn&apos;t qualify for higher opportunity
                    </option>
                    <option value="Physician/Center requested by name">
                      Physician/Center requested by name
                    </option>
                    <option value="Reason if other is selected">
                      Reason if other is selected
                    </option>
                    <option value="-NONE-">-NONE-</option>
                  </Select>
                </FormControl>
              </Box>
              <Box my={2}>
                <Typography variant="subtitle1" className={classes.subHeading}>
                  Reason if other is selected
                </Typography>
                <TextareaAutosize
                  aria-label="other reason"
                  rowsMin={3}
                  name="reason"
                  onChange={handleChange}
                />
              </Box>
            </CardContent>
          </Card>
          {/* save btn card */}
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="subtitle2" className={classes.instruction}>
                Please click on Launch Document Template on the Top of the Page
                After you click Save
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className={classes.submitBtn}
                onClick={handleSave}
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </div>
  );
};

OpportunitySearch.propTypes = {
  loadedOpportunities: PropTypes.object,
  fetchOpportunity: PropTypes.func,
  saveOpportunity: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loadedOpportunities: state.sim.loadedOpportunities[0],
  savedOpportunity: state.sim.saveOpportunity,
});

const mapDispatchToProps = {
  fetchOpportunity,
  saveOpportunity,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitySearch);
