const dashboard = {
  user_name: {
    view: {
      task: 'Display User Name',
      type: 'view',
    },
  },
  user_account: {
    view: {
      task: 'Display Group Roll-up Counts',
      type: 'view',
    },
  },
  company_name: {
    view: {
      task: 'Display Company Name',
      type: 'view',
    },
  },
  assessor_notes: {
    view: {
      task: 'Assessor Notes',
      type: 'view',
    },
    create: {
      task: 'Assessor Notes',
      type: 'create',
    },
    update: {
      task: 'Assessor Notes',
      type: 'update',
    },
  },
  review_assignments: {
    view: {
      task: 'Review/Score Assignments',
      type: 'view',
    },
  },
  notes_to_user: {
    view: {
      task: 'Sim Assignment Instructions',
      type: 'view',
    },
    create: {
      task: 'Sim Assignment Instructions',
      type: 'create',
    },
    update: {
      task: 'Sim Assignment Instructions',
      type: 'update',
    },
  },
  my_notepad: {
    view: {
      task: 'My Notes',
      type: 'view',
    },
    create: {
      task: 'My Notes',
      type: 'create',
    },
    update: {
      task: 'My Notes',
      type: 'update',
    },
    delete: {
      task: 'My Notes',
      type: 'delete',
    },
  },
  all_assignments: {
    view: {
      task: 'All Assignments',
      type: 'view',
    },
  },
};
export default dashboard;
