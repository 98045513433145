import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    maxWidth: "21vw",
    borderRadius: "1%",
  },
  subtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    textTransform: "capitalize"
  },

  formControl: {
    "& .MuiInputBase-input": {
      backgroundColor: "#fff",
      borderRadius: "5px",
    },
    width: "70%",
    margin: "auto",
    paddingBottom: theme.spacing(7),
  },

  select: {
    padding: theme.spacing(0.5, 2, 0.5, 0),
    textAlign: "right",
    backgroundColor: "#fff",
    borderRadius: "5px",
    "&:before": {
      borderColor: "transparent",
    },
    "&:after": {
      borderColor: "transparent",
    },
    "& .MuiNativeSelect-icon": {
      paddingRight: "3px",
    },
    "& .MuiNativeSelect-select": {
      paddingLeft: "16px",
    },
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#fff !important",
    },
  },
  cssFocused: {},

  notchedOutline: {
    borderColor: "#fff !important",
  },
  textField: {
    borderRadius: "5px",
  },
  label: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: theme.spacing(2),
    color: "#000",
    fontSize: "small",
  },
  gridContainer: {
    marginTop: theme.spacing(3),
    flexGrow: 1,
  },
  largeAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),

    [theme.breakpoints.up("xl")]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    "& .MuiAvatar-img": {
      borderRadius: "10%",
      backgroundColor: "#fff",
    },
  },
  onSelectAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),

    [theme.breakpoints.up("xl")]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    "& .MuiAvatar-img": {
      borderRadius: "10%",
      backgroundColor: "#fff",
      border: "2px solid black",
    },
  },
}));
