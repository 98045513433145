import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  blankcard: {
    height: theme.spacing(6),
  },
  headTypo: {
    fontSize: "1.2vw",
    fontWeight: 700,
    margin: theme.spacing(2, 0, 2, 0),
  },
  tableHead: {
    backgroundColor: "#FAFAF9",
    "& th": {
      fontSize: "0.9vw",
      borderTop: "1px solid rgba(224, 224, 224, 1)",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  row: {
    "& a": {
      color: "#4675d3",
    },
  },
  heading: {
    backgroundColor: "#f3f0f1",
    padding: theme.spacing(0.5),
    margin: theme.spacing(4, 0, 4, 0),
    color: "#353e58",
  },
  subHeading: {
    color: "#3b3b3b",
    marginRight: theme.spacing(1),
  },
  title: {
    color: "#353e58",
  },
  purpleTitle: {
    color: "#132641",
    margin: theme.spacing(2, 0, 2, 0),
  },
  mentions: {
    margin: theme.spacing(3, 0, 3, 0),
    color: "#353e58",
    width: "50%",
  },
  instruction: {
    color: "red",
    fontWeight: "bold",
    margin: theme.spacing(2, 0, 2, 0),
  },
  submitBtn: {
    color: "white",
  },
}));
