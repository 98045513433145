import { makeStyles } from "@material-ui/core/styles";

export const useDetailsStyle = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  editIcon: {
    color: "#882647",
    fontSize: "small",
  },
  editDetails: {
    color: "#882647",
    margin: "6px 0px 12px 0px",
    cursor: "pointer",
  },
  userDetails: {
    textAlign: "right",
    fontWeight: "2rem",
    letterSpacing: "1.5px",
    fontSize: "12px",
  },
}));
