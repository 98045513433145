import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FooterStyle from './Footer';

const Footer = () => {
  const useStyles = makeStyles((theme) => FooterStyle(theme));
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography className={classes.copyright} variant="body2">
        Copyright &copy; iLearnCC 2020. All rights reserved
      </Typography>
      <Typography className={classes.elements} variant="body2">
        CONTACT US
      </Typography>
      <Typography className={classes.elements} variant="body2">
        SUPPORT/HELP
      </Typography>
      <Typography className={classes.elements} variant="body2">
        ABOUT US
      </Typography>
    </Paper>
  );
};

export default Footer;
