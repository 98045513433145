export const simWizard = (theme) => ({
  mainDiv: {
    width: '100%',
  },
  characterDiv: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5vw 0vw',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  centerElement: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  levelButton: {
    margin: '1rem 0rem',
    backgroundColor: '#E5E5E5',
    color: '#fff',
    // minWidth: '3.5vw',
    // maxWidth: '3vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  restartButton: {
    margin: '1vw 0.5vw 1vw 0',
    backgroundColor: '#D8FAE3',
    color: '#37A559',
    minWidth: '6vw',
    maxWidth: '5vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  exitIcon: {
    width: 15,
    height: 15,
    marginRight: -5,
  },
  restartIcon: {
    width: 11,
    height: 11,
    marginRight: -3,
  },
  exitButton: {
    margin: '1vw 0vw',
    backgroundColor: '#FFE7E7',
    color: '#99455F',
    minWidth: '2vw',
    maxWidth: '5vw',
    fontSize: '0.8vw',
    fontWeight: 600,
  },
  avatarDiv: {
    width: '25%',
    height: '5.5vw',
    margin: '0px auto',
    backgroundColor: '#E8E8E8',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '1vw',
  },
  avatar: {
    '& .MuiAvatar-img': {
      marginBottom: 7,
    },
    width: '100%',
    height: '100%',
  },
  characterName: {
    backgroundColor: '#882647',
    WebkitTextFillColor: '#FFFFFF',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textTransform: 'capitalize',
  },
  dots: {
    width: '100%',
    justifyContent: 'space-evenly',
  },
  stepperRoot: {
    padding: '0px',
    justifyContent: 'space-evenly',
    width: '93%',
    backgroundColor: 'white',
  },
  dot: {
    width: '3vw',
    height: '0.5vw',
    borderRadius: '28%',
    color: '##8B888B',
  },
  dotActive: {
    backgroundColor: '#882647',
  },
  characterTypo: {
    display: 'contents',
    fontSize: '0.8vw',
    cursor: 'pointer',
  },
  radioTypo: {
    color: 'black',
    display: 'contents',
    fontSize: '0.8vw',
  },
  characterSpan: {
    color: '#BB8798',
    fontSize: '0.8vw',
    marginTop: 'auto',
    cursor: 'pointer',
  },
  instructionDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginBlockEnd: '8.5vw',
  },
  instructionTypo: {
    margin: '1vw 1.5vw',
    padding: '1vw 1.5vw',
    backgroundColor: '#FFF1F6',
    fontSize: '0.8vw',
    flexWrap: 'wrap',
    borderRadius: '5%',
  },
  hintText: {
    color: '#999999',
  },
  nextBackDiv: {
    display: 'flex',
    margin: '0vw 5.5vw',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
  },
  nextBackAutoplayDiv: {
    margin: '0vw 9.5vw',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
  },
  backButton: {
    backgroundColor: '#F0F0F0',
    color: '#A8A8A8',
    fontSize: '12px',
  },
  activeBackButton: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
  },
  pauseButton: {
    '& hover': {
      backgroundColor: '#882647',
    },
    backgroundColor: '#882647',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    margin: '0.5vw',
  },
  pauseAutoplayButton: {
    '& hover': {
      backgroundColor: '#882647',
    },
    backgroundColor: '#882647',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    marginLeft: 60,
    marginTop: 10,
    marginBottom: 10,
  },
  recButton: {
    backgroundColor: '#fc031c',
    borderRadius: '6%',
    color: '#FFFFFF',
    height: '2vw',
    margin: '0.5vw',
  },
  nextButton: {
    backgroundColor: '#F0F0F0',
    color: '#892848',
    fontSize: '12px',
  },
  restartDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerDiv: {
    display: 'grid',
    justifyContent: 'center',
    margin: '0.5vw 0.5vw 0 0.5vw',
    padding: '1vw 0.5vw 0vw 0.5vw',
    backgroundColor: '#F3F3F3',
  },
  simAuthor: {
    width: '100%',
    fontSize: '11px',
    color: '#818181',
  },
  databaseDiv: {
    display: 'flex',
    margin: '0vw 0.5vw 0vw 0.5vw',
    padding: '0 0.5vw 1vw 0.5vw',
    backgroundColor: '#F3F3F3',
  },
  databaseTypo: {
    fontSize: '11px',
    flexGrow: 1,
    color: '#818181',
    marginLeft: '0.5vw',
  },
  databaseSpan: {
    fontSize: '11px',
    marginTop: 'auto',
    color: 'black',
    fontWeight: 500,
  },
  practiceTypo: {
    fontSize: '11px',
    color: '#818181',
    marginRight: '0.5vw',
  },
  practiceSpan: {
    fontSize: '11px',
    marginTop: 'auto',
    color: 'black',
    fontWeight: 500,
  },
  characterInfo: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '-0.7vw',
  },
  modal: {
    '& .MuiDialog-paperFullWidth': {
      marginLeft: 'auto',
    },
  },
  label: {
    textAlign: 'center',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
  },
  missedKeywords: {
    fontSize: 15,
    fontWeight: 600,
    color: 'red',
  },
  correctKeywords: {
    fontSize: 15,
    fontWeight: 600,
    color: 'green',
  },
  list: {
    color: 'black',
  },
  player: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hr: {
    width: 'inherit',
  },
  modalContent: {
    textAlign: 'center',
  },
  playerPlayBtn: {
    color: 'rgb(136,38,71)',
    padding: 3,
  },
  loader: {
    width: '100%',
  },
  radioBtnDiv: {
    display: 'contents',
    '& .MuiFormControlLabel-root': {
      marginRight: 10,
    },
  },
  radioYes: {
    color: '#4185EA !important',
    marginRight: -7,
    marginInlineStart: '10px',
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 15,
    },
  },
  radioNo: {
    color: '#4185EA !important',
    marginRight: -7,
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 15,
    },
  },
  radioBtn: {
    '& .MuiTypography-body1': {
      fontSize: 15,
    },
  },
  radioDiv: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5vw 0vw',
  },
  warnTypo: {
    fontWeight: [700],
    margin: '10px 0px',
  },
  info: {
    marginBottom: '-5px',
    height: '24px',
    width: '28px',
    color: 'orange',
  },
});

export const simWizardActiveLevelButton = {
  margin: '1rem 0rem',
  backgroundColor: '#882647',
  color: '#fff',
  // minWidth: '3.5vw',
  // maxWidth: '3vw',
};
export const simWizardActiveLevel = {
  fontSize: '11px',
  flexGrow: 1,
  color: '#818181',
  marginLeft: '0.5vw',
};
export const simWizardHideLevelButton = {
  display: 'none',
};
