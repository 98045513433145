/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
const local = {
  API_BASE_URL: 'http://localhost:8585',
  GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
  GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
  GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
  MOCK_URL: 'localhost:4000',
  SF_API_BASE_URL: 'http://localhost:8080',
};

const dev = {
  API_BASE_URL: 'https://rlc.api.edstem.com',
  GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
  GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
  GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
  MOCK_URL: 'http://ec2-34-229-143-78.compute-1.amazonaws.com:4000',
  SF_API_BASE_URL: 'https://rlc.sfproxy.edstem.com',
};

const stage = {
  API_BASE_URL: 'https://rlc-stage.api.edstem.com',
  GOOGLE_MAP_KEY: 'AIzaSyBG3o3uYCyRUQ56z1kok47J2wNdYN8HqW8',
  GOOGLE_PLACE_API: 'https://maps.googleapis.com/maps/api/js',
  GOOGLE_GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
  MOCK_URL: 'http://ec2-34-229-143-78.compute-1.amazonaws.com:4000',
  SF_API_BASE_URL: 'https://rlc-stage.sfproxy.edstem.com',
};

// Get token from local storage
const getToken = () => `Bearer ${localStorage.getItem('token')}`;

// change dev to local to point to local server.
const config = process.env.REACT_APP_STAGE === 'stage'
  ? stage
  : dev;

export default {
  getToken,
  ...config,
};
