import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Button,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import {
  fetchPhysicianMapForm,
  saveAddress,
} from "../../../redux/sims/actions";
import Map from "./MyMapComponent";
import { useToolbarStyles, BootstrapInput } from "./PhysicianMap";

const PhysicianMap = ({
  physicianMapForm,
  fetchPhysicianMapForm,
  saveAddress,
}) => {
  const classes = useToolbarStyles();
  const [data, setData] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    rangeinmiles: "",
    selectanInsurance: "",
  });
  const [shownMap, setShownMap] = useState(false);
  useEffect(() => {
    fetchPhysicianMapForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const word =
      e.target.name.charAt(0).toLowerCase() + e.target.name.substring(1);
    const name = word.replace(/[^a-zA-Z]/g, "");
    setData({
      ...data,
      [name]: e.target.value,
    });
    setShownMap(false);
  };

  const handleSubmit = () => {
    if (
      data.street !== "" &&
      data.city !== "" &&
      data.state !== "" &&
      data.postalCode !== "" &&
      data.country !== "" &&
      data.rangeinmiles !== "" &&
      data.selectanInsurance !== ""
    ) {
      saveAddress(data);
      setShownMap(true);
    } else {
      alert("All Fields Required!!!");
    }
  };
  const getValue = (field) => {
    let value = "";
    switch (field) {
      case "Street":
        value = data.street;
        break;
      case "City":
        value = data.city;
        break;
      case "State":
        value = data.state;
        break;
      case "Postal Code":
        value = data.postalCode;
        break;
      case "Country":
        value = data.country;
        break;
      case "Range (in miles)":
        value = data.range;
        break;
      case "Select an Insurance":
        value = data.insurance;
        break;
      default:
        value = data.street;
    }
    return value;
  };
  return (
    <>
      <Toolbar className={classes.root}>
        <LocationOnIcon fontSize="large" className={classes.iconStyle} />
        <Typography
          className={classes.title}
          variant="h5"
          id="addressTitle"
          component="div"
        >
          Enter Address
        </Typography>
      </Toolbar>
      <Grid container spacing={2} className={classes.gridStyle}>
        {physicianMapForm.fields &&
          physicianMapForm.fields.map((singleField, i) =>
            singleField.fieldType ? (
              <Grid key={singleField.id} item xs={(i + 1) % 3 === 0 ? 4 : 3}>
                <InputLabel
                  className={classes.lableStyle}
                  htmlFor={singleField.fieldTitle}
                >
                  {singleField.fieldTitle}
                </InputLabel>
                <NativeSelect
                  name={singleField.fieldTitle}
                  className={classes.selectField}
                  value={getValue(singleField.fieldTitle)}
                  onChange={handleChange}
                  variant="outlined"
                  required
                  input={<BootstrapInput />}
                  IconComponent={UnfoldMoreIcon}
                >
                  {singleField.fieldOptions.map((opt) => (
                    <option name={singleField.fieldTitle} value={opt} key={opt}>
                      {" "}
                      {opt}{" "}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>
            ) : (
              <Grid key={singleField.id} item xs={(i + 1) % 3 === 0 ? 4 : 3}>
                <InputLabel
                  value={getValue(singleField.fieldTitle)}
                  className={classes.lableStyle}
                  htmlFor={singleField.fieldTitle}
                  required
                >
                  {singleField.fieldTitle}
                </InputLabel>
                <TextField
                  name={singleField.fieldTitle}
                  onChange={handleChange}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )
          )}
        <Grid item xs={12} className={classes.gridButtonStyle}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={classes.buttonStyle}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {shownMap ? (
        <Map center={{ lat: 26.4499, lng: 80.3319 }} height="500px" zoom={10} />
      ) : null}
    </>
  );
};

PhysicianMap.propTypes = {
  physicianMapForm: PropTypes.shape({ fields: PropTypes.array }),
  fetchPhysicianMapForm: PropTypes.func,
  saveAddress: PropTypes.func,
};
const mapStateToProps = (state) => ({
  physicianMapForm: state.sim.physicianMapForm,
  insurances: state.sim.insurances,
});
const mapDispatchToProps = { fetchPhysicianMapForm, saveAddress };

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianMap);
