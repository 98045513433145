import background from '../../assets/images/Profilebg.png';

export const UserData = (theme) => ({
  back: {
    backgroundSize: 'cover',
    height: 100,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`,
  },
  company: {
    fontSize: 16,
    fontWeight: 600,
  },
  divider: {
    borderTop: '1px dashed rgba(0, 0, 0, 0.12)',
    background: '#ffffff',
    marginTop: 5,
  },
  total: {
    height: 80,
    borderRadius: 5,
    alignItems: 'center',
    padding: '0px 15px',
    background: 'rgb(237,245,254)',
  },
  completed: {
    background: 'rgb(238,246,237)',
    height: 80,
    borderRadius: 5,
    alignItems: 'center',
    padding: '0px 10px',
  },
  review: {
    height: 80,
    borderRadius: 5,
    alignItems: 'center',
    padding: '0px 15px',
    background: 'rgb(252,234,241)',
  },
  rect: {
    fontSize: 17,
    borderRadius: 5,
    height: '50%',
    fontWeight: 600,
    background: '#ffffff',
    width: '25%',
    textAlign: 'center',
    paddingTop: 9,
  },
  text: {
    fontWeight: 600,
    fontFamily: 'Proxima Nova',
    fontSize: 14,
    color: '#292828db',
  },
  head: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '15%',
    fontSize: 25,
    transform: 'translate(0, -50%)',
  },
  time: {
    color: '#fff',
    fontWeight: 400,
    fontSize: 14,
  },
  name: {
    color: '#ffffff',
    fontSize: 18,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up(1200)]: {
      fontSize: 15,
    },
    [theme.breakpoints.up(1500)]: {
      fontSize: 18,
    },
  },
  content: {
    paddingBottom: '15px !important',
  },
  textTotal: {
    color: 'rgb(71,93,119)',
  },
  textCompleted: {
    color: 'rgb(89,175,100)',
  },
  textReview: {
    color: 'rgb(235,82,102)',
  },
  cardPadding: {
    paddingTop: 25,
  },
  avatar: {
    width: 48,
    border: '5px solid #354E7E',
    height: 50,
  },
  listText: {
    '& .MuiTypography-displayBlock': {
      fontSize: 14,
    },
  },
});

export default UserData;
