import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CircularProgress, ThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { indigo } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import {
  fetchData,
  initializeNewSim,
  setEditSimStep,
  fetchProductList,
  deleteAssignmentDetails,
  saveSelectedSim,
  reset,
  resetVideoStats,
} from "../../redux/sims/actions";
import StyledLink from "../../components/common/StyledLink";
import DetailsScreen from "../../components/sim/DetailsScreen.jsx";
import {
  getSimById,
  deleteSim,
  saveAvatarDetails,
  deleteLevel,
} from "../../services/result.service";
import { sfReset} from '../../redux/sf/actions';
import { useLocation } from "react-router-dom";
import noSims from "../../assets/images/noSims.png";
import SimsPermissions from "../../permissions/sims";
import { permissionCheck } from "../../utils/permissionCheck";
import { useTableStyles, useToolbarStyles } from "./Sims";
import AddIcon from "@material-ui/icons/Add";
import { Redirect } from 'react-router-dom';
import ProfilePic from '../../assets/images/profile_pic.png'

const useQuery = () => new URLSearchParams(useLocation().search);
const innerTheme = createMuiTheme({
  palette: {
    primary: indigo,
  },
});

const limit = 20;

const EnhancedTableToolbar = ({
  initializeNewSim,
  filterType,
  setFilterType,
  setTextSearch,
  products,
}) => {
  const classes = useToolbarStyles();
  const [text, setText] = useState("");
  const search = (event) => {
    if (event.key === "Enter" || (event.type === "mousedown")) {
      setTextSearch(text);
    }
  };

  const handleChange = (event) => {
    setFilterType(event.target.value);
  };
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Manage and Create Simulators
      </Typography>
      <div className={classes.search}>
        <InputBase
          placeholder="Search Simulators"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => search(e)}
        />
        <div>
          <Button onMouseDown={(e) => search(e)}><SearchIcon /></Button>
        </div>
      </div>
      <FormControl variant="outlined" size="small">
        <Select
          labelId="select-filter-type"
          id="simple-select-filter"
          value={filterType}
          onChange={handleChange}
          className={classes.select}
        >
          <MenuItem value="All" selected>
            All
          </MenuItem>
          {products.length > 0 &&
            products.map((product) => (
              <MenuItem key={product._id} value={product._id}>
                {product.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Tooltip
        title={
          !permissionCheck(
            SimsPermissions.sim_list.create.task,
            SimsPermissions.sim_list.create.type
          )
            ? "You don't have permission to create"
            : ""
        }
        placement="bottom"
      >
        <span>
          <StyledLink to="/sims/createsim">
            <Button
              className={classes.addNewButton}
              variant="contained"
              color="primary"
              disabled={
                !permissionCheck(
                  SimsPermissions.sim_list.create.task,
                  SimsPermissions.sim_list.create.type
                )
              }
              disableElevation
              onClick={() => {
                initializeNewSim();
              }}
            >
              <AddIcon className={classes.plusIcon} />
              ADD NEW SIM
            </Button>
          </StyledLink>
        </span>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  initializeNewSim: PropTypes.func,
  filterType: PropTypes.string,
  setFilterType: PropTypes.func,
  setTextSearch: PropTypes.func,
  products: PropTypes.array,
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Sims = ({
  totalSims,
  sims,
  fetchData,
  initializeNewSim,
  setEditSimStep,
  fetchProductList,
  deleteAssignmentDetails,
  saveSelectedSim,
  selectedSim,
  products,
  prop,
  reset,
  resetVideoStats,
  characters,
  sim_listing,
  userDetails,
  sfReset,
}) => {
  const query = useQuery();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);
  // const location = useLocation();
  const classes = useTableStyles();
  const [showDetailsScreen, setShowDetailsScreen] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [filterType, setFilterType] = useState("All");
  const [textSearch, setTextSearch] = useState("");
  const [simsList, setSimsList] = useState([]);
  const [assignmentStatus, setAssignmentStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentSim, setCurrentSim] = useState({});
  const [assignMentProps, setAssignmentProps] = useState(prop);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMesage, setAlertMessage] = React.useState("");
  localStorage.setItem('sims_id', '');
  useEffect(() => {
    if (sims && sims.length > 0) {
      setSimsList((prev) => [...prev, ...sims]);
      reset();
      resetVideoStats();
    }
  }, [sims, reset, resetVideoStats]);

  // get paginated sims data
  useEffect(() => {
    if (userDetails.role.toLowerCase() !== 'agent') {
      sfReset();
      if (filterType === "All") {
        fetchData(limit, pageNumber, null, textSearch);
      } else {
        fetchData(limit, pageNumber, filterType, textSearch);
      }
    }
  }, [pageNumber, fetchData, filterType, textSearch, userDetails, sfReset]);

  useEffect(() => {
    setPageNumber(0);
    setSimsList([]);
  }, [filterType, textSearch]);

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  const handleRowClick = async (row) => {
    const requestData = {}
    requestData.sim_id = row._id
    await deleteLevel(requestData);
    localStorage.setItem("sim_id", row._id);
    deleteAssignmentDetails();
    setAssignmentProps(null);
    setDetailsData(row);
    saveSelectedSim(row);
  };

  useEffect(() => {
    if (detailsData) {
      setShowDetailsScreen(true);
    }
  }, [detailsData]);

  useEffect(() => {
    if (!showDetailsScreen) {
      setDetailsData(null);
    }
  }, [showDetailsScreen]);

  if (prop.sim_id && !detailsData && !assignmentStatus) {
    getSimById(prop.sim_id).then((res) => {
      setDetailsData(res.data);
    });
    setAssignmentStatus(true);
  }

  const handleClick = async (row) => {
    if (!row.sim_characters.length) {
      for (const singleCharacter of characters) {
        singleCharacter.sim_id = row._id;
      }
      const requestData = { sim_characters: characters };
      await saveAvatarDetails(requestData);
    }
    await setEditSimStep();
    history.push(`/sims/createsim?simId=${row._id}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteConfirm = async () => {
    const requestData = {};
    requestData.sim_id = currentSim._id;
    const deletesimStatus = await deleteSim(requestData);
    if (!deletesimStatus.data.success) {
      setAlertMessage(deletesimStatus.data.message);
      setOpenAlert(true);
    }
    setSimsList([]);
    setOpen(false);
    setPageNumber(0);
    if (userDetails.role.toLowerCase() !== 'agent') {
      if (filterType === "All") {
        fetchData(limit, pageNumber, null, textSearch);
      } else {
        fetchData(limit, pageNumber, filterType, textSearch);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setOpen(true);
    setCurrentSim(row);
  };

  useEffect(() => {
    if (query.get("exit")) {
      if (Object.keys(selectedSim).length > 0) {
        setDetailsData(selectedSim);
      }
      if (assignMentProps && assignMentProps.type === undefined) {
        setAssignmentProps({
          ...assignMentProps,
          type: query.get("type"),
        });
      }
    }
  }, [setDetailsData, query, selectedSim, assignMentProps]);

  useEffect(() => { }, [sim_listing]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setAlertMessage("");
  };

  if (userDetails.role.toLowerCase() === 'agent' && !prop.sim_id) {
    return (
      <Redirect to={'/dashboard'} />
    );
  }

  return (
    <>
      <Paper className={userDetails.role.toLowerCase() === 'agent' ? classes.hidePage : classes.paper}>
        <EnhancedTableToolbar
          initializeNewSim={initializeNewSim}
          filterType={filterType}
          setFilterType={setFilterType}
          setTextSearch={setTextSearch}
          products={products || []}
        />
        {showDetailsScreen && (
          <DetailsScreen
            sim={detailsData}
            open={showDetailsScreen}
            handleClose={setShowDetailsScreen}
            assignmentData={assignMentProps}
          />
        )}
        {openAlert ? (
          <Alert variant="filled" severity="error" onClose={handleAlertClose} className={classes.errorAlert}>
            {alertMesage}
          </Alert>
        ) : null}

        {sim_listing && simsList && simsList.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={simsList.length}
            next={() => setPageNumber((prevPageNumber) => prevPageNumber + 1)}
            hasMore={simsList.length < totalSims}
            loader={<p>Loading...</p>}
          >
            <TableContainer component={Paper} className={classes.root}>
              <Table aria-label="sims table">
                <TableHead className={classes.thead}>
                  <TableRow>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        CALL SIMULATOR
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        TYPE
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        CREATED
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        UPDATED
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        ADMIN NOTES
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading} padding="none">
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        DRAFT
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction="desc"
                        active
                        IconComponent={ArrowDropDownIcon}
                      >
                        BUILT BY
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {simsList.length > 0
                    ? simsList.map((row, index) => (
                      <StyledTableRow
                        key={index}
                        className={classes.tableRow}
                      >
                        <TableCell className={classes.bold}>
                          {row.title}
                        </TableCell>
                        <TableCell className={classes.type}>
                          {products &&
                            products.map((product) => {
                              return row.product_id === product._id
                                ? product.name
                                : null;
                            })}
                        </TableCell>
                        <TableCell className={classes.created}>
                          {moment(row.created_at).format("MMM DD YYYY")}
                        </TableCell>
                        <TableCell className={classes.type}>
                          {moment(row.updated_at).startOf("minute").fromNow()}
                        </TableCell>
                        <TableCell
                          title={
                            row.admin_note?.length > 120
                              ? row.admin_note
                              : null
                          }
                          className={classes.admin_note}
                        >
                          {row?.admin_note?.length > 120
                            ? `${row.admin_note.slice(0, 120)}  ...`
                            : row.admin_note}
                        </TableCell>
                        <TableCell padding="none">
                          {row.draft ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          <div className={classes.built}>
                            <Avatar
                              src={
                                row.profile_pic_url ? row.profile_pic_url : ProfilePic
                              }
                              alt="profile"
                              style={{ marginRight: "10px" }}
                              className={classes.small}
                            />
                            <div>{`${row.first_name} ${row.last_name}`}</div>
                          </div>
                        </TableCell>
                        <TableCell key={`actions_${row.id}`}>
                          <div className={classes.built}>
                            <ThemeProvider theme={innerTheme}>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonSqr}
                                disableElevation
                              >
                                <VisibilityIcon
                                  onClick={() => handleRowClick(row)}
                                  fontSize="small"
                                />
                              </Button>
                            </ThemeProvider>
                            <Tooltip
                              title={
                                !permissionCheck(
                                  SimsPermissions.sim_list.update.task,
                                  SimsPermissions.sim_list.update.type
                                )
                                  ? "You don't have permission to update"
                                  : ""
                              }
                              placement="bottom"
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  disabled={
                                    !permissionCheck(
                                      SimsPermissions.sim_list.update.task,
                                      SimsPermissions.sim_list.update.type
                                    )
                                  }
                                  className={classes.buttonSqr}
                                  disableElevation
                                >
                                  <EditIcon
                                    onClick={() => handleClick(row)}
                                    fontSize="small"
                                  />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={
                                !permissionCheck(
                                  SimsPermissions.sim_list.delete.task,
                                  SimsPermissions.sim_list.delete.type
                                )
                                  ? "You don't have permission to delete"
                                  : ""
                              }
                              placement="bottom"
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  className={classes.buttonDelete}
                                  disabled={
                                    !permissionCheck(
                                      SimsPermissions.sim_list.delete.task,
                                      SimsPermissions.sim_list.delete.type
                                    )
                                  }
                                  disableElevation
                                >
                                  <DeleteIcon
                                    onClick={() => handleDeleteClick(row)}
                                    fontSize="small"
                                  />
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Sim</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Do you want to delete the sim "${currentSim.title}"?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button
              onClick={() => deleteConfirm()}
              className={classes.dialogDeleteBtn}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      {!sim_listing && simsList.length === 0 ? (
        <div className={userDetails.role.toLowerCase() === 'agent' ? classes.hidePage : classes.noData}>
          <img src={noSims} height={55} width={54} alt="noSims" />
          <h3 className={classes.noAssign}>There are no SIMs added yet!</h3>
          <p className={classes.noAssignPara}>
            Are you up for creating new SIM now?
          </p>
          <StyledLink to="/sims/createsim">
            <Button
              variant="outlined"
              color="primary"
              className={classes.addButton}
              onClick={() => {
                initializeNewSim();
              }}
            >
              <span className={classes.plus}>+</span> ADD NEW SIM
            </Button>
          </StyledLink>
        </div>
      ) : null}
    </>
  );
};

Sims.propTypes = {
  totalSims: PropTypes.number,
  sims: PropTypes.array,
  fetchData: PropTypes.func,
  initializeNewSim: PropTypes.func,
  setEditSimStep: PropTypes.func,
  fetchProductList: PropTypes.func,
  deleteAssignmentDetails: PropTypes.func,
  saveSelectedSim: PropTypes.func,
  products: PropTypes.array,
  prop: PropTypes.object,
  selectedSim: PropTypes.object,
  reset: PropTypes.func,
  resetVideoStats: PropTypes.func,
  sim_listing: PropTypes.bool,
  userDetails: PropTypes.object,
  sfReset: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
  isLoading: state.sim.isLoading,
  totalSims: state.sim.total,
  sims: state.sim.data,
  status: state.sim.status,
  products: state.sim.products,
  prop: props && props.location.state ? props.location.state : localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : {},
  selectedSim: state.sim.selectedSim,
  characters: state.sim.newSim.sim_characters,
  sim_listing: state.sim.sim_listing,
  userDetails: state.login.user,
});

const mapDispatchToProps = {
  fetchData,
  initializeNewSim,
  setEditSimStep,
  fetchProductList,
  deleteAssignmentDetails,
  saveSelectedSim,
  reset,
  resetVideoStats,
  sfReset,
};

// connect Sims component to redux store

export default connect(mapStateToProps, mapDispatchToProps)(Sims);
