/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useCharacterDataStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 15,
  },
  gridStyle: {
    background: '#ffff',
    borderRadius: 5,
    minHeight: 100,
    maxWidth: '100%',
  },
  priorityIcon: {
    background: '#B5E8D1',
    color: '#037240',
    borderRadius: 5,
    width: 28,
    height: 28,
    margin: 14,
    padding: 3,
    cursor: 'pointer',
  },
  micIcon: {
    background: '#E3E3E3',
    color: '#595959',
    borderRadius: 5,
    width: 28,
    height: 28,
    margin: '14px 14px 14px 0px',
    padding: 3,
    cursor: 'pointer',
  },
  micOffIcon: {
    background: 'red',
    color: '#595959',
    borderRadius: 5,
    width: 28,
    height: 28,
    margin: '14px 14px 14px 0px',
    padding: 3,
    cursor: 'pointer',
  },
  avatarImg: {
    margin: 10,
  },
  nameLabel: {
    marginTop: 12,
    fontSize: 14,
    fontWeight: 700,
    width: '100%',
  },
  roleLabel: {
    fontSize: 10,
    fontWeight: 100,
  },
  descriptionLabel: {
    fontSize: 12,
    cursor: 'pointer',
  },
  editIcon: {
    width: 20,
    height: 13,
  },
  topBox: {
    display: 'flex',
    width: '100%',
    paddingBottom: 10,
  },
  bottomBox: {
    width: '100%',
  },
  toolBar: {
    minHeight: 48,
    paddingLeft: 8,
    paddingRight: 8,
  },
  toolBarLabel: {
    fontSize: 10,
    fontWeight: '700',
    padding: '3px 7px 3px 7px',
    whiteSpace: 'nowrap',
    color: 'black',
  },
  autopopulateTypo: {
    display: 'contents',
    fontSize: 11,
    fontWeight: '700',
    padding: 3,
    whiteSpace: 'nowrap',
    color: 'black',
  },
  toolBarValue: {
    fontSize: 12,
    fontWeight: 500,
  },
  switchBtn: {
    color: '#943E5B',
    fontSize: 10,
    fontWeight: '700',
    letterSpacing: 0,
    marginLeft: 'auto',
    [theme.breakpoints.down(1285)]: {
      fontSize: 10,
      padding: 0,
      marginLeft: -12,
    },
  },
  recordSwitchBtn: {
    color: '#943E5B',
    fontSize: 10,
    fontWeight: '700',
    letterSpacing: 0,
    marginLeft: 'auto',
    [theme.breakpoints.down(1285)]: {
      fontSize: 10,
      padding: 0,
      marginLeft: -12,
    },
  },
  generateDiv: {
    marginLeft: 'auto',
    display: 'contents',
  },
  recordaudioDiv: {
    display: 'block',
    margin: 'auto',
    paddingLeft: '12px',
  },
  generateAudioBtn: {
    color: '#943E5B',
    fontSize: 11,
    fontWeight: '700',
    letterSpacing: 0,
    marginLeft: 'auto',
    float: 'right',
    [theme.breakpoints.down(1285)]: {
      fontSize: 10,
    },
  },
  generateAudio: {
    color: '#943E5B',
    fontSize: 10,
    fontWeight: '700',
    letterSpacing: 0,
    [theme.breakpoints.down(1285)]: {
      fontSize: 10,
    },
  },
  recordedAudio: {
    fontSize: 9,
    fontWeight: '600',
    color: 'black',
    paddingTop: '12px',
    paddingRight: '4px',
  },
  promptRecord: {
    fontSize: 10,
    fontWeight: '600',
    color: 'black',
  },
  verticalLine: {
    height: 15,
    margin: 3,
    alignSelf: 'center',
    [theme.breakpoints.down(1285)]: {
      margin: 2,
    },
  },
  deleteIcon: {
    marginLeft: 'auto',
    marginTop: 4,
    marginRight: 4,
    padding: 3,
    cursor: 'pointer',
  },
  label: {
    fontSize: 10,
    fontWeight: '600',
    color: 'black',
  },
  radioBtn: {
    marginRight: 0,
    marginLeft: -1,
    [theme.breakpoints.down(1285)]: {
      marginLeft: -4,
      marginRight: 0,
    },
  },
  formGroup: {
    marginLeft: -13,
  },
  formControlLabel: {
    marginLeft: -9,
    marginRight: 3,
    transform: 'scale(0.7)',
  },
  radioBtnDiv: {
    display: 'contents',
  },
  radioLabels: {
    display: 'contents',
    fontSize: 11,
    fontWeight: '700',
    padding: 3,
    whiteSpace: 'nowrap',
    color: 'black !important',
  },
  radio: {
    color: '#4185EA !important',
    marginRight: -7,
    transform: 'scale(0.8)',
  },
  labelFont: {
    fontSize: 12,
  },
  textFielDiv: {
    display: 'contents',
  },
  saveBtn: {
    alignSelf: 'flex-end',
    marginBottom: -10,
    padding: 2,
    marginRight: 5,
  },
  cancelBtn: {
    alignSelf: 'flex-end',
    marginBottom: -10,
    padding: 2,
  },
  cancelButton: {
    color: '#313387',
  },
  tagInput: {
    maxWidth: 450,
    minWidth: 400,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    overflow: 'hidden',
    paddingLeft: 5,
    paddingTop: 5,
    '& .react-tagsinput-tag': {
      borderRadius: 2,
      display: 'inline-block',
      fontSize: 14,
      fontWeight: '400',
      marginBottom: 5,
      marginRight: 5,
      padding: 5,
      background: '#F5E2E9',
      border: '1px solid #F5E2E9',
      color: 'black',
      fontFamily: 'inherit',
    },
  },
  secondsTextField: {
    width: 64,
    height: 24,
  },
  dropdown: {
    width: '32.5rem',
    textAlign: 'left',
    paddingLeft: 6,
  },
  index: {
    textAlign: 'center',
    paddingTop: 12,
    fontSize: 25,
    width: '6%',
    color: '#949393',
    background: '#dddddd',
  },
  scriptGrid: {
    width: '94%',
  },
  chckboxIcn: {
    color: '#882647',
    marginBottom: -10,
    fontSize: 28,
    cursor: 'pointer',
  },
  select: {
    height: 25,
    width: 100,
    fontSize: 14,
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 8,
    },
  },
  selectDiv: {
    marginLeft: 'auto',
    marginBottom: 2,
    padding: 4,
    whiteSpace: 'nowrap',
  },
  switch: {
    margin: 'auto',
    paddingTop: 12,
  },
}));
