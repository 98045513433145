import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  TextField,
  InputLabel,
  NativeSelect,
  Typography,
  Avatar,
  Grid,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// connect to redux to get select voices list
import { connect } from 'react-redux';
// import avatar images
import avatar1 from '../../../assets/images/avatars/avatar-1.png';
import avatar2 from '../../../assets/images/avatars/avatar-2.png';
import avatar3 from '../../../assets/images/avatars/avatar-3.png';
import avatar4 from '../../../assets/images/avatars/avatar-4.png';
import avatar5 from '../../../assets/images/avatars/avatar-5.png';
import avatar6 from '../../../assets/images/avatars/avatar-6.png';
import avatar7 from '../../../assets/images/avatars/avatar-7.png';
import avatar8 from '../../../assets/images/avatars/avatar-8.png';
import avatar9 from '../../../assets/images/avatars/avatar-9.png';
import avatar10 from '../../../assets/images/avatars/avatar-10.png';
import avatar11 from '../../../assets/images/avatars/avatar-11.png';
import avatar12 from '../../../assets/images/avatars/avatar-12.png';
import avatar13 from '../../../assets/images/avatars/avatar-13.png';
import avatar14 from '../../../assets/images/avatars/avatar-14.png';
import avatar16 from '../../../assets/images/avatars/avatar-16.png';
import avatar17 from '../../../assets/images/avatars/avatar-17.png';
import avatar18 from '../../../assets/images/avatars/avatar-18.png';
import avatar19 from '../../../assets/images/avatars/avatar-19.png';
import avatar15 from '../../../assets/images/avatars/avatar-15.png';
import avatar20 from '../../../assets/images/avatars/avatar-20.png';
import avatar21 from '../../../assets/images/avatars/avatar-21.png';
import avatar22 from '../../../assets/images/avatars/avatar-22.png';
import avatar23 from '../../../assets/images/avatars/avatar-23.png';
import avatar24 from '../../../assets/images/avatars/avatar-24.png';
import { useStyles } from './Avatars';

const Avatars = ({
  bgColor,
  data,
  updateData,
  invalid,
  newSim,
}) => {
  const classes = useStyles();
  const [voice, setVoice] = useState(data.voice);
  const [name, setName] = useState(data.name);
  const [masked_type, setMaskedType] = useState(data.masked_type);
  const [avatar, setSelectedAvatar] = useState(data.avatar);

  const handleChange = (event) => {
    setVoice(event.target.value);
  };
  const validate = () => {
    if (name && voice && avatar) {
      const res = {
        name,
        voice,
        type: data.type,
        avatar,
        masked_type,
      };
      updateData(res);
    } else {
      invalid();
    }
  };

  const handleOnClick = (avatarName) => {
    setSelectedAvatar(avatarName);
  };

  useEffect(() => {
    validate();
  }, [avatar]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Paper
      style={{ backgroundColor: bgColor }}
      className={classes.root}
      elevation={0}
    >
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.subtitle}
      >
        {data.type}
        {' '}
        character
      </Typography>
      <form className={classes.formControl}>
        <InputLabel className={classes.label}>Name</InputLabel>
        <TextField
          className={classes.textField}
          id={`${data.type}-name-input`}
          required
          placeholder="Enter Name/Title"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            inputMode: 'string',
          }}
          variant="outlined"
          size="small"
          fullWidth
          onBlur={validate}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <InputLabel className={classes.label}>Masked Type</InputLabel>
        <TextField
          className={classes.textField}
          id={`${data.type}-role-input`}
          required
          placeholder="Enter type/role"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            inputMode: 'string',
          }}
          variant="outlined"
          size="small"
          fullWidth
          onBlur={validate}
          value={masked_type}
          onChange={(e) => setMaskedType(e.target.value)}
        />
        <br />
        <InputLabel id="selectLable" className={classes.label}>
          Voice
        </InputLabel>
        <NativeSelect
          lableid="selectLable"
          id="selectVoice"
          value={voice}
          // error={voice ? false : true}
          onChange={handleChange}
          className={classes.select}
          fullWidth
          required
          onBlur={validate}
          IconComponent={KeyboardArrowDownIcon}
        >
          <option value="" disabled>
            Select Voice
          </option>
          {Object.entries(newSim.selectVoices).map(([key, value]) => (
            <option value={key} key={key}>
              {value}
            </option>
          ))}
        </NativeSelect>

        <Grid
          container
          spacing={2}
          justify="center"
          className={classes.gridContainer}
        >
          <Grid item xs={3}>
            <Avatar
              src={avatar1}
              alt="avatar-1"
              variant="rounded"
              className={
                avatar === 'avatar-1'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-1')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar2}
              alt="avatar-2"
              variant="rounded"
              className={
                avatar === 'avatar-2'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-2')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar3}
              alt="avatar-3"
              variant="rounded"
              className={
                avatar === 'avatar-3'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-3')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar4}
              alt="avatar-4"
              variant="rounded"
              className={
                avatar === 'avatar-4'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-4')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar5}
              alt="avatar-5"
              variant="rounded"
              className={
                avatar === 'avatar-5'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-5')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar6}
              alt="avatar-6"
              variant="rounded"
              className={
                avatar === 'avatar-6'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-6')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar7}
              alt="avatar-7"
              variant="rounded"
              className={
                avatar === 'avatar-7'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-7')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar8}
              alt="avatar-8"
              variant="rounded"
              className={
                avatar === 'avatar-8'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-8')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar9}
              alt="avatar-9"
              variant="rounded"
              className={
                avatar === 'avatar-9'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-9')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar10}
              alt="avatar-10"
              variant="rounded"
              className={
                avatar === 'avatar-10'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-10')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar11}
              alt="avatar-11"
              variant="rounded"
              className={
                avatar === 'avatar-11'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-11')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar12}
              alt="avatar-12"
              variant="rounded"
              className={
                avatar === 'avatar-12'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-12')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar13}
              alt="avatar-13"
              variant="rounded"
              className={
                avatar === 'avatar-13'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-13')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar14}
              alt="avatar-14"
              variant="rounded"
              className={
                avatar === 'avatar-14'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-14')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar15}
              alt="avatar-15"
              variant="rounded"
              className={
                avatar === 'avatar-15'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-15')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar16}
              alt="avatar-16"
              variant="rounded"
              className={
                avatar === 'avatar-16'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-16')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar17}
              alt="avatar-17"
              variant="rounded"
              className={
                avatar === 'avatar-17'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-17')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar18}
              alt="avatar-18"
              variant="rounded"
              className={
                avatar === 'avatar-18'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-18')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar19}
              alt="avatar-19"
              variant="rounded"
              className={
                avatar === 'avatar-19'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-19')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar20}
              alt="avatar-20"
              variant="rounded"
              className={
                avatar === 'avatar-20'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-20')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar21}
              alt="avatar-21"
              variant="rounded"
              className={
                avatar === 'avatar-21'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-21')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar22}
              alt="avatar-22"
              variant="rounded"
              className={
                avatar === 'avatar-22'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-22')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar23}
              alt="avatar-23"
              variant="rounded"
              className={
                avatar === 'avatar-23'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-23')}
            />
          </Grid>
          <Grid item xs={3}>
            <Avatar
              src={avatar24}
              alt="avatar-24"
              variant="rounded"
              className={
                avatar === 'avatar-24'
                  ? classes.onSelectAvatar
                  : classes.largeAvatar
              }
              onClick={() => handleOnClick('avatar-24')}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

Avatars.propTypes = {
  bgColor: PropTypes.string,
  data: PropTypes.object,
  updateData: PropTypes.func,
  invalid: PropTypes.func,
  newSim: PropTypes.object,
};

const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Avatars);
