import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Paper,
  Popper,
  Divider,
  Tooltip,
  Modal,
} from "@material-ui/core";
import { connect } from "react-redux";
import { addAssignmentStatus, fetchUserResponse } from "../../redux/sims/actions";
import StyledLink from "../common/StyledLink";
import {
  checkUserResultExist,
  checkContinueLevel,
} from "../../services/result.service";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Levels";
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';


const Levels = ({
  level,
  index,
  simId,
  fetchUserResponse,
  assignmentData,
  type,
  addAssignmentStatus,
  currentAssignmentStatus,
  document,
  hotLinks,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [userResultExist, setUserResultExist] = useState(false);
  const [opneModal, setOpenModal] = useState(false);
  const [simType, setSimType] = useState(type);
  const [mode, setMode] = useState("practice");
  const [activeClip, setActiveClip] = useState(0);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleArrowRef = (node) => {
    setArrowRef(node);
  };

  const startPractice = () => {
    setMode("practice");
    checkContinueLevel(simId, level.level_id, "practice").then((res) => {
      if (res.data.continue_agent) {
        setOpenModal(true);
        setActiveClip(res.data.active_clip_index);
      } else {
        fetchUserResponse({
          sim_level_id: level.level_id,
          sim_id: simId,
          mode: "practice",
          resumeLevel: false,
          assignment_id: assignmentData
            ? assignmentData.assignment_id
            : undefined,
        });
        if (assignmentData && assignmentData.assignment_id) {
          const data = {
            _id: assignmentData.assignment_id,
            status: "IN PROGRESS",
          };
          addAssignmentStatus(data);
        }
        startOver("practice");
      }
    });
  };

  const startAutoPlay = () => {
    setMode("autoplay");
    checkContinueLevel(simId, level.level_id, "autoplay").then((res) => {
      if (res.data.continue_agent) {
        setOpenModal(true);
        setActiveClip(res.data.active_clip_index);
      } else {
        fetchUserResponse({
          sim_level_id: level.level_id,
          sim_id: simId,
          mode: "autoplay",
          resumeLevel: false,
          assignment_id: assignmentData
            ? assignmentData.assignment_id
            : undefined,
        });
        if (assignmentData && assignmentData.assignment_id) {
          const data = {
            _id: assignmentData.assignment_id,
            status: "IN PROGRESS",
          };
          addAssignmentStatus(data);
        }
        startOver("autoplay");
      }
    });
  };

  useEffect(() => {
    const asyncFunctionCall = async () => {
      const payload = {
        simId: assignmentData?.sim_id ? assignmentData.sim_id : simId,
        simLevelId: level.level_id,
        ...(assignmentData?.assignment_id
          ? { assignment_id: assignmentData.assignment_id }
          : {}),
      };
      const userId =
        assignmentData?.assigned_to_id || assignmentData?.agent_user_id;
      const exist = await checkUserResultExist(payload, userId);
      setUserResultExist(exist);
    };
    asyncFunctionCall();
    assignmentData && assignmentData.sim_type
      ? setSimType(assignmentData.sim_type)
      : setSimType(type);
  }, [assignmentData, level, simId, type]);

  const open = Boolean(anchorEl);
  const id = open ? "popper with arrow" : undefined;
  // Close Modal
  const handleClose = () => setOpenModal(false);
  const startOver = (simMode) => {
    fetchUserResponse({
      sim_level_id: level.level_id,
      sim_id: simId,
      mode: simMode ? simMode : mode,
      resumeLevel: false,
      assignment_id: assignmentData ? assignmentData.assignment_id : undefined,
    });
    if (assignmentData && assignmentData.assignment_id) {
      const data = {
        _id: assignmentData.assignment_id,
        status: "IN PROGRESS",
      };
      addAssignmentStatus(data);
    }
    history.push({
      pathname: "/sims/takesim",
      search: assignmentData?.assignment_id
        ? `?levelId=${level.level_id}&assignment_id=${
            assignmentData.assignment_id
          }${
            assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
          }&continueSimLevel=false&order=${level.level_order}`
        : `?levelId=${level.level_id}${
            assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
          }&continueSimLevel=false&order=${level.level_order}`,
      state: {
        viewResult: false,
        assignment: assignmentData ? assignmentData : {},
        type: simType,
        documents: document,
        hotLinks: hotLinks,
        screenRecording: level.screenRecording,
      },
    });
  };
  // If agent continue for level
  const resumeLevel = () => {
    fetchUserResponse({
      sim_level_id: level.level_id,
      sim_id: simId,
      mode: mode,
      resumeLevel: true,
      assignment_id: assignmentData ? assignmentData.assignment_id : undefined,
    });
    if (assignmentData && assignmentData.assignment_id) {
      const data = {
        _id: assignmentData.assignment_id,
        status: "IN PROGRESS",
      };
      addAssignmentStatus(data);
    }
    history.push({
      pathname: "/sims/takesim",
      search: assignmentData?.assignment_id
        ? `?levelId=${level.level_id}&assignment_id=${
            assignmentData.assignment_id
          }${
            assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
          }&continueSimLevel=true&activeIndex=${activeClip}&order=${level.level_order}`
        : `?levelId=${level.level_id}${
            assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
          }&continueSimLevel=true&activeIndex=${activeClip}&order=${level.level_order}`,
      state: {
        viewResult: false,
        assignment: assignmentData ? assignmentData : {},
        type: simType,
        documents: document,
        hotLinks: hotLinks,
        screenRecording: level.screenRecording,
      },
    });
  };
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      color="default"
      mb={1}
      borderRadius={16}
      className={classes.root}
      onMouseLeave={handlePopoverClose}
    >
      <Modal
        open={opneModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <p>You already started this level. Do you want to resume?</p>
          <button
            className={classes.startBtn}
            style={{ marginRight: "1rem" }}
            onClick={() => startOver()}
          >
            START OVER
          </button>
          <button className={classes.startBtn} onClick={resumeLevel}>
            RESUME
          </button>
        </div>
      </Modal>
      <Box display="flex" alignItems="center" className={classes.flexItem1}>
        <div className={classes.flex2item1}>
          <Checkbox 
          className= {classes.checkBox}
          icon={<CircleUnchecked/>}
          checkedIcon={<CircleCheckedFilled className={classes.chckBoxFilled}/>} 
          checked={level.completed_attempts > 0}
          />
        </div>
        <div>
          <div className={classes.textBold}>
            LEVEL&nbsp;
            {index + 1}
          </div>
          <div>{level.level_name}</div>
        </div>
      </Box>
      <div className={classes.flexItem3}>
        {simType === "hiring" ? (
          // <StyledLink
          //   to={{
          //     pathname: "/sims/takesim",
          //     search: assignmentData?.assignment_id
          //       ? `?levelId=${level.level_id}&assignment_id=${
          //           assignmentData.assignment_id
          //         }${
          //           assignmentData?.reviewer
          //             ? "&reviewer=true"
          //             : "&reviewer=false"
          //         }`
          //       : `?levelId=${level.level_id}${
          //           assignmentData?.reviewer
          //             ? "&reviewer=true"
          //             : "&reviewer=false"
          //         }`,
          //     state: {
          //       viewResult: false,
          //       assignment: assignmentData,
          //     },
          //   }}
          // >
          <Tooltip
            title={
              currentAssignmentStatus?.status === "PENDING REVIEW"
                ? "Locked"
                : ""
            }
            placement="top"
          >
            <span>
              <Button
                variant="contained"
                className={classes.startBtn}
                disableElevation
                color="primary"
                size="small"
                aria-describedby={id}
                onClick={startAutoPlay}
                disabled={
                  currentAssignmentStatus?.status === "PENDING REVIEW"
                    ? true
                    : false
                }
              >
                START
              </Button>
            </span>
          </Tooltip>
        ) : (
          // </StyledLink>
          <Tooltip
            title={
              currentAssignmentStatus?.status === "PENDING REVIEW"
                ? "Locked"
                : ""
            }
            placement="top"
          >
            <span>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                className={classes.startBtn}
                size="small"
                onClick={handleClick}
                aria-describedby={id}
                disabled={
                  currentAssignmentStatus?.status === "PENDING REVIEW"
                    ? true
                    : false
                }
              >
                START
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.flexItem2}>
        <div className={classes.status}>
          {level.total_attempts === 0 && <div>Not yet completed ...</div>}
          {level.total_attempts !== 0 && (
            <div>
              Completed {level.completed_attempts} times in{" "}
              {level.total_attempts} attempts
              <br />
              {userResultExist && level.completed_attempts > 0 && (
                <StyledLink
                  to={{
                    pathname: "/sims/takesim",
                    search: assignmentData?.assignment_id
                      ? `?levelId=${level.level_id}&assignment_id=${
                          assignmentData.assignment_id
                        }&sim_id=${assignmentData.sim_id}&user_id=${
                          assignmentData.assigned_to_id ||
                          assignmentData.agent_user_id
                        }${
                          assignmentData?.reviewer
                            ? "&reviewer=true"
                            : "&reviewer=false"
                        }&order=${level.level_order}`
                      : `?levelId=${level.level_id}${
                          assignmentData?.reviewer
                            ? "&reviewer=true"
                            : "&reviewer=false"
                        }&order=${level.level_order}`,
                    type: simType,
                    state: {
                      viewResult: true,
                      assignment: assignmentData,
                    },
                  }}
                >
                  <u>View Results</u>
                </StyledLink>
              )}
            </div>
          )}
        </div>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="left"
        className={classes.popper}
        disablePortal={false}
        onMouseLeave={handlePopoverClose}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        {/* arrow element */}
        <div
          data-popper-arrow
          className={classes.rightTriangle}
          ref={handleArrowRef}
        />
        <Paper elevation={2} className={classes.popperPaper}>
          {/* <StyledLink
            to={{
              pathname: "/sims/takesim",
              search: assignmentData?.assignment_id
                ? `?levelId=${level.level_id}&assignment_id=${
                    assignmentData.assignment_id
                  }${
                    assignmentData?.reviewer
                      ? "&reviewer=true"
                      : "&reviewer=false"
                  }`
                : `?levelId=${level.level_id}${
                    assignmentData?.reviewer
                      ? "&reviewer=true"
                      : "&reviewer=false"
                  }`,
              state: {
                viewResult: false,
                assignment: assignmentData ? assignmentData : {},
              },
            }}
          > */}
          <Button
            variant="contained"
            className={classes.practiceBtn}
            disableElevation
            onClick={startPractice}
          >
            Start in Practice mode
          </Button>
          {/* </StyledLink> */}
          <br />
          {/* <StyledLink
            to={{
              pathname: "/sims/takesim",
              search: assignmentData?.assignment_id
                ? `?levelId=${level.level_id}&assignment_id=${
                    assignmentData.assignment_id
                  }${
                    assignmentData?.reviewer
                      ? "&reviewer=true"
                      : "&reviewer=false"
                  }`
                : `?levelId=${level.level_id}${
                    assignmentData?.reviewer
                      ? "&reviewer=true"
                      : "&reviewer=false"
                  }`,
              state: {
                viewResult: false,
                assignment: assignmentData ? assignmentData : {},
              },
            }}
          > */}
          <Button
            variant="contained"
            className={classes.autoplayBtn}
            disableElevation
            onClick={startAutoPlay}
          >
            Start in Autoplay mode
          </Button>
          {/* </StyledLink> */}
        </Paper>
      </Popper>
    </Box>
  );
};

Levels.propTypes = {
  level: PropTypes.shape({
    level_order: PropTypes.number,
    level_name: PropTypes.string,
    total_attempts: PropTypes.number,
    completed_attempts: PropTypes.number,
    level_id: PropTypes.string,
    screenRecording: PropTypes.bool,
  }),
  index: PropTypes.number,
  simId: PropTypes.string,
  type: PropTypes.string,
  fetchUserResponse: PropTypes.func,
  assignmentData: PropTypes.object,
  addAssignmentStatus: PropTypes.func,
};

const mapDispatchToProps = {
  fetchUserResponse,
  addAssignmentStatus,
};

export default connect(null, mapDispatchToProps)(Levels);
