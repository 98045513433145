/* eslint-disable react/no-danger */
import { Input, InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import PropTypes from 'prop-types';

const KeywordInput = ({
  classes,
  handleBlur,
  handleChange,
  fieldName,
  fetchCaseCallerDetails,
}) => {
  const formatUrl = (str) => {
    const attribute = ' rel="noopener noreferrer"';
    const position = 2;
    const output = [
      str.slice(0, position),
      attribute,
      str.slice(position),
    ].join('');
    return output;
  };
  const numberList = '0123456789';
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };
  return (
    <div>
      {fieldName && fieldName.value && fieldName.value.startsWith('<a ') ? (
        <div className={classes.linkTag}>
          <span
            className={classes.anim}
            dangerouslySetInnerHTML={{
              __html: formatUrl(fieldName.value),
            }}
          />
        </div>
      ) : (
        <Input
          fullWidth
          onKeyUp={
            fieldName.fieldTitle === 'Caller Name'
              ? fetchCaseCallerDetails
              : null
          }
          value={
            fieldName.current_value
              ? numberList.includes(fieldName.current_value[0])
                ? formatPhoneNumber(fieldName.current_value)
                : fieldName.current_value
              : ''
          }
          onBlur={handleBlur}
          onAnimationEnd={handleBlur}
          inputProps={{
            'data-currentvalue': fieldName.value,
            'data-caseid': fieldName._id,
            'data-inputtype': fieldName.type,
            'data-isscorable': fieldName.is_scorable,
            'data-fieldtitle': fieldName.fieldTitle,
          }}
          name={fieldName.fieldTitle}
          onChange={handleChange}
          className={fieldName.current_value ? classes.anim : null}
          endAdornment={(
            <InputAdornment position="end">
              <EditIcon className={classes.editIcon} />
            </InputAdornment>
          )}
        />
      )}
    </div>
  );
};

KeywordInput.propTypes = {
  classes: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  fieldName: PropTypes.object,
  fetchCaseCallerDetails: PropTypes.func,
};
export default KeywordInput;
