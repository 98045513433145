import { fade } from '@material-ui/core/styles';

export const DashBoard = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.5),
      fontSize: 12,
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  search: {
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    margin: 6,
    width: 220,
    height: 38,
    border: '2px solid #e1e1e1',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    [theme.breakpoints.up(1200)]: {
      width: 200,
      padding: '2px 5px',
    },
    [theme.breakpoints.up(1500)]: {
      width: 220,
    },
  },
  searchIcon: {
    marginLeft: 20,
    height: '100%',
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSqr: {
    minHeight: 0,
    minWidth: 35,
    padding: 4,
    marginLeft: 2,
  },
  addButton: {
    fontSize: 12,
    color: 'white',
    paddingLeft: 12,
    paddingRight: 12,
    whiteSpace: 'nowrap',
    height: 36,
    backgroundColor: '#284375',
  },
  title: {
    flexGrow: 1,
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  padding: {
    paddingTop: 0,
  },
  thead: {
    backgroundColor: '#e1e1e1',
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: '0.7rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  built: {
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  tableRow: {
    cursor: 'pointer',
  },
  container: {
    padding: '0px 15px',
  },
  active: {
    background: '#f8dee7',
    borderBottom: '2px solid #882e47',
    '&:hover': {
      backgroundColor: '#f1d0dc',
    },

  },
  badge: {
    marginLeft: 25,
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      background: 'red',
      color: 'white',
      fontWeight: 600,
    },
    animation: '$blink 2s linear infinite',

  },
  '@keyframes blink': {
    '50%': {
      opacity: 0,
    },
  },
  select: {
    height: 35,
    fontSize: 14,
    minWidth: 200,
  },
  menuItem: {
    fontSize: 'unset',
  },
  grid3: {
    width: '20%',
  },
  grid9: {
    width: '80%',
  },
  plus: {
    fontSize: 16,
    fontWeight: 600,
    paddingRight: 5,
  },
  inputSearch: {
    fontSize: 14,
  },
  icon: {
    fontSize: 20,
    verticalAlign: 'middle',
  },
  scoreTable: {
    width: 100,
  },
  label: {
    fontSize: 11,
    paddingTop: 10,
    margin: 0,
    paddingLeft: 0,
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  paper: {
    height: '100%',
    position: 'relative',
  },
  gridWidth: {
    width: 1200,
  },
  assignmentDiv: {
    display: 'contents',
  },
  searchBtn: {
    '& .MuiButton-root': {
      minWidth: 12,
    },
  },
  excel: {
    background: '#e0e0e0',
    padding: '6px 7px',
  },
  secondGrid: {
    maxWidth: '41%',
  },
  filterSpan: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  borderLeft: {
    borderLeft: '1px solid #00000054',
  },
});

export default DashBoard;
