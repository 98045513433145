// reference https://github.com/amazon-archives/amazon-transcribe-websocket-static
import MicrophoneStream from "microphone-stream";
const marshaller = require("@aws-sdk/eventstream-marshaller");
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);
export const pcmEncode = (input) => {
  var offset = 0;
  var buffer = new ArrayBuffer(input.length * 2);
  var view = new DataView(buffer);
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
}

export const downsampleBuffer = (
  buffer,
  inputSampleRate = 44100,
  outputSampleRate = 16000
) => {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }

  var sampleRateRatio = inputSampleRate / outputSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Float32Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;

  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

    var accum = 0,
      count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
}

export const getAudioEventMessage = (buffer) => {
  // wrap the audio data in a JSON envelope
  return {
    headers: {
      ":message-type": {
        type: "string",
        value: "event",
      },
      ":event-type": {
        type: "string",
        value: "AudioEvent",
      },
    },
    body: buffer,
  };
}

export const handleEventStreamMessage = (messageJson, transcription) => {
  let results = messageJson.Transcript.Results;
  if (results.length > 0) {
    if (results[0].Alternatives.length > 0) {
      let transcript = results[0].Alternatives[0].Transcript;
      // fix encoding for accented characters
      transcript = decodeURIComponent(escape(transcript));
      if (!results[0].IsPartial) {
        transcription += transcript + " ";
      }
    }
  }
  return transcription;
}

// Function for converting blob in buffer
export const convertAudioToBinaryMessage = (audioChunk) => {
  let raw = MicrophoneStream.toRaw(audioChunk);
  if (raw == null) return;

  // downsample and convert the raw audio bytes to PCM
  let downsampledBuffer = downsampleBuffer(raw, 44100, 44100);
  let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

  // add the right JSON headers and structure to the message
  let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

  let binary = eventStreamMarshaller.marshall(audioEventMessage);
  return binary;
}
