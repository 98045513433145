import { makeStyles } from "@material-ui/core/styles";

export const useTabContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  mainCard: {
    width: "58vw",
  },
  accordionDetails: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  arrowDropDown: {
    backgroundColor: "#D7D7D7",
    margin: "3px 9px -5px 5px",
    borderRadius: "3px",
  },
  title: {
    backgroundColor: "#EFEFEF",
    padding: "6px 6px",
    borderRadius: "3px",
  },
  link: {
    padding: theme.spacing(2),
  },
  linkText: {
    textDecoration: "unset",
    color: "#287ad0",
    fontWeight: 500,
  },
}));
