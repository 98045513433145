import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import StyledLink from "../../common/StyledLink";
import CircularProgress from "@material-ui/core/CircularProgress";
import loadingIcon from "../../../assets/images/icons/loading-1.gif";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {
  userFinalScoreResponse,
  viewResult,
  toggleResultButton,
  currentStatus,
  audioPlay,
  addAssessor,
  SaveAssessorNotes,
  getAssessorNotes,
  checkAllLevelAgentAudio,
} from "../../../redux/sims/actions";
import moment from "moment";
import AgentScript from "./AgentScript.jsx";
import OtherScript from "./OtherScript.jsx";
import { useLocation } from "react-router-dom";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DashboardPermissions from "../../../permissions/dashboard";
import { permissionCheck } from "../../../utils/permissionCheck";
import { fetchVidoeDetails } from "../../../services/result.service";
import { useStyles } from "./TakeSimResult";
import ProfilePic from '../../../assets/images/profile_pic.png'

const TakeSimResult = ({
  elapsedTime,
  userFinalScoreResponse,
  toggleResultButton,
  viewResult,
  userResponse,
  userResult,
  caseValuesArray,
  user,
  resultScreenInputData,
  isPost,
  simId,
  currentAudioPlaying,
  currentStatus,
  audioPlaying,
  audioPlay,
  assessorNotes,
  addAssessor,
  SaveAssessorNotes,
  getAssessorNotes,
  assessor_notes,
  checkAllLevelAgentAudio,
  allAudioProcessed,
  videoRecordingEnabled,
  videoUploadStatus,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  let simLevelId = urlParams.get("levelId");
  let order = urlParams.get("order");
  const assignment_id = urlParams.get("assignment_id");
  const [notes, setNotes] = React.useState("");
  const agent_user_id = urlParams.get("user_id");
  const sim_id = urlParams.get("sim_id");
  const reviewer_come = urlParams.get("reviewer");
  const [noteStatus, setNoteStatus] = React.useState("view");
  const [open, setOpen] = React.useState(false);
  const [video, setVideo] = React.useState("");
  const [videoDetailsFetched, setVideoDetailsFetched] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [callTranscript, setCallTranscript] = React.useState(0);
  const [enableVideoBtn, setEnableVideoBtn] = React.useState(false);
  const [disablePolling, setDisablePolling] = React.useState(false);
  const [userResults, setUserResults] = React.useState(userResult);
  const loggedUser = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if(userResult && userResult.scripts && userResult.scripts.length) {
      setUserResults(userResult);
    }
  },[userResult]);
  
  useEffect(() => {
    if (isPost === false) {
      const payload = {
        simLevelId,
        simId: sim_id ? sim_id : simId,
        ...(assignment_id ? { assignment_id } : {}),
      };
      viewResult(payload, agent_user_id);
    } else {
      const payload = {
        ...caseValuesArray,
        isPost: true,
        assignment_id: userResponse.assignment_id,
        elapsedTime: elapsedTime,
      };
      userFinalScoreResponse(userResponse._id, payload);
    }
    return () => {
      currentStatus(0);
      audioPlay("");
    };
  }, [
    userResponse,
    userFinalScoreResponse,
    caseValuesArray,
    viewResult,
    isPost,
    simId,
    simLevelId,
    assignment_id,
    sim_id,
    agent_user_id,
    elapsedTime,
    currentStatus,
    audioPlay,
    // userResult, getAssessorNotes, assessor_notes
  ]);
  const [lastFive, setLastFive] = React.useState("");
  const numberList = "0123456789";
  const checkNumber = (str) => {
    let x = str.split("").every((ele) => numberList.includes(ele));
    return x && str.length > 9;
  };
  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    toggleResultButton(true);
    return () => toggleResultButton(false);
  });
  const callApiInInterval = () => {
    const payload = {
      ...caseValuesArray,
      isPost: true,
      ...(assignment_id ? { assignment_id } : {}),
      elapsedTime: elapsedTime,
    };
    if (isPost !== false) userFinalScoreResponse(userResponse._id, payload);
  };
  useEffect(() => {
    if (isPost !== true) {
      if (
        !permissionCheck(
          DashboardPermissions.assessor_notes.update.task,
          DashboardPermissions.assessor_notes.update.type
        ) &&
        !permissionCheck(
          DashboardPermissions.assessor_notes.create.task,
          DashboardPermissions.assessor_notes.create.type
        ) &&
        permissionCheck(
          DashboardPermissions.assessor_notes.view.task,
          DashboardPermissions.assessor_notes.view.type
        )
      ) {
        getAssessorNotes(userResult._id);
        setNotes(assessor_notes);
        setNoteStatus("view");
      } else if (
        permissionCheck(
          DashboardPermissions.assessor_notes.update.task,
          DashboardPermissions.assessor_notes.update.type
        ) ||
        permissionCheck(
          DashboardPermissions.assessor_notes.create.task,
          DashboardPermissions.assessor_notes.create.type
        )
      ) {
        getAssessorNotes(userResult._id);
        setNotes(assessor_notes);
        setNoteStatus(assessor_notes === "" ? "add" : "edit");
      }
    } else {
      if (
        !permissionCheck(
          DashboardPermissions.assessor_notes.update.task,
          DashboardPermissions.assessor_notes.update.type
        ) &&
        !permissionCheck(
          DashboardPermissions.assessor_notes.create.task,
          DashboardPermissions.assessor_notes.create.type
        ) &&
        permissionCheck(
          DashboardPermissions.assessor_notes.view.task,
          DashboardPermissions.assessor_notes.view.type
        )
      ) {
        setNoteStatus("view");
      } else if (
        permissionCheck(
          DashboardPermissions.assessor_notes.update.task,
          DashboardPermissions.assessor_notes.update.type
        ) ||
        permissionCheck(
          DashboardPermissions.assessor_notes.create.task,
          DashboardPermissions.assessor_notes.create.type
        )
      ) {
        setNoteStatus("add");
      }
    }
  }, [getAssessorNotes, userResult._id, assessor_notes, isPost]);
  useEffect(() => {
    const simID = sim_id ? sim_id : simId;
    checkAllLevelAgentAudio(simID, assignment_id, agent_user_id);
  }, [sim_id, simId, checkAllLevelAgentAudio, agent_user_id, assignment_id]);
 
  function CircularProgressWithLabel(props) {
    const allUserScriptsLoaded =
      userResult.agent_scripts &&
      userResult.agent_scripts.every((ele) => ele.status === "Completed");
    if (userResult.agent_scripts && !allUserScriptsLoaded) {
      setTimeout(() => {
        callApiInInterval();
      }, 5000);
    }
    return (
      <div>
        <Box position="relative" display="inline-flex" className={classes.prog}>
          <CircularProgress
            variant="determinate"
            size="6rem"
            thickness={2}
            {...props}
            className={classes.circularProg}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              className={classes.progTypo}
            >
              {userResult &&
                Object.keys(userResult).length !== 0 &&
                userResult.agent_scripts.length &&
                userResult.agent_scripts[0].audio_url !== "" ? (
                allUserScriptsLoaded ? (
                  `${userResult.keyword_accuracy}%`
                ) : (
                  <img height="40px" src={loadingIcon} alt="loading..." />
                )
              ) : (
                "0%"
              )}
            </Typography>
          </Box>
        </Box>
      </div>
    );
  }
  const handleClose = () => {
    addAssessor(false);
    setNotes(assessor_notes);
  };
  const handleClosePreview = () => {
    setOpen(false);
  };
  const openPreview = () => {
    if (
      !isPost &&
      userResult.video_status === "saved" &&
      userResult.video_url
    ) {
      setVideo(userResult.video_url);
    }
    setOpen(true);
  };
  const handleAdd = () => {
    const payload = {
      assessor_notes: notes,
    };
    SaveAssessorNotes(userResult._id, payload);
    addAssessor(false);
  };
  const handleEdit = () => {
    const payload = {
      assessor_notes: notes,
    };
    SaveAssessorNotes(userResult._id, payload, true);
    addAssessor(false);
  };

  const getVideoDetails = async () => {
    const videoDetails = await fetchVidoeDetails(userResponse._id);
    if (videoDetails.data.video_status === "saved") {
      setVideoDetailsFetched(true);
      setEnableVideoBtn(true);
      setVideo(videoDetails.data.video_url);
    }
    if (videoDetails.data.video_status === "processing") {
      setTimeout(async () => {
        getVideoDetails();
      }, 10000);
    }
  };

  if (isPost && videoRecordingEnabled) {
    if (videoUploadStatus === true && !disablePolling) {
      setTimeout(async () => {
        getVideoDetails();
      }, 15000);
      setDisablePolling(true);
    } else if (videoUploadStatus === false && !disablePolling) {
      setOpenAlert(true);
      setDisablePolling(true);
    }
  }

  const showVideoPreview = () => {
    if (isPost && videoRecordingEnabled) {
      return (
        <Tooltip
          title={
            videoDetailsFetched && videoUploadStatus !== null
              ? ""
              : "Video is in processing. Please wait for few seconds."
          }
          placement="bottom"
        >
          <span className={classes.editSpan}>
            <Button
              variant="contained"
              className={classes.preview}
              size="small"
              onClick={openPreview}
              disabled={!videoDetailsFetched && !enableVideoBtn}
              startIcon={
                videoDetailsFetched && videoUploadStatus !== null ? (
                  <VideoLabelIcon />
                ) : (
                  <CircularProgress className={classes.previewLoader} />
                )
              }
            >
              Preview Screen Recording
            </Button>
          </span>
        </Tooltip>
      );
    } else if (
      !isPost &&
      userResult.video_status === "saved" &&
      userResult.video_url
    ) {
      return (
        <Button
          variant="contained"
          className={classes.preview}
          size="small"
          onClick={openPreview}
          startIcon={<VideoLabelIcon />}
        >
          Preview Screen Recording
        </Button>
      );
    } else {
      return null;
    }
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [currentAudio, setCurrentAudio] = React.useState({});
  const [timePlay, setTimePlay] = React.useState(0);
  const [callOrAgentPlay, setCallOrAgentPlay] = React.useState(audioPlaying);
  const setTimePlayfun = (audioPlayer, dataa, type) => {
    setCallOrAgentPlay(audioPlaying ? audioPlaying : '');
    if(type === 'agent' && dataa.agentId) {
      userResults.agent_scripts.map((script)=>{
          if(script.agentId === dataa.agentId) {
            script.play = false;
          } else {
            script.play = true;
          }
        return userResults;
      });
      userResults.scripts.map((script)=>{
        script.play = true;
        return true;
      });
    } else {
      userResults.scripts.map((script)=>{
        if(script._id === dataa._id) {
          script.play = false;
        } else {
          script.play = true;
        }
        return userResults;
      });
      userResults.agent_scripts.map((script)=>{
        script.play = true;
        return true;
      });
    }
    setUserResults(userResults)
    setCurrentAudio(audioPlayer);
    setTimePlay((prev) =>prev +1)
}
const pauseFunction = (dataa, type) => {
  if(type === 'agent' && dataa.agentId) {
    userResults.agent_scripts.map((script)=>{
      if(script.agentId === dataa.agentId) {
        script.play = true;
        setTimePlay(0)
      }
      return userResults;
    });
  } else {
    userResults.scripts.map((script)=>{
      if(script._id === dataa._id) {
        script.play = true;
        setTimePlay(0)
      }
      return userResults;
    });
  }
  setUserResults(userResults);
}
  return (
    <>
      {openAlert ? (
        <Alert
          variant="filled"
          className={classes.alertBox}
          onClose={handleAlertClose}
          severity="error"
        >
          Error in saving the screen recording!
        </Alert>
      ) : null}
      <Grid container>
        <Grid item xs={6}>
          {!sim_id && (
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.mainBreadBlock}
            >
              <StyledLink to="/dashboard" className={classes.breadcrumbs}>
                Dashboard
              </StyledLink>
            </Breadcrumbs>
          )}
        </Grid>
        <Grid item xs={6}>
          {reviewer_come === "false" ? (
            <StyledLink
              color="inherit"
              underline="always"
              to={{
                pathname: "/sims",
                state:
                  location.state.assignment && location.state.assignment.sim_id
                    ? {
                      ...location.state.assignment,
                      reviewer: false,
                      type: location.state.type,
                    }
                    : {
                      sim_id: sim_id || simId,
                      reviewer: false,
                      type: location.state.type,
                    },
              }}
            >
               <Tooltip
                title='Re-Take or go to next level'
                placement="bottom"
              >
                <Button
                  variant="contained"
                  className={classes.retake}
                  disableElevation
                  color="primary"
                  size="small"
                >
                  Re-take
                </Button>
              </Tooltip>
            </StyledLink>
          ) : (
            <StyledLink
              color="inherit"
              underline="always"
              to={{
                pathname: "/sims",
                state:
                  location.state.assignment && location.state.assignment.sim_id
                    ? { ...location.state.assignment, reviewer: true }
                    : {
                      sim_id: sim_id || simId,
                      reviewer: true,
                      assignment_id,
                      agent_user_id,
                    },
              }}
            >
              <Button
                variant="contained"
                className={classes.retake}
                disableElevation
                color="primary"
                size="small"
              >
                View Other Level Result
              </Button>
            </StyledLink>
          )}
          {showVideoPreview()}
        </Grid>
      </Grid>
      <Dialog onClose={handleClosePreview} open={open} fullWidth maxWidth="md">
        <DialogTitle className={classes.previewDialogTitle}>
          Screen Recording
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClosePreview}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.previewDialog}>
            <video
              className={classes.videoModal}
              width="750"
              height="500"
              controls
              autoPlay
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleClose}
        open={assessorNotes}
        classes={{ paper: classes.dialog }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Assessor Notes</DialogTitle>
        <DialogContent>
          <div style={{ padding: "0.5rem", width: "27vw" }}>
            <TextField
              InputProps={{
                disableUnderline: true,
                style: { fontSize: 14, paddingLeft: 8 },
              }}
              fullWidth
              multiline
              rows={5}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={classes.textArea}
              placeholder="Type your note here"
              disabled={
                !permissionCheck(
                  DashboardPermissions.assessor_notes.update.task,
                  DashboardPermissions.assessor_notes.update.type
                ) &&
                !permissionCheck(
                  DashboardPermissions.assessor_notes.create.task,
                  DashboardPermissions.assessor_notes.create.type
                ) &&
                permissionCheck(
                  DashboardPermissions.assessor_notes.view.task,
                  DashboardPermissions.assessor_notes.view.type
                )
              }
            />
            <Button
              size="small"
              style={{
                fontWeight: 600,
                padding: "3px 11px",
                backgroundColor: "#F8DEE7",
                float: "right",
                color: "#A55B73",
                margin: "8px 0px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            {noteStatus === "edit" ? (
              <Tooltip
                title={
                  !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                  )
                    ? "You don't have permission to update"
                    : ""
                }
                placement="top"
              >
                <span className={classes.editSpan}>
                  <Button
                    size="small"
                    className={classes.button}
                    disabled={
                      !permissionCheck(
                        DashboardPermissions.assessor_notes.update.task,
                        DashboardPermissions.assessor_notes.update.type
                      )
                    }
                    onClick={handleEdit}
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            ) : noteStatus === "add" ? (
              <Tooltip
                title={
                  !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                  )
                    ? "You don't have permission to create"
                    : ""
                }
                placement="top"
              >
                <span className={classes.editSpan}>
                  <Button
                    size="small"
                    className={classes.button}
                    disabled={
                      !permissionCheck(
                        DashboardPermissions.assessor_notes.create.task,
                        DashboardPermissions.assessor_notes.create.type
                      )
                    }
                    onClick={handleAdd}
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2}>
        <Grid container className={classes.girdContainer} item md={3}>
          <div className={classes.userDetails}>
            <div className={classes.userImage}>
              <img
                src={userResult?.user_id
                  ? userResult.user_id.profile_pic_url
                    ? userResult.user_id.profile_pic_url
                    : ProfilePic
                  : userResult.profile_pic_url
                    ? userResult.profile_pic_url
                    : ProfilePic
                }
                alt="userprofile"
                className={classes.userImage}
              />
            </div>
            <div>
              <Typography className={classes.name}>
                {userResult?.user_id
                  ? `${userResult?.user_id?.first_name} ${userResult?.user_id?.last_name}`
                  : `${user.first_name} ${user.last_name}`}
              </Typography>
              <Typography variant="caption" className={classes.details}>
                {userResult ? (
                  moment(userResult.completed_on).format("MM-DD-YYYY h:mm A")
                ) : (
                  <span>Loading...</span>
                )}
                <br />
                Mode:{" "}
                {userResult?.mode?.charAt(0).toUpperCase() +
                  userResult?.mode?.slice(1)}
              </Typography>
            </div>
            <Divider variant="middle" className={classes.divider} />
            <Typography
              style={{ marginBottom: "0.5rem" }}
              className={classes.details}
            >
              Transcription confidence :
            </Typography>
            <div className={classes.select}>
              <Typography className={classes.details}>
                {userResult?.scripts?.length &&
                  userResult?.scripts[0]?.sim_level_id?.name}
              </Typography>
            </div>
            <div className={classes.timeDiv}>
              <Typography className={classes.details}>Total Time</Typography>
              <Typography className={classes.details}>:</Typography>
              <Typography className={classes.details}>
                {userResult?.elapsedTime ?? elapsedTime}
              </Typography>
            </div>
            
          </div>
          <Paper className={classes.accuracyPaper}>
          {(loggedUser.role).toLowerCase() === 'agent' ? 
            userResult?.scripts?.length &&
            userResult?.scripts[0]?.sim_level_id?.keyword_score ?
             <div className={classes.accuracySection} style={{lineHeight: '0.5rem'}}>
                 <Typography className={classes.keyTypo}>
                  Keyword Accuracy
                </Typography>
                {
                  userResult?.keyword_accuracy ?
                  (userResult?.auto_populate && (order === '0' || order === '1')) ? 
                    <div className={classes.keywordAccuracy}><span className={classes.blackColor}>
                      N/A
                    </span></div> :
                  <CircularProgressWithLabel
                  value={userResult?.keyword_accuracy}
                  /> :
                 <div className={classes.keywordAccuracy}><span className={classes.blackColor}>
                   {(userResult?.auto_populate && (order === '0' || order === '1')) ? 'N/A' : '0%'}
                  </span></div>
                }
               
              </div> :''
              :
              <div className={classes.accuracySection} style={{lineHeight: '0.5rem'}}>
                <Typography className={classes.keyTypo}>
                  Keyword Accuracy
                </Typography>
                {
                  userResult?.keyword_accuracy ?
                  (userResult?.auto_populate && (order === '0' || order === '1')) ? 
                  <div className={classes.keywordAccuracy}><span className={classes.blackColor}>
                  N/A
                  </span></div> :
                  <CircularProgressWithLabel
                  value={userResult?.keyword_accuracy}
                  /> : <div className={classes.keywordAccuracy}><span className={classes.blackColor}>
                  {userResult?.auto_populate && (order === '0' || order === '1') ? 'N/A' : '0%'}
                  </span></div>
                }
              </div>
            }
            <div>
              <Typography className={classes.accuracySection}>
                Typing Accuracy
                <br />
                {userResult.auto_populate ?
                <span className={classes.numberColor}>N/A</span> :
                <span className={classes.numbers}>
                  <span className={classes.numberColor}>
                    <span className={classes.blackColor}>
                      {userResult.correct_entries}
                    </span>{" "}
                    of {userResult.total_entries}
                  </span><br/>
                (Correct Entries)
                </span>
              }
               
              </Typography>
            </div>
            <div>
              <Typography className={classes.accuracySection}>
                Data Selection Accuracy
                <br />
                {userResult.auto_populate ?
                <span className={classes.numberColor}>N/A</span> :
                <span className={classes.numbers}>
                  <span className={classes.numberColor}>
                    <span className={classes.blackColor}>
                      {userResult.correct_selections}
                    </span>{" "}
                    of {userResult.total_selections}
                  </span>
                  <br />
                  (Selected Accurately)
                </span>
                }
              </Typography>
            </div>
            <div>
              <Typography className={classes.accuracySection}>
                Overall Data Accuracy
                <br />
                { userResult.auto_populate ?
                <span className={classes.numberColor}>N/A</span> :
                <span className={classes.numbers}>
                  <span className={classes.numberColor}>
                    <span className={classes.blackColor}>
                      {userResult.overall_percentage} %
                    </span>
                  </span>
                </span>
                }
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid container className={classes.girdContainer} item md={5}>
          <Paper className={`${classes.root}`}>
            <h2>Call Transcript</h2>
            {userResults.scripts &&
              userResults.scripts.map((singleScript, index) => {
                if(callOrAgentPlay && !audioPlaying) {
                  singleScript.play = undefined;
                  setCallOrAgentPlay(audioPlaying);
                  setTimePlay(0);
                }
                if(singleScript.play === undefined) {
                  singleScript.play = true;
                }
                if (singleScript.sim_character_id.type === "agent") {
                  const currentAgentScript = userResults.agent_scripts.filter(
                    (ele) =>
                      ele.sim_script_id.toString() ===
                      singleScript._id.toString()
                  );
                  // eslint-disable-next-line
                  currentAgentScript.map((agent, index) => {
                    agent.id = index;
                    if(agent.play === undefined) {
                      agent.play =true;
                    } else if(callOrAgentPlay && !audioPlaying) {
                      agent.play =true;
                      setCallOrAgentPlay(audioPlaying);
                      setTimePlay(0);
                    }
                  });
                  return (
                    <Paper className={classes.agentList} key={index}>
                      <AgentScript
                        key={index}
                        index={index}
                        simCharacter={singleScript.sim_character_id.type}
                        currentAudioPlaying={currentAudioPlaying}
                        totalLength={userResult.scripts.length - 1}
                        // status={status}
                        setLastFive={setLastFive}
                        currentStatus={currentStatus}
                        audioPlaying={audioPlaying}
                        singleScript={singleScript}
                        currentAgentScript={currentAgentScript}
                        latestAgentScript={currentAgentScript[0]}
                        callTranscript={callTranscript}
                        setCallTranscript={setCallTranscript}
                        setTimePlayfun={setTimePlayfun}
                        currentAudio={currentAudio}
                        timePlay={timePlay}
                        pauseFunction={pauseFunction}
                      />
                      <div key={singleScript._id}>
                        <Accordion className={classes.accordian}>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon className={classes.expand} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <p className={classes.heading}>
                              Last 5 agent audios
                            </p>
                          </AccordionSummary>
                          {currentAgentScript.length > 0 &&
                            currentAgentScript
                              .slice(1)
                              .map((latestAgentScript, i) => {
                                if (
                                  lastFive &&
                                  lastFive.audio_url &&
                                  lastFive.audio_url ===
                                  latestAgentScript.audio_url
                                ) {
                                  return (
                                    <AccordionDetails key={i}>
                                      <AgentScript
                                        key={index}
                                        index={index}
                                        simCharacter={
                                          singleScript.sim_character_id.type
                                        }
                                        currentAudioPlaying={
                                          currentAudioPlaying
                                        }
                                        totalLength={
                                          userResult.scripts.length - 1
                                        }
                                        // status={status}
                                        currentStatus={currentStatus}
                                        audioPlaying={audioPlaying}
                                        singleScript={singleScript}
                                        value={true}
                                        currentAgentScript={currentAgentScript}
                                        latestAgentScript={latestAgentScript}
                                        setLastFive={setLastFive}
                                        setTimePlayfun={setTimePlayfun}
                                        currentAudio={currentAudio}
                                        timePlay={timePlay}
                                        pauseFunction={pauseFunction}
                                      />
                                    </AccordionDetails>
                                  );
                                } 
                                else {
                                  return (
                                    <AccordionDetails key={i}>
                                      <AgentScript
                                        key={index}
                                        index={index}
                                        currentAudioPlaying={
                                          currentAudioPlaying
                                        }
                                        totalLength={
                                          userResult.scripts.length - 1
                                        }
                                        // status={status}
                                        currentStatus={currentStatus}
                                        audioPlaying={audioPlaying}
                                        singleScript={singleScript}
                                        currentAgentScript={currentAgentScript}
                                        latestAgentScript={latestAgentScript}
                                        onLoadedData={
                                          lastFive.audio_url ===
                                          latestAgentScript.audio_url
                                        }
                                        setTimePlayfun={setTimePlayfun}
                                        currentAudio={currentAudio}
                                        timePlay={timePlay}
                                        pauseFunction={pauseFunction}
                                      />
                                    </AccordionDetails>
                                  );
                                }
                              })}
                        </Accordion>
                      </div>
                    </Paper>
                  );
                } else {
                 
                  return (
                    <OtherScript
                      index={index}
                      currentAudioPlaying={currentAudioPlaying}
                      totalLength={userResults.scripts.length - 1}
                      audioPlaying={audioPlaying}
                      key={singleScript._id}
                      singleScript={singleScript}
                      callTranscript={callTranscript}
                      setCallTranscript={setCallTranscript}
                      setTimePlayfun={setTimePlayfun}
                      currentAudio={currentAudio}
                      timePlay={timePlay}
                      pauseFunction={pauseFunction}
                    />
                  );
                }
              })}
          </Paper>
        </Grid>
        <Grid container className={classes.girdContainer} item md={4}>
          <Paper style={{ width: "100%", borderRadius: "1%" }}>
            <div className={classes.root}>
              <h2>
                {userResult?.scripts?.length &&
                  userResult?.scripts[0]?.sim_id?.title}
              </h2>
            </div>
            <Divider variant="fullWidth" />
            <div className={classes.root}>
              <h2 style={{ marginBottom: "0.7rem", fontSize: '1.2rem' }}>TYPING</h2>
              {userResult.typing_inputs?.map((ele, idx) => (
                <div
                  key={idx}
                  className={
                    ele.input_value === ele.target_value
                      ? classes.patientDataMatched
                      : classes.patientData
                  }
                >
                  {ele.input_value === ele.target_value ? (
                    <div className={classes.flex}>
                      <CheckCircleRoundedIcon
                        className={classes.success}
                      ></CheckCircleRoundedIcon>{" "}
                      <span className={classes.bold}>
                        {ele.target_value.startsWith("<a ") ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ele.target_value,
                            }}
                          ></span>
                        ) : checkNumber(ele.target_value) ? (
                          formatPhoneNumber(ele.target_value)
                        ) : (
                          ele.target_value
                        )}
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={classes.flex}>
                        <CancelRoundedIcon
                          className={classes.failed}
                        ></CancelRoundedIcon>
                        <span className={classes.script}>
                          {ele.input_value}
                        </span>
                      </div>
                      <span
                        className={classes.bold}
                        style={{
                          marginTop: ".5rem",
                          display: "inline-block",
                          wordBreak: "break-all",
                        }}
                      >
                        {ele.target_value.startsWith("<a ") ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ele.target_value,
                            }}
                          ></span>
                        ) : checkNumber(ele.target_value) ? (
                          formatPhoneNumber(ele.target_value)
                        ) : (
                          ele.target_value
                        )}
                      </span>
                    </>
                  )}
                </div>
              ))}
              <h4 style={{ marginBottom: "0.7rem", fontSize: '1.2rem' }}>Data Selection</h4>
              {userResult.data_inputs?.map((ele, i) => (
                <div
                  key={i}
                  className={
                    ele.input_value === ele.target_value
                      ? classes.patientDataMatched
                      : classes.patientData
                  }
                >
                  {ele.input_value === ele.target_value ? (
                    <div className={classes.flex}>
                      <CheckCircleRoundedIcon
                        className={classes.success}
                      ></CheckCircleRoundedIcon>
                      <span className={classes.bold}>{ele.target_value}</span>
                    </div>
                  ) : (
                    <>
                      <div className={classes.flex}>
                        <CancelRoundedIcon
                          className={classes.failed}
                        ></CancelRoundedIcon>{" "}
                        {ele.input_value}
                        <span className={classes.script}></span>
                      </div>
                      <span
                        className={classes.bold}
                        style={{ marginTop: ".5rem", display: "inline-block" }}
                      >
                        {ele.target_value}
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

TakeSimResult.propTypes = {
  elapsedTime: PropTypes.string,
  userFinalScoreResponse: PropTypes.func,
  toggleResultButton: PropTypes.func,
  viewResult: PropTypes.func,
  userResponse: PropTypes.object,
  userResult: PropTypes.object,
  user: PropTypes.object,
  caseValuesArray: PropTypes.object,
  isPost: PropTypes.bool,
  currentAudioPlaying: PropTypes.any,
  currentStatus: PropTypes.func,
  audioPlaying: PropTypes.string,
  audioPlay: PropTypes.func,
  assessorNotes: PropTypes.bool,
  addAssessor: PropTypes.func,
  SaveAssessorNotes: PropTypes.func,
  getAssessorNotes: PropTypes.func,
  assessor_notes: PropTypes.string,
  checkAllLevelAgentAudio: PropTypes.func,
  allAudioProcessed: PropTypes.bool,
  videoRecordingEnabled: PropTypes.bool,
  videoUploadStatus: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  elapsedTime: state.sim.elapsedTime,
  userResponse: state.sim.userResponse,
  userResult: state.sim.userResult,
  user: state.login.user,
  caseValuesArray: state.sim.caseValuesArray,
  resultScreenInputData: state.sim.resultScreenInputData,
  simId: state?.sim?.stats?.result?.sim_id,
  assessorNotes: state.sim.assessorNotes,
  currentAudioPlaying: state.sim.currentAudioPlaying,
  audioPlaying: state.sim.audioPlaying,
  assessor_notes: state.sim.assessor_notes,
  allAudioProcessed: state.sim.allAudioProcessed,
  videoRecordingEnabled: state.sim.videoRecordingEnabled,
  videoUploadStatus: state.sim.videoUploadStatus,
});

const mapDispatchToProps = {
  userFinalScoreResponse: userFinalScoreResponse,
  viewResult,
  toggleResultButton,
  currentStatus,
  audioPlay,
  addAssessor,
  SaveAssessorNotes,
  getAssessorNotes,
  checkAllLevelAgentAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(TakeSimResult);
