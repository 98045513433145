import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import config from '../../../config';
import axios from 'axios';

class Map extends React.Component{
  constructor( props ){
    super( props );
    this.state = {
      zoom: this.props.zoom,
      height: this.props.height,
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      }
    }
  }
/**
  * Get the current address from the default map position and set those values in the state
  */
 componentDidMount = async () => {
  try {
    const { street, city, state, postalCode, country } = this.props.addressDetails;
    const getLatLng = await axios.get(`${config.GOOGLE_GEOCODE_API}?address=${street.split(' ').join('+')},${city.split(' ').join('+')},${state.split(' ').join('+')},${postalCode.split(' ').join('+')},${country.split(' ').join('+')}&key=${config.GOOGLE_MAP_KEY}`)
    const {lat, lng} = getLatLng.data.results[0].geometry.location;
    this.setState({
      markerPosition: {
        lat: lat,
        lng: lng,
      }
    });
  } catch (error) {
    alert('Something went wrong in map!!')
  }
 };

  render(){
    const { zoom, markerPosition, height } = this.state;
    const AsyncMap = withScriptjs (
      withGoogleMap(
        props => (
        <GoogleMap
          defaultZoom={zoom}
          defaultCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        >
          <Marker
          name={'Dolores park'}
              draggable={false}
              position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
          />
        </GoogleMap>
        )
      )
    );
    return (<AsyncMap
        googleMapURL={`${config.GOOGLE_PLACE_API}?key=${config.GOOGLE_MAP_KEY}&libraries=places`}
        loadingElement={
        <div style={{ height: `100%` }} />
        }
        containerElement={
        <div style={{ height: height }} />
        }
        mapElement={
        <div style={{ height: `100%` }} />
        }
      />
    )
    
  }
}

Map.propTypes = {
  addressDetails: PropTypes.object,
};
const mapStateToProps = (state) => ({
  addressDetails: state.sim.newSim.addressDetails,
});
const mapDispatchToProps = { };
export default connect(mapStateToProps, mapDispatchToProps)(Map);