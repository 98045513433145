import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
  },
  backBtn: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.75),
    minHeight: 0,
    minWidth: 0,
    border: "2px solid rgb(136,38,71)",
    "&:hover": { border: "2px solid rgb(136,38,71)" },
  },
  nextBtn: {
    padding: theme.spacing(1, 5, 1, 5),
    borderWidth: "2px",
  },
  gridContainer: {
    margin: theme.spacing(3, 0, 1, 0),
    paddingBottom: theme.spacing(8),
  },
}));
