import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      height: "2vw",
      width: "25vw",
      margin: "1vw 0vw",
      fontSize: "0.9vw",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#4190DC !important",
    },
  },
  headTypo: {
    fontSize: "1.2vw",
    fontWeight: 700,
  },
  mainDiv: {
    display: "flex",
    justifyContent: "center",
  },
  mainCard: {
    padding: "0.5vw",
    width: "58vw",
    borderColor: "#EBEAEA",
    border: "1px solid",
  },
  typo: {
    fontSize: "0.9vw",
  },
  searchCard: {
    width: "53vw",
    display: "flex",
    borderColor: "#EBEAEA",
    border: "1px solid",
    margin: "2vw 1.5vw",
  },
  searchButton: {
    color: "#4190DC",
    fontSize: "0.9vw",
    marginRight: "0.5vw",
    "& .Mui-focused fieldset": {
      borderColor: "#4190DC !important",
      backgroundColor: "#F4F6F9",
    },
  },
  searchText: {
    marginBottom: "1em",
    minWidth: "29vw",
    height: "4vw",
  },
  tableCard: {
    margin: "0vw 1.5vw 1vw 1.5vw",
    width: "53vw",
    borderColor: "#EBEAEA",
    border: "1px solid",
  },
  tableHead: {
    backgroundColor: "#FAFAF9",
  },
  tableHeadCells: {
    fontSize: "0.9vw",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  tableHeadReferCells: {
    justifyContent: "space-evenly",
    fontSize: "0.9vw",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  tableBodyCells: {
    fontSize: "0.9vw",
    color: "#4190DC",
  },
  bodyProtocolCell: {
    fontSize: "0.9vw",
    color: "#16325C",
  },
  checkboxStyle: {
    padding: "0px",
  },
  synonymCell: {
    margin: "0.5vw 0vw",
  },
  radioGroup: {
    display: "contents",
  },
  radioButtonLeft: {
    padding: "0px",
    marginRight: "2vw",
  },
  radioButtonRight: {},
  space: {
    marginLeft: "2vw",
    marginRight: "-2vw",
  },
}));
