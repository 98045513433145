export const Groups = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  thead: {
    backgroundColor: '#e1e1e1',
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  heading: {
    fontWeight: [600],
    fontSize: '0.9em',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  built: {
    fontSize: '0.9em',
    display: 'flex',
    justifyContent: 'left',
  },
  permissionsTableCell: {
    paddingLeft: theme.spacing(3),
    color: '#B88495',
    textDecoration: 'underline',
    fontWeight: [600],
    fontSize: '0.9em',
  },
  firstNameCell: {
    paddingTop: theme.spacing(1),
  },
  lastNameCell: {
    fontSize: '0.9em',
  },
  emailIdCell: {
    fontSize: '0.9em',
  },
  roleCell: {
    fontSize: '0.9em',
  },
  groupCell: {
    textDecoration: 'underline',
    fontSize: '0.9em',
  },
  statusCell: {
    fontSize: '0.9em',
  },
  addIcon: {
    marginLeft: '1em',
    minWidth: '0vw',
    backgroundColor: '#882647',
    color: '#fff',
    width: '1.3vw',
    height: '1.1vw',
  },
  titleCell: {
    fontSize: '0.9em',
  },
  phoneNumberCell: {
    fontSize: '0.9em',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  buttonSqr: {
    padding: '5px',
    marginRight: '5px',
    minHeight: 0,
    minWidth: 0,
  },
  editIcon: {
    color: '#fff',
    backgroundColor: '#882647',
    cursor: 'pointer',
  },
  no_of_members: {
    paddingRight: '6em',
  },
});

export default Groups;
