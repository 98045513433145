import React, {
  useEffect, Fragment, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import {
  Button, Paper, InputLabel, TextField, Typography, Tooltip, TableContainer,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  fetchPermissionList,
} from '../../redux/sims/actions';
import {
  savePermissionTooltip,
} from '../../redux/admin/action';
import AdminsPermissions from '../../permissions/admin';
import { permissionCheck } from '../../utils/permissionCheck';
import PermissionsStyle from './Permissions';

const Permissions = ({
  fetchPermissionList, permissionList, handleChange, refreshPermission, savePermissionTooltip,
  roles, editClick,
}) => {
  const [roleList, setRoleList] = useState([]);
  const [pDetailCount, setPdetailCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [currentProduct, setCurrentProduct] = useState('');
  //const [descDisplay, setDescDisplay] = useState(false);
  // const [description, setDescription] = useState('');
  const [state, setState] = useState({
    tooltip: '',
    description: '',
  });
  useEffect(() => {
    fetchPermissionList(roles);
  }, [fetchPermissionList, roles]);
  useEffect(() => {
    if (refreshPermission) {
      fetchPermissionList();
    }
  }, [refreshPermission, fetchPermissionList]);
  useEffect(() => {
    if (permissionList && permissionList.roles && permissionList.roles.length && pDetailCount) {
      setRoleList(permissionList.roles);
      setLoading(false);
    }
  }, [permissionList, pDetailCount]);
  const useStyles = makeStyles((theme) => PermissionsStyle(theme));
  const classes = useStyles();
  const checkboxChange = (role, task, permission) => {
    const perm = task.permissions.find((obj) => obj.permission_type === permission);
    const viewData = task.permissions.find((obj) => obj.permission_type === 'view');
    // eslint-disable-next-line array-callback-return
    permissionList.roles.map((list) => {
      if (list._id === role._id) {
        const findIndex = list.permission_details.findIndex((a) => a.permission_id === perm.permission_id);
        if (findIndex > -1) {
          list.permission_details.splice(findIndex, 1);
        } else {
          list.permission_details.push({
            task_id: task.task_id,
            task_name: task.task_name,
            permission_type: perm.permission_type,
            permission_id: perm.permission_id,
          });
          if (viewData
            && list.permission_details.findIndex((a) => a.permission_id === viewData.permission_id) === -1) {
            list.permission_details.push({
              task_id: task.task_id,
              task_name: task.task_name,
              permission_type: viewData.permission_type,
              permission_id: viewData.permission_id,
            });
          }
          if (Object.keys(list.permission_details[0]).length === 0) {
            list.permission_details.splice(0, 1);
          }
        }
        if (pDetailCount === list.permission_details.length) {
          setPdetailCount(pDetailCount + 1);
        } else {
          setPdetailCount(list.permission_details.length);
        }
      }
    });
  };
  // const editTooltip = (row) => {
  //   const tooltip = row.tooltip ? row.tooltip : '';
  //   const description = row.description ? row.description : '';
  //   setCurrentProduct(row._id);
  //   setState({
  //     ...state,
  //     tooltip,
  //     description,
  //   });
  //   setEdit(true);
  //   editClick();
  // };
  const tooltipHandleChange = (evt) => {
    if (evt.target) {
      const { value } = evt.target;
      setState({
        ...state,
        [evt.target.name]: value,
      });
    }
  };

  const saveTooltip = async () => {
    await savePermissionTooltip(state, currentProduct);
    setEdit(false);
    setCurrentProduct('');
    fetchPermissionList();
    editClick();
  };
  // const onDisplayDialog = (row) => {
  //   setDescription(row.description);
  //   setDescDisplay(true);
  // };
  // const onCancel = () => {
  //   setDescDisplay(false);
  // };

  return (
    <>
      {
        !edit
          ? (
            loading ? <CircularProgress className={classes.loading} color="primary" />
              : (
                <div className={classes.mainDiv}>
                  <TableContainer style={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table" className={classes.leftTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.productHead}>PRODUCT</TableCell>
                          <TableCell className={classes.taskHead}>TASKS</TableCell>
                          {permissionList && permissionList.roles
                            && permissionList.roles.map((role) => (
                              <TableCell key={role._id} className={classes.rolesHead} style={{ backgroundColor: '#e1e1e1' }}>
                                {role.name}
                              </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.productHead} style={{ backgroundColor: '#d1d1d1', top: 57 }} />
                          <TableCell className={classes.taskHead} style={{ backgroundColor: '#d1d1d1', top: 57 }} />
                          {permissionList && permissionList.roles && permissionList.roles.length
                            && permissionList.roles.map((role, index) => (
                              <TableCell key={role._id} className={classes.rolesHead} style={{ top: 57 }}>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3}><span>VIEW</span></Grid>
                                  <Grid item xs={3}><span>CREATE</span></Grid>
                                  <Grid item xs={3}><span>UPDATE</span></Grid>
                                  <Grid item xs={3}><span>DELETE</span></Grid>
                                </Grid>
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissionList && permissionList.productTasks
                          && permissionList.productTasks.map((row) => (
                            <Fragment key={row._id}>
                              <TableRow>
                                <TableCell rowSpan={row.tasks.length + 1} className={classes.products}>
                                  {row.product_name === 'Dashboard' && (
                                    <DashboardIcon className={classes.icons} />
                                  )}
                                  {row.product_name === 'Admin' && (
                                    <PersonIcon className={classes.icons} />
                                  )}
                                  {row.product_name === 'Sims' && (
                                    <PlayCircleFilledIcon className={classes.icons} />
                                  )}
                                  <br />
                                  <span>
                                    <span className={classes.productTitle}>
                                      {row.product_name}
                                    </span>
                                    {/* <Tooltip
                                      title={row && row.tooltip ? row.tooltip : ''}
                                      placement="top"
                                    >
                                      <span
                                        className={classes.round}
                                        onClick={() => onDisplayDialog(row)}
                                      >
                                        !
                                      </span>
                                    </Tooltip> */}
                                  </span>
                                  {/* <br />
                                  <Button
                                    className={classes.edit}
                                    onClick={() => editTooltip(row)}
                                  >
                                    EDIT
                                  </Button> */}
                                </TableCell>
                              </TableRow>
                              {row.tasks && row.tasks.map((task, index) => (
                                <TableRow key={task.task_id}>
                                  <Tooltip title={task.task_definition} placement="top">
                                    <TableCell
                                      className={(index % 2) === 0
                                        ? classes.even_left : classes.next_left}
                                      style={{
                                        left: 113,
                                        position: 'sticky',
                                        zIndex: 2,
                                      }}
                                    >
                                      {' '}
                                      {task.task_name}
                                    </TableCell>
                                  </Tooltip>
                                  {roleList && roleList.map((role) => (
                                    <TableCell
                                      key={role._id}
                                      style={{ paddingLeft: '8', paddingRight: '8', textAlign: 'center' }}
                                      className={(index % 2) === 0 ? classes.eventd : classes.td}
                                    >
                                      <Grid container
                                        className={classes.container}
                                        spacing={1}
                                      >
                                        <Grid item xs={3}>
                                          <Checkbox
                                            className={role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'view'))
                                              ? classes.checkBox : ''}
                                            checked={(role.permission_details.length
                                              && Object.keys(role.permission_details[0]).length > 0)
                                              ? role.permission_details.some((permission) => (permission.task_id.includes(task.task_id) && permission.permission_type === 'view')) : false}
                                            color="primary"
                                            onClick={() => handleChange(role, task, 'view')}
                                            onChange={() => checkboxChange(role, task, 'view')}
                                            disabled={!(task.permissions.some((permission) => permission.permission_type.includes('view')))}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Checkbox
                                            className={role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'create'))
                                              ? classes.checkBox : ''}
                                            onClick={() => handleChange(role, task, 'create')}
                                            onChange={() => checkboxChange(role, task, 'create')}
                                            disabled={!(task.permissions.some((permission) => permission.permission_type.includes('create')))}
                                            checked={(role.permission_details.length
                                              && Object.keys(role.permission_details[0]).length > 0)
                                              ? role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'create')) : false}
                                            color="primary"
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Checkbox
                                            className={role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'update'))
                                              ? classes.checkBox : ''}
                                            onClick={() => handleChange(role, task, 'update')}
                                            onChange={() => checkboxChange(role, task, 'update')}
                                            checked={(role.permission_details.length
                                              && Object.keys(role.permission_details[0]).length > 0)
                                              ? role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'update')) : false}
                                            color="primary"
                                            disabled={!(task.permissions.some((permission) => permission.permission_type.includes('update')))}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Checkbox
                                            className={role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'delete'))
                                              ? classes.checkBox : ''}
                                            onClick={() => handleChange(role, task, 'delete')}
                                            onChange={() => checkboxChange(role, task, 'delete')}
                                            checked={(role.permission_details.length
                                              && Object.keys(role.permission_details[0]).length > 0)
                                              ? role.permission_details.some((permission) => (permission.task_id === task.task_id && permission.permission_type === 'delete')) : false}
                                            color="primary"
                                            disabled={!(task.permissions.some((permission) => permission.permission_type.includes('delete')))}
                                          />
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </Fragment>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )
          )
          : (
            <div>
              <Paper elevation={2} className={classes.paper}>
                <Typography className={classes.mainTitle}>
                  Edit Permission Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <form className={classes.formControl}>
                      <InputLabel className={classes.initialLabel}>Tool Tip</InputLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="tooltip"
                        value={state.tooltip}
                        onChange={tooltipHandleChange}
                      />
                      <InputLabel className={classes.label}>Description</InputLabel>
                      <TextField
                        className={classes.margin}
                        fullWidth
                        name="description"
                        required
                        multiline
                        rows={20}
                        variant="outlined"
                        value={state.description}
                        onChange={tooltipHandleChange}
                      />
                      <div>
                        <Tooltip
                          title={
                            !permissionCheck(
                              AdminsPermissions.permissions.update.task,
                              AdminsPermissions.permissions.update.type,
                            )
                              ? "You don't have permission to create/update Tooltip"
                              : ''
                          }
                          placement="right"
                        >
                          <span>
                            <Button
                              className={permissionCheck(
                                AdminsPermissions.permissions.update.task,
                                AdminsPermissions.permissions.update.type,
                              ) ? classes.saveBtn : classes.saveDisableBtn}
                              style={{
                                marginBottom: '35px',
                                marginLeft: '16%',
                              }}
                              variant="contained"
                              color="primary"
                              onClick={permissionCheck(
                                AdminsPermissions.permissions.update.task,
                                AdminsPermissions.permissions.update.type,
                              ) ? saveTooltip : () => setEdit(true)}
                            >
                              Save
                            </Button>
                          </span>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          className={classes.saveBtn}
                          disableElevation
                          color="primary"
                          style={{
                            marginBottom: '35px',
                          }}
                          onClick={() => {
                            setEdit(false);
                            editClick();
                          }}
                        >
                          CANCEL
                        </Button>
                      </div>
                    </form>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )
      }
      {/* {description
        ? (
          <Dialog
            open={descDisplay}
            fullWidth
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Permission Details</DialogTitle>
            <DialogContent>
              <TextField
                className={classes.margin}
                fullWidth
                name="description"
                required
                multiline
                rows={20}
                variant="outlined"
                value={description}
                onChange={tooltipHandleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} className={classes.cancelButton}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )
        : ''} */}

    </>
  );
};

Permissions.propTypes = {
  fetchPermissionList: PropTypes.func,
  permissionList: PropTypes.object,
  handleChange: PropTypes.func,
  refreshPermission: PropTypes.bool,
  savePermissionTooltip: PropTypes.func,
  roles: PropTypes.array,
  editClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  permissionList: state.sim.permissionList ? state.sim.permissionList.permissionList : {},
});

const mapDispatchToProps = { fetchPermissionList, savePermissionTooltip };

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
