import React from 'react';
import {
  Grid, Card, CardContent, Avatar,
  Paper,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { permissionCheck } from '../../utils/permissionCheck';
import DashboardPermissions from '../../permissions/dashboard';
import UserDataStyle from './UserData';
import ProfilePic from '../../assets/images/profile_pic.png';

const UserData = ({
  userData,
  allAssignmentCount,
  readyForReviewCount,
  completedAssignCount,
}) => {
  const useStyles = makeStyles((theme) => UserDataStyle(theme));
  const classes = useStyles();
  let time = '';
  // To find the relative time for greeting message in header
  const currentTime = new Date();
  const user = JSON.parse(localStorage.getItem('user'));
  if (currentTime.getHours() < 12) {
    time = 'Morning';
  } else if (currentTime.getHours() < 16) {
    time = 'Afternoon';
  } else if (currentTime.getHours() < 20) {
    time = 'Evening';
  } else if (currentTime.getHours() < 23) {
    time = 'Night';
  }
  return (
    <Paper elevation={0}>
      <Card className={classes.back}>
        <CardContent className={classes.cardPadding}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Avatar
                height={47}
                width={46}
                alt="profile"
                className={classes.avatar}
                src={user.profile_pic_url ? user.profile_pic_url : ProfilePic}
              />
            </Grid>
            <Grid item xs={9}>
              <span className={classes.time}>
                Good
                {' '}
                {time}
                !
              </span>
              <br />
              <b className={classes.name}>
                {permissionCheck(
                  DashboardPermissions.user_name.view.task,
                  DashboardPermissions.user_name.view.type,
                ) ? `${user.first_name} ${user.last_name}` : ''}
              </b>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid item xs={12} md={12}>
        <Box pt={3} pl={2} className={classes.company}>
          {permissionCheck(
            DashboardPermissions.company_name.view.task,
            DashboardPermissions.company_name.view.type,
          ) ? `${user.company_name}` : ''}
        </Box>
        {permissionCheck(
          DashboardPermissions.user_account.view.task,
          DashboardPermissions.user_account.view.type,
        )
          ? (
            <List>
              <ListItem>
                <ListItemText className={classes.listText}>Total Supervisors</ListItemText>
                <ListItemSecondaryAction><b>{userData ? userData.superVisorsCount : ''}</b></ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText
                  className={classes.listText}
                  primary="Total Managers"
                />
                <ListItemSecondaryAction><b>{userData ? userData.managersCount : ''}</b></ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText
                  className={classes.listText}
                  primary="Total Agents"
                />
                <ListItemSecondaryAction><b>{userData ? userData.AgentsCount : ''}</b></ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText
                  className={classes.listText}
                  primary="Total Users"
                />
                <ListItemSecondaryAction><b>{userData ? userData.usersCount : ''}</b></ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText
                  className={classes.listText}
                  primary="Total Applicants"
                />
                <ListItemSecondaryAction>
                  <b>{userData && userData.totalApplicants ? userData.totalApplicants : 0}</b>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText
                  className={classes.listText}
                  primary="Total Groups"
                />
                <ListItemSecondaryAction><b>{userData ? userData.groupCount : ''}</b></ListItemSecondaryAction>
              </ListItem>
            </List>
          )
          : ''}
      </Grid>
      <Divider className={classes.divider} />
      <Grid item xs={12} md={12}>
        <Box pt={3} pl={2} className={classes.company}>Assignments</Box>
        <List>
          <ListItem>
            <Grid container className={classes.completed}>
              <Grid item xs={8} className={classes.text}>
                Completed
                {' '}
                <br />
                {' '}
                Assignments
              </Grid>
              <Grid item xs={4} className={classes.rect}>
                <span className={classes.textCompleted}>
                  {completedAssignCount >= 0
                    ? `${completedAssignCount}/${userData ? userData.myAssignmentCount + userData.completedAssignmentCount : 0}`
                    : '/'}
                  {' '}
                </span>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container className={classes.review}>
              <Grid item xs={8} className={classes.text}>
                Ready
                {' '}
                <br />
                for Review
              </Grid>
              <Grid item xs={4} className={classes.rect}>
                <span className={classes.textReview}>
                  {readyForReviewCount}
                </span>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Paper>
  );
};

UserData.propTypes = {
  userData: PropTypes.object,
  allAssignmentCount: PropTypes.number,
  readyForReviewCount: PropTypes.number,
  completedAssignCount: PropTypes.number,
};
export default UserData;
