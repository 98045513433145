import { makeStyles } from '@material-ui/core/styles';
import background from '../../../assets/images/create-levels/levels_bg_1.png';

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  cancelButton: {
    color: '#313387',
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  icon: {
    padding: '6px',
    backgroundColor: '#F5E2E9',
    borderRadius: '11%',
    margin: theme.spacing(0, 1),
  },
  printIcon: {
    padding: '6px',
    backgroundColor: '#F5E2E9',
    borderRadius: '11%',
    margin: theme.spacing(0, 1),
    marginRight: '1.5rem',
  },
  nextBtn: {
    padding: '8px 40px 8px 40px',
  },
  previewBtn: {
    padding: '8px 40px 8px 40px',
    marginRight: 12,
  },
  divider: {
    marginTop: '5px',
  },
  previousBtn: {
    margin: '0rem 1rem',
    width: '35px',
    minWidth: 0,
    minHeight: 0,
    border: '2px solid rgb(136,38,71)',
    '&:hover': { border: '2px solid rgb(136,38,71)' },
    padding: '4px 0',
  },
  templateLink: {
    display: 'block',
    cursor: 'pointer',
    textAlign: 'end',
  },
  uploadDialog: {
    padding: '16px 24px 10px',
  },
}));

export const useLevelsStyle = makeStyles((theme) => ({
  levelHeader: {
    background: '#2A416C',
    margin: '0',
    width: '100%',
    color: '#ffff',
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  cancelButton: {
    color: '#313387',
  },
  levels: {
    margin: 'auto',
    width: '5vw',
    backgroundColor: '#3E5785',
    fontWeight: '800',
    padding: '0',
    borderBottomRightRadius: '1%',
    borderBottomLeftRadius: '1%',
  },
  addLevel: {
    fontSize: 12,
    marginTop: 100,
    marginBottom: 60,
    cursor: 'pointer',
    color: '#ffff',
  },
  activeCard: {
    padding: theme.spacing(1),
    color: 'black',
    background: '#ffff',
    margin: '7px',
    borderRadius: 3,
  },
  inacticeCard: {
    padding: theme.spacing(1),
    color: '#ffff',
    background: '#1B2D4F',
    margin: '7px',
    borderRadius: 3,
  },
  checkCircleIcon: {
    color: '#2CB174',
    height: 15,
    marginBottom: -5,
  },
  label: {
    padding: 8,
    fontSize: 10,
    fontWeight: '100',
  },
  levelLabel: {
    padding: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  levelName: {
    padding: '0 10px 10px 10px',
    fontSize: 14,
    fontWeight: '600',
  },
  simGeneratedLabel: {
    float: 'right',
    fontSize: 8,
  },
  rightArrow: {
    width: '10px',
    height: '10px',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid white',
    position: 'absolute',
    top: '340px',
    right: '1168px',
    zIndex: '1000',
  },
  refreshIcon: {
    width: 20,
    height: 19,
    padding: 2,
    background: '#E9F0FE',
    color: '#3C5582',
    float: 'right',
    marginTop: -9,
    marginRight: -3,
  },
  firstGrid: {
    padding: 20,
  },
  text: {
    '& .MuiInputBase-input': {
      color: '#fff',
      fontSize: 14,
      fontWeight: '600',
    },
  },
  textFielDiv: {
    padding: 10,
  },
  saveBtn: {
    alignSelf: 'flex-end',
    marginBottom: -10,
    padding: 2,
    marginRight: 8,
    minWidth: '50px',
  },
  cancelBtn: {
    alignSelf: 'flex-end',
    marginBottom: -10,
    padding: 2,
    minWidth: '50px',
  },
}));

export const useStyle = makeStyles((theme) => ({
  toolBar: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  cancelButton: {
    color: '#313387',
  },
  scriptDiv: {
    width: '100%',
    height: '88%',
    overflowY: 'scroll',
    maxHeight: 500,
  },
  scriptDiv1: {
    width: '103%',
    height: '88%',
    maxHeight: 500,
  },
  mainDiv: {
    position: 'relative',
  },
  loaderDiv: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
  gridContainer: {
    margin: theme.spacing(0, 0, 1, 0),
    paddingBottom: theme.spacing(8),
  },
  intialCharacterMainGrid: {
    margin: '11px 10px 20px 10px',
    background: '#F5F5F5',
    width: '16vw',
    borderRadius: '1%',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down(1285)]: {
      width: '15vw',
      padding: 6,
    },
    border: '1px dotted',
  },
  finalCharacterMainGrid: {
    margin: '11px 10px 20px 10px',
    background: '#F5F5F5',
    width: '16vw',
    borderRadius: '1%',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down(1285)]: {
      width: '15vw',
      padding: 6,
    },
    border: 'none',
  },
  characterLabel: {
    fontSize: 17,
    fontWeight: 700,
    marginTop: 12,
    marginLeft: 15,
  },
  editCanvas: {
    background: '#F5F5F5',
    borderRadius: '1%',
    marginTop: 11,
    marginBottom: 21,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down(1285)]: {
      width: '63.3%',
    },
    maxWidth: '64%',
  },
  canvasLabel: {
    fontSize: 20,
    fontWeight: '800',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  editBtn: {
    padding: '0px 8px 6px 8px',
  },
  editIcon: {
    marginLeft: -2,
    height: 23,
    width: 23,
    background: 'white',
    padding: 4,
    borderRadius: 3,
    color: '#3E5785',
    boxShadow: '0px 2px 0px #EEEEEE',
  },
  generateSimBtn: {
    color: '#475D84',
    background: 'white',
    border: '1px solid black',
    display: 'flex',
    fontSize: 11,
    fontWeight: 600,
    marginRight: 14,
  },
  iconBox: {
    background: '#ffff',
    margin: 10,
    whiteSpace: 'nowrap',
  },
  iconStar: {
    width: 40,
    height: 27,
    marginBottom: -2,
    color: '#3E5785',
    cursor: 'pointer',
  },
  iconFeedback: {
    color: '#3E5785 !important',
    marginRight: 8,
    marginLeft: 3,
    padding: 0,
    marginTop: -17,
    '& .MuiSvgIcon-root': {
      width: 19,
    },
  },
  iconVolume: {
    width: 29,
    height: 23,
    color: '#3E5785',
    cursor: 'pointer',
  },
  iconCopy: {
    width: 18,
    height: 25,
    marginLeft: 9,
    color: '#3E5785',
    cursor: 'pointer',
    marginBottom: -2,
  },
  iconDownload: {
    width: 40,
    height: 22,
    marginLeft: -2,
    color: '#3E5785',
    cursor: 'pointer',
  },
  iconDelete: {
    width: 40,
    height: 22,
    marginLeft: -7,
    color: '#3E5785',
    cursor: 'pointer',
  },
  verticalDivider: {
    marginRight: 5,
    verticalAlign: 'super',
    borderLeft: '2px solid #ededed9e',
    height: 10,
  },
  verticalDiv: {
    marginRight: 5,
    verticalAlign: 'super',
    borderLeft: '2px solid #ededed9e',
    height: 10,
    margin: 15,
  },
  backgroundImage: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  dialogFormControl: {
    margin: '0em 2em 2em 2em',
    padding: '0em 3em',
  },
  resize: {
    fontWeight: [600],
    fontSize: '1.1em',
  },
  generateButton: {
    marginRight: '1rem',
    pointerEvents: 'unset !important',
  },
  alert: {
    margin: 10,
  },
  textfield: {
    margin: theme.spacing(0, 0, 0, 1),
    '& .MuiOutlinedInput-root': {
      height: '29px',
      width: '69px',
    },
  },
  checkbox: {
    marginLeft: '1rem',
    padding: '0px',
    paddingBottom: '2px',
  },
  modalPaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  progress: {
    margin: 10,
  },
  otherMicIcon: {
    backgroundColor: '#2CB174',
    color: '#fff',
    borderRadius: '50%',
    margin: '0px 1px',
    padding: '2px',
    fontSize: 15,
    [theme.breakpoints.between(1280, 1500)]: {
      fontSize: 13,
    },
    [theme.breakpoints.between(1000, 1300)]: {
      fontSize: 11,
    },
  },
  agentMicIcon: {
    backgroundColor: '#eb6d20',
    color: '#fff',
    borderRadius: '50%',
    margin: '0px 1px',
    padding: '2px',
    fontSize: 15,
    [theme.breakpoints.between(1280, 1500)]: {
      fontSize: 13,
    },
    [theme.breakpoints.between(1000, 1300)]: {
      fontSize: 11,
    },
  },
  customerMicIcon: {
    backgroundColor: '#2694D3',
    color: '#fff',
    borderRadius: '50%',
    margin: '0px 1px',
    padding: '2px',
    fontSize: 15,
    [theme.breakpoints.between(1280, 1500)]: {
      fontSize: 13,
    },
    [theme.breakpoints.between(1000, 1300)]: {
      fontSize: 11,
    },
  },
  coachMicIcon: {
    backgroundColor: '#882647',
    color: '#fff',
    borderRadius: '50%',
    margin: '0px 1px',
    padding: 2,
    fontSize: 15,
    [theme.breakpoints.between(1280, 1500)]: {
      fontSize: 13,
    },
    [theme.breakpoints.between(1000, 1300)]: {
      fontSize: 11,
    },
  },
}));

export const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};
