/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HelpIcon from "@material-ui/icons/Help";
import FileSaver from "file-saver";
import {
  Divider,
  Grid,
  Toolbar,
  Box,
  Icon,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Tooltip,
  Modal,
  LinearProgress,
  CircularProgress,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import MicOutlinedIcon from '@material-ui/icons/MicOutlined';
// import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useLocation } from "react-router-dom";
import {
  increaseStep,
  fetchCurrentSim,
  decreaseStep,
  fetchCharacterList,
  addLevels,
  addNewLevels,
  uploadLevels,
  generateSim,
  setPassFailCriteriea,
  fetchAutoPopulate,
  fetchSimDetails,
} from "../../../redux/sims/actions";
import Characters from "./Characters.jsx";
import CharacterData from "./CharacterData.jsx";
import Download from "../../../assets/images/icons/Download.png";
import Print from "../../../assets/images/icons/Print.png";
import Details from "../Details.jsx";
import {
  addLevelScript,
  editLevels,
  deleteScript,
  deleteLevel,
  duplicateSim,
  generateAudio,
  downloadTemplate,
  downloadExcelLevel,
  realTimeFeedback,
  updateHintText,
  updateKeywordScore,
} from "../../../services/result.service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getModalStyle,
  useToolbarStyles,
  useLevelsStyle,
  useStyle,
} from "./Createlevels";
import StyledLink from "../../common/StyledLink";
const useQuery = () => new URLSearchParams(useLocation().search);

const TopTableToolbar = ({
  decreaseStep,
  data,
  increaseStep,
  addLevels,
  uploadLevels,
  newSim,
  setFlag,
  status,
  importLevel,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState("");
  const classes = useToolbarStyles();
  const [excelStatus, setExcelStatus] = React.useState(status);
  const handleNext = async () => {
    await addLevels(data);
    increaseStep();
  };
  const handlePreview = async () => {
    await addLevels(data);
    localStorage.setItem('sim_id', data.id);
    const simdata = JSON.stringify({ sim_id: data.id });
    localStorage.setItem('state', simdata);
  };
  useEffect(() => {
    if (status === "400") {
      setExcelStatus(status);
    }
  }, [status]);
  useEffect(() => {
    if (excelStatus === "400" && selectedFileName !== "") {
      alert("More than 5 levels are not allowed for a sim.");
    }
  }, [selectedFileName, excelStatus]);

  const HandleExcelUpload = () => {
    setExcelStatus("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fileSelected = (event) => {
    setSelectedFileName(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    importLevel();
    const formData = new FormData();
    formData.append("file", selectedFile);
    setOpen(false);
    await uploadLevels(formData, newSim.id, data.levels.length, data.levels[data.levels.length - 1].scripts.length 
      ? data.levels[data.levels.length - 1].order + 1 : 0);
    setFlag(true);
    importLevel();
    handleClose();
  };
  
  const templateDownload = () => {
    downloadTemplate().then((response) => {
      const fileName = "Excel Template";
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  };
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Create Levels
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.uploadDialog} id="form-dialog-title">
          Upload Excel
          <Link
            variant="subtitle1"
            underline="always"
            className={classes.templateLink}
            onClick={templateDownload}
          >
            Download Template
          </Link>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the file with xlsx format only
          </DialogContentText>
          <Button color="primary" component="label">
            Browse{" "}
            <input
              hidden
              type="file"
              accept=".xlsx, .csv"
              onChange={fileSelected}
            />
          </Button>
          {selectedFileName || data.sheet_name}
          <Divider variant="fullWidth" className={classes.excelDivider} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <StyledLink to="/sims"
      target="_blank">
        <Button
          variant="contained"
          color="primary"
          className={classes.previewBtn}
          disableElevation
          size="small"
          onClick={handlePreview}
        >
          PREVIEW
        </Button>
      </StyledLink>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Tooltip title="Import">
        <IconButton className={classes.icon} onClick={HandleExcelUpload}>
          <Icon>
            <img src={Download} height={20} width={20} alt="download" />
          </Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title="Print">
        <IconButton className={classes.printIcon}>
          <Icon>
            <img src={Print} height={20} width={20} alt="print" />
          </Icon>
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Button
        variant="outlined"
        color="primary"
        className={classes.previousBtn}
        disableElevation
        onClick={() => decreaseStep()}
      >
        <ArrowBackIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.nextBtn}
        disableElevation
        size="small"
        onClick={handleNext}
      >
        NEXT
      </Button>
      <Divider />
    </Toolbar>
  );
};

const Levels = ({
  simGenerated,
  Level,
  levels,
  data,
  setData,
  levelOrder,
  setLevelOrder,
  deleted,
  flag,
  setFlag,
  setdeleted,
}) => {
  const classes = useLevelsStyle();
  const [currentIndex, setIndex] = React.useState(0);
  const [insertLevel, setInsertLevel] = useState(false);
  const [levelName, setlevelName] = useState("");
  const handleTabs = (level) => {
    const index = levels.findIndex((lvl) => lvl.order === level.order);
    setIndex(index);
    Level(level);
  };
  useEffect(() => {
    if (levels.length && flag) {
      const simData = { ...data };
      const { length } = simData.levels;
      const latestOrder = simData.levels[length - 1].order;
      setLevelOrder(latestOrder);
      setFlag(false);
    }
  }, [levels.length, flag, setLevelOrder, data, setFlag]);

  useEffect(() => {
    if (deleted) {
      setIndex(currentIndex - 1);
      setdeleted(false);
    }
  }, [deleted, setdeleted, currentIndex]);

  const save = () => {
    const simData = { ...data };
    simData.levels.push({
      _id: undefined,
      name: levelName,
      order: Math.max.apply(Math, levels.map(function (o) { return o.order + 1; })),
      hint_text: true,
      keyword_score: true,
      scripts: [],
    });
    setLevelOrder(levelOrder + 1);
    setData(simData);
    setlevelName("");
    setInsertLevel(false);
  };

  const cancel = () => {
    setInsertLevel(false);
    setlevelName("");
  };

  const createNewLevel = () => {
    setInsertLevel(true);
  };
  return (
    <Grid item key="card" className={classes.firstGrid}>
      <Paper elevation={0}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid className={classes.levelHeader}>
            <Typography className={classes.levelLabel}>
              Levels
              <span className={classes.simGeneratedLabel}>
                <CheckCircleIcon className={classes.checkCircleIcon} />
                SIM GENERATED
              </span>
            </Typography>
          </Grid>
          <Grid className={classes.levels} item xs>
            {levels.map((level, index) => (
              <Box
                onClick={() => handleTabs(level)}
                className={
                  levels.length &&
                    levels[currentIndex] &&
                    levels[currentIndex].order === level.order
                    ? classes.activeCard
                    : classes.inacticeCard
                }
                key={index}
              >
                <Typography className={classes.label}>
                  {simGenerated ? (
                    <CheckCircleIcon className={classes.checkCircleIcon} />
                  ) : null}
                  LEVEL {index + 1}:
                  {/* {levels[currentIndex] &&
                  levels[currentIndex].name === level.name ? (
                    <RefreshIcon className={classes.refreshIcon} />
                  ) : null} */}
                </Typography>
                <Typography className={classes.levelName}>
                  {level.name}
                </Typography>
              </Box>
            ))}
            {/* {!simGenerated
              && (
                <Box
                  className={classes.activeCard}
                >
                  <Typography className={classes.label}>
                    {simGenerated ? <CheckCircleIcon className={classes.checkCircleIcon} /> : null}
                    LEVEL 1:
                    <RefreshIcon className={classes.refreshIcon} />
                  </Typography>
                  <Typography className={classes.levelName}>Watch and Learn</Typography>
                </Box>
              )} */}
            {insertLevel ? (
              <Box className={classes.inacticeCard}>
                <Typography className={classes.label}>
                  {simGenerated ? (
                    <CheckCircleIcon className={classes.checkCircleIcon} />
                  ) : null}
                  LEVEL {data.levels.length + 1}:
                </Typography>
                <TextField
                  placeholder="Enter Level name"
                  className={classes.text}
                  value={levelName}
                  onChange={(e) => setlevelName(e.target.value)}
                />
                <div className={classes.textFielDiv}>
                  <Button
                    name="script"
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.saveBtn}
                    onClick={() => save()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    className={classes.cancelBtn}
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                </div>
                {/* <div className={classes.rightArrow} /> */}
              </Box>
            ) : null}
            <Typography className={classes.addLevel} onClick={createNewLevel}>
              + ADD A LEVEL
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const getBackground = (charachter) => {
  let background = "";
  switch (charachter) {
    case "coach":
      background = "#882647";
      break;
    case "agent":
      background = "#EB6D20";
      break;
    case "customer":
      background = "#2694D3";
      break;
    case "other":
      background = "#2CB174";
      break;
    default:
      background = "#882647";
  }
  return background;
};

const CreateLevels = ({
  newSim,
  fetchCurrentSim,
  currentSim,
  decreaseStep,
  increaseStep,
  addLevels,
  uploadLevels,
  generateSim,
  setPassFailCriteriea,
  passFailCriteriaRes,
  fetchAutoPopulate,
  tabs,
  fetchSimDetails,
  status,
}) => {
  const classes = useStyle();
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [disableButton, setGenerateSim] = useState(true);
  const [simGenerated, setSimGenrated] = useState(false);
  const [data, setData] = useState({ ...newSim });
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [audioOpen, setAudioOpen] = useState(false);
  const [audioCount, setAudioCount] = useState(0);
  const [newLevels, setNewLevels] = React.useState("");
  const [levelSelect, setLevelSelect] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openSuccessAlert, setSuccessAlert] = React.useState(false);
  const [currentIndex, setIndex] = React.useState(0);
  const [alertMesage, setAlertMessage] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [passFailCriteriaStateRes, setPassFailCriteriaStateRes] = useState({});
  const [passFailBool, setPassFailBool] = React.useState(false);
  const [showModalButton, setShowModalButton] = React.useState(false);
  const [alertType, setAlertType] = React.useState("error");
  const [load, setLoad] = React.useState(false);
  const [levelOrder, setLevelOrder] = React.useState(0);
  const [flag, setFlag] = useState(true);
  const [totalLevel, setTotalLevel] = useState(0);
  const [deleted, setdeleted] = React.useState(false);
  const [selectedClip, setSelectedClip] = React.useState('');
  const [importLevel, setImportLevel] = React.useState(false);
  const [defLevels] = React.useState([
    "Watch and Learn",
    "Watch the experts",
    "Try w/ scripts",
    "Try w/ keywords",
    "Try w/o help",
  ]);
  const [passDialog, setPassDialog] = React.useState(false);
  const [accuracyValues, setAccuracyValues] = React.useState([
    "keyword",
    "typing",
    "data",
    "overall",
  ]);
  const [levelName, setLevelName] = React.useState(
    data.levels[currentIndex] ? data.levels[currentIndex].name : ""
  );
  const [generateClicked, setGenerateClicked] = useState(false);
  const [deleteLastLevel, setDeleteLastLevel] = useState(false);
  const [timePlay, setTimePlay] = useState(0);
  const [currentAudio, setCurrentAudio] = useState({});

  const handleClickOpen = () => {
    setLevelName(data.levels[currentIndex].name);
    setOpen(true);
  };

  const handleClose = () => {
    setLevelName('');
    setOpen(false);
  };
  const initialize = () => {
    if (query.get("simId") && !data.id) {
      fetchSimDetails(query.get("simId"));
    } else {
      setIsLoading(false);
    }
  };
  useEffect(initialize, []);

  useEffect(() => {
    if (query.get("simId") && data.fetchStatus === true && data.levels.length) {
      setIsLoading(false);
      localStorage.setItem('sims_id', query.get("simId"))
    }
  }, [data, query]);

  useEffect(() => {
    if (data.id && currentSim && Object.keys(currentSim).length === 0) {
      fetchCurrentSim(data.id);
    }
  }, [fetchCurrentSim, data.id, currentSim]);

  useEffect(() => {
    fetchAutoPopulate();
  }, [fetchAutoPopulate]);

  const clipRef = useRef(null);

  useEffect(() => {
    if (selectedClip) {
      clipRef.current.scrollIntoView(false);
    }
  }, [clipRef, selectedClip]);

  const initializeLevelArray = React.useCallback(() => {
    const simData = { ...data };
    const simLevels = ["Watch and Learn"];
    if (simData.levels.length === 0) {
      let index = 1;
      for (const level of simLevels) {
        simData.levels.push({
          _id: undefined,
          name: level,
          order: index - 1,
          real_time_feedback: false,
          hint_text: false,
          keyword_score: true,
          scripts: [],
        });
        index += 1;
      }
    }
    setData(simData);
  }, [data]);

  useEffect(() => {
    if (data.id && data.levels.length <= 0) {
      initializeLevelArray();
    }
  }, [data.id, data.levels, initializeLevelArray]);

  useEffect(() => {
    if (data && data.levels.length) {
      setTotalLevel(data.levels.length);
      setLevelSelect(false);
      if (data.levels[currentIndex].scripts.length) {
        data.levels[currentIndex].scripts.map((script) => {
          script.play = true;
          return script;
        });
      }
    }
  }, [data, currentIndex]);
  useEffect(() => {
    setData({ ...newSim });
  }, [newSim]);

  const updateData = async (index, characterData) => {
    const simData = { ...data };
    const requestData = {};
    const addIntialScript = async (requestData) => {
      const response = await addLevelScript(requestData);
      simData.levels[currentIndex].scripts[index]._id = response.sim_script_id;
    };
    simData.levels[currentIndex].scripts[index].script = characterData.script;
    simData.levels[currentIndex].scripts[index].keywords =
      characterData.keywords;
    simData.levels[currentIndex].scripts[index].hint_text =
      characterData.hint_text;
    simData.levels[currentIndex].scripts[index].hide_text =
      characterData.hide_text;
    simData.levels[currentIndex].scripts[index].real_time_feedback =
      characterData.real_time_feedback;
    requestData.is_level = false;
    requestData._id = simData.levels[currentIndex].scripts[index]._id;
    requestData.sim_level_id = simData.levels[currentIndex]._id;
    requestData.sim_id = simData.id;
    requestData.script = characterData.script;
    requestData.sim_character_id = characterData.sim_character_id;
    requestData.keywords = characterData.keywords;
    requestData.hint_text = characterData.hint_text;
    requestData.hide_text = characterData.hide_text;
    requestData.autopopulate_timer = characterData.autopopulate_timer;
    requestData.autopopulate_data = characterData.autopopulate_data;
    requestData.audio_url = characterData.audio_url;
    requestData.order = simData.levels[currentIndex].scripts[index].order;
    requestData.real_time_feedback = characterData.real_time_feedback;
    requestData.agent_recorded_audio = characterData.agent_recorded_audio;
    addIntialScript(requestData);
    setData(simData);
  };

  const Level = (cLevel) => {
    const simData = { ...data };
    const index = simData.levels.findIndex(
      (level) => level.order === cLevel.order
    );
    setIndex(index);
    setTimePlay(0);
  };
  const addScripts = async (character) => {
    const simData = { ...data };
    const { length } = simData.levels[currentIndex].scripts;
    if (length === 0 || simData.levels[currentIndex]._id) {
      simData.levels[currentIndex].scripts.push({
        order: length,
        sim_character_id: character._id,
        script: "",
        keywords: [],
        hint_text: "",
        hide_text: false,
        audio_url: "",
        autopopulate_timer: 0,
        autopopulate_data: [...tabs],
        status: "saved",
        real_time_feedback: false,
        agent_recorded_audio: false,
        newScript: true,
      });
      const requestData = {};
      if (
        simData.levels[currentIndex].scripts.length === 1 &&
        !simData.levels[currentIndex]._id
      ) {
        // request data when initial script is added to rescpective level
        requestData.is_level = true;
        requestData.name = simData.levels[currentIndex].name;
        requestData.level_order = simData.levels[currentIndex].order;
        requestData.real_time_feedback = simData.levels[currentIndex].real_time_feedback;
        requestData.level_hint_text = (simData.levels[currentIndex].order === 0 ? false : true);
      } else {
        // request data when a respective level has more than one script
        requestData.is_level = false;
        requestData.sim_level_id = simData.levels[currentIndex]._id;
      }
      requestData.sim_id = simData.id;
      requestData.script = "";
      requestData.sim_character_id = character._id;
      requestData.audio_url = "";
      requestData.order =
        simData.levels[currentIndex].scripts.length.toString();
      requestData.status = "saved";
      requestData.real_time_feedback = false;
      requestData.keywords = [];
      requestData.hint_text = "";
      requestData.hide_text = false;
      requestData.agent_recorded_audio = false;
      requestData.autopopulate_timer = 0;
      requestData.autopopulate_data = tabs;
      const response = await addLevelScript(requestData);
      simData.levels[currentIndex].scripts[length]._id = response.sim_script_id;
      if (response.sim_level_id) {
        simData.levels[currentIndex]._id = response.sim_level_id;
      }
      setData(simData);
      setSelectedClip(response.sim_script_id);
    }
  };

  const showGenerateSim = () => {
    setGenerateSim(false);
  };

  const enableIcon = () => {
    let coachid;
    let agentid;
    setGenerateClicked(false);
    newSim.sim_characters.forEach((char) => {
      if (char.type === "agent") {
        agentid = char._id;
      }
      if (char.type === "coach") {
        coachid = char._id;
      }
    });
    const { length } = data.levels;
    const genLevels = [];
    for (let i = 0; i < 4; i += 1) {
      const obj = {};
      obj.name = defLevels[i + 1];
      obj.order = data.levels[length - 1].order + i + 1;
      obj.sim_id = newSim.id;
      obj.hint_text = true;
      obj.keyword_score = true;
      obj.coachId = coachid;
      obj.agentId = agentid;
      obj.scripts = [];
      obj.generateLevel = true;
      if (i === 0) {
        obj.removecoach = true;
        obj.includekeywords = false;
        obj.hinttext = false;
        obj.removeagent = false;
        obj.convertagent = false;
        obj.autopopulate = true;
        obj.autopopulate_data = tabs;
      }
      if (i === 1) {
        obj.removecoach = false;
        obj.includekeywords = false;
        obj.hinttext = false;
        obj.removeagent = false;
        obj.convertagent = true;
        obj.autopopulate = false;
        obj.autopopulate_data = tabs;
      }
      if (i === 2) {
        obj.removecoach = false;
        obj.includekeywords = true;
        obj.removeagent = false;
        obj.hinttext = false;
        obj.convertagent = true;
        obj.autopopulate = false;
        obj.autopopulate_data = tabs;
      }
      if (i === 3) {
        obj.removeagent = true;
        obj.includekeywords = false;
        obj.hinttext = true;
        obj.removecoach = true;
        obj.convertagent = true;
        obj.autopopulate = false;
        obj.autopopulate_data = tabs;
      }
      genLevels.push(obj);
    }
    setTotalLevel(totalLevel + genLevels.length);
    setNewLevels(genLevels);
    setSimGenrated(true);
    setLevelSelect(true);
  };
  useEffect(() => {
    if (
      newSim.levels[currentIndex] &&
      newSim.levels[currentIndex].scripts.length >= 1
    ) {
      showGenerateSim();
    }
  });
  const handleChange = (value, index, name) => {
    const sim = { ...data };
    if (name === "script") {
      sim.levels[currentIndex].scripts[index].script = value;
    } else if (name === "hint_text") {
      sim.levels[currentIndex].scripts[index].hint_text = value;
    } else if (name === "keywords") {
      sim.levels[currentIndex].scripts[index].keywords = value;
    }
    setData(sim);
  };
  const handleKeywordChange = (index, event) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].keywords = [...event];
    setData(sim);
  };

  const handleAudioGenerate = async (index, url, expiry_date, key, type, voice) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].agent_recorded_audio = type;
    sim.levels[currentIndex].scripts[index].audio_url = url;
    sim.levels[currentIndex].scripts[index].expiry_date = expiry_date;
    sim.levels[currentIndex].scripts[index].audio_key = key;
    sim.levels[currentIndex].scripts[index].voice = voice;
    setData(sim);
  };

  const handleEditLevel = (event) => {
    const { value } = event.target;
    setLevelName(value);
  };
  const handleSave = async () => {
    const sim = { ...data };
    data.levels[currentIndex].name = levelName;
    setData(sim);
    setOpen(false);
    editLevels(data.levels[currentIndex]._id, {
      name: data.levels[currentIndex].name,
      sim_id: data.levels[currentIndex].sim_id,
    });
    setLevelName("");
  };

  const handleCheckboxChange = (index, event) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].hide_text = event.target.checked;
    setData(sim);
  };
  const handleRadioChange = (index, event) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].real_time_feedback =
      event.target.value === "yes";
    setData(sim);
  };

  const handleDeleteScript = async (index, script) => {
    const sim = { ...data };
    const requestData = {};
    requestData.script_id = script._id;
    requestData.sim_id = script.sim_id;
    await deleteScript(requestData);
    sim.levels[currentIndex].scripts.splice(index, 1);
    setData(sim);
  };

  const handleLevelName = (event, index) => {
    const arr = [...newLevels];
    arr[index].name = event.target.value;
    setNewLevels(arr);
  };

  const handleGenerateCheckbox = (event, index) => {
    const arr = [...newLevels];
    if (event.target.checked === true) {
      arr[index].generateLevel = true;
      setTotalLevel(totalLevel + 1);
    } else {
      setTotalLevel(totalLevel - 1);
      arr[index].generateLevel = false;
    }
    setNewLevels(arr);
  };

  const handleRemCoachCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].generateLevel = true;
        arr[index].removecoach = true;
        setNewLevels(arr);
        return;

      case false:
        arr[index].removecoach = event.target.checked;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(newLevels);
    }
  };

  const handlekeywordsCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].generateLevel = true;
        arr[index].scripts = [];
        arr[index].includekeywords = true;
        setNewLevels(arr);
        return;

      case false:
        arr[index].includekeywords = false;
        arr[index].scripts = [];
        setNewLevels(arr);
        return;

      default:
        setNewLevels(arr);
    }
  };

  const handleAutopopulateCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].generateLevel = true;
        arr[index].autopopulate = true;
        setNewLevels(arr);
        return;
      case false:
        arr[index].autopopulate = false;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };
  const handleCheckboxSelect = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].generateLevel = true;
        arr[index].convertagent = true;
        setNewLevels(arr);
        return;
      case false:
        arr[index].convertagent = false;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };
  const handleRemAgentCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].generateLevel = true;
        arr[index].removeagent = true;
        setNewLevels(arr);
        return;
      case false:
        arr[index].removeagent = event.target.checked;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };

  const handleHintTextCheckbox = (event, index) => {
    const arr = [...newLevels];
    const levels = data.levels.slice(0, 1);
    switch (event.target.checked) {
      case true:
        arr[index].generateLevel = true;
        arr[index].hinttext = true;
        for (let i = 0; i < arr[index].scripts.length; i += 1) {
          arr[index].scripts[i].hint_text = levels[0].scripts[i].hint_text;
        }
        setNewLevels(arr);
        return;
      case false:
        arr[index].hinttext = false;
        for (let i = 0; i < arr[index].scripts.length; i += 1) {
          arr[index].scripts[i].hint_text = "";
        }
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };

  const handleDialogGenerate = async () => {
    setLoad(true);
    const arr = [...newLevels];
    for (let i = arr.length - 1; i >= 0; i -= 1) {
      if (arr[i].generateLevel === false) {
        arr.splice(i, 1);
      }
    }
    for (let i = 0; i < arr.length; i += 1) {
      delete arr[i].generateLevel;
    }
    setGenerateClicked(true);
    setLevelSelect(false);
    setFlag(true);
    await generateSim(arr, data.levels[0]._id);
    setNewLevels("");
    const simId = query.get("simId") ? query.get("simId") : data.id;
    await fetchSimDetails(simId);
    setLoad(false);
    setAlertType("success");
    setAlertMessage("Sim levels generated successfully!!!");
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const handleCancel = () => {
    setLevelSelect(false);
    setTotalLevel(data.levels.length);
  };

  const handlePassDialog = (text) => {
    if (text === "open") {
      setPassDialog(true);
    }
    if (text === "close") {
      setPassDialog(false);
    }
  };

  const handlePassCheckbox = (event) => {
    const arr = [...accuracyValues];
    let index;
    switch (event.target.name) {
      case "keyword":
        index = arr.indexOf("keyword");
        if (event.target.checked === true) {
          arr.splice(index, 1);
          setAccuracyValues(arr);
          break;
        } else {
          arr.push("keyword");
          setAccuracyValues(arr);
          break;
        }
      case "typing":
        index = arr.indexOf("typing");
        if (event.target.checked === true) {
          arr.splice(index, 1);
          setAccuracyValues(arr);
          break;
        } else {
          arr.push("typing");
          setAccuracyValues(arr);
          break;
        }
      case "data":
        index = arr.indexOf("data");
        if (event.target.checked === true) {
          arr.splice(index, 1);
          setAccuracyValues(arr);
          break;
        } else {
          arr.push("data");
          setAccuracyValues(arr);
          break;
        }
      case "overall":
        index = arr.indexOf("overall");
        if (event.target.checked === true) {
          arr.splice(index, 1);
          setAccuracyValues(arr);
          break;
        } else {
          arr.push("overall");
          setAccuracyValues(arr);
          break;
        }
      default:
        setAccuracyValues(arr);
    }
  };
  const handleDeleteLevel = async () => {
    const sim = { ...data };
    if (sim.levels.length === 1) {
      setDeleteLastLevel(true);
      setDeleteOpen(true);
    } else {
      setDeleteOpen(true);
    }
  };

  const deleteConfirm = async () => {
    setdeleted(true);
    const sim = { ...data };
    if (sim.levels[currentIndex]._id === undefined) {
      sim.levels.splice(currentIndex, 1);
    } else if (deleteLastLevel) {
      const data = {}
      data.sim_level_id = sim.levels[currentIndex]._id;
      await deleteScript(data);
      setDeleteLastLevel(false);
      sim.levels[currentIndex].scripts.splice(0, sim.levels[currentIndex].scripts.length);
      setData(sim);
    } else {
      const requestData = {};
      requestData.level_id = sim.levels[currentIndex]._id;
      requestData.sim_id = sim.levels[currentIndex].sim_id;
      await deleteLevel(requestData);
      sim.levels.splice(currentIndex, 1);
    }
    if (currentIndex > 0) {
      setIndex(currentIndex - 1);
    }
    setDeleteOpen(false);
    setData(sim);
  };

  const handleDuplicateSim = async () => {
    const sim = { ...data };
    const requestData = {};
    requestData.simId = sim.id;
    const response = await duplicateSim(requestData);
    if (response.data.success) {
      setSuccessAlert(true);
    } else {
      setAlertType("error");
      setAlertMessage("Error in creating duplicate sim. Please try again.");
      setOpenAlert(false);
    }
  };
  const downloadLevel = async () => {
    downloadExcelLevel(data.levels[currentIndex]._id).then((response) => {
      const fileName = data.levels[currentIndex].name.replace(/\//g, '_');
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  };
  
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlert(false);
    setAlertType("");
    setAlertMessage("");
  };
  const keywordInputRef = useRef(null);
  const typingInputRef = useRef(null);
  const dataSelInputRef = useRef(null);
  const overAllInputRef = useRef(null);
  const skipLevelRef = useRef(null);

  useEffect(() => {
    if (
      passFailCriteriaRes?.sim_level_id?.toString() ===
      data.levels[currentIndex]?._id?.toString()
    ) {
      setPassFailCriteriaStateRes(passFailCriteriaRes);
      setPassFailBool(
        passFailCriteriaRes.allow_skip_levels
          ? passFailCriteriaRes.allow_skip_levels
          : false
      );
    } else {
      setPassFailCriteriaStateRes({});
      setPassFailBool(false);
    }
  }, [
    passFailCriteriaRes,
    setPassFailCriteriaStateRes,
    setPassFailBool,
    currentIndex,
    data,
  ]);

  const submitPassFailCriteria = () => {
    // Create criteria params
    const criteriaParams = {
      threshold_keyword_accuracy:
        keywordInputRef.current.value !== "" &&
          !keywordInputRef.current.disabled
          ? Number(keywordInputRef.current.value)
          : undefined,
      threshold_typing_accuracy:
        typingInputRef.current.value !== "" && !typingInputRef.current.disabled
          ? Number(typingInputRef.current.value)
          : undefined,
      threshold_data_selection_accuracy:
        dataSelInputRef.current.value !== "" &&
          !dataSelInputRef.current.disabled
          ? Number(dataSelInputRef.current.value)
          : undefined,
      threshold_overall_accuracy:
        overAllInputRef.current.value !== "" &&
          !overAllInputRef.current.disabled
          ? Number(overAllInputRef.current.value)
          : undefined,
      allow_skip_levels: skipLevelRef.current.checked,
      status: "set",
      sim_level_id: data.levels[currentIndex]._id,
      sim_id: data.id,
    };
    // when pass fail set for first time
    if (!data.is_pass_fail_criteria_set) {
      // dispatch setPassFailCriteriea action
      setPassFailCriteriea(criteriaParams);
      // show successfully message
      setAlertType("success");
      setAlertMessage("Criteria is set successfully for this level!!!");
      setOpenAlert(true);
      // After 2 sec alert message hide
      setTimeout(() => {
        setAlertType("");
        setAlertMessage("");
        setOpenAlert(false);
      }, 2000);
      handlePassDialog("close");
    } else {
      // Alert agent if criteria already setted for a level
      setModalTitle("Pass / Fail - Warning");
      setModalBody(
        "Pass / Fail criteria is already set for this sim, do you want to continue?"
      );
      setShowModalButton(true);
      setOpenModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const anywaySubmitPassFail = () => {
    const criteriaParams = {
      threshold_keyword_accuracy:
        keywordInputRef.current.value !== "" &&
          !keywordInputRef.current.disabled
          ? Number(keywordInputRef.current.value)
          : undefined,
      threshold_typing_accuracy:
        typingInputRef.current.value !== "" && !typingInputRef.current.disabled
          ? Number(typingInputRef.current.value)
          : undefined,
      threshold_data_selection_accuracy:
        dataSelInputRef.current.value !== "" &&
          !dataSelInputRef.current.disabled
          ? Number(dataSelInputRef.current.value)
          : undefined,
      threshold_overall_accuracy:
        overAllInputRef.current.value !== "" &&
          !overAllInputRef.current.disabled
          ? Number(overAllInputRef.current.value)
          : undefined,
      allow_skip_levels: skipLevelRef.current.checked,
      status: "set",
      sim_level_id: data.levels[currentIndex]._id,
      sim_id: data.id,
    };
    // save _id to criteriaParams, if criteria setting for same level
    if (passFailCriteriaRes._id) {
      criteriaParams._id =
        passFailCriteriaRes?.sim_level_id?.toString() ===
          data.levels[currentIndex]?._id?.toString()
          ? passFailCriteriaRes._id
          : undefined;
    }
    setPassFailCriteriea(criteriaParams);
    setOpenModal(false);
    setModalTitle("");
    setModalBody("");
    setShowModalButton(false);
    handlePassDialog("close");
    setAlertType("success");
    setAlertMessage("Criteria is set successfully for this level!!!");
    setOpenAlert(true);
    setTimeout(() => {
      setAlertType("");
      setAlertMessage("");
      setOpenAlert(false);
    }, 2000);
  };
  const handleAudioChange = (index) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].agent_recorded_audio = false;
    sim.levels[currentIndex].scripts[index].audio_url = "";
    setData(sim);
  };
  const handleAgentRecordedAudio = (index) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].agent_recorded_audio = true;
    setData(sim);
  };

  const handleAutopopulateChange = async (index, array, timer) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].autopopulate_timer = timer;
    sim.levels[currentIndex].scripts[index].autopopulate_data = [...array];
    sim.levels[currentIndex].scripts[index].autopopulate_data.forEach(
      (field) => {
        delete field._id;
        field.fields.forEach((element) => {
          delete element._id;
        });
      }
    );
    setData(sim);
  };
  const handleResetAutopopulate = async (index) => {
    const sim = { ...data };
    sim.levels[currentIndex].scripts[index].autopopulate_timer = 0;
    sim.levels[currentIndex].scripts[index].autopopulate_data = [...tabs];
    setData(sim);
  };

  const getAudioCount = () => {
    const result = data.levels[currentIndex].scripts.filter(
      (script) => script.audio_url !== ""
    );
    if (result.length <= 0) {
      handleLevelAudioGenerate();
    } else {
      setAudioCount(result.length);
      setAudioOpen(true);
    }
  };

  const handleLevelAudioGenerate = async () => {
    setAudioOpen(false);
    setLoad(true);
    const sim = { ...data };
    for (let i = 0; i < sim.levels[currentIndex].scripts.length; i++) {
      const character = data.sim_characters.find(
        (item) =>
          item._id === sim.levels[currentIndex].scripts[i].sim_character_id
      );
      if (
        character.type === "agent" &&
        !sim.levels[currentIndex].scripts[i].agent_recorded_audio
      ) {
        if (sim.levels[currentIndex].order === 0 || sim.levels[currentIndex].order === 1) {
          const req = {};
          req.sim_script_id = sim.levels[currentIndex].scripts[i]._id;
          req.sim_id = sim.levels[currentIndex].scripts[i].sim_id;
          req.voice = character.voice;
          const response = await generateAudio(req);
          sim.levels[currentIndex].scripts[i].audio_url = response.audio_url;
          sim.levels[currentIndex].scripts[i].expiry_date = response.expiry_date;
          sim.levels[currentIndex].scripts[i].audio_key = response.audio_key;
          sim.levels[currentIndex].scripts[i].agent_recorded_audio = true;
          sim.levels[currentIndex].scripts[i].voice = character.voice;
          setData(sim);
          await updateData(i, sim.levels[currentIndex].scripts[i]);
        }
        // If the character type is 'agent' and enabled to prompt to record, then do not generate audio for that script
      } else {
        const req = {};
        req.sim_script_id = sim.levels[currentIndex].scripts[i]._id;
        req.sim_id = sim.levels[currentIndex].scripts[i].sim_id;
        req.voice = character.voice;
        const response = await generateAudio(req);
        sim.levels[currentIndex].scripts[i].audio_url = response.audio_url;
        sim.levels[currentIndex].scripts[i].expiry_date = response.expiry_date;
        sim.levels[currentIndex].scripts[i].audio_key = response.audio_key;
        sim.levels[currentIndex].scripts[i].voice = character.voice;
        if (sim.levels[currentIndex].order === 0 || sim.levels[currentIndex].order === 1) {
          sim.levels[currentIndex].scripts[i].agent_recorded_audio = true
        }
        setData(sim);
        await updateData(i, sim.levels[currentIndex].scripts[i]);
      }
    }
    setLoad(false);

  };
  const [hint, setHint] = useState(1);
  const [keywordScore, setKeywordScore] = useState(1);
  const hintTextChange = async(evn) => {
    setHint(hint+1);
    let changeData = {};
    changeData.sim_id = data.id;
    changeData.hint_text =  evn.target.checked;
    changeData.level_id = data.levels[currentIndex]._id;
    data.levels[currentIndex].hint_text =  evn.target.checked;
    await updateHintText(changeData);
  }
  const KeywordChange = async(evn) => {
    setKeywordScore(keywordScore+1);
    let changeData = {};
    changeData.sim_id = data.id;
    changeData.keyword_score =  evn.target.checked;
    changeData.level_id = data.levels[currentIndex]._id;
    data.levels[currentIndex].keyword_score =  evn.target.checked;
    await updateKeywordScore(changeData);
  }
  const enableRealTimeFeedback = async (evn) => {
    let checked = evn.target.checked;
    let count = 0;
    const selectedCharacter = data.sim_characters.find(
      (item) => item.type === "agent"
    );
    const sim = { ...data };
    const requestData = {};
    requestData.scripts = [];
    requestData.sim_id = data.id;
    requestData.real_time_feedback = checked ? true : false;
    requestData.level_id = data.levels[currentIndex]._id;
    if (data.id && data.levels[currentIndex].scripts.length > 0) {
      setLoad(true);
      for (let i = 0; i < sim.levels[currentIndex].scripts.length; i++) {
        if (
          sim.levels[currentIndex].scripts[i].sim_character_id ===
          selectedCharacter._id
        ) {
          count++;
          requestData.scripts.push(sim.levels[currentIndex].scripts[i]._id);
          sim.levels[currentIndex].real_time_feedback = checked ? true : false;
          sim.levels[currentIndex].scripts[i].real_time_feedback = checked ? true : false;
        }
        setData(sim);
      }
      if (count === 0) {
        setLoad(false);
        setAlertType("warning");
        setAlertMessage(
          "No Agent Clip found to enable/disable real time feedback"
        );
        setOpenAlert(true);
      } else {
        const res = await realTimeFeedback(requestData);
        if (res.success) {
          setLoad(false);
          setAlertType("success");
          setAlertMessage("Successfuly updated real time feedback");
          setOpenAlert(true);
        } else {
          setLoad(false);
          setAlertType("error");
          setAlertMessage("Error in changing real time feedback");
          setOpenAlert(true);
        }
      }
    } else {
      setAlertType("warning");
      setAlertMessage("Add scripts to enable/disable real time feedback");
      setOpenAlert(true);
    }
  };

  // Method to handle drag and drop in scripts
  const handleOnDragEnd = (result) => {
    const levelScript = { ...data };
    const items = Array.from(levelScript.levels[currentIndex].scripts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.map(async (item, index) => {
      item.order = index + 1;
      await addLevelScript(item);
    });
    levelScript.levels[currentIndex].scripts = items;
    setData(levelScript);
  };

  const clipScroll = (id) => {
    setSelectedClip(id);
  }
  const setTimePlayfun = (audioPlayer, dataa) => {
    data.levels[currentIndex].scripts.map((script) => {
      if (script._id === dataa._id) {
        script.play = false;
      } else {
        script.play = true;
      }
      return data;
    });
    setData(data)
    setCurrentAudio(audioPlayer);
    setTimePlay((prev) => prev + 1)
  }
  const pauseFunction = (dataa) => {
    data.levels[currentIndex].scripts.map((script) => {
      if (script._id === dataa._id) {
        script.play = true;
        setTimePlay(0)
      }
      return data;
    });
    setData(data);
  }
  return (
    <Paper elevation={0}>
      <TopTableToolbar
        updateData={updateData}
        decreaseStep={decreaseStep}
        increaseStep={increaseStep}
        data={data}
        addLevels={addLevels}
        uploadLevels={uploadLevels}
        newSim={newSim}
        setFlag={setFlag}
        status={status}
        importLevel={() => setImportLevel((prev) => !prev)}
      />
      <Details currentSim={currentSim} title={newSim.title} />
      {openAlert ? (
        <Alert
          variant="filled"
          onClose={handleAlertClose}
          severity={alertType}
          className={alertType === 'error' ? classes.errorAlert : ''}
        >
          {alertMesage}
        </Alert>
      ) : null}
      {openSuccessAlert ? (
        <Alert
          className={classes.alert}
          variant="filled"
          onClose={handleSuccessAlertClose}
          severity="success"
        >
          Success! Duplicate Sim Created.
        </Alert>
      ) : null}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.modalPaper}>
          <h2>{modalTitle}</h2>
          <p>{modalBody}</p>
          {showModalButton ? (
            <>
              <Button
                onClick={anywaySubmitPassFail}
                color="primary"
                variant="contained"
                style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
              >
                Continue
              </Button>
              <Button
                onClick={handleModalClose}
                variant="contained"
                style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
              >
                Cancel
              </Button>
            </>
          ) : null}
        </div>
      </Modal>
      <div className={classes.mainDiv}>
        {isLoading ? (
          <div className={classes.loaderDiv}>
            <CircularProgress color="primary" />
          </div>
        ) : null}
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2} sm={2} lg={2} xl={2}>
            <Levels
              simGenerated={simGenerated}
              Level={Level}
              levels={data.levels}
              data={data}
              setData={setData}
              newSim={newSim}
              levelOrder={levelOrder}
              setLevelOrder={setLevelOrder}
              deleted={deleted}
              flag={flag}
              setFlag={setFlag}
              setdeleted={setdeleted}
            />
          </Grid>
          <Grid item className={classes.editCanvas} sm={8} lg={8} xl={8} xs={7}>
            <Toolbar className={classes.toolBar}>
              <Typography className={classes.canvasLabel}>
                {currentIndex + 1}.{" "}
                {data.levels[currentIndex]
                  ? data.levels[currentIndex].name
                  : ""}
                <Button
                  disabled={
                    !(
                      data.levels &&
                      data.levels.length > 0 &&
                      data.levels[currentIndex]
                    )
                  }
                  className={classes.editBtn}
                  color="default"
                  onClick={handleClickOpen}
                  startIcon={<EditIcon className={classes.editIcon} />}
                />
              </Typography>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="levelName"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="LevelName">Edit Level Name</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    id="LevelName"
                    margin="dense"
                    name="name"
                    multiline
                    value={levelName}
                    onChange={(e) => handleEditLevel(e)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} className={classes.cancelButton}>
                    Cancel
                  </Button>
                  <Button onClick={handleSave} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="outlined"
                className={classes.generateSimBtn}
                onClick={() => handlePassDialog("open")}
              >
                SET PASS/FAIL FOR THE SIM
              </Button>
              <Dialog
                open={passDialog}
                onClose={handlePassDialog}
                aria-labelledby="form-dialog-title"
                fullWidth
              >
                <DialogTitle id="form-dialog-title">
                  <h3 style={{ margin: "0px" }}>Set Pass Criteria</h3>
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    <strong>
                      Set Pass Criteria (Select NA if criteria not applicable)
                    </strong>
                  </Typography>
                  <Typography gutterBottom style={{ margin: "1rem 0rem" }}>
                    Threshold Keyword Accuracy{" "}
                    <TextField
                      disabled={!accuracyValues.includes("keyword")}
                      type="number"
                      inputRef={keywordInputRef}
                      defaultValue={
                        passFailCriteriaStateRes.threshold_keyword_accuracy !==
                          0
                          ? passFailCriteriaStateRes.threshold_keyword_accuracy
                          : ""
                      }
                      variant="outlined"
                      size="small"
                      className={classes.textfield}
                    />{" "}
                    %
                    <Checkbox
                      checked={!accuracyValues.includes("keyword")}
                      onChange={(event) => handlePassCheckbox(event)}
                      name="keyword"
                      className={classes.checkbox}
                    />{" "}
                    NA
                  </Typography>
                  <Typography gutterBottom style={{ marginBottom: "1rem" }}>
                    Threshold Typing Accuracy{" "}
                    <TextField
                      disabled={!accuracyValues.includes("typing")}
                      inputRef={typingInputRef}
                      type="number"
                      defaultValue={
                        passFailCriteriaStateRes.threshold_typing_accuracy !== 0
                          ? passFailCriteriaStateRes.threshold_typing_accuracy
                          : ""
                      }
                      variant="outlined"
                      size="small"
                      className={classes.textfield}
                    />{" "}
                    <Checkbox
                      checked={!accuracyValues.includes("typing")}
                      onChange={(event) => handlePassCheckbox(event)}
                      name="typing"
                      className={classes.checkbox}
                    />{" "}
                    NA
                  </Typography>
                  <Typography gutterBottom style={{ marginBottom: "1rem" }}>
                    Threshold Data Selection Accuracy{" "}
                    <TextField
                      disabled={!accuracyValues.includes("data")}
                      inputRef={dataSelInputRef}
                      defaultValue={
                        passFailCriteriaStateRes.threshold_data_selection_accuracy !==
                          0
                          ? passFailCriteriaStateRes.threshold_data_selection_accuracy
                          : ""
                      }
                      type="number"
                      variant="outlined"
                      size="small"
                      className={classes.textfield}
                    />{" "}
                    <Checkbox
                      checked={!accuracyValues.includes("data")}
                      onChange={(event) => handlePassCheckbox(event)}
                      name="data"
                      className={classes.checkbox}
                    />{" "}
                    NA
                  </Typography>
                  <Typography gutterBottom style={{ marginBottom: "1rem" }}>
                    Threshold OverAll Accuracy{" "}
                    <TextField
                      disabled={!accuracyValues.includes("overall")}
                      inputRef={overAllInputRef}
                      defaultValue={
                        passFailCriteriaStateRes.threshold_overall_accuracy !==
                          0
                          ? passFailCriteriaStateRes.threshold_overall_accuracy
                          : ""
                      }
                      type="number"
                      variant="outlined"
                      size="small"
                      className={classes.textfield}
                    />{" "}
                    %
                    <Checkbox
                      checked={!accuracyValues.includes("overall")}
                      onChange={(event) => handlePassCheckbox(event)}
                      name="overall"
                      className={classes.checkbox}
                    />{" "}
                    NA
                  </Typography>
                  <Typography gutterBottom style={{ marginBottom: "1rem" }}>
                    Allow Learner to skip levels{" "}
                    <Checkbox
                      inputRef={skipLevelRef}
                      onChange={() =>
                        setPassFailBool(skipLevelRef.current.checked)
                      }
                      checked={passFailBool}
                    />{" "}
                    <Tooltip title="If this box is checked, Learners are allowed to skip levels and go directly to the You Try w/ No Help level and send for review">
                      <HelpIcon style={{ marginBottom: "-8px" }} />
                    </Tooltip>
                  </Typography>
                  <Divider />
                  <Button
                    onClick={submitPassFailCriteria}
                    color="primary"
                    variant="contained"
                    style={{
                      float: "right",
                      margin: "0.5rem 1rem 0.5rem 0rem",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      float: "right",
                      margin: "0.5rem 1rem 0.5rem 0rem",
                    }}
                    onClick={() => handlePassDialog("close")}
                  >
                    Cancel
                  </Button>
                </DialogContent>
              </Dialog>
              <Button
                disabled={disableButton}
                variant="outlined"
                className={classes.generateSimBtn}
                onClick={enableIcon}
              >
                GENERATE SIM
              </Button>
              <Dialog
                open={levelSelect}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
              >
                <DialogTitle id="form-dialog-title">Generate Sim</DialogTitle>
                <DialogContent>
                  {newLevels &&
                    newLevels.map((level, index) => (
                      <FormControl
                        key={index}
                        component="fieldset"
                        className={classes.dialogFormControl}
                      >
                        <TextField
                          fullWidth
                          label="Title"
                          InputProps={{
                            classes: {
                              input: classes.resize,
                            },
                          }}
                          value={level.name}
                          variant="outlined"
                          onChange={(event) => handleLevelName(event, index)}
                        />
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handleGenerateCheckbox(event, index)
                                }
                                name="generate"
                                color="primary"
                                checked={level.generateLevel}
                              />
                            }
                            label="Generate this level"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handleRemCoachCheckbox(event, index)
                                }
                                name="removecoach"
                                color="primary"
                                checked={level.removecoach === true}
                              />
                            }
                            label="Remove coach"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handlekeywordsCheckbox(event, index)
                                }
                                name="keywords"
                                color="primary"
                                checked={level.includekeywords === true}
                              />
                            }
                            label="Only include keywords"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handleRemAgentCheckbox(event, index)
                                }
                                name="removeagent"
                                color="primary"
                                checked={level.removeagent === true}
                              />
                            }
                            label="Remove Agent Script"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handleCheckboxSelect(event, index)
                                }
                                name="convertagent"
                                color="primary"
                                checked={level.convertagent === true}
                              />
                            }
                            label="Convert Agent Clips to Prompts"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  handleHintTextCheckbox(event, index)
                                }
                                name="hinttext"
                                color="primary"
                                checked={level.hinttext === true}
                              />
                            }
                            label="Include Hint Text"
                          />
                          {data.auto_populate ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(event) =>
                                    handleAutopopulateCheckbox(event, index)
                                  }
                                  name="autopopulate"
                                  color="primary"
                                  checked={level.autopopulate === true}
                                />
                              }
                              label="Include Auto-populate"
                            />
                          ) : null}
                        </FormGroup>
                      </FormControl>
                    ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancel} className={classes.cancelButton}>
                    Cancel
                  </Button>
                  <Tooltip
                    title={
                      totalLevel > 5
                        ? "More than 5 levels are not allowed for a sim."
                        : ""
                    }
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Button
                      color="primary"
                      className={classes.generateButton}
                      onClick={handleDialogGenerate}
                      disabled={totalLevel > 5 || generateClicked}
                    >
                      Generate
                    </Button>
                  </Tooltip>
                </DialogActions>
              </Dialog>
              
              <div>
                <Box className={classes.iconBox}>
                  {data.levels.length && hint ?
                  <Tooltip title={`${data.levels[currentIndex] && data.levels[currentIndex].keyword_score ? "Disable" : "Enable"
                } Keyword-Score in Agent Result Screen`}>
                  <Checkbox
                   checked={data.levels[currentIndex].keyword_score}
                   onChange={KeywordChange}
                   className={classes.iconFeedback}
                />
                </Tooltip>
                  : null}
                  {data.levels.length && hint && data.levels[currentIndex].order === 0 ?
                  <Tooltip title={`${data.levels[currentIndex] && data.levels[currentIndex].hint_text ? "Disable" : "Enable"
                } Hint-Text in Level 1`}>
                  <Checkbox
                   checked={data.levels[currentIndex].hint_text}
                   onChange={hintTextChange}
                   className={classes.iconFeedback}
                />
                </Tooltip>
                  : null}
                  <span className={classes.verticalDivider} />
                  {data.real_time_feedback && data.levels[currentIndex] ? (
                    <Tooltip
                      title={`${data.levels[currentIndex] && data.levels[currentIndex].real_time_feedback ? "Disable" : "Enable"
                        } Real-Time Feedback`}
                    >
                      <Checkbox
                        checked={data.levels[currentIndex].real_time_feedback}
                        onChange={enableRealTimeFeedback}
                        className={classes.iconFeedback}
                      />
                    </Tooltip>
                  ) : null}
                  {data.real_time_feedback ? (
                    <span className={classes.verticalDivider} />
                  ) : null}
                  {data.levels[currentIndex] &&
                    data.levels[currentIndex].scripts.length <= 0 ? (
                    <Tooltip title="Generate Audio">
                      <VolumeUpRoundedIcon className={classes.iconVolume} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Generate Audio">
                      <VolumeUpRoundedIcon
                        className={classes.iconVolume}
                        onClick={getAudioCount}
                      />
                    </Tooltip>
                  )}
                </Box>
              </div>
              <Dialog
                open={audioOpen}
                onClose={() => setAudioOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Generate Audio
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`There are ${audioCount} audio clips already. Are you sure you want to recored over them?`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAudioOpen(false)} className={classes.cancelButton}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleLevelAudioGenerate()}
                    className={classes.dialogDeleteBtn}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <div>
                <Box className={classes.iconBox}>
                  <Tooltip title="Duplicate Sim">
                    <FileCopyRoundedIcon
                      className={classes.iconCopy}
                      onClick={handleDuplicateSim}
                    />
                  </Tooltip>
                  <span className={classes.verticalDiv} />
                  <Tooltip title="Download CSV">
                    <GetAppIcon
                      className={classes.iconDownload}
                      onClick={downloadLevel}
                    />
                  </Tooltip>
                  <span className={classes.verticalDivider} />
                  <Tooltip title="Delete Level">
                    <DeleteOutlineIcon
                      className={classes.iconDelete}
                      onClick={handleDeleteLevel}
                    />
                  </Tooltip>
                </Box>
              </div>
              <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Delete Level</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Do you want to delete the level "${data.levels[currentIndex]
                      ? data.levels[currentIndex].name
                      : ""
                      }"`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    setDeleteOpen(false);
                    setDeleteLastLevel(false)
                  }} className={classes.cancelButton}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => deleteConfirm()}
                    className={classes.dialogDeleteBtn}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Toolbar>
            <div className={classes.progress}>
              {load ? <LinearProgress color="primary" /> : null}
            </div>
            <div>
              {data?.levels[currentIndex]?.scripts.map((clip, i) => {
                let currentChar;
                const agentCharIndex = data?.sim_characters?.findIndex(char => char.type === 'agent')
                const customerCharIndex = data?.sim_characters?.findIndex(char => char.type === 'customer')
                const otherCharIndex = data?.sim_characters?.findIndex(char => char.type === 'other')

                if (data?.sim_characters[agentCharIndex]?._id === clip.sim_character_id) {
                  currentChar = (<span key={clip._id}><Tooltip key={clip._id} title={clip.script}><MicOutlinedIcon className={classes.agentMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                    {(i + 1) % 50 === 0 ? <br></br> : ''}
                  </span>)
                  if (clip.agent_recorded_audio) {
                    currentChar = (<span key={clip._id}><Tooltip key={clip._id} title={clip.script}><VolumeUpIcon className={classes.agentMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                      {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                  }
                } else if (data?.sim_characters[customerCharIndex]?._id === clip.sim_character_id) {
                  currentChar = (<span key={clip._id}><Tooltip title={clip.script}><VolumeUpIcon className={classes.customerMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                    {(i + 1) % 50 === 0 ? <br></br> : ''}
                  </span>)
                } else if (data?.sim_characters[otherCharIndex]?._id === clip.sim_character_id) {
                  currentChar = (<span key={clip._id} ><Tooltip title={clip.script}><VolumeUpIcon className={classes.otherMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                    {(i + 1) % 50 === 0 ? <br></br> : ''}
                  </span>)
                } else {
                  currentChar = (<span key={clip._id}><Tooltip title={clip.script}><VolumeUpIcon className={classes.coachMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                    {(i + 1) % 50 === 0 ? <br></br> : ''}
                  </span>)
                }
                return currentChar
              }
              )}
            </div>

            <div
              className={
                data.levels.length > 0 &&
                  data.levels[currentIndex] &&
                  data.levels[currentIndex].scripts.length === 0
                  ? classes.scriptDiv1
                  : classes.scriptDiv
              }
            >
              {data.levels.length > 0 &&
                data.levels[currentIndex] &&
                data.levels[currentIndex].scripts.length === 0 ? (
                <section className={classes.backgroundImage} />
              ) : null}
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <div
                      className="characters"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {data.levels.length > 0 &&
                        data.levels[currentIndex] &&
                        data.levels[currentIndex].scripts.map(
                          (script, index) => (
                            <Draggable
                              key={script._id}
                              draggableId={script._id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    ref={selectedClip === script._id ? clipRef : null}
                                  >
                                    <CharacterData
                                      key={index}
                                      index={index}
                                      data={script}
                                      sim={data}
                                      tabs={tabs}
                                      setTimePlayfun={setTimePlayfun}
                                      currentAudio={currentAudio}
                                      timePlay={timePlay}
                                      pauseFunction={pauseFunction}
                                      characterData={data.sim_characters}
                                      handleChange={handleChange}
                                      handleAudioChange={handleAudioChange}
                                      handleKeywordChange={handleKeywordChange}
                                      handleCheckboxChange={handleCheckboxChange}
                                      handleAudioGenerate={handleAudioGenerate}
                                      handleRadioChange={handleRadioChange}
                                      handleDeleteScript={handleDeleteScript}
                                      handleAgentRecordedAudio={
                                        handleAgentRecordedAudio
                                      }
                                      handleAutopopulateChange={
                                        handleAutopopulateChange
                                      }
                                      handleResetAutopopulate={
                                        handleResetAutopopulate
                                      }
                                      updateData={updateData}
                                      borderColor={getBackground(script.type)}
                                      levelOrder={data.levels[currentIndex].order}
                                      newSim={newSim}
                                      currentVoice={script.voice
                                        ? [script.voice] : newSim.sim_characters.filter((char) =>
                                          char._id === script.sim_character_id).map(char => char.voice)}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))
                      }
                      {
                        importLevel && data.levels[currentIndex].scripts.length === 0 ?
                          <div className={classes.loaderDiv}>
                            <CircularProgress color="primary" />
                          </div> : ''
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Grid>
          <Grid
            className={
              data.levels &&
                data.levels.length > 0 &&
                data.levels[currentIndex] &&
                data.levels[currentIndex].scripts.length === 0
                ? classes.intialCharacterMainGrid
                : classes.finalCharacterMainGrid
            }
            item
            xs={2} lg={2} xl={2} sm={2}
            key="card"
          >
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography className={classes.characterLabel}>
                Characters
              </Typography>
              {data.sim_characters.map((character, index) => (
                <Characters
                  showDetails={() => addScripts(character)}
                  bgColor={getBackground(character.type)}
                  avatar={character.avatar}
                  key={index}
                  character={character}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div >
    </Paper >
  );
};

TopTableToolbar.propTypes = {
  decreaseStep: PropTypes.func,
  data: PropTypes.object,
  increaseStep: PropTypes.func,
  addLevels: PropTypes.func,
  uploadLevels: PropTypes.func,
  newSim: PropTypes.object,
  setFlag: PropTypes.func,
};

Levels.propTypes = {
  simGenerated: PropTypes.bool,
  levels: PropTypes.array,
  Level: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  levelOrder: PropTypes.number,
  setLevelOrder: PropTypes.func,
  deleted: PropTypes.bool,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
  setdeleted: PropTypes.func,
};

CreateLevels.propTypes = {
  newSim: PropTypes.object,
  increaseStep: PropTypes.func,
  decreaseStep: PropTypes.func,
  currentSim: PropTypes.object,
  passFailCriteriaRes: PropTypes.object,
  levels: PropTypes.array,
  fetchCurrentSim: PropTypes.func,
  addLevels: PropTypes.func,
  uploadLevels: PropTypes.func,
  generateSim: PropTypes.func,
  setPassFailCriteriea: PropTypes.func,
  fetchAutoPopulate: PropTypes.func,
  tabs: PropTypes.array,
  fetchSimDetails: PropTypes.func,
};

const mapStateToProps = (state) => ({
  status: state.sim.status,
  newSim: state.sim.newSim,
  currentSim: state.sim.currentSim,
  levels: state.sim.levels,
  characters: state.sim.characters,
  passFailCriteriaRes: state.sim.passFailCriteriaRes,
  tabs: state.sim.autoPopulate,
});

const mapDispatchToProps = {
  increaseStep,
  decreaseStep,
  fetchCurrentSim,
  fetchCharacterList,
  addLevels,
  addNewLevels,
  uploadLevels,
  generateSim,
  setPassFailCriteriea,
  fetchAutoPopulate,
  fetchSimDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLevels);
