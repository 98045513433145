import axios from 'axios';
import config from '../config';
import sims from '../permissions/sims';
import admin from '../permissions/admin';
import dashboard from '../permissions/dashboard';

const getHeader = (task, type) => ({
  headers: {
    Authorization: config.getToken(),
    task,
    type,
  },
});

export const addLevelScript = async (data) => axios.post(`${config.API_BASE_URL}/api/v1/sims/scripts`, data, getHeader(null, null))
  .then((res) => res.data);

export const saveKeywords = async (data) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/keywords`,
  data,
  getHeader(null, null),
);

export const saveAvatarDetails = async (data) => axios
  .patch(
    `${config.API_BASE_URL}/api/v1/sims/avatars/update-details`,
    data,
    getHeader(null, null),
  )
  .then((res) => res.data);

export const editLevels = async (id, data) => axios.patch(
  `${config.API_BASE_URL}/api/v1/sims/editLevel/${id}`,
  data,
  getHeader(null, null),
);

export const uploadAudio = async (data) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/generate-text`,
  data,
  getHeader(null, null),
);

export const deleteScript = async (data) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/delete-script`,
  data,
  getHeader(null, null),
);

export const generateAudio = async (data) => axios
  .post(
    `${config.API_BASE_URL}/api/v1/sims/generate-voice`,
    data,
    getHeader(null, null),
  )
  .then((res) => res.data);

export const getSims = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/list?product_id=${id}`, getHeader(null, null));

export const getSimById = async (id) => axios.get(
  `${config.API_BASE_URL}/api/v1/sims/details?simId=${id}`,
  getHeader(null, null),
);

export const deleteLevel = async (data) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/delete-level`,
  data,
  getHeader(null, null),
);

export const deleteSim = async (data) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/delete`,
  data,
  getHeader(sims.sim_list.delete.task, sims.sim_list.delete.type),
);

export const duplicateSim = async (data) => axios.post(`${config.API_BASE_URL}/api/v1/sims/duplicate-sim`, data, getHeader(null, null));

export const getMissedCorrectKeywords = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/get-missed-correct-keywords?id=${id}`, getHeader(null, null));

export const deleteRole = async (id) => axios.post(
  `${config.API_BASE_URL}/api/v1/company/delete-role`,
  id,
  getHeader(admin.roles.delete.task, admin.roles.delete.type),
);

export const deleteGroup = async (id) => axios.post(
  `${config.API_BASE_URL}/api/v1/groups/delete`,
  id,
  getHeader(admin.groups.delete.task, admin.groups.delete.type),
);

export const uploadRecordedAudio = async (data) => axios.post(`${config.API_BASE_URL}/api/v1/sims/upload-audio`, data, getHeader(null, null));

export const fetchUserByGroup = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/users/user-by-group?group_id=${id}`, getHeader(null, null));
export const SaveAssessorNotes = async (id, notes, forUpdate) => axios
  .post(
    `${config.API_BASE_URL}/api/v1/sims/save-assessor-notes/${id}`,
    notes,
    getHeader(
      forUpdate
        ? dashboard.assessor_notes.update.task
        : dashboard.assessor_notes.create.task,
      forUpdate
        ? dashboard.assessor_notes.update.type
        : dashboard.assessor_notes.create.type,
    ),
  )
  .then((res) => res.data);

export const checkUserResultExist = async (payload, user_id) => axios({
  url: `${config.API_BASE_URL}/api/v1/sims/result?check_result_exist=true${user_id ? `&user_id=${user_id}` : ''
  }`,
  method: 'POST',
  ...getHeader(null, null),
  data: payload,
})
  .then((res) => res.data.result_exist)
  .catch(() => false);
export const fetchAllAssignments = async (myAssignments, tab, textSearch, task, type) => axios.get(`${config.API_BASE_URL}/api/v1/sims/excel/assignments?${textSearch ? `&status=${textSearch}` : ''}&my_assignment=${myAssignments}${tab ? `&tab=${tab}` : ''}`,
  {
    ...getHeader(task, type),
    responseType: 'arraybuffer',
  });

export const fetchScoreLists = async (textSearch, finalResult, download, filterDatas) => axios.post(`${config.API_BASE_URL}/api/v1/sims/level/listing?${textSearch ? `&status=${textSearch}` : ''}
  ${finalResult ? `&final_result=${finalResult}` : ''}${download ? `&download=${download}` : ''}`,
filterDatas,
{
  ...getHeader(null, null),
  responseType: 'arraybuffer',
});

export const downloadTemplate = async () => axios.get(`${config.API_BASE_URL}/api/v1/sims/download-template`, {
  ...getHeader(null, null),
  responseType: 'arraybuffer',
});
export const duplicateEmailCheck = async (email, id) => axios.get(
  `${config.API_BASE_URL}/api/v1/users/duplicate-email?emailId=${email}${id ? `&id=${id}` : ''
  }`,
  getHeader(null, null),
);

export const savePermission = async (data, header) => axios.post(
  `${config.API_BASE_URL}/api/v1/roles/save-permissions`,
  data,
  getHeader(header.task, header.type),
);

export const getAwsPresignedUrl = async () => axios.get(
  `${config.API_BASE_URL}/api/v1/presigned/url`,
  getHeader(null, null),
);

export const uploadRecordedVideo = async (data) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/upload-video`,
  data,
  getHeader(null, null),
);
export const AssignTemporaryRole = async (data, user) => axios.post(
  `${config.API_BASE_URL}/api/v1/roles/add-temp-role?user_id=${user}`, data,
  getHeader(admin.roles.update.task, admin.roles.update.type),
);
export const DeleteTempRole = async (role, user) => axios.post(
  `${config.API_BASE_URL}/api/v1/roles/delete-temp-role?user_id=${user}`, { role },
  getHeader(null, null),
);
export const fetchVidoeDetails = async (id) => axios.get(
  `${config.API_BASE_URL}/api/v1/sims/get-video?id=${id}`,
  getHeader(null, null),
);

export const downloadExcelLevel = async (levelId) => axios.get(`${config.API_BASE_URL}/api/v1/sims/excel/levels?level_id=${levelId}`,
  {
    ...getHeader(),
    responseType: 'arraybuffer',
  });

export const checkContinueLevel = async (simId, simLevelId, mode) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/continue`,
  { simId, simLevelId, mode },
  getHeader(null, null),
);

export const getUserScriptId = async (user_response_id, sim_script_id) => axios.post(
  `${config.API_BASE_URL}/api/v1/sims/user-script-id`,
  { user_response_id, sim_script_id },
  getHeader(null, null),
);

// forgot Password
export const forgotPassword = async (payload) => {
  axios.post(
    `${config.API_BASE_URL}/api/v1/login/forgot-password`,
    payload,
    getHeader(null, null),
  );
};
export const resetPassword = async (payload) => {
  axios.post(
    `${config.API_BASE_URL}/api/v1/login/reset-password`,
    payload,
    getHeader(null, null),
  );
};
export const removePdf = async (simId, documentId) => axios.patch(
  `${config.API_BASE_URL}/api/v1/sims/remove-document`,
  { simId, documentId },
  getHeader(null, null),
);

export const realTimeFeedback = async (data) => axios
  .post(
    `${config.API_BASE_URL}/api/v1/sims/scripts/real-time-feedback`,
    data,
    getHeader(null, null),
  )
  .then((res) => res.data);

export const DeleteUserEmployee = async (id) => axios.post(
  `${config.API_BASE_URL}/api/v1/employee/delete`,
  { id },
  getHeader(admin.employee_list.delete.task, admin.employee_list.delete.type),
);
export const getSimsData = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/simData?product_id=${id}`, getHeader(null, null));

export const updateHintText = async (data) => axios
  .post(
    `${config.API_BASE_URL}/api/v1/sims/scripts/hint-text`,
    data,
    getHeader(null, null),
  )
  .then((res) => res.data);
export const updateKeywordScore = async (data) => axios
  .post(
    `${config.API_BASE_URL}/api/v1/sims/levels/keyword-score`,
    data,
    getHeader(null, null),
  )
  .then((res) => res.data);
