import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import SimWizard from './SimWizard.jsx';
import TakeSimDetails from './TakeSimDetails.jsx';
import TakeSimResult from './TakeSimResult.jsx';
// import PropTypes from 'prop-types';

const TakeSim = () => {
  const [viewState, setViewState] = React.useState(false);

  const handleViewState = (view) => {
    setViewState(view);
  };
  const location = useLocation();
  if (location.state.viewResult === true) {
    localStorage.setItem('sims_id', localStorage.getItem('sim_id'));
    return <TakeSimResult isPost={false} />;
  }
  if (!viewState) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TakeSimDetails />
        </Grid>

        <Grid item xs={3}>
          <SimWizard handleViewState={handleViewState} />
        </Grid>
      </Grid>
    );
  }
  if (viewState === true) {
    return <TakeSimResult isPost />;
  }
  return (
    <div>
      <CircularProgress color="secondary" />
    </div>
  );
};

// TakeSim.propTypes = {
//   viewResult: Location,
// };

export default TakeSim;
