import {
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_STATUS,
  FETCH_SUB_STATUS,
  FETCH_ROLES,
  FETCH_GROUPS,
  SAVE_SETTINGS,
  UPDATE_PASSWORD,
} from './settingsType';

const initialState = {
  isLoading: false,
  data: [],
  status: null,
  userData: {
    _id: '',
    first_name: '',
    last_name: '',
    title: '',
    email_id: '',
    phone_number: '',
    profile_pic_url: '',
    status: '',
    sub_status: '',
    role: '',
    schedule: '',
    duration: '',
    employment: '',
    workplace: '',
    joined_company: '',
    probation: '',
    promoted: '',
    groups: [],
  },
  selectStatus: {},
  selectSubStatus: {},
  selectRoles: {},
  selectGroups: [],
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FAILURE:
      const status = payload.message ? payload.message.split(' ') : [];
      if (status[status.length - 1] === '403') {
        localStorage.setItem('redirectUrl', '/permissiondenied');
        window.location.href = '/permissiondenied';
        return {
          ...state,
          isLoading: false,
          permissionDenied: true,
        };
      }
      if (status[status.length - 1] === '401') {
        window.location.href = '/login';
        localStorage.clear('token');
        localStorage.clear('user');
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          status: action.payload,
        };
      }
      return {
        ...state,
        isLoading: false,
        status: action.payload,
      };
    case FETCH_STATUS:
      return {
        ...state,
        isLoading: true,
        selectStatus: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_SUB_STATUS:
      return {
        ...state,
        isLoading: true,
        selectSubStatus: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_ROLES:
      return {
        ...state,
        isLoading: true,
        selectRoles: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_GROUPS:
      return {
        ...state,
        isLoading: true,
        selectGroups: action.payload.data,
        status: action.payload.status,
      };
    case SAVE_SETTINGS:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        result: action.payload.data,
      };
    default:
      return state;
  }
};
export default reducer;
