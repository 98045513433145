export const ResultScreen = (theme) => ({
  root: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  startBtn: {
    fontSize: '0.8em',
    alignSelf: 'center',
    padding: theme.spacing(1, 2, 1, 2),
    fontWeight: 'bold',
    marginLeft: '.6rem',
  },
  modalPaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  alert: {
    position: 'absolute',
    top: '50',
    left: '10%',
    width: '80vw',
  },
  audioButtons: {
    backgroundColor: '#264173',
    color: 'white',
    margin: '0px 10px',
    padding: '5px 12px',
  },
  downloadBtn: {
    marginTop: 5,
  },
  assessor: {
    backgroundColor: '#882648',
    color: 'white',
    margin: '0px 10px',
    padding: '5px 12px',
  },
});

export default ResultScreen;
