import React from "react";
import { connect } from "react-redux";
import { Divider, Toolbar, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import moment from "moment";
import { setEditSimDetailsStep } from "../../redux/sims/actions";
import { useDetailsStyle } from "./Details";

const Details = ({ currentSim, setEditSimDetailsStep, title }) => {
  const classes = useDetailsStyle();
  if (currentSim) {
    return (
      <div>
        <Divider className={classes.divider} />
        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant="h6" component="div">
            {title}
            <br />
            <Typography
              variant="subtitle1"
              className={classes.editDetails}
              onClick={setEditSimDetailsStep}
            >
              <EditIcon className={classes.editIcon} />
              <strong>Edit Details</strong>
            </Typography>
          </Typography>
          <Typography variant="caption" className={classes.userDetails}>
            <strong>
              {" "}
              {currentSim.created_by} / {currentSim.company}{" "}
            </strong>
            <br />
            Product : <strong> {currentSim.product} </strong>| Created :{" "}
            <strong>
              {moment(new Date(currentSim.created_at)).format(
                "MMM DD YYYY h:mm A"
              )}
            </strong>
          </Typography>
        </Toolbar>
      </div>
    );
  }
  return null;
};

Details.propTypes = {
  currentSim: PropTypes.object,
  setEditSimDetailsStep: PropTypes.func,
  title: PropTypes.string,
};
const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
});
const mapDispatchToProps = { setEditSimDetailsStep };

export default connect(mapStateToProps, mapDispatchToProps)(Details);
