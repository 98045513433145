export const NewSimMenu = (theme) => ({
  root: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  stepperRoot: {
    padding: '0px',
  },
  step: {
    margin: 0,
    padding: theme.spacing(0, 5, 1, 0),
  },
  stepActive: {
    margin: 0,
    padding: theme.spacing(0, 5, 1, 0),
    borderBottom: '2px solid #882647',
  },
  tutorialBtn: {
    border: '2px solid #51668C',
    color: '#51668C',
    bottom: 5,
    fontSize: 11,
    fontWeight: 700,
    marginLeft: 'auto',
  },
});

export default NewSimMenu;
