import axios from 'axios';
import moment from 'moment';
import {
  FETCH_REQUEST_SF,
  FETCH_FAILURE_SF,
  FETCH_ALL_CASE_ID,
  FETCH_CASE_DETAILS,
  FETCH_FAILURE_SF_CASEIDS,
  SF_RESET,
  FETCH_CASE_CALLER_SUCCESS,
  FETCH_CASE_HISTORY,
  SF_DATA_LOADED,
} from './sfTypes';
import config from '../../config';
import { hexToString } from '../../utils/hexToString';

export const fetchRequest = () => ({
  type: FETCH_REQUEST_SF,
});

export const fetchFailure = (data) => ({
  type: FETCH_FAILURE_SF,
  payload: data,
});

export const fetchFailureCaseIds = (data) => ({
  type: FETCH_FAILURE_SF_CASEIDS,
  payload: data,
});

export const fetchAllCaseIdSuccess = (data) => ({
  type: FETCH_ALL_CASE_ID,
  payload: data,
});

export const fetchAllCaseId = () => async (dispatch) => {
  dispatch(fetchRequest);
  await axios.get(`${config.SF_API_BASE_URL}/api/v1/case/ids`, {
    headers: {
      Authorization: config.getToken(),
    },
  })
    .then((res) => dispatch(fetchAllCaseIdSuccess(res)))
    .catch((error) => dispatch(fetchFailureCaseIds(error)));
};

export const fetchCaseDetailsSuccess = (data) => ({
  type: FETCH_CASE_DETAILS,
  payload: data,
});

export const fetchCaseDetails = (caseNumber) => async (dispatch) => {
  dispatch(fetchRequest);
  await axios.get(`${config.SF_API_BASE_URL}/api/v1/case-details?caseNumber=${caseNumber}`, {
    headers: {
      Authorization: config.getToken(),
    },
  })
    .then((res) => dispatch(fetchCaseDetailsSuccess(res)))
    .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchCallerSuccess = (payload) => ({
  type: FETCH_CASE_CALLER_SUCCESS,
  payload,
});

export const fetchCaseHistory = (data) => ({
  type: FETCH_CASE_HISTORY,
  payload: data,
});

export const fetchHistory = (id) => async (dispatch) => {
  dispatch(fetchRequest);
  await axios(
    `${config.SF_API_BASE_URL}/api/v1/casehistory/fetch-history?id=${id}`,
  )
    .then((res) => {
      dispatch(fetchCaseHistory(hexToString(res.data.caseHistory).records));
    })
    .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchCaseCaller = (payload = 'DANANI WRIGHT-WOOTEN') => (dispatch) => {
  dispatch(fetchRequest);
  axios.get(`${config.SF_API_BASE_URL}/api/v1/case-search?callerName=${payload}`, {
    headers: {
      Authorization: config.getToken(),
    },
  })
    .then((res) => {
      const data = hexToString(res.data[0].attributes);
      const a = moment(data.CreatedDate);
      const b = moment(data.ClosedDate);
      const days = b.diff(a, 'days');
      const hours = b.diff(a, 'hours');
      const passedTime = `${days} ${days > 1 ? 'Days ' : 'Day '}${hours > 0 ? hours : ''}${hours > 0 ? (hours > 1 ? ' Hours' : ' Hour') : ''} passed`;
      const payload = {
        caseId: data.Id,
        name: data.Caller_Name_Formula_DT__c,
        caseNumber: data.CaseNumber,
        caseRecordType: data.Case_Record_Type_Formula__c,
        openedDate: moment(data.CreatedDate).format('DD MM YYYY, h:mm A'),
        passedTime,
        recentCase: data.Recent_Case__c,
        recentCaseDate: moment(data.Recent_Case_Date__c).format(
          'DD MM YYYY, h:mm A',
        ),
      };
      dispatch(fetchHistory(data.Id));
      dispatch(fetchCallerSuccess(payload));
    })
    .catch((error) => dispatch(fetchFailure(error)));
};

export const sfReset = () => ({
  type: SF_RESET,
});

export const setDataLoaded = (data) => ({
  type: SF_DATA_LOADED,
  payload: data,
});
