import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import NativeSelect from "@material-ui/core/NativeSelect";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Faculty from "../../../assets/images/badges/faculty.jpeg";
import C1 from "../../../assets/images/badges/C1.jpeg";
import Appointment from "../../../assets/images/badges/appointment.png";
import { fetchOppByName, fetchOpportunity } from "../../../redux/sims/actions";
import { BootstrapInput, useStyles } from "./OpportunitiesName";

const OpportunitiesName = ({
  fetchOppByName,
  opportunitiesByName,
  subSpecialities,
  specialities,
  fetchOpportunity,
}) => {
  useEffect(() => {
    fetchOpportunity();
  }, [fetchOpportunity]);

  const [searchWord, setSearchWord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const handleSearch = (event) => {
    const word = event.target.value;
    setSearchWord(word.toLowerCase());
  };

  const searchButton = () => {
    setCurrentPage(1);
    fetchOppByName(searchWord);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const onCheck = async () => {
    // async api call for when checkbox is selected
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = opportunitiesByName.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const classes = useStyles();
  return (
    <div>
      <Typography
        className={classes.headTypo}
        fullWidth
        mb={2}
        variant="outlined"
      >
        Search Name
      </Typography>
      <Card className={classes.searchCard}>
        <CardContent>
          <Typography variant="body1" fullWidth className={classes.typo}>
            Enter Name
          </Typography>
          <form className={classes.mainDiv} noValidate autoComplete="off">
            <TextField
              className={classes.textField}
              variant="outlined"
              size="small"
              onChange={handleSearch}
            />
          </form>
          <Typography variant="body1" className={classes.typo}>
            Select Speciality
          </Typography>
          <form className={classes.mainDiv} noValidate autoComplete="off">
            <NativeSelect
              className={classes.dropDown}
              variant="outlined"
              required
              input={<BootstrapInput />}
            >
              <option>--NONE--</option>
              {specialities.map((speciality) => (
                <option key={speciality}>{speciality}</option>
              ))}
            </NativeSelect>
          </form>
          <Typography variant="body1" className={classes.typo}>
            Select Sub-Speciality
          </Typography>
          <form className={classes.mainDiv} noValidate autoComplete="off">
            <NativeSelect
              className={classes.dropDown}
              variant="outlined"
              required
              input={<BootstrapInput />}
            >
              <option>--NONE--</option>
              {subSpecialities.map((speciality) => (
                <option key={speciality}>{speciality}</option>
              ))}
            </NativeSelect>
          </form>
          <Button
            variant="outlined"
            className={classes.searchButton}
            onClick={searchButton}
          >
            Search
          </Button>
        </CardContent>
      </Card>
      <Card className={classes.tableCard}>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    SELECT
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    NAME
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    CENTERS
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    CENTER LINKS
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    GENDER
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    SPECIALITY
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    BADGES
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    COMMENTS
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.tableHeadCells}
                    align="center"
                  >
                    MAPS
                  </TableCell>
                </TableRow>
              </TableHead>
              {currentRows.length > 0 &&
                currentRows.map((data, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableBody key={data.id}>
                      <TableRow role="checkbox" tabIndex={-1} key={data.id}>
                        <TableCell padding="none" align="center">
                          <Checkbox
                            color="secondary"
                            size="small"
                            className={classes.checkboxStyle}
                            onClick={() => onCheck(data)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableBodyCells}
                          align="center"
                        >
                          {data.name}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.bodyProtocolCell}
                          align="center"
                        >
                          {data.centers}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableBodyCells}
                          align="center"
                        >
                          {data.centersLinks}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.bodyProtocolCell}
                          align="center"
                        >
                          {data.gender}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.bodyProtocolCell}
                          align="center"
                        >
                          {data.speciality}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableBodyCells}
                          align="center"
                        >
                          <div
                            style={{
                              display: "grid",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={Appointment}
                              width="25"
                              height="25"
                              alt="Appointment"
                            />
                            <img
                              src={Faculty}
                              width="25"
                              height="25"
                              alt="faculty"
                            />
                            <img src={C1} width="25" height="25" alt="C1" />
                          </div>
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.bodyProtocolCell}
                          align="center"
                        >
                          {data.comments}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableBodyCells}
                          align="center"
                        >
                          {data.map}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
            <Button
              variant="outlined"
              className={classes.searchButton}
              onClick={handlePrev}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              className={classes.searchButton}
              onClick={handleNext}
            >
              Next
            </Button>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

OpportunitiesName.propTypes = {
  fetchOpportunity: PropTypes.func,
  fetchOppByName: PropTypes.func,
  opportunitiesByName: PropTypes.array,
  subSpecialities: PropTypes.array,
  specialities: PropTypes.array,
};

const mapStateToProps = (state) => ({
  opportunitiesByName: state.sim.opportunitiesByName,
  subSpecialities:
    state.sim.loadedOpportunities.length > 0
      ? state.sim.loadedOpportunities[0].tabContent[0].filterSubSpeciality
      : [],
  specialities:
    state.sim.loadedOpportunities.length > 0
      ? state.sim.loadedOpportunities[0].tabContent[0].filterSpeciality
      : [],
});

const mapDispatchToProps = {
  fetchOppByName,
  fetchOpportunity,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesName);
