export const AssignmentTable = (theme) => ({
  thead: {
    backgroundColor: '#e1e1e1',
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .MuiTableCell-root': {
      padding: 12,
    },
    whiteSpace: 'nowrap',
    '-webkit-backface-visibility': 'hidden;',
  },
  tbody: {
    '-webkit-backface-visibility': 'hidden;',
    '& .MuiTableCell-root': {
      padding: 12,
    },
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '0.7rem',
  },
  subHeading: {
    fontWeight: 'bold',
    fontSize: '0.7rem',
    minWidth: '198px',
    // maxWidth: "6rem",
    whiteSpace: 'pre-wrap',
  },
  cancelButton: {
    color: '#313387',
  },
  bold: {
    fontWeight: 'bold',
  },
  built: {
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  tableRow: {
    cursor: 'pointer',
  },
  edit: {
    float: 'left',
  },
  viewNote: {
    color: '#8A2A4B',
    float: 'left',
    fontSize: '1.38rem',
    marginBottom: 3,
  },
  delete: {
    float: 'right',
  },
  disabledDelete: {
    filter: 'grayscale(100%)',
    float: 'right',
  },
  name: {
    color: '#882e47',
    fontWeight: 600,
  },
  overflow: {
    width: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  tableSpan: {
    borderBottom: '1px solid #6d6c6c',
    paddingBottom: 2,
    color: '#212121',
  },
  rfrTableRow: {
    cursor: 'pointer',
    background: '#f8dee7',
  },
  failedTableRow: {
    cursor: 'pointer',
    color: 'red',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
  button: {
    pointerEvents: 'auto !important',
    cursor: 'pointer',
    padding: 5,
    color: 'white',
    fontSize: 12,
    '& .MuiButton-endIcon': {
      marginLeft: 2,
    },
  },
  viewRetryButton: {
    pointerEvents: 'auto !important',
    cursor: 'pointer',
    padding: 5,
    color: 'white',
    fontSize: 10,
  },
  completedActionCell: {
    // maxWidth: "8rem",
    // minWidth: "6rem",
  },
  assignmentActionCell: {
    // maxWidth: "8rem",
    // minWidth: "4rem",
  },
  adminNote: {
    // maxWidth: "10rem",
    // minWidth: "10rem",
    pointerEvents: 'auto !important',
  },
  cell: {
    pointerEvents: 'none',
  },
  viewIcon: {
    pointerEvents: 'auto !important',
    cursor: 'pointer',
    color: '#882847',
    marginLeft: '.6rem',
  },
  dialog: {
    minHeight: '39vh',
    maxHeight: '26vh',
    maxWidth: '26rem',
  },
  backdrop: {
    zIndex: 1,
    position: 'inherit',
  },
  editSpan: {
    float: 'right',
  },
  dialogButton: {
    fontWeight: 600,
    padding: '3px 11px',
    backgroundColor: '#F8DEE7 !important',
    color: '#A55B73',
    margin: '8px 5px',
  },
  textArea: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    backgroundColor: '#F1F1F1',
    marginTop: '-18px',
    borderRadius: '4px',
    height: '21vh',
  },
  noData: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    textAlign: 'center',
  },
  plus: {
    fontSize: 16,
    fontWeight: 600,
    paddingRight: 5,
  },
  noAssign: {
    marginBottom: 0,
  },
  noAssignPara: {
    fontSize: 12,
  },
  addButton: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    height: 34,
  },
  tableContainer: {
    transform: 'rotateX(180deg)',
  },
  table: {
    transform: 'rotateX(180deg)',
  },
});

export default AssignmentTable;
