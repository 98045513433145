import React, { useEffect, useState } from 'react';
import {
  Paper, Select, Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DehazeIcon from '@material-ui/icons/Dehaze';
import PersonIcon from '@material-ui/icons/Person';
import {Tooltip} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ScoreFilterStyle from './ScoreFilters';
import { getSimsData } from '../../services/result.service';
import {
  fetchSelectGroups,
  fetchEmployees,
} from '../../redux/admin/action';
import {
  fetchProductList,
} from '../../redux/sims/actions';

const ScoreFilters = ({
  fetchSelectGroups, selectGroups, fetchEmployees, employees, fetchProductList,
  products, productChange,
}) => {
  const useStyles = makeStyles((theme) => ScoreFilterStyle(theme));
  const classes = useStyles();
  const [openGroup, setOpenGroup] = React.useState(false);
  const [openEmployee, setOpenEmployee] = useState(false);
  const [openSims, setOpenSims] = useState(false);
  const [sims, setSims] = useState([]);
  const [state, setState] = useState({
    product: '',
    groups: [],
    members: [],
    sims: [],
  });
  useEffect(() => {
    fetchSelectGroups();
    fetchEmployees();
    fetchProductList();
  }, [fetchSelectGroups, fetchEmployees, fetchProductList]);

  useEffect(() => {
    if (products.length) {
      setState({
        ...state,
        product: products[0]._id,
      });
      getSimsData(products[0]._id)
        .then((res) => {
          res.data.map((sim) => {
            sim.open = false;
            return sim;
          });
          setSims(res.data);
        });
    }
    // eslint-disable-next-line
  }, [products]); 
  const handleGroupClick = () => {
    setOpenGroup(!openGroup);
  };
  const handleEmpClick = () => {
    setOpenEmployee(!openEmployee);
  };
  const handleSimClick = () => {
    setOpenSims(!openSims);
  };
  const handleChangeProduct = async (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    state.product = e.target.value;
    await getSimsData(e.target.value)
      .then((res) => {
        setSims(res.data);
      });
    productChange(state);
  };
  const handleCheckboxClicks = (e) => {
    if (e.target.name === 'members') {
      const index = state.members.indexOf(e.target.value);
      if (index > -1) {
        state.members.splice(index, 1);
      } else {
        state.members.push(e.target.value);
      }
    } else if (e.target.name === 'groups') {
      const index = state.groups.indexOf(e.target.value);
      if (index > -1) {
        state.groups.splice(index, 1);
      } else {
        state.groups.push(e.target.value);
      }
    }
    productChange(state);
  };
  const handleCheckboxSimClicks = (sim) => {
    sims.filter((simm, i) => {
      if (simm._id === sim._id) {
        simm.open = !(simm.open);
      }
      return simm;
    });
    let index = -1;
    state.sims.filter((simz, i) => {
      if (simz.sim_id === sim._id) {
        index = i;
      }
      return simz;
    });
    if (index > -1) {
      state.sims.splice(index, 1);
    } else {
      state.sims.push({ sim_id: sim._id });
    }
    productChange(state);
    setSims(sims);
  };
  const handleCheckboxLevelClicks = (level) => {
    state.sims.map((sim, i) => {
      if (sim.sim_id === level.sim_id) {
        state.sims[i].levels = state.sims[i].levels === undefined ? [] : state.sims[i].levels;
        if (state.sims[i].levels.length) {
          const index = state.sims[i].levels.indexOf(level._id);
          if (index > -1) {
            state.sims[i].levels.splice(index, 1);
          } else {
            state.sims[i].levels.push(level._id);
          }
        } else {
          state.sims[i].levels.push(level._id);
        }
      }
      return sim;
    });
    productChange(state);
  };
  return (
    <Paper elevation={0} className={classes.scroll}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem>
          <ListItemIcon className={classes.toolIcon}>
            <DehazeIcon />
          </ListItemIcon>
          <ListItemText className={classes.textool}><b>Tool</b></ListItemText>
        </ListItem>
        <ListItem>
          <Select
            fullWidth
            variant="outlined"
            className={classes.select}
            value={state.product}
            name="product"
            onChange={handleChangeProduct}
            native
          >
            {products.length && products.map(
              (product) => (
                <option className={classes.option} key={product._id} value={product._id}>
                  {product.name}
                </option>
              ),
            )}
          </Select>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.toolIcon}>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText className={classes.texts}><b>Groups</b></ListItemText>
        </ListItem>
        { // eslint-disable-next-line
        selectGroups.length ? selectGroups.map((group, index) => {
          if (index < 6 || openGroup) {
            return (
              <ListItem dense button key={group._id} className={classes.listItem}>
                <ListItemIcon className={classes.toolIcon}>
                  <Checkbox
                    edge="start"
                    name="groups"
                    value={group._id}
                    className={classes.chkbox}
                    onChange={handleCheckboxClicks}
                  />
                </ListItemIcon>
                <ListItemText>{group.name}</ListItemText>
              </ListItem>
            );
          }
        }) : ''
        }
        {selectGroups.length > 6
          ? openGroup
            ? (
              <ListItemText onClick={handleGroupClick}>
                <span className={classes.showHide}>{`Hide all groups (${selectGroups.length})`}</span>
              </ListItemText>
            )
            : (
              <ListItemText onClick={handleGroupClick}>
                <span className={classes.showHide}>
                  {`Show all groups (${selectGroups.length})`}
                </span>
              </ListItemText>
            )
          : ''}
      </List>
      <ListItem>
        <ListItemIcon className={classes.toolIcon}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText className={classes.texts}><b>Members</b></ListItemText>
      </ListItem>
      { // eslint-disable-next-line
      employees.length ? employees.map((emp, index) => {
        if (index < 6 || openEmployee) {
          return (
            <ListItem dense button key={emp._id} className={classes.listItem}>
              <ListItemIcon className={classes.toolIcon}>
                <Checkbox
                  name="members"
                  edge="start"
                  value={emp._id}
                  className={classes.chkbox}
                  onChange={handleCheckboxClicks}
                />
              </ListItemIcon>
              <ListItemText>
                {`${emp.first_name} ${emp.last_name}`}
              </ListItemText>
            </ListItem>
          );
        }
      }) : ''
      }
      {employees.length > 6
        ? openEmployee
          ? (
            <ListItemText onClick={handleEmpClick}>
              <span className={classes.showHide}>{`Hide all members (${employees.length})`}</span>
            </ListItemText>
          )
          : (
            <ListItemText onClick={handleEmpClick}>
              <span className={classes.showHide}>{`Show all members (${employees.length})`}</span>
            </ListItemText>
          )
        : '' }
      <ListItem>
        <ListItemIcon className={classes.toolIcon}>
          <DehazeIcon />
        </ListItemIcon>
        <ListItemText className={classes.textool}><b>SmartySim</b></ListItemText>
      </ListItem>
      {
      // eslint-disable-next-line
      sims.length ? sims.map((sim, index) => {
        if (index < 6 || openSims) {
          return (
            <div key={sim._id}>
              <ListItem dense button className={classes.listItem}>
                <ListItemIcon className={classes.toolIcon}>
                  <Checkbox
                    edge="start"
                    value={sim._id}
                    onChange={() => handleCheckboxSimClicks(sim)}
                    className={classes.chkbox}
                  />
                </ListItemIcon>
                <Tooltip title={ sim.title === 0 ? 'None' : sim.title}>
                  <ListItemText>
                    {sim?.title?.length > 22
                      ? `${sim.title.slice(0, 22)}  ...`
                      : sim.title}
                  </ListItemText>
                </Tooltip>
              </ListItem>
              {
                sim.simLevel.length > 1
                  ? (
                    <Collapse in={sim.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {
                          sim.simLevel.map((lvl) => (
                            <ListItem key={lvl._id} button className={classes.nested}>
                              <ListItemIcon className={classes.toolIcon}>
                                <Checkbox
                                  edge="start"
                                  value={lvl._id}
                                  onChange={() => handleCheckboxLevelClicks(lvl)}
                                  className={classes.chkbox}
                                />
                              </ListItemIcon>
                              <ListItemText className={classes.font}>
                                {lvl.name}
                              </ListItemText>
                            </ListItem>
                          ))
                        }
                      </List>
                    </Collapse>
                  ) : ''
              }

            </div>
          );
        }
      }) : ''
}
      {sims.length > 6
        ? openSims
          ? (
            <ListItemText onClick={handleSimClick}>
              <span className={classes.showHide}>
                {`Hide all sims (${sims.length})`}
              </span>
            </ListItemText>
          )
          : (
            <ListItemText onClick={handleSimClick}>
              <span className={classes.showHide}>{`Show all sims (${sims.length})`}</span>
            </ListItemText>
          )
        : '' }
    </Paper>

  );
};
ScoreFilters.propTypes = {
  selectGroups: PropTypes.array,
  fetchSelectGroups: PropTypes.func,
  fetchEmployees: PropTypes.func,
  fetchProductList: PropTypes.func,
  employees: PropTypes.array,
  products: PropTypes.array,
  productChange: PropTypes.func,
};
const mapStateToProps = (state) => ({
  selectGroups: state.admin.selectGroups,
  employees: state.admin.employees ? state.admin.employees : [],
  products: state.sim.products ? state.sim.products : [],
});
const mapDispatchToProps = {
  fetchSelectGroups,
  fetchEmployees,
  fetchProductList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreFilters);
