import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import {
  makeStyles,
} from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import StyledLink from '../common/StyledLink';
import { permissionCheck } from '../../utils/permissionCheck';
import AdminsPermissions from '../../permissions/admin';
import GroupsStyle from './Groups';

const Groups = ({
  groupList, total, handleNext,
}) => {
  const handleChange = () => {
    handleNext();
  };
  const useStyles = makeStyles((theme) => GroupsStyle(theme));
  const classes = useStyles();
  return (
    <InfiniteScroll
      dataLength={groupList.length}
      next={handleChange}
      hasMore={groupList.length < total}
      loader={<p>Loading...</p>}
    >
      <TableContainer component={Paper}>
        <Table aria-label="Product Access Table" className={classes.root}>
          <TableHead className={classes.thead}>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  NAME
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  DESCRIPTION
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  CREATED
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  LAST UPDATED
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  GROUP OWNER
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  NO. OF MEMBERS
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  IconComponent={ArrowDropDownIcon}
                >
                  CREATED BY
                </TableSortLabel>
              </TableCell>
              {
                 permissionCheck(
                   AdminsPermissions.groups.update.task,
                   AdminsPermissions.groups.update.type,
                 )
                   ? (
                     <TableCell className={classes.heading}>
                       EDIT
                     </TableCell>
                   )
                   : null
              }

            </TableRow>
          </TableHead>
          <TableBody>
            {groupList.length > 0 && groupList.map((row) => (
              <TableRow
                key={row._id}
        //       onClick={() => handleRowClick(row)}
                className={classes.tableRow}
              >
                <TableCell className={classes.bold}>
                  {row.name}
                </TableCell>
                <TableCell className={classes.lastNameCell}>
                  {row.description}
                </TableCell>
                <TableCell className={classes.lastNameCell}>
                  {moment(row.created_at).format('MM-DD-YYYY')}
                </TableCell>
                <TableCell className={classes.lastNameCell}>
                  {moment(row.updated_at).format('MM-DD-YYYY')}
                </TableCell>
                <TableCell>
                  <div className={classes.built}>
                    <Avatar
                     // src={row.group_owner.profile_pic ? row.group_owner.profile_pic : ''}
                      alt="profile"
                      style={{ marginRight: '1em' }}
                      className={classes.small}
                    />
                    <div className={classes.firstNameCell}>{row.owner}</div>
                  </div>
                </TableCell>
                <TableCell align="center" className={classes.no_of_members}>
                  {row.member_count}
                </TableCell>
                <TableCell align="left" className={classes.lastNameCell}>{row.created_by}</TableCell>
                {
                  permissionCheck(
                    AdminsPermissions.groups.update.task,
                    AdminsPermissions.groups.update.type,
                  )
                    ? (
                      <TableCell>
                        <StyledLink to={{
                          pathname: '/admin',
                          state: row,
                        }}
                        >
                          <EditOutlinedIcon className={classes.editIcon} fontSize="small" />
                        </StyledLink>
                      </TableCell>
                    )
                    : null
                }

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

Groups.propTypes = {
  groupList: PropTypes.array,
  total: PropTypes.number,
  handleNext: PropTypes.func,
};

export default Groups;
