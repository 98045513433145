const drawerWidth = 82;

export const ResponsiveDrawer = (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    boxShadow: '0px 0px 0px 0px',
    backgroundColor: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    justifyContent: 'center',
    width: 56,
    height: 60,
    margin: 'auto',
    marginBottom: '5px',
  },
  menuIcon: {
    color: 'white',
    margin: 'auto',
  },
  adminIcon: {
    margin: 'auto',
  },
  menuText: {
    padding: 5,
    fontSize: '0.5rem',
    textAlign: 'center',
    color: 'white',
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  customToolbar: {
    display: 'relative',
    height: '30px',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#26344d',
    color: 'white',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 2, 3, 2),
    backgroundColor: '#f6f6f6',
    minHeight: '100vh',
  },
  divider: {
    backgroundColor: '#e1e1e1',
  },
  logo: {
    width: drawerWidth,
    textAlign: 'center',
  },
  buttonSqr: {
    padding: '5px',
    minHeight: 0,
    minWidth: 0,
  },
  expand: {
    background: '#f1c5d4',
    color: '#82203b',
  },
  button: {
    color: '#82203b',
    fontSize: 12,
  },
});

export default ResponsiveDrawer;
