import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core';
import Common from './routes/common';
import Footer from './components/common/Footer.jsx';

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(136,38,71)',
      light: '#bb5672',
      dark: '#560020',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f8dee7',
      light: '#ffffff',
      dark: '#c5acb5',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Proxima Nova',
  },
});
function App() {
  return (
    <div>
      <MuiThemeProvider theme={globalTheme}>
        <Common />
        <Footer />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
