import {
  FETCH_FAILURE_SF,
  FETCH_REQUEST_SF,
  FETCH_ALL_CASE_ID,
  FETCH_CASE_DETAILS,
  FETCH_FAILURE_SF_CASEIDS,
  SF_RESET,
  FETCH_CASE_HISTORY,
  FETCH_CASE_CALLER_SUCCESS,
  SF_DATA_LOADED,
} from './sfTypes';

const initialState = {
  caseIds: [],
  status: null,
  isLoading: false,
  caseDetails: {},
  callerCaseDetails: {},
  caseHistory: [],
  dataLoaded: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REQUEST_SF:
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case FETCH_FAILURE_SF:
      const status = payload.message ? payload.message.split(' ') : [];
      if (status[status.length - 1] === '403') {
        localStorage.setItem('redirectUrl', '/permissiondenied');
        window.location.href = '/permissiondenied';
        return {
          ...state,
          isLoading: false,
          permissionDenied: true,
        };
      }
      if (status[status.length - 1] === '401') {
        window.location.href = '/login';
        localStorage.clear('token');
        localStorage.clear('user');
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          status: action.payload,
        };
      }
      return {
        ...state,
        isLoading: false,
        status: action.payload,
      };

    case FETCH_FAILURE_SF_CASEIDS:
      return {
        ...state,
        isLoading: false,
        status: null,
      };
    case FETCH_ALL_CASE_ID:
      return {
        ...state,
        isLoading: false,
        caseIds: payload.data,
        status: payload.status,
      };
    case FETCH_CASE_DETAILS:
      return {
        ...state,
        isLoading: false,
        caseDetails: payload.data,
        status: payload.status,
      };
    case SF_RESET:
      return initialState;
    case FETCH_CASE_CALLER_SUCCESS:
      return {
        ...state,
        callerCaseDetails: action.payload,
      };
    case FETCH_CASE_HISTORY:
      return {
        ...state,
        isLoading: false,
        caseHistory: action.payload,
        status: action.payload.status,
      };
    case SF_DATA_LOADED:
      return {
        ...state,
        dataLoaded: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
