import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  CHECK_AUTHORIZATION,
  USER_LOGOUT,
  FETCH_FAILURE,
  SAVE_PERMISSIONS,
  UPDATE_LOGIN_STATUS,
  REGISTRATION_FAILURE,
  UPDATE_ROLE,
} from './authTypes';

const initialState = {
  isAuth: false,
  user: {},
  permissions: [],
  isLoading: false,
  errMessage: '',
  status: null,
  loginStatus: null,
  data: [],
  registrationStatus: null,
  registrationMessage: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      localStorage.setItem('user', JSON.stringify(payload.user));
      return {
        ...state,
        isAuth: true,
        isLoading: false,
        user: payload.user,
        permissions: payload.permissions,
        loginStatus: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        errMessage: payload.message,
        loginStatus: payload.success,
      };
    case CHECK_AUTHORIZATION:
      if (!JSON.parse(localStorage.getItem('user')) || !localStorage.getItem('token')) {
        return {
          ...state,
          isAuth: false,
        };
      }
      return {
        ...state,
        isAuth: true,
        user: JSON.parse(localStorage.getItem('user')),
      };

    case USER_LOGOUT:
      localStorage.clear('token');
      localStorage.clear('user');
      localStorage.clear('backToDashboard');
      return {
        ...state,
        isAuth: false,
      };
    case FETCH_FAILURE:
      const status = payload.message ? payload.message.split(' ') : [];
      if (status[status.length - 1] === '403') {
        localStorage.setItem('redirectUrl', '/permissiondenied');
        window.location.href = '/permissiondenied';
        return {
          ...state,
          isLoading: false,
          permissionDenied: true,
        };
      }
      if (status[status.length - 1] === '401') {
        window.location.href = '/login';
        localStorage.clear('token');
        localStorage.clear('user');
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          data: payload.data,
          status: payload,
        };
      }
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        status: status[status.length - 1],
      };
    case SAVE_PERMISSIONS:
      return {
        ...state,
        permissions: payload.permissions,
      };
    case UPDATE_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: payload,
        registrationStatus: payload,
      };
    case REGISTRATION_FAILURE:
      return {
        ...state,
        registrationMessage: payload.message ? payload.message : '',
        registrationStatus: payload.success,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        user: {
          ...state.user,
          role: payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
