const sims = {
  sim_list: {
    view: {
      task: 'Sim Admin access',
      type: 'view',
    },
    create: {
      task: 'Sim Admin access',
      type: 'create',
    },
    update: {
      task: 'Sim Admin access',
      type: 'update',
    },
    delete: {
      task: 'Sim Admin access',
      type: 'delete',
    },
  },
  assignment: {
    view: {
      task: 'Sim Assignment access',
      type: 'view',
    },
    create: {
      task: 'Sim Assignment access',
      type: 'create',
    },
    delete: {
      task: 'Sim Assignment access',
      type: 'delete',
    },
  },
};
export default sims;
