import { makeStyles } from "@material-ui/core/styles";
import WhiteDottedBackground from "../../../assets/images/takeSim/whitedotted.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "1.5%",
    padding: theme.spacing(1, 2.5),
  },
  heading: {
    fontSize: 13,
    color: "blue",
    marginLeft: "76%",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightRegular,
  },
  userDetails: {
    width: "100%",
    backgroundColor: "#264172",
    padding: theme.spacing(2, 3),
    borderRadius: "2%",
  },
  userImage: {
    display: "inline-block",
    width: "4rem",
    height: "4rem",
    float: "left",
    marginRight: "1.5rem",
    borderRadius: "8%",
  },
  name: {
    color: "#fff",
    fontSize: "1.5em",
  },
  details: {
    color: "#fff",
    fontSize: "0.8em",
    borderRadius: "2%",
  },
  divider: {
    backgroundColor: "#39527F",
    margin: theme.spacing(2, 0),
  },
  select: {
    backgroundColor: "#3D588A",
    padding: ".5rem 0 .5rem 1rem",
    color: "#fff",
    borderRadius: "2%",
    fontSize: "1em",
    "& .MuiSelect-icon": {
      color: "#fff",
    },
  },
  timeDiv: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 0),
    width: "100%",
    backgroundColor: "#162C55",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "2%",
  },
  circProg: {
    textAlign: "center",
    backgroundImage: `url(${WhiteDottedBackground})`,
    backgroundSize: "cover",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 0),
    width: "100%",
    backgroundColor: "#162C55",
    height: "13rem",
    borderRadius: "2%",
  },
  accuracyPaper: {
    width: "100%",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2.5),
  },
  accuracySection: {
    width: "100%",
    backgroundColor: "#F1F5FB",
    padding: "1.5rem",
    textAlign: "center",
    lineHeight: "2.5rem",
    borderRadius: "2%",
    marginBottom: "1rem",
    fontWeight: 500,
    fontSize: ".9rem",
  },
  numbers: {
    width: "100%",
    fontWeight: [700],
  },
  numberColor: {
    color: "#989999",
    fontSize: "1.7em",
    marginTop: "1em",
  },
  blackColor: {
    color: "#989999",
  },
  priorityIcon: {
    float: "left",
    background: "#B5E8D1",
    color: "#037240",
    borderRadius: 5,
    width: 32,
    height: 32,
    margin: 14,
    padding: 3,
    marginBottom: "3rem",
  },
  agent: {
    borderLeft: "3px solid #7B95C5",
    backgroundColor: "#EEF3FC",
    width: "100%",
    borderRadius: "2%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  customer: {
    borderLeft: "3px solid #882647",
    backgroundColor: "#FCF1F5",
    width: "100%",
    borderRadius: "2%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  patientData: {
    borderLeft: "3px solid #882647",
    backgroundColor: "#F6F6F6",
    width: "100%",
    borderRadius: "2%",
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1.5),
  },
  patientDataMatched: {
    borderLeft: "3px solid #2CB174",
    backgroundColor: "#F6F6F6",
    width: "100%",
    borderRadius: "2%",
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1.5),
  },
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  success: {
    fontSize: "16px",
    color: "#2CB174",
    marginRight: "6px",
  },
  failed: {
    fontSize: "16px",
    color: "#882647",
    marginRight: "6px",
  },

  scriptTypo: {
    paddingTop: theme.spacing(1.5),
  },
  bold: {
    fontWeight: [700],
    wordBreak: "break-all",
  },

  script: {
    fontSize: "0.8em",
  },
  highlight: {
    backgroundColor: "#ADBBD4",
  },
  checkCircleIcon: {
    color: "#2CB174",
    height: 15,
    marginBottom: -5,
  },
  checkCircle: {
    float: "right",
    color: "#2CB174",
    fontSize: "0.7em",
  },
  circularProg: {
    "& .MuiCircularProgress-svg": {
      color: "#09C68F",
    },
  },
  prog: {
    marginTop: "1rem",
  },
  progTypo: {
    fontSize: "1rem",
  },
  keyTypo: {
    fontSize: "0.9rem",
  },
  breadcrumbs: {
    textDecoration: "underline !important",
    color: "#3a3939e3",
    fontSize: 15,
    fontWeight: 600,
  },
  mainBreadBlock: {
    marginTop: -15,
    marginBottom: 10,
  },
  previewLoader: {
    color: "white",
    width: "10px !important",
    height: "10px !important",
    marginTop: 3,
  },
  preview: {
    float: "right",
    marginTop: -20,
    color: "white",
    background: "#264173",
    marginRight: 20,
  },
  videoModal: {
    border: "2px solid black",
  },
  closeButton: {
    color: "white",
    float: "right",
    marginTop: -6,
  },
  previewDialogTitle: {
    color: "white",
    background: "#264173",
  },
  previewDialog: {
    textAlign: "center",
    margin: 10,
  },
  retake: {
    float: "right",
    marginTop: -20,
    marginRight: 35,
  },
  dialog: {
    minHeight: "39vh",
    maxHeight: "26vh",
    maxWidth: "30vw",
  },
  agentList: {
    marginBottom: 10,
  },
  accordian: {
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-root": {
      minHeight: 25,
      height: 25,
      padding: 0,
    },
  },
  expand: {
    height: 35,
    width: 32,
  },
  editSpan: {
    float: "right",
  },
  button: {
    fontWeight: 600,
    padding: "3px 11px",
    backgroundColor: "#F8DEE7 !important",
    color: "#A55B73",
    margin: "8px 5px",
  },
  textArea: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "black",
    },
    backgroundColor: "#F1F1F1",
    marginTop: "-18px",
    borderRadius: "4px",
    height: "21vh",
  },
  alertBox: {
    marginTop: -14,
    marginBottom: 27,
    backgroundColor: "#600727",
  },
  girdContainer: {
    height: "fit-content",
    minHeight: "100vh"
  },
  keywordAccuracy: {
    marginTop: '1em',
    fontSize: '1.7em',
    fontWeight: 500,
  }
}));
