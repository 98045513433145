export const CreateGroup = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(100),
      height: theme.spacing(100),
    },
  },
  formControl: {
    '& .MuiInputBase-input': {
      backgroundColor: '#fff',
      fontSize: 13,
    },
    '& .MuiTypography-body1': {
      fontSize: 13,
      fontWeight: '500',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.1rem',
    },
    width: '78%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
    fontSize: 13,
    fontWeight: '500',
  },
  label: {
    padding: theme.spacing(0, 2, 1, 0),
    marginTop: 20,
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  initialLabel: {
    padding: '16px 16px 8px 0px',
    marginTop: 0,
    color: '#000',
    fontSize: 'small',
    fontWeight: '500',
  },
  mainTitle: {
    padding: '5px 0 8px 28px',
    color: '#000',
    fontSize: 20,
    fontWeight: '700',
  },
  paper: {
    height: '73vh',
  },
  cancelButton: {
    color: '#313387',
  },
  errorAlert: {
    backgroundColor: '#600727',
  },
  saveBtn: {
    float: 'right',
    width: '40%',
    marginTop: 15,
    marginRight: 15,
  },
  description: {
    width: '100%',
  },
  delete: {
    color: '#FF0000',
  },
  deleteDisable: {
    color: '#FF0000',
    opacity: '0.5',
    cursor: 'none',
  },
  deleteBtn: {
    float: 'right',
    marginRight: 20,
    marginTop: 5,
  },
  deleteIcon: {
    color: '#6b6a6a',
  },
  memberTitle: {
    padding: '3px 0px',
    fontWeight: 600,
    fontSize: 18,
  },
});

export default CreateGroup;
