/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { addGroupToUser } from '../../redux/admin/action';

const InputWrapper = styled('div')`
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  flex-wrap: wrap;
  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #4B6AA3;
  border: 1px solid #e8e8e8;
  color: #fff;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 1px 3px 0px 10px;
  font-size: 12px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 8px;
    cursor: pointer;
    padding: 4px;
    background: #8197BF;
    margin: 6px;
    border-radius: 2px;
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const TagInput = ({
  groupUsers, employees, prop, addGroupToUser,
}) => {
  const filteredArray = groupUsers && groupUsers.users
    ? employees.filter((user) => groupUsers.users.includes(user._id)) : [];
  // eslint-disable-next-line array-callback-return
  filteredArray.map((user) => {
    user.delete = true;
  });
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: filteredArray,
    multiple: true,
    options: employees,
    getOptionLabel: (option) => `${option.first_name} ${option.last_name}`,
  });
  const click = (option) => {
    if (option.delete) {
      option.delete = false;
      const data = {
        _id: option._id,
        group_id: prop._id,
        delete: true,
      };
      addGroupToUser(data);
    } else {
      option.delete = true;
      const data = {
        _id: option._id,
        group_id: prop._id,
      };
      addGroupToUser(data);
    }
  };
  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} name="members">
            {value.map((option, index) => (
              <Tag
                label={`${option.first_name} ${option.last_name}`}
                {...getTagProps({ index })}
                onClickCapture={() => click(option)}
              />
            ))}

            <input {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })} onClickCapture={() => click(option)}>
                <span>{`${option.first_name} ${option.last_name}`}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
};

TagInput.propTypes = {
  groupUsers: PropTypes.object,
  employees: PropTypes.array,
  prop: PropTypes.object,
  addGroupToUser: PropTypes.func,
};
const mapStateToProps = (state) => ({
  group: state.admin.group,
  employees: state.admin.employees ? state.admin.employees : [],
});
const mapDispatchToProps = { addGroupToUser };

export default connect(mapStateToProps, mapDispatchToProps)(TagInput);
